import axiosInstance from "../services/AxiosInstance";

export function getRequestDemoDetails(requestDemoId) {
	return axiosInstance.get(`admin/requestDemo/${requestDemoId}`);
}

export function getRequestDemos() {
	return axiosInstance.get(`admin/requestDemos`);
}

export function createRequestDemo(requestDemoData) {
	return axiosInstance.post(`admin/requestDemo`, requestDemoData);
}

export function updateRequestDemo(requestDemo, requestDemoId) {
	return axiosInstance.put(`admin/requestDemo/${requestDemoId}`, requestDemo);
}

export function deleteRequestDemo(requestDemoId) {
	return axiosInstance.delete(`admin/requestDemo/${requestDemoId}`);
}
