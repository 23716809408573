import {
	CONFIRMED_GET_EXPENSECATEGORY_DETAILS,
	CONFIRMED_CREATE_EXPENSECATEGORY_ACTION,
	CONFIRMED_GET_EXPENSECATEGORIES,
	CONFIRMED_EDIT_EXPENSECATEGORY_ACTION,
	CONFIRMED_DELETE_EXPENSECATEGORY_ACTION,
	LOADING,
} from "../actions/ExpenseCategoryTypes";

const initialState = {
	expenseCategories: [],
	expenseCategory: {},
};

export default function ExpenseCategoryReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_EXPENSECATEGORY_DETAILS) {
		return {
			...state,
			expenseCategory: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_EXPENSECATEGORY_ACTION) {
		const expenseCategories = [...state.expenseCategories.expenseCategorys];
		const expenseCategoryIndex = expenseCategories.findIndex(
			(expenseCategory) => expenseCategory.id === actions.payload
		);

		expenseCategories.splice(expenseCategoryIndex, 1);

		const expenseCategorys = expenseCategories;

		return {
			...state,
			expenseCategories: { expenseCategorys },
		};
	}

	if (actions.type === CONFIRMED_EDIT_EXPENSECATEGORY_ACTION) {
		return {
			...state,
			expenseCategories: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_EXPENSECATEGORY_ACTION) {
		return {
			...state,
			expenseCategories: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_EXPENSECATEGORIES) {
		return {
			...state,
			expenseCategories: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
