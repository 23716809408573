import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getRequestQueryAction,
	createRequestQueryAction,
	updateRequestQueryAction,
} from "../../../../../store/actions/RequestQueryActions";

class AddEditRequestQuery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: {},
			societyId: "",
			flatNo: "",
			ownerName: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let flatData = JSON.parse(localStorage.getItem("userFlatDetails"));

		this.setState({
			societyId: flatData?.societyId,
			flatNo: flatData?.flatNo,
			ownerName: flatData?.ownerName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.query) {
				this.props.getRequestQueryAction(id);
			}
		} else {
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.query?.length !== this.props?.query?.length) {
			this.setState({
				query: this.props?.query,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateRequestQueryAction(
				values,
				this.props.history,
				this.props?.query?._id
			);
		} else {
			this.props.createRequestQueryAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var query = this.props?.query;
		} else {
			query = "";
		}

		const initialValues = {
			societyId: this.state.societyId,
			flatNo: this.state?.flatNo,
			memberName: this.state?.ownerName,
			commments: query ? query.commments : "",
			title: query ? query.title : "",
			description: query ? query.description : "",
		};

		const validationSchema = Yup.object().shape({
			title: Yup.string().required("Please Enter Title."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item
						linkProps={{ to: "/member-request-queries" }}
						linkAs={Link}
					>
						Request Query Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Request Query</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Request Query</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Request Query</h3>
						) : (
							<h3 className="pt-3">Add Request Query</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/member-request-queries"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Title</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-title"
																	placeholder="Enter a title.."
																	name="title"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.title || ""}
																/>
																{errors.title && touched.title ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.title}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Comments</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-commments"
																	placeholder="Enter a commments.."
																	name="commments"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.commments || ""}
																/>
																{errors.commments && touched.commments ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.commments}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Description</label>
															<div>
																<textarea
																	type="text"
																	className="form-control"
																	id="val-description"
																	placeholder="Enter a description.."
																	name="description"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.description || ""}
																	rows="4"
																></textarea>
																{errors.description && touched.description ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.description}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddRequestQuery;
const mapStateToProps = (state) => {
	return {
		query: state.query?.query,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getRequestQueryAction,
			createRequestQueryAction,
			updateRequestQueryAction,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddEditRequestQuery);
