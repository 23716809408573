import axiosInstance from "../services/AxiosInstance";

export function getUserDetails(userId) {
	return axiosInstance.get(`admin/user/${userId}`);
}

export function getUsers(societyId) {
	return axiosInstance.get(`admin/users/${societyId}`);
}

export function createUser(userData) {
	return axiosInstance.post(`admin/user/add`, userData);
}

export function updateUser(user, userId) {
	return axiosInstance.put(`admin/user/${userId}`, user);
}

export function deleteUser(userId) {
	return axiosInstance.delete(`admin/user/${userId}`);
}

export function updatePassword(user) {
	return axiosInstance.put(`/password/update`, user);
}

export function uploadUser(userData) {
	return axiosInstance.post(`admin/user/upload`, userData);
}
