import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
	getSocietyAction,
	createSocietyAction,
	updateSocietyAction,
	createSocietyImageAction,
} from "../../../../store/actions/SocietyActions";

class AddEditSocietyImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.match.params.id,
			currentFile: undefined,
			previewImage: undefined,
			progress: 0,
			message: "",

			imageInfos: [],
		};

		this.handleAddImage = this.handleAddImage.bind(this);
		this.selectFile = this.selectFile.bind(this);
	}

	selectFile(event) {
		this.setState({
			currentFile: event.target.files[0],
			previewImage: URL.createObjectURL(event.target.files[0]),
			progress: 0,
			message: "",
		});
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));

		if (this.state.id) {
			if (this.props.image) {
				this.props.getSocietyAction(userData.user.societyName);
			}
		} else {
		}
	}

	handleAddImage = (values) => {
		let societyImages = [
			{
				public_id: values.public_id,
				url: values.url,
			},
		];
		if (this.state.id) {
			this.props.updateSocietyAction(
				societyImages,
				this.props.history,
				this.props?.image?.society?._id
			);
		} else {
			this.props.createSocietyAction(values, this.props.history);
		}
	};

	upload = (e) => {
		e.preventDefault();
		let userData = JSON.parse(localStorage.getItem("userDetails"));

		let fn = this.state.currentFile;
		let rename_file = this.props?.image?.society?.societyName + "___" + fn.name;

		let formData = new FormData();
		formData.append("fileurl", this.state.currentFile, rename_file);

		this.props.createSocietyImageAction(
			formData,
			this.props.history,
			userData.user.societyName
		);
	};

	render() {
		const { currentFile, previewImage } = this.state; // progress, message, imageInfos,

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/society-images" }} linkAs={Link}>
						Society Image Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Add Society Image</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Add Society Image</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/society-images"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<form>
										<div className="row">
											<div className="col-8">
												<label className="btn btn-default p-0">
													<input
														type="file"
														accept="image/*"
														onChange={this.selectFile}
													/>
												</label>
											</div>

											<div className="col-4">
												<button
													className="btn btn-success btn-sm"
													disabled={!currentFile}
													onClick={this.upload}
												>
													Upload
												</button>
											</div>
										</div>

										{previewImage && (
											<div className="society-image">
												<img className="preview" src={previewImage} alt="" />
											</div>
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddSociety Image;
const mapStateToProps = (state) => {
	return {
		image: state.society?.society,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getSocietyAction,
			createSocietyAction,
			updateSocietyAction,
			createSocietyImageAction,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddEditSocietyImage);
