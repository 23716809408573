import axiosInstance from "../services/AxiosInstance";

export function getPaymentReceiptDetails(paymentId) {
	return axiosInstance.get(`admin/paymentReceipt/${paymentId}`);
}

// export function getPaymentReceipts(societyId, year, month) {
// 	return axiosInstance.get(
// 		`admin/paymentReceipts/${societyId}/${year}/${month}`
// 	);
// }
export function getPaymentReceipts(societyId) {
	return axiosInstance.get(`admin/paymentReceipts/${societyId}`);
}

export function createPaymentReceipt(paymentData) {
	return axiosInstance.post(`admin/paymentReceipt`, paymentData);
}

export function updatePaymentReceipt(payment, paymentId) {
	return axiosInstance.put(`admin/paymentReceipt/${paymentId}`, payment);
}

export function deletePaymentReceipt(paymentId) {
	return axiosInstance.delete(`admin/paymentReceipt/${paymentId}`);
}
