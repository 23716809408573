import {
	CONFIRMED_GET_BILLINGTEMPLATE_DETAILS,
	CONFIRMED_CREATE_BILLINGTEMPLATE_ACTION,
	CONFIRMED_GET_BILLINGTEMPLATES,
	CONFIRMED_EDIT_BILLINGTEMPLATE_ACTION,
	CONFIRMED_DELETE_BILLINGTEMPLATE_ACTION,
} from "../actions/BillingTemplateTypes";

const initialState = {
	billingTemplates: [],
	billingTemplate: {},
};

export default function BillingTemplateReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_BILLINGTEMPLATE_DETAILS) {
		return {
			...state,
			billingTemplate: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_BILLINGTEMPLATE_ACTION) {
		const billingTemplates = [
			...state.billingTemplate.billingTemplates.billingTemplates,
		];
		const billingTemplateIndex = billingTemplates.findIndex(
			(billingTemplate) => billingTemplate.id === actions.payload
		);

		billingTemplates.splice(billingTemplateIndex, 1);

		return {
			...state,
			billingTemplates,
		};
	}

	if (actions.type === CONFIRMED_EDIT_BILLINGTEMPLATE_ACTION) {
		return {
			...state,
			billingTemplates: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_BILLINGTEMPLATE_ACTION) {
		return {
			...state,
			billingTemplates: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_BILLINGTEMPLATES) {
		return {
			...state,
			billingTemplates: actions.payload,
		};
	}

	return state;
}
