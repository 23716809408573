import {
	getNoticeDetails,
	getNotices,
	createNotice,
	updateNotice,
	deleteNotice,
} from "../../services/NoticeService";
import {
	CONFIRMED_GET_NOTICE_DETAILS,
	CONFIRMED_CREATE_NOTICE_ACTION,
	CONFIRMED_GET_NOTICES,
	CONFIRMED_EDIT_NOTICE_ACTION,
	CONFIRMED_DELETE_NOTICE_ACTION,
	NOTICES_LOADING,
} from "./NoticeTypes";

export function getNoticeAction(noticeId) {
	return (dispatch, getState) => {
		getNoticeDetails(noticeId).then((response) => {
			dispatch(confirmedGetNoticeAction(response.data.notice));
		});
	};
}

export function confirmedGetNoticeAction(notice) {
	return {
		type: CONFIRMED_GET_NOTICE_DETAILS,
		payload: notice,
	};
}

export function deleteNoticeAction(noticeId, history) {
	return (dispatch, getState) => {
		deleteNotice(noticeId).then((response) => {
			dispatch(confirmedDeleteNoticeAction(noticeId));
			history.push("/notices");
		});
	};
}

export function confirmedDeleteNoticeAction(noticeId) {
	return {
		type: CONFIRMED_DELETE_NOTICE_ACTION,
		payload: noticeId,
	};
}

export function createNoticeAction(NoticeData, history) {
	return (dispatch, getState) => {
		createNotice(NoticeData).then((response) => {
			const singleNotice = {
				...NoticeData,
				id: response.data._id,
			};
			dispatch(confirmedCreateNoticeAction(singleNotice));
			history.push("/notices");
		});
	};
}

export function confirmedCreateNoticeAction(singleNotice) {
	return {
		type: CONFIRMED_CREATE_NOTICE_ACTION,
		payload: singleNotice,
	};
}

export function getNoticesAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: NOTICES_LOADING });
		getNotices(societyId).then((response) => {
			dispatch(confirmedGetNoticesAction(response.data));
		});
	};
}

export function confirmedGetNoticesAction(Notices) {
	return {
		type: CONFIRMED_GET_NOTICES,
		payload: Notices,
	};
}

export function confirmedUpdateNoticeAction(Notice) {
	return {
		type: CONFIRMED_EDIT_NOTICE_ACTION,
		payload: Notice,
	};
}

export function updateNoticeAction(Notice, history, noticeId) {
	return (dispatch, getState) => {
		updateNotice(Notice, noticeId).then((reponse) => {
			dispatch(confirmedUpdateNoticeAction(Notice));
			history.push("/notices");
		});
	};
}
