import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import { format } from "date-fns";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { getPaymentsByFlatAction } from "../../../../../store/actions/PaymentActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class Payment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			societyId: "",
			search: "",
			currentPayments: [],
			currentPage: null,
			totalPages: null,
		};
	}

	componentDidMount() {
		let flatData = JSON.parse(localStorage.getItem("userFlatDetails"));

		this.props.getPaymentsByFlatAction(flatData?.societyId, flatData?.flatId);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.payments?.paymentDetails?.length !==
			this.props?.payments?.paymentDetails?.length
		) {
			this.setState({
				currentPayments: this.props?.payments?.paymentDetails,
			});
		}
	}

	initData() {
		let currentPayments =
			this.state?.currentPayments?.length > 0
				? this.state?.currentPayments
				: this.props?.payments?.paymentDetails;

		this.setState({ currentPayments });
	}

	onPageChanged = (data) => {
		const allPayments = this.props?.payments?.paymentDetails;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentPayments = allPayments?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentPayments, totalPages });
	};

	handleSearch(event) {
		let currentPayments = this.props.payments?.paymentDetails;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentPayments = currentPayments.filter(
				(e) =>
					e.flatId.toLowerCase().match(searchString) ||
					e.month.toLowerCase().match(searchString) ||
					e.year.toLowerCase().match(searchString)
			);
		}
		this.setState({ currentPayments });
	}

	render() {
		let totalPayments = this.props?.payments?.paymentDetails?.length || 0;

		const { currentPayments, currentPage, totalPages } = this.state;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Payment Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Payments</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									{/* <input
									type="text"
									className="form-control mb-2 search-list pull-right"
									placeholder="Search..."
									onChange={(e) => this.handleSearch(e)}
								/> */}
								</div>
								{/* <div className="col-xl-4 col-sm-12">
									<Link
										to={"member/add-payment"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Payment
									</Link>
								</div> */}
							</div>

							<div className="card-body pb-0">
								<Table bordered striped responsive id="payments">
									<thead>
										<tr>
											<th>Flat No</th>
											<th>Owner Name</th>
											<th>Bank Branch</th>
											<th>Payment Mode</th>
											<th>Transaction Details</th>
											<th>Maintenance Month</th>
											<th>Maintenance year</th>
											<th>Payment Type</th>
											<th>Amount</th>
											<th>Payment Date</th>
											{/* <th>Action</th> */}
										</tr>
									</thead>
									<tbody>
										{currentPayments?.length
											? currentPayments
													?.sort((a, b) => a.flatNo.localeCompare(b.flatNo))
													?.map((payment, i) => (
														<tr key={i}>
															<td>{payment.flatNo} </td>
															<td>{payment.ownerName}</td>
															<td>{payment.bankDetails}</td>
															<td>{payment.paymentMode}</td>
															<td>{payment.transactionDetails}</td>
															<td>{payment.month}</td>
															<td>{payment.year}</td>
															<td>{payment.paymentType}</td>
															<td>{payment.amount}</td>
															<td>
																{payment.date &&
																	format(new Date(payment?.date), "dd-MM-yyyy")}
															</td>
															{/* <td>
																<div className="d-flex">
																	<Link
																		to={`member/add-payment/${payment._id}`}
																		className="btn btn-info shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-pencil"></i>
																	</Link>
																</div>
															</td> */}
														</tr>
													))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalPayments ? (
										<Pagination
											totalRecords={totalPayments}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		payments: state.payment?.payments,
		loading: state?.payment?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getPaymentsByFlatAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
