import {
  getFlatDetails,
  getFlats,
  createFlat,
  updateFlat,
  deleteFlat,
  getFlatBySocietyUser,
  updateFlatAssociation,
  uploadFlat,
} from "../../services/FlatManagementService";
import {
  CONFIRMED_GET_FLAT_DETAILS,
  CONFIRMED_CREATE_FLAT_ACTION,
  CONFIRMED_GET_FLATS,
  CONFIRMED_EDIT_FLAT_ACTION,
  CONFIRMED_DELETE_FLAT_ACTION,
  CONFIRMED_GET_FLAT_SOCIETY_USER_DETAILS,
  CONFIRMED_EDIT_FLAT_ASSOCIATION_ACTION,
  CONFIRMED_UPLOAD_FLAT_ACTION,
  LOADING,
} from "./FlatManagementTypes";

import { toast } from "react-toastify"; //ToastContainer
import "react-toastify/dist/ReactToastify.css";

export function getFlatAction(flatId) {
  return (dispatch, getState) => {
    getFlatDetails(flatId).then((response) => {
      dispatch(confirmedGetFlatAction(response.data));
    });
  };
}

export function confirmedGetFlatAction(flat) {
  return {
    type: CONFIRMED_GET_FLAT_DETAILS,
    payload: flat,
  };
}

export function deleteFlatAction(flatId, history) {
  return (dispatch, getState) => {
    deleteFlat(flatId).then((response) => {
      dispatch(confirmedDeleteFlatAction(flatId));
      history.push("/flat-management");
    });
  };
}

export function confirmedDeleteFlatAction(FlatId) {
  return {
    type: CONFIRMED_DELETE_FLAT_ACTION,
    payload: FlatId,
  };
}

export function createFlatAction(FlatData, history) {
  return (dispatch, getState) => {
    createFlat(FlatData)
      .then((response) => {
        const singleFlat = {
          ...FlatData,
          id: response.data._id,
        };
        dispatch(confirmedCreateFlatAction(singleFlat));
        if (response.status === 200) {
          toast.success("Password is changed successfully!");
          history.push("/flat-management");
        } else {
          toast.error(response.errMessage);
        }
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error?.response?.data?.msg);
      });
  };
}

// updateAdminPassword(AdminUser, adminId)
// 		.then((response) => {
// 			dispatch(confirmedUpdateAdminPasswordAction(AdminUser));
// 			if (response.status === 200) {
// 				toast.success("Password is changed successfully!");
// 				history.push("/login");
// 				localStorage.removeItem("userDetails");
// 				window.location.reload();
// 			} else {
// 				toast.error(response.errMessage);
// 			}
// 		})
// 		.catch((error) => {
// 			console.log(error.response);
// 			toast.error(error?.response?.data?.errMessage);
// 		});

export function getFlatsAction(societyId) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING });
    getFlats(societyId).then((response) => {
      dispatch(confirmedGetFlatsAction(response.data));
    });
  };
}

export function confirmedCreateFlatAction(singleFlat) {
  return {
    type: CONFIRMED_CREATE_FLAT_ACTION,
    payload: singleFlat,
  };
}

export function confirmedGetFlatsAction(Flats) {
  return {
    type: CONFIRMED_GET_FLATS,
    payload: Flats,
  };
}

export function confirmedUpdateFlatAction(Flat) {
  return {
    type: CONFIRMED_EDIT_FLAT_ACTION,
    payload: Flat,
  };
}

export function updateFlatAction(Flat, history, flatId) {
  return (dispatch, getState) => {
    updateFlat(Flat, flatId).then((reponse) => {
      dispatch(confirmedUpdateFlatAction(Flat));
      history.push("/flat-management");
    });
  };
}

export function getFlatSocietyUserAction(societyId, userId) {
  return (dispatch, getState) => {
    getFlatBySocietyUser(societyId, userId).then((response) => {
      dispatch(confirmedGetFlatSocietyUserAction(response.data.flat));
    });
  };
}

export function confirmedGetFlatSocietyUserAction(flat) {
  return {
    type: CONFIRMED_GET_FLAT_SOCIETY_USER_DETAILS,
    payload: flat,
  };
}

export function confirmedUpdateFlatAssociationAction(Flat) {
  return {
    type: CONFIRMED_EDIT_FLAT_ASSOCIATION_ACTION,
    payload: Flat,
  };
}

export function updateFlatAssociationAction(Flat, history, flatId) {
  return (dispatch, getState) => {
    updateFlatAssociation(Flat, flatId).then((reponse) => {
      dispatch(confirmedUpdateFlatAssociationAction(Flat));
      history.push("/add-association");
    });
  };
}

export function uploadFlatAction(FlatData, history) {
  return (dispatch, getState) => {
    uploadFlat(FlatData).then((response) => {
      const multipleFlat = {
        ...FlatData,
        id: response.data._id,
      };
      dispatch(confirmedUploadFlatAction(multipleFlat));
      history.push("/flat-management");
    });
  };
}

export function confirmedUploadFlatAction(singleFlat) {
  return {
    type: CONFIRMED_UPLOAD_FLAT_ACTION,
    payload: singleFlat,
  };
}
