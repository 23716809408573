export const GET_NOTICE_DETAILS = "[Notice Action] Get Notice";
export const CONFIRMED_GET_NOTICE_DETAILS =
	"[Notice Action] Confirmed Get Notice";
export const CREATE_NOTICE_ACTION = "[Notice Action] Create Notice";
export const CONFIRMED_CREATE_NOTICE_ACTION =
	"[Notice Action] Confirmed Create Notice";
export const GET_NOTICES = "[Notices Action] Get Notices";
export const CONFIRMED_GET_NOTICES = "[Notices Action] Confirmed Get Notices";
export const EDIT_NOTICE_ACTION = "[Notice Action] Edit Notice";
export const CONFIRMED_EDIT_NOTICE_ACTION =
	"[Notice Action] Confirmed Edit Notice";
export const CONFIRMED_DELETE_NOTICE_ACTION =
	"[Notice Action] Confirmed Delete Notice";
export const NOTICES_LOADING = "[Notices Loading Action] Notices Loading";
