export const GET_EXPENSECATEGORY_DETAILS =
	"[Expense Category Action] Get Expense Category";
export const CONFIRMED_GET_EXPENSECATEGORY_DETAILS =
	"[Expense Category Action] Confirmed Get Expense Category";
export const CREATE_EXPENSECATEGORY_ACTION =
	"[Expense Category Action] Create Expense Category";
export const CONFIRMED_CREATE_EXPENSECATEGORY_ACTION =
	"[Expense category Action] Confirmed Create Expense Category";
export const GET_EXPENSECATEGORIES =
	"[Expense Categories Action] Get Expense Categories";
export const CONFIRMED_GET_EXPENSECATEGORIES =
	"[Expense Categories Action] Confirmed Get Expense Categories";
export const EDIT_EXPENSECATEGORY_ACTION =
	"[Expense Categories Action] Edit Expense Categories";
export const CONFIRMED_EDIT_EXPENSECATEGORY_ACTION =
	"[Expense Category Action] Confirmed Edit Expense Category";
export const CONFIRMED_DELETE_EXPENSECATEGORY_ACTION =
	"[Expense Category Action] Confirmed Delete Expense Category";
export const LOADING =
	"[Expense Category Loading Action] Confirmed Expense category Loading";
