export const GET_PAYMENTRECEIPT_DETAILS =
	"[Payment Receipt Action] Get Payment Receipt";
export const CONFIRMED_GET_PAYMENTRECEIPT_DETAILS =
	"[Payment Receipt Action] Confirmed Get Payment Receipt";
export const CREATE_PAYMENTRECEIPT_ACTION =
	"[Payment Receipt Action] Create Payment Receipt";
export const CONFIRMED_CREATE_PAYMENTRECEIPT_ACTION =
	"[Payment Receipt Action] Confirmed Create Payment Receipt";
export const GET_PAYMENTRECEIPTS =
	"[Payment Receipts Action] Get Payment Receipts";
export const CONFIRMED_GET_PAYMENTRECEIPTS =
	"[Payment Receipts Action] Confirmed Get Payment Receipts";
export const EDIT_PAYMENTRECEIPT_ACTION =
	"[Payment Receipt Action] Edit Payment Receipt";
export const CONFIRMED_EDIT_PAYMENTRECEIPT_ACTION =
	"[Payment Receipt Action] Confirmed Edit Payment Receipt";
export const CONFIRMED_DELETE_PAYMENTRECEIPT_ACTION =
	"[Payment Receipt Action] Confirmed Delete Payment Receipt";
