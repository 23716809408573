import { applyMiddleware, combineReducers, compose, createStore } from "redux";
// import PostsReducer from './reducers/PostsReducer';
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
//import rootReducers from './reducers/Index';
import todoReducers from "./reducers/Reducers";
import { reducer as reduxFormReducer } from "redux-form";

import DashboardReducer from "./reducers/DashboardReducer";
import SocietyReducer from "./reducers/SocietyReducer";
import MaintenanceReducer from "./reducers/MaintenanceReducer";
import MaintenanceMasterReducer from "./reducers/MaintenanceMasterReducer";
import FlatManagementReducer from "./reducers/FlatManagementReducer";
import AmenitiesMasterReducer from "./reducers/AmenitiesMasterReducer";
import ExpenseCategoryReducer from "./reducers/ExpenseCategoryReducer";
import ExpenseReducer from "./reducers/ExpenseReducer";
import BillingAdjustment from "./reducers/BillingAdjustmentReducer";
import Billing from "./reducers/BillingReducer";
import PaymentReceipt from "./reducers/PaymentReceiptReducer";
import Payment from "./reducers/PaymentReducer";
import Vendor from "./reducers/VendorReducer";
import BillingTemplate from "./reducers/BillingTemplateReducer";
import News from "./reducers/NewsReducer";
import Notice from "./reducers/NoticeReducer";
import RequestQuery from "./reducers/RequestQueryReducer";
import Amc from "./reducers/AmcReducer";
import VendorType from "./reducers/VendorTypeReducer";
import AdminUser from "./reducers/AdminUserReducer";
import User from "./reducers/UserReducer";
import BillingHeaderMapping from "./reducers/BillingHeaderMappingReducer";
import Meeting from "./reducers/MeetingReducer";
import Polling from "./reducers/PollingReducer";
import BankDetail from "./reducers/BankDetailsReducer";
import RequestDemo from "./reducers/RequestDemoReducer";

///Member Login
import UserDashboardReducer from "./reducers/Member/DashboardReducer";

// const loggerMiddleware = (store) => (next) => (action) => {
// 	console.log("dispatching action", action);
// 	console.log("before dispatching state", store.getState());
// 	let result = next(action);
// 	setTimeout(() => {
// 		console.log("dispatch time out");
// 	}, 3000);
// 	console.log("next state", store.getState());
// 	return result;
// };

// const fetchDataMiddleware = (store) => (next) => (action) => {
//     if (action.type === GET_POSTS) {
//         //ajax call
//     }

//     return next(action);
// };

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
	//posts: PostsReducer,
	auth: AuthReducer,
	todoReducers,
	dashboard: DashboardReducer,
	society: SocietyReducer,
	maintenance: MaintenanceReducer,
	maintenancemaster: MaintenanceMasterReducer,
	flat: FlatManagementReducer,
	amenity: AmenitiesMasterReducer,
	expenseCategory: ExpenseCategoryReducer,
	expense: ExpenseReducer,
	billingAdjustment: BillingAdjustment,
	billing: Billing,
	paymentReceipt: PaymentReceipt,
	payment: Payment,
	vendor: Vendor,
	billingTemplate: BillingTemplate,
	news: News,
	notice: Notice,
	query: RequestQuery,
	amc: Amc,
	vendorType: VendorType,
	admin: AdminUser,
	user: User,
	billingHeaderMapping: BillingHeaderMapping,
	meeting: Meeting,
	polling: Polling,
	bankDetail: BankDetail,
	requestDemo: RequestDemo,
	form: reduxFormReducer,

	///Member Login imports below
	userDashboard: UserDashboardReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
