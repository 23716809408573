import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getAmcAction,
	createAmcAction,
	updateAmcAction,
} from "../../../../store/actions/AmcActions";
import { getVendorTypesAction } from "../../../../store/actions/VendorTypeActions";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

class AddEditAmc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			amc: {},
			vendorTypes: [],
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.amc) {
				this.props.getAmcAction(id);
			}
		} else {
		}
		this.props.getVendorTypesAction(userData.user.societyName);
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.amc?.length !== this.props?.amc?.length) {
			this.setState({
				amc: this.props?.amc,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateAmcAction(
				values,
				this.props.history,
				this.props?.amc?._id
			);
		} else {
			this.props.createAmcAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var amc = this.props?.amc;
		} else {
			amc = "";
		}

		let type_data = this.props.vendorTypes?.vendorTypes;

		const initialValues = {
			societyId: this.state.societyId,
			vendorType: amc ? amc.vendorType : "",
			amcDescription: amc ? amc.amcDescription : "",
			vendorName: amc ? amc.vendorName : "",
			vendorEmail: amc ? amc.vendorEmail : "",
			vendorMobile: amc ? amc.vendorMobile : "",
			vendorAddress: amc ? amc.vendorAddress : "",
			amcStartDate: amc ? amc.amcStartDate : new Date(),
			amcEndDate: amc ? amc.amcEndDate : new Date(),
			status: amc ? amc.status : true,
		};

		const validationSchema = Yup.object().shape({
			vendorType: Yup.string().required("Please Select Vendor type."),
			vendorName: Yup.string().required("Please Enter Vendor Name."),
			vendorMobile: Yup.string()
				.matches(phoneRegExp, "Phone number is not valid")
				.min(10, "Min 10 digit")
				.max(10, "Max 10 digit")
				.required("Please Enter Mobile."),
			// vendorEmail: Yup.string()
			// 	.required("Please Enter Email.")
			// 	.email("Please Enter Valid Email."),
			amcStartDate: Yup.date().required("Please Select AMC Start Date."),
			amcEndDate: Yup.date()
				.required("Please select AMC End Date")
				.min(
					Yup.ref("amcStartDate"),
					"End date should be greater than Start date"
				),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/amc" }} linkAs={Link}>
						AMC Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>AMC Edit</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>AMC Add</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id?.length ? (
							<h3 className="pt-3">Edit AMC</h3>
						) : (
							<h3 className="pt-3">Add AMC</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/amc"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Vendor Type *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-vendorType"
																	placeholder="Select a vendor Type.."
																	name="vendorType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorType || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	{type_data?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt.vendorTypeName}
																			label={opt.vendorTypeName}
																		/>
																	))}
																</select>
																{errors.vendorType && touched.vendorType ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorType}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Vendor Name *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-vendorName"
																	placeholder="Enter a Vendor Name.."
																	name="vendorName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorName || ""}
																/>
																{errors.vendorName && touched.vendorName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Vendor Description
															</label>
															<div>
																<textarea
																	type="text"
																	className="form-control"
																	id="val-amcDescription"
																	placeholder="Enter a Amc Description.."
																	name="amcDescription"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.amcDescription || ""}
																	rows="4"
																></textarea>
																{errors.amcDescription &&
																touched.amcDescription ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.amcDescription}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Vendor Email</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-vendorEmail"
																	placeholder="Enter a vendor Email.."
																	name="vendorEmail"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorEmail || ""}
																/>
																{errors.vendorEmail && touched.vendorEmail ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorEmail}
																	</div>
																) : null}
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Vendor Mobile *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-vendorMobile"
																	placeholder="Enter a vendor Mobile.."
																	name="vendorMobile"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorMobile || ""}
																/>
																{errors.vendorMobile && touched.vendorMobile ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorMobile}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Vendor Address
															</label>
															<div>
																<textarea
																	type="text"
																	className="form-control"
																	id="val-vendorAddress"
																	placeholder="Enter a vendor Address.."
																	name="vendorAddress"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorAddress || ""}
																	rows="4"
																></textarea>
																{errors.vendorAddress &&
																touched.vendorAddress ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorAddress}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																AMC Start Date *
															</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="amcStartDate"
																		format="dd/MM/yyyy"
																		value={values.amcStartDate}
																		onChange={(e) =>
																			setFieldValue("amcStartDate", e)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.amcStartDate && touched.amcStartDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.amcStartDate}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																AMC End Date *
															</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="amcEndDate"
																		format="dd/MM/yyyy"
																		value={values.amcEndDate}
																		onChange={(e) =>
																			setFieldValue("amcEndDate", e)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.amcEndDate && touched.amcEndDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.amcEndDate}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddVendor;
const mapStateToProps = (state) => {
	return {
		amc: state.amc?.amc,
		vendorTypes: state?.vendorType?.vendorTypes,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getAmcAction,
			createAmcAction,
			updateAmcAction,
			getVendorTypesAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAmc);
