import {
	getBillingDetails,
	getBillings,
	createBilling,
	updateBilling,
	deleteBilling,
	getBillingsByFlat,
} from "../../services/BillingService";
import {
	CONFIRMED_GET_BILLING_DETAILS,
	CONFIRMED_CREATE_BILLING_ACTION,
	CONFIRMED_GET_BILLINGS,
	CONFIRMED_EDIT_BILLING_ACTION,
	CONFIRMED_DELETE_BILLING_ACTION,
	CONFIRMED_GET_BILLINGS_BY_FLAT,
	BILLINGS_LOADING,
} from "./BillingTypes";

export function getBillingAction(billingId) {
	return (dispatch, getState) => {
		getBillingDetails(billingId).then((response) => {
			dispatch(confirmedGetBillingAction(response.data.billing));
		});
	};
}

export function confirmedGetBillingAction(billing) {
	return {
		type: CONFIRMED_GET_BILLING_DETAILS,
		payload: billing,
	};
}

export function deleteBillingAction(billingId, history) {
	return (dispatch, getState) => {
		deleteBilling(billingId).then((response) => {
			dispatch(confirmedDeleteBillingAction(billingId));
			history.push("/billings");
		});
	};
}

export function confirmedDeleteBillingAction(billingId) {
	return {
		type: CONFIRMED_DELETE_BILLING_ACTION,
		payload: billingId,
	};
}

export function createBillingAction(BillingData, history) {
	return (dispatch, getState) => {
		createBilling(BillingData).then((response) => {
			const singleBilling = {
				...BillingData,
				id: response.data._id,
			};
			dispatch(confirmedCreateBillingAction(singleBilling));
			history.push("/billings");
		});
	};
}

export function getBillingsAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: BILLINGS_LOADING });
		getBillings(societyId).then((response) => {
			dispatch(confirmedGetBillingsAction(response.data));
		});
	};
}

export function confirmedCreateBillingAction(singleBilling) {
	return {
		type: CONFIRMED_CREATE_BILLING_ACTION,
		payload: singleBilling,
	};
}

export function confirmedGetBillingsAction(Billings) {
	return {
		type: CONFIRMED_GET_BILLINGS,
		payload: Billings,
	};
}

export function confirmedUpdateBillingAction(Billing) {
	return {
		type: CONFIRMED_EDIT_BILLING_ACTION,
		payload: Billing,
	};
}

export function updateBillingAction(Billing, history, billingId) {
	return (dispatch, getState) => {
		updateBilling(Billing, billingId).then((reponse) => {
			dispatch(confirmedUpdateBillingAction(Billing));
			history.push("/billings");
		});
	};
}

export function getBillingsByFlatAction(societyId, flatId) {
	return (dispatch, getState) => {
		dispatch({ type: BILLINGS_LOADING });
		getBillingsByFlat(societyId, flatId).then((response) => {
			dispatch(confirmedGetBillingsByFlatAction(response.data));
		});
	};
}

export function confirmedGetBillingsByFlatAction(Billings) {
	return {
		type: CONFIRMED_GET_BILLINGS_BY_FLAT,
		payload: Billings,
	};
}
