import axiosInstance from "../services/AxiosInstance";

export function getRequestQueryDetails(queryId) {
	return axiosInstance.get(`admin/queries/${queryId}`);
}

export function getRequestQueries(societyId) {
	return axiosInstance.get(`admin/queriess/${societyId}`);
}

export function createRequestQuery(queryData) {
	return axiosInstance.post(`admin/queries`, queryData);
}

export function updateRequestQuery(query, queryId) {
	return axiosInstance.put(`admin/queries/${queryId}`, query);
}

export function deleteRequestQuery(queryId) {
	return axiosInstance.delete(`admin/queries/${queryId}`);
}
