import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getUserAction,
	createUserAction,
	updateUserAction,
} from "../../../../store/actions/UserActions";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

class AddEditUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			this.props.getUserAction(id);
		} else {
		}
		//this.props.getSocietiesAction();
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.user?.length !== this.props?.user?.length) {
			this.setState({
				user: this.props?.user,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		const createData = {...values, societyId: this?.state?.societyId}
		if (id) {
			this.props.updateUserAction(
				values,
				this.props.history,
				this.props?.user?._id
			);
		} else {
			this.props.createUserAction(createData, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var user = this.props?.user;
		} else {
			user = "";
		}

		//let society_data = this.props.societies?.societies;

		const initialValues = {
			name: user ? user.name : "",
			email: user ? user.email : "",
			password: user ? user.password : "",
			mobile: user ? user.mobile : "",
			address: user ? user.address : "",
			status: user ? user.status : "",
		};

		const validationSchema = Yup.object().shape({
			name: Yup.string().required("Please Enter Name."),
			email: Yup.string()
				.email("Invalid Email")
				.required("Please Enter Email."),
			password: Yup.string()
				//.required("Please Enter Password."),
				.concat(!id ? Yup.string().required("Password is required") : null)
				.min(6, "Password must be at least 6 characters"),
			mobile: Yup.string()
				.matches(phoneRegExp, "Phone number is not valid")
				.min(10, "Min 10 digit")
				.max(10, "Max 10 digit")
				.required("Please Enter Mobile no."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/users" }} linkAs={Link}>
						Users Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit User</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add User</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit User</h3>
						) : (
							<h3 className="pt-3">Add User</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/users"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">User Name *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-name"
																	placeholder="Enter a Name.."
																	name="name"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.name || ""}
																/>
																{errors.name && touched.name ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.name}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Email *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-email"
																	placeholder="Enter a Email.."
																	name="email"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.email || ""}
																/>
																{errors.email && touched.email ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.email}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														{!id && (
															<div className="form-group">
																<label className="text-label">Password *</label>

																<div>
																	<input
																		type="password"
																		className="form-control"
																		id="val-password"
																		placeholder="Enter a password.."
																		name="password"
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.password || ""}
																	/>
																	{errors.password && touched.password ? (
																		<div
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.password}
																		</div>
																	) : null}
																</div>
															</div>
														)}
													</div>

													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Mobile *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-mobile"
																	placeholder="Enter a Mobile.."
																	name="mobile"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.mobile || ""}
																/>
																{errors.mobile && touched.mobile ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.mobile}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Address</label>
															<div>
																<textarea
																	type="text"
																	className="form-control"
																	id="val-address"
																	placeholder="Enter a Address.."
																	name="address"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.address || ""}
																	rows="5"
																></textarea>
																{errors.address && touched.address ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.address}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddUser;
const mapStateToProps = (state) => {
	return {
		user: state.user?.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getUserAction,
			createUserAction,
			updateUserAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
