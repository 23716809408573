export const GET_USER_DETAILS = "[User Action] Get User";
export const CONFIRMED_GET_USER_DETAILS = "[User Action] Confirmed Get User";
export const CREATE_USER_ACTION = "[User Action] Create User";
export const CONFIRMED_CREATE_USER_ACTION =
	"[User Action] Confirmed Create User";
export const GET_USERS = "[Users Action] Get Users";
export const CONFIRMED_GET_USERS = "[Users Action] Confirmed Get Users";
export const EDIT_USER_ACTION = "[User Action] Edit User";
export const CONFIRMED_EDIT_USER_ACTION = "[User Action] Confirmed Edit User";
export const CONFIRMED_DELETE_USER_ACTION =
	"[User Action] Confirmed Delete User";
export const UPDATE_PASSWORD_ACTION =
	"[User Password Action] Edit User Password";
export const CONFIRMED_UPDATE_PASSWORD_ACTION =
	"[User Password Action] Confirmed Edit User Password";
export const UPLOAD_USER_ACTION = "[User Action] Upload User";
export const CONFIRMED_UPLOAD_USER_ACTION =
	"[User Action] Confirmed Upload User";
export const LOADING = "[User Loading Action] Confirmed User Loading Action";
