import {
	getRequestDemoDetails,
	getRequestDemos,
	createRequestDemo,
	updateRequestDemo,
	deleteRequestDemo,
} from "../../services/RequestDemoService";
import {
	CONFIRMED_GET_REQUESTDEMO_DETAILS,
	CONFIRMED_CREATE_REQUESTDEMO_ACTION,
	CONFIRMED_GET_REQUESTDEMOS,
	CONFIRMED_EDIT_REQUESTDEMO_ACTION,
	CONFIRMED_DELETE_REQUESTDEMO_ACTION,
	REQUESTDEMOS_LOADING,
} from "./RequestDemoTypes";

export function getRequestDemoAction(requestDemoId) {
	return (dispatch, getState) => {
		getRequestDemoDetails(requestDemoId).then((response) => {
			dispatch(confirmedGetRequestDemoAction(response.data.requestDemo));
		});
	};
}

export function confirmedGetRequestDemoAction(requestDemo) {
	return {
		type: CONFIRMED_GET_REQUESTDEMO_DETAILS,
		payload: requestDemo,
	};
}

export function deleteRequestDemoAction(requestDemoId, history) {
	return (dispatch, getState) => {
		deleteRequestDemo(requestDemoId).then((response) => {
			dispatch(confirmedDeleteRequestDemoAction(requestDemoId));
			history.push("/superadmin/requestDemo-lists");
		});
	};
}

export function confirmedDeleteRequestDemoAction(requestDemoId) {
	return {
		type: CONFIRMED_DELETE_REQUESTDEMO_ACTION,
		payload: requestDemoId,
	};
}

export function createRequestDemoAction(RequestDemoData, history) {
	return (dispatch, getState) => {
		createRequestDemo(RequestDemoData).then((response) => {
			const singleRequestDemo = {
				...RequestDemoData,
				id: response.data._id,
			};
			dispatch(confirmedCreateRequestDemoAction(singleRequestDemo));
			history.push("/superadmin/requestDemo-lists");
		});
	};
}

export function confirmedCreateRequestDemoAction(singleRequestDemo) {
	return {
		type: CONFIRMED_CREATE_REQUESTDEMO_ACTION,
		payload: singleRequestDemo,
	};
}

export function getRequestDemosAction() {
	return (dispatch, getState) => {
		dispatch({ type: REQUESTDEMOS_LOADING });
		getRequestDemos().then((response) => {
			dispatch(confirmedGetRequestDemosAction(response.data));
		});
	};
}

export function confirmedGetRequestDemosAction(RequestDemos) {
	return {
		type: CONFIRMED_GET_REQUESTDEMOS,
		payload: RequestDemos,
	};
}

export function updateRequestDemoAction(RequestDemo, history, requestDemoId) {
	return (dispatch, getState) => {
		updateRequestDemo(RequestDemo, requestDemoId).then((reponse) => {
			dispatch(confirmedUpdateRequestDemoAction(RequestDemo));
			history.push("/superadmin/requestDemo-lists");
		});
	};
}

export function confirmedUpdateRequestDemoAction(RequestDemo) {
	return {
		type: CONFIRMED_EDIT_REQUESTDEMO_ACTION,
		payload: RequestDemo,
	};
}
