import {
	getBankDetailDetails,
	getBankDetails,
	createBankDetail,
	updateBankDetail,
	deleteBankDetail,
} from "../../services/BankDetailsService";
import {
	CONFIRMED_GET_BANKDETAILS_DETAILS,
	CONFIRMED_CREATE_BANKDETAILS_ACTION,
	CONFIRMED_GET_BANKDETAILS,
	CONFIRMED_EDIT_BANKDETAILS_ACTION,
	CONFIRMED_DELETE_BANKDETAILS_ACTION,
} from "./BankDetailsTypes";

export function getBankDetailAction(bankDetailId) {
	return (dispatch, getState) => {
		getBankDetailDetails(bankDetailId).then((response) => {
			dispatch(confirmedGetBankDetailAction(response.data.bankDetail));
		});
	};
}

export function confirmedGetBankDetailAction(bankDetail) {
	return {
		type: CONFIRMED_GET_BANKDETAILS_DETAILS,
		payload: bankDetail,
	};
}

export function deleteBankDetailAction(bankDetailId, history) {
	return (dispatch, getState) => {
		deleteBankDetail(bankDetailId).then((response) => {
			dispatch(confirmedDeleteBankDetailAction(bankDetailId));
			history.push("/bankdetails");
		});
	};
}

export function confirmedDeleteBankDetailAction(bankDetailId) {
	return {
		type: CONFIRMED_DELETE_BANKDETAILS_ACTION,
		payload: bankDetailId,
	};
}

export function createBankDetailAction(BankDetailData, history) {
	return (dispatch, getState) => {
		createBankDetail(BankDetailData).then((response) => {
			const singleBankDetail = {
				...BankDetailData,
				id: response.data._id,
			};
			dispatch(confirmedCreateBankDetailAction(singleBankDetail));
			history.push("/bankdetails");
		});
	};
}

export function confirmedCreateBankDetailAction(singleBankDetail) {
	return {
		type: CONFIRMED_CREATE_BANKDETAILS_ACTION,
		payload: singleBankDetail,
	};
}

export function getBankDetailsAction(societyId) {
	return (dispatch, getState) => {
		getBankDetails(societyId).then((response) => {
			dispatch(confirmedGetBankDetailsAction(response.data));
		});
	};
}

export function confirmedGetBankDetailsAction(BankDetails) {
	return {
		type: CONFIRMED_GET_BANKDETAILS,
		payload: BankDetails,
	};
}

export function confirmedUpdateBankDetailAction(BankDetail) {
	return {
		type: CONFIRMED_EDIT_BANKDETAILS_ACTION,
		payload: BankDetail,
	};
}

export function updateBankDetailAction(BankDetail, history, bankDetailId) {
	return (dispatch, getState) => {
		updateBankDetail(BankDetail, bankDetailId).then((reponse) => {
			dispatch(confirmedUpdateBankDetailAction(BankDetail));
			history.push("/bankdetails");
		});
	};
}
