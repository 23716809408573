import axiosInstance from "../services/AxiosInstance";

export function getNewsDetails(newsId) {
	return axiosInstance.get(`admin/news/${newsId}`);
}

export function getNewses(societyId) {
	return axiosInstance.get(`admin/newss/${societyId}`);
}

export function createNews(newsData) {
	return axiosInstance.post(`admin/news`, newsData);
}

export function updateNews(news, newsId) {
	return axiosInstance.put(`admin/news/${newsId}`, news);
}

export function deleteNews(newsId) {
	return axiosInstance.delete(`admin/news/${newsId}`);
}
