import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { format } from "date-fns";
import swal from "sweetalert";
import ReactHtmlParser from "react-html-parser";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
	getNoticesAction,
	deleteNoticeAction,
} from "../../../../store/actions/NoticeActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class Notice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentNotices: [],
			currentPage: null,
			totalPages: null,
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));

		this.props.getNoticesAction(userData.user.societyName);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.notices?.notices?.length !==
			this.props?.notices?.notices?.length
		) {
			this.setState({
				currentNotices: this.props?.notices?.notices,
			});
		}
	}

	initData() {
		let currentNotices =
			this.state?.currentNotices?.length > 0
				? this.state?.currentNotices
				: this.props?.notices?.notices;

		this.setState({ currentNotices });
	}

	onPageChanged = (data) => {
		const allNotices = this.props?.notices?.notices;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentNotices = allNotices?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentNotices, totalPages });
	};

	handleSearch(event) {
		let currentNotices = this.props.notices?.notices;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentNotices = currentNotices?.filter(
				(e) =>
					e.title?.toLowerCase().match(searchString) ||
					e.commments?.toLowerCase().match(searchString) ||
					e.date?.toLowerCase().match(searchString)
			);
			this.setState({ currentNotices });
		} else {
			let arrayLength = currentNotices.slice(0, 5);
			this.setState({ currentNotices: arrayLength });
		}
		//this.setState({ currentNotices });
	}

	deleteRecord(id) {
		//this.props.deleteNoticeAction(id, this.props.history);
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteNoticeAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		let totalNotices = this.props?.notices?.notice?.length || 0;

		const { currentNotices, currentPage, totalPages } = this.state;
		this.state.currentNotices = this.props.notices?.notices;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Notice Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Notice Lists</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-notice"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Notice
									</Link>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Title</th>
											<th>Comments</th>
											<th>Date</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentNotices?.length
											? currentNotices
													?.sort((a, b) => a.date.localeCompare(b.date))
													.map((notice, i) => (
														<tr key={i}>
															<td>{notice.title} </td>
															<td>
																{notice.commments &&
																	ReactHtmlParser(notice.commments)}
															</td>
															<td>
																{notice.date &&
																	format(new Date(notice?.date), "dd-MM-yyyy")}
															</td>
															<td>
																<div className="d-flex">
																	<Link
																		to={`add-notice/${notice._id}`}
																		className="btn btn-info shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-pencil"></i>
																	</Link>
																	<Link
																		to={`notice-details/${notice._id}`}
																		className="btn btn-primary shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-th"></i>
																	</Link>
																	<Link
																		to="#"
																		className="btn btn-danger shadow btn-xs sharp"
																		onClick={this.deleteRecord.bind(
																			this,
																			notice._id
																		)}
																	>
																		<i className="fa fa-trash"></i>
																	</Link>
																</div>
															</td>
														</tr>
													))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalNotices ? (
										<Pagination
											totalRecords={totalNotices}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		notices: state.notice?.notices,
		loading: state.notice?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getNoticesAction, deleteNoticeAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Notice);
