import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import swal from "sweetalert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
	getUsersAction,
	deleteUserAction,
} from "../../../../store/actions/UserActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class User extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentUsers: [],
			currentPage: null,
			totalPages: null,
			societyId: "",
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		this.props.getUsersAction(userData.user.societyName);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.users?.users?.length !== this.props?.users?.users?.length) {
			this.setState({
				currentUsers: this.props?.users?.users,
			});
		}
	}

	initData() {
		let currentUsers =
			this.state?.currentUsers?.length > 0
				? this.state?.currentUsers
				: this.props?.users?.users;

		this.setState({ currentUsers });
	}

	onPageChanged = (data) => {
		const allUsers = this.props?.users?.users;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentUsers = allUsers?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentUsers, totalPages });
	};

	handleSearch(event) {
		let currentUsers = this.props.users?.users;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentUsers = currentUsers.filter(
				(e) =>
					e.name.toLowerCase().match(searchString) ||
					e.mobile.toLowerCase().match(searchString)
			);
			this.setState({ currentUsers });
		} else {
			let arrayLength = currentUsers.slice(0, 5);
			this.setState({ currentUsers: arrayLength });
		}
		//this.setState({ currentUsers });
	}

	deleteRecord(id) {
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteUserAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		let totalUsers = this.props?.users?.users?.length || 0;

		const { currentUsers, currentPage, totalPages } = this.state;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>User Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-3 col-sm-12 pl-0">
									<h2 className="card-title">User Lists</h2>
								</div>

								<div className="col-xl-3 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search By Name & Mobile..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								<div className="col-xl-3 col-sm-12">
									<Link
										to={"add-user"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add User
									</Link>
								</div>
								<div className="col-xl-3 col-sm-12">
									<Link
										to={"upload-user"}
										className="btn btn-primary mb-2 pull-right"
									>
										Upload Users
									</Link>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>User Name</th>
											<th>Mobile</th>
											<th>Email</th>
											<th>Address</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentUsers?.length
											? currentUsers
													?.sort((a, b) => a.name.localeCompare(b.name))
													.map((user, i) => (
														<tr key={i}>
															<td>{user.name} </td>
															<td>{user.mobile} </td>
															<td>{user.email} </td>
															<td>{user.address}</td>
															<td>
																<div className="d-flex">
																	<Link
																		to={`add-user/${user._id}`}
																		className="btn btn-info shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-pencil"></i>
																	</Link>
																	<Link
																		to={`user-details/${user._id}`}
																		className="btn btn-primary shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-th"></i>
																	</Link>
																	<Link
																		to="#"
																		className="btn btn-danger shadow btn-xs sharp"
																		onClick={this.deleteRecord.bind(
																			this,
																			user._id
																		)}
																	>
																		<i className="fa fa-trash"></i>
																	</Link>
																</div>
															</td>
														</tr>
													))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalUsers ? (
										<Pagination
											totalRecords={totalUsers}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
					<ToastContainer
						position="top-right"
						autoClose={3000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		users: state?.user?.users,
		loading: state?.user?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getUsersAction, deleteUserAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
