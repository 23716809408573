import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import { format } from "date-fns";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { getBillingAdjustmentsByFlatAction } from "../../../../../store/actions/BillingAdjustmentActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class BillingAdjustment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentBillingAdjustments: [],
			currentPage: null,
			totalPages: null,
			societyId: "",
		};
	}

	componentDidMount() {
		let flatData = JSON.parse(localStorage.getItem("userFlatDetails"));

		this.props.getBillingAdjustmentsByFlatAction(flatData?.flatId);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.billingAdjustments?.billAdjustments?.length !==
			this.props?.billingAdjustments?.billAdjustments?.length
		) {
			this.setState({
				currentBillingAdjustments: this.props?.billingAdjustments
					?.billAdjustments,
			});
		}
	}

	initData() {
		let currentBillingAdjustments =
			this.state?.currentBillingAdjustments?.length > 0
				? this.state?.currentBillingAdjustments
				: this.props?.billingAdjustments?.billAdjustments;

		this.setState({ currentBillingAdjustments });
	}

	onPageChanged = (data) => {
		const allBillingAdjustments = this.props?.billingAdjustments
			?.billAdjustments;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentBillingAdjustments = allBillingAdjustments?.slice(
			offset,
			offset + pageLimit
		);

		this.setState({ currentPage, currentBillingAdjustments, totalPages });
	};

	handleSearch(event) {
		let currentBillingAdjustments = this.props.billingAdjustments
			?.billAdjustments;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentBillingAdjustments = currentBillingAdjustments.filter(
				(e) =>
					e.flatNo.toLowerCase().match(searchString) ||
					e.adjustmentType.toLowerCase().match(searchString) ||
					e.adjustmentDescription.toLowerCase().match(searchString)
			);
			this.setState({ currentBillingAdjustments });
		} else {
			let arrayLength = currentBillingAdjustments.slice(0, 5);
			this.setState({ currentBillingAdjustments: arrayLength });
		}
		//this.setState({ currentBillingAdjustments });
	}

	render() {
		let totalBillingAdjustments =
			this.props?.billingAdjustments?.billAdjustments?.length || 0;

		const { currentBillingAdjustments, currentPage, totalPages } = this.state;
		this.state.currentBillingAdjustments = this.props.billingAdjustments?.billAdjustments;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Billing Adjustments</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Billing Adjustment</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
							</div>

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Flat No</th>
											<th>Adjustment Type</th>
											<th>Adjustment Description</th>
											<th>Amount</th>
											<th>Date</th>
											{/* <th>Action</th> */}
										</tr>
									</thead>
									<tbody>
										{currentBillingAdjustments?.length
											? currentBillingAdjustments.map((adjustment, i) => (
													<tr key={i}>
														<td>{adjustment.flatNo} </td>
														<td>{adjustment.adjustmentType}</td>
														<td>{adjustment.adjustmentDescription}</td>
														<td>{adjustment.amount}</td>
														<td>
															{adjustment?.createdAt &&
																format(
																	new Date(adjustment?.createdAt),
																	"dd-MM-yyyy"
																)}
														</td>
														{/* <td>
															<div className="d-flex">
																
																<Link
																	to={`billing-adjustment-details/${adjustment._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
																<Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={this.deleteRecord.bind(
																		this,
																		adjustment._id
																	)}
																>
																	<i className="fa fa-trash"></i>
																</Link>
															</div>
														</td> */}
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalBillingAdjustments ? (
										<Pagination
											totalRecords={totalBillingAdjustments}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		billingAdjustments: state.billingAdjustment?.billingAdjustments,
		loading: state?.billingAdjustment?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getBillingAdjustmentsByFlatAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAdjustment);
