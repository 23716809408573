import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getPaymentReceiptAction,
	createPaymentReceiptAction,
	updatePaymentReceiptAction,
} from "../../../../store/actions/PaymentReceiptActions";
import { getFlatsAction } from "../../../../store/actions/FlatManagementActions";

class AddEditPaymentReceipt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			paymentReceipt: {},
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.paymentReceipt) {
				this.props.getPaymentReceiptAction(id);
			}
		} else {
		}
		this.props.getFlatsAction(userData.user.societyName);
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.paymentReceipt?.length !== this.props?.paymentReceipt?.length
		) {
			this.setState({
				paymentReceipt: this.props?.paymentReceipt,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;

		var e = document.getElementById("val-flatId"); // To get the Flat NO from Flat id
		var flatText = e.options[e.selectedIndex].text;

		if (id) {
			this.props.updatePaymentReceiptAction(
				values,
				this.props.history,
				this.props?.paymentReceipt?._id
			);
		} else {
			Object.assign(values, { flatNo: flatText }); // Push flat no
			this.props.createPaymentReceiptAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var paymentReceipt = this.props?.paymentReceipt;
		} else {
			paymentReceipt = "";
		}

		let flat_data = this.props.flats?.flats;

		const initialValues = {
			societyId: this.state?.societyId,
			flatId: paymentReceipt ? paymentReceipt.flatId : "",
			month: paymentReceipt ? paymentReceipt.month : "",
			year: paymentReceipt ? paymentReceipt.year : "",
			paymentType: paymentReceipt ? paymentReceipt.paymentType : "",
			paymentMode: paymentReceipt ? paymentReceipt.paymentMode : "",
			amount: paymentReceipt ? paymentReceipt.amount : 0,
			bankDetails: paymentReceipt ? paymentReceipt.bankDetails : "",
			date: paymentReceipt ? paymentReceipt.date : new Date(),

			status: paymentReceipt ? paymentReceipt.status : true,
		};

		const validationSchema = Yup.object().shape({
			flatId: Yup.string().required("Please Select Flat Name."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item
						linkProps={{ to: "/payment-receipts" }}
						linkAs={Link}
					>
						Payment Receipt Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Payment Receipt</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Payment Receipt</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Payment Receipt</h3>
						) : (
							<h3 className="pt-3">Add Payment Receipt</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/payment-receipts"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Flat No.</label>
															<div>
																<select
																	className="form-control"
																	id="val-flatId"
																	placeholder="Select a Flat Name.."
																	name="flatId"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.flatId || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	{flat_data?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt._id}
																			// label={opt.flatNo}
																		>
																			{opt.flatNo}
																		</option>
																	))}
																</select>
																{errors.flatId && touched.flatId ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.flatId}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Maintenance Year
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-year"
																	placeholder="Enter a maintenance Year.."
																	name="year"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.year || ""}
																/>
																{errors.year && touched.year ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.year}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Maintenance Month
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-month"
																	placeholder="Enter a maintenance Month.."
																	name="month"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.month || ""}
																/>
																{errors.month && touched.month ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.month}
																	</div>
																) : null}
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Payment Type</label>
															<div>
																<select
																	className="form-control"
																	id="val-paymentType"
																	placeholder="Select a payment Type.."
																	name="paymentType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.paymentType || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	<option
																		value="Maintenance"
																		label="Maintenance"
																	/>
																	<option value="NOC" label="NOC" />
																	<option value="Other" label="other" />
																</select>
																{errors.paymentType && touched.paymentType ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.paymentType}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Payment Mode</label>
															<div>
																<select
																	className="form-control"
																	id="val-paymentMode"
																	placeholder="Select a payment mode.."
																	name="paymentMode"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.paymentMode || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	<option value="Cheque" label="Cheque" />
																	<option value="Cash" label="Cash" />
																	<option value="Transfer" label="Transfer" />
																</select>
																{errors.paymentMode && touched.paymentMode ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.paymentMode}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Amount</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-amount"
																	placeholder="Enter a Amount.."
																	name="amount"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.amount || 0}
																/>
																{errors.amount && touched.amount ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.amount}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Bank Details</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-bankDetails"
																	placeholder="Enter a Bank Details.."
																	name="bankDetails"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.bankDetails || ""}
																/>
																{errors.bankDetails && touched.bankDetails ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.bankDetails}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Date</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="date"
																		format="dd/MM/yyyy"
																		value={values.date}
																		onChange={(e) => setFieldValue("date", e)}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.date && touched.date ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.date}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddPaymentReceipt;
const mapStateToProps = (state) => {
	return {
		paymentReceipt: state.paymentReceipt?.paymentReceipt,
		flats: state.flat?.flats,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getPaymentReceiptAction,
			createPaymentReceiptAction,
			updatePaymentReceiptAction,
			getFlatsAction,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddEditPaymentReceipt);
