import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb, Modal } from "react-bootstrap";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Editor } from "@tinymce/tinymce-react";
import ReactHtmlParser from "react-html-parser";

import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify"; /// toast
import "react-toastify/dist/ReactToastify.css";

import {
	getRequestDemoAction,
	updateRequestDemoAction,
} from "../../../../../store/actions/RequestDemoActions";

class RequestDemoDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			requestDemo: {},
			scheduleDemoModal: false,
		};
		this.openScheduleDemo = this.openScheduleDemo.bind(this);
		this.handleUpdateSchedule = this.handleUpdateSchedule.bind(this);
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.requestDemo) {
			this.props.getRequestDemoAction(id);
		}
		this.init();
	}

	init() {
		this.state.requestDemo = this.props?.requestDemo;
	}

	openScheduleDemo() {
		this.setState({
			scheduleDemoModal: !this.state.scheduleDemoModal,
		});
	}

	handleUpdateSchedule = (values) => {
		const id = this.props.match.params.id;

		this.props.updateRequestDemoAction(values, this.props.history, id);
	};

	render() {
		this.state.requestDemo = this.props?.requestDemo;

		const societyName = this.state.requestDemo?.societyName || "";
		const societyAddress = this.state.requestDemo?.societyAddress || "";
		const name = this.state.requestDemo?.name || "";
		const mobile = this.state.requestDemo?.mobile || "";
		const email = this.state.requestDemo?.email || "";
		const status = this.state.requestDemo?.status || "";
		const requestedDate = this.state.requestDemo?.requestedDate || "";
		const demoScheduledDate = this.state.requestDemo?.demoScheduledDate || "";
		const feedback = this.state.requestDemo?.feedback || "";

		const initialValues = {
			status: this.state.requestDemo?.status
				? this.state.requestDemo?.status
				: "",
			feedback: this.state.requestDemo?.feedback
				? this.state.requestDemo?.feedback
				: "",
			//demoScheduledDate: new Date(),
		};

		const today = new Date();
		today.setHours(0, 0, 0, 0);

		const validationSchema = Yup.object().shape({
			status: Yup.string().required("Please Select a Status"),
			demoScheduledDate: Yup.date().when("status", (status, schema) => {
				if (status === "Demo Scheduled")
					return schema
						.required("Please Select Demo Schedule Date")
						.min(today, "Date cannot be in the past");
			}),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item
						linkProps={{ to: "/superadmin/requestDemo-lists" }}
						linkAs={Link}
					>
						Request Demo Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Request Demo Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Request Demo Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/superadmin/requestDemo-lists"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Name</strong>
										<span className="mb-0">{societyName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Address</strong>
										<span className="mb-0">{societyAddress}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Contact Name</strong>
										<span className="mb-0">{name}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Contact Mobile</strong>
										<span className="mb-0">{mobile}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Contact Email</strong>
										<span className="mb-0">{email}</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === "Demo Requested" && (
													<span className="badge badge-rounded badge-primary">
														{status}
													</span>
												)}
												{status === "Demo Scheduled" && (
													<span className="badge badge-rounded badge-info">
														{status}
													</span>
												)}
												{status === "Demo Given" && (
													<span className="badge badge-rounded badge-warning">
														{status}
													</span>
												)}
												{status === "Approval Pending" && (
													<span className="badge badge-rounded badge-danger">
														{status}
													</span>
												)}

												{status === "Completed" && (
													<span className="badge badge-rounded badge-success">
														{status}
													</span>
												)}
											</div>
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Requested Date</strong>
										<span className="mb-0">
											{requestedDate &&
												format(new Date(requestedDate), "dd-MM-yyyy")}
										</span>
									</li>
									{demoScheduledDate && (
										<li className="list-group-item d-flex px-0 justify-content-between">
											<strong>Demo Scheduled Date</strong>
											<span className="mb-0">
												{demoScheduledDate &&
													format(new Date(demoScheduledDate), "dd-MM-yyyy")}
											</span>
										</li>
									)}
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Feedback</strong>
										<span
											className="mb-0"
											style={{ width: "100%", paddingLeft: "20rem" }}
										>
											{feedback && ReactHtmlParser(feedback)}
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<button
											className="btn btn-primary"
											onClick={this.openScheduleDemo}
										>
											Update Status
										</button>
										<Modal
											show={this.state.scheduleDemoModal}
											onHide={this.openScheduleDemo}
										>
											<Modal.Header>
												<h3>Change Demo Status</h3>
												<button
													type="button"
													className="close"
													aria-label="Close"
													onClick={this.openScheduleDemo}
												>
													<span aria-hidden="true">&times;</span>
												</button>
											</Modal.Header>
											<Modal.Body className="p-1">
												<div className="row">
													<div className="col-sm-12">
														<div className="card">
															<div className="card-body">
																<div className="form-validation">
																	<Formik
																		initialValues={initialValues}
																		validationSchema={validationSchema}
																		onSubmit={(values, { setSubmitting }) => {
																			setTimeout(() => {
																				this.handleUpdateSchedule(values);
																				setSubmitting(false);
																			}, 400);
																		}}
																	>
																		{({
																			values,
																			errors,
																			touched,
																			handleChange,
																			handleBlur,
																			handleSubmit,
																			isSubmitting,
																			setFieldValue,
																		}) => (
																			<form onSubmit={handleSubmit}>
																				<div className="row">
																					<div className="col-lg-6">
																						<div
																							className={`form-group ${
																								values.status
																									? errors.status
																										? "is-invalid"
																										: "is-valid"
																									: ""
																							}`}
																						>
																							<label className="text-label">
																								Status *
																							</label>
																							<div>
																								<select
																									className="form-control"
																									id="val-status"
																									placeholder="Select Status.."
																									name="status"
																									onChange={handleChange}
																									onBlur={handleBlur}
																									value={values.status}
																								>
																									<option
																										value="Select"
																										label="Select"
																									/>

																									<option
																										value="Demo Scheduled"
																										label="Demo Scheduled"
																									/>
																									<option
																										value="Demo Given"
																										label="Demo Given"
																									/>
																									<option
																										value="Approval Pending"
																										label="Approval Pending"
																									/>
																									<option
																										value="Completed"
																										label="Completed"
																									/>
																								</select>

																								<div
																									id="val-status-error"
																									className="invalid-feedback animated fadeInUp"
																									style={{ display: "block" }}
																								>
																									{errors.status &&
																										errors.status}
																								</div>
																							</div>
																						</div>
																					</div>
																					{values.status ===
																						"Demo Scheduled" && (
																						<div className="col-lg-6">
																							<div
																								className={`form-group ${
																									values.demoScheduledDate
																										? errors.demoScheduledDate
																											? "is-invalid"
																											: "is-valid"
																										: ""
																								}`}
																							>
																								<label className="text-label">
																									Schedule Demo Date *
																								</label>
																								<div>
																									<MuiPickersUtilsProvider
																										utils={DateFnsUtils}
																									>
																										<DatePicker
																											id="demoScheduledDate"
																											format="dd/MM/yyyy"
																											value={
																												values.demoScheduledDate ||
																												new Date()
																											}
																											onChange={(e) =>
																												setFieldValue(
																													"demoScheduledDate",
																													e
																												)
																											}
																											onBlur={handleBlur}
																										/>
																									</MuiPickersUtilsProvider>
																									<div
																										id="val-demoScheduledDate-error"
																										className="invalid-feedback animated fadeInUp"
																										style={{ display: "block" }}
																									>
																										{errors.demoScheduledDate &&
																											errors.demoScheduledDate}
																									</div>

																									<div
																										id="val-demoScheduledDate-error"
																										className="invalid-feedback animated fadeInUp"
																										style={{ display: "block" }}
																									/>
																								</div>
																							</div>
																						</div>
																					)}
																				</div>
																				<div className="row">
																					<div className="col-xl-12 col-sm-12">
																						<div className="form-group">
																							<label className="text-label">
																								Feedback{" "}
																							</label>
																							<div>
																								<Editor
																									init={{
																										height: 300,
																										//menubar: false,
																										plugins: [
																											"advlist autolink lists link image code charmap print preview anchor",
																											"searchreplace visualblocks code fullscreen",
																											"insertdatetime media table paste code help wordcount",
																										],
																										toolbar:
																											"undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \
            																bullist numlist outdent indent | removeformat | help ",
																										content_style:
																											"body { color: #828282 }",
																									}}
																									onEditorChange={(e) =>
																										handleChange({
																											target: {
																												name: "feedback",
																												value: e,
																											},
																										})
																									}
																									onChange={handleChange}
																									onBlur={handleBlur}
																									value={values.feedback || ""}
																								/>
																								{errors.feedback &&
																								touched.feedback ? (
																									<div
																										className="invalid-feedback animated fadeInUp"
																										style={{ display: "block" }}
																									>
																										{errors.feedback}
																									</div>
																								) : null}
																							</div>
																						</div>
																					</div>
																				</div>

																				<button
																					type="submit"
																					className="btn mr-2 btn-primary"
																					disabled={isSubmitting}
																				>
																					Send Request
																				</button>
																			</form>
																		)}
																	</Formik>
																</div>
															</div>
														</div>
													</div>
													<ToastContainer
														position="top-right"
														autoClose={3000}
														hideProgressBar={false}
														newestOnTop={false}
														closeOnClick
														rtl={false}
														pauseOnFocusLoss
														draggable
														pauseOnHover
													/>
												</div>
											</Modal.Body>
										</Modal>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		requestDemo: state.requestDemo?.requestDemo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getRequestDemoAction, updateRequestDemoAction },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDemoDetail);
