import {
	getNewsDetails,
	getNewses,
	createNews,
	updateNews,
	deleteNews,
} from "../../services/NewsService";
import {
	CONFIRMED_GET_NEWS_DETAILS,
	CONFIRMED_CREATE_NEWS_ACTION,
	CONFIRMED_GET_NEWSES,
	CONFIRMED_EDIT_NEWS_ACTION,
	CONFIRMED_DELETE_NEWS_ACTION,
} from "./NewsTypes";

export function getNewsAction(newsId) {
	return (dispatch, getState) => {
		getNewsDetails(newsId).then((response) => {
			dispatch(confirmedGetNewsAction(response.data.news));
		});
	};
}

export function confirmedGetNewsAction(news) {
	return {
		type: CONFIRMED_GET_NEWS_DETAILS,
		payload: news,
	};
}

export function deleteNewsAction(newsId, history) {
	return (dispatch, getState) => {
		deleteNews(newsId).then((response) => {
			dispatch(confirmedDeleteNewsAction(newsId));
			history.push("/news");
		});
	};
}

export function confirmedDeleteNewsAction(newsId) {
	return {
		type: CONFIRMED_DELETE_NEWS_ACTION,
		payload: newsId,
	};
}

export function createNewsAction(NewsData, history) {
	return (dispatch, getState) => {
		createNews(NewsData).then((response) => {
			const singleNews = {
				...NewsData,
				id: response.data._id,
			};
			dispatch(confirmedCreateNewsAction(singleNews));
			history.push("/news");
		});
	};
}

export function confirmedCreateNewsAction(singleNews) {
	return {
		type: CONFIRMED_CREATE_NEWS_ACTION,
		payload: singleNews,
	};
}

export function getNewsesAction(societyId) {
	return (dispatch, getState) => {
		getNewses(societyId).then((response) => {
			dispatch(confirmedGetNewsesAction(response.data));
		});
	};
}

export function confirmedGetNewsesAction(Newses) {
	return {
		type: CONFIRMED_GET_NEWSES,
		payload: Newses,
	};
}

export function confirmedUpdateNewsAction(News) {
	return {
		type: CONFIRMED_EDIT_NEWS_ACTION,
		payload: News,
	};
}

export function updateNewsAction(News, history, newsId) {
	return (dispatch, getState) => {
		updateNews(News, newsId).then((reponse) => {
			dispatch(confirmedUpdateNewsAction(News));
			history.push("/news");
		});
	};
}
