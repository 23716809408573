import { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getSocietiesAction } from "../../../../../store/actions/SocietyActions";

import axiosInstance from "../../../../../services/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options = [
	{ value: "maintenanceCharges", label: "Maintenance Charges" },
	{ value: "residentialCharges", label: "Residential Charges" },
	{ value: "commercialCharges", label: "Commercial Charges" },
	{ value: "parkingCharges", label: "Parking Charges" },
	{ value: "festivalCharges", label: "Festival Charges" },
	{ value: "sinkingFund", label: "Sinking Fund" },
	{ value: "repairFund", label: "Repair Fund" },
	{ value: "cidcoCharges", label: "Cidco Charges" },
	{ value: "nocCharges", label: "Noc Charges" },
	{ value: "otherCharges", label: "Other Charges" },
	// { value: "arrearsInterest", label: "arrearsInterest" },
	{ value: "arrears", label: "Arrears" },
];

const CustomLabel = ({
	societies,
	getSocietiesAction,
	getSingleCustomLabel,
}) => {
	const { id } = useParams();
	let history = useHistory();
	const [societiesArray, setsocietiesArray] = useState([]);
	const [selected, setSelected] = useState([]);
	const [society, setSociety] = useState("Select");
	const [customLabelArray, setCustomLabelArray] = useState([]);

	useEffect(() => {
		getSocietiesAction();
		if (id) {
			getSingleCustomLabel();
		}
	}, []);

	useEffect(() => {
		setsocietiesArray(societies?.societies);
	}, [societies]);

	function handleChange(event) {
		setSociety(event.target.value);
	}

	async function getSingleCustomLabel() {
		const resp = await axiosInstance
			.get(`admin/customLabel/${id}`)
			.then((res) => res?.data)
			.catch((err) => {
				return err;
			});
		setCustomLabelArray(resp?.customLabel);
		if (customLabelArray?.customLabel?.length) {
			setSelected(customLabelArray?.customLabel);
		}
	}

	async function handleSubmit(e) {
		e.preventDefault();

		const payload = {
			societyId: customLabelArray?.societyId?._id || society,
			customLabel: selected,
		};

		if (id) {
			const resp = await axiosInstance
				.put(`admin/customLabel/${id}`, payload)
				.then((res) => res?.data)
				.catch((err) => {
					return err;
				});
			if (resp.success === true) {
				history.push("/superadmin/custom-labels");
				toast.success("Successfully Updated Custom Label!");
			} else {
				toast.error("Error updating the record!");
			}
		} else {
			const resp = await axiosInstance
				.post(`admin/customLabel`, payload)
				.then((res) => res?.data)
				.catch((err) => {
					return err;
				});
			if (resp.success === true) {
				history.push("/superadmin/custom-labels");
				toast.success("Successfully Added Custom Label!");
			} else {
				toast.error("Error adding the record!");
			}
		}
	}

	return (
		<>
			<Breadcrumb>
				<Breadcrumb.Item
					linkProps={{ to: "/superadmin/custom-labels" }}
					linkAs={Link}
				>
					Custom Label Lists
				</Breadcrumb.Item>{" "}
				<Breadcrumb.Item active>
					Add Society Custom Billing Label
				</Breadcrumb.Item>
			</Breadcrumb>
			<div className="row">
				<div className="col-md-6  col-sm-12">
					<h3 className="pt-3">Add Society Custom Billing Label</h3>
				</div>
				<div className="col-md-6 col-sm-12">
					<Link
						to={"/superadmin/custom-labels"}
						className="btn btn-primary mb-2 pull-right"
					>
						Back to Lists
					</Link>
				</div>

				<div className="col-sm-12">
					<div className="card">
						<div className="card-body">
							<div className="form-validation">
								<form onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-sm-4">
											<strong>Society Name:</strong>
											<div>
												{id ? (
													<select
														className="form-control"
														id="val-societyId"
														placeholder="Select a Society.."
														name="societyId"
														onChange={handleChange}
														value={customLabelArray?.societyId?._id}
													>
														<option value={customLabelArray?.societyId?._id}>
															{customLabelArray?.societyId?.societyName}
														</option>
													</select>
												) : (
													<select
														className="form-control"
														id="val-societyId"
														placeholder="Select a Society.."
														name="societyId"
														onChange={handleChange}
														value={society}
													>
														<option value="Select" label="Select" disabled />
														{societiesArray?.map((opt) => (
															<option
																key={opt._id}
																value={opt._id}
																label={opt.societyName}
															/>
														))}
													</select>
												)}
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-sm-6">
											<strong>Select Custom Label:</strong>
											<div>
												{customLabelArray.length && id ? (
													<MultiSelect
														options={options}
														value={customLabelArray?.customLabel}
														onChange={setSelected}
														labelledBy="Select"
														name="customLabel"
														className="form-control pl-0"
													/>
												) : (
													<MultiSelect
														options={options}
														value={selected}
														onChange={setSelected}
														labelledBy="Select"
														name="customLabel"
														className="form-control pl-0"
													/>
												)}
											</div>
										</div>
									</div>

									<input
										type="submit"
										value="Save"
										className="btn btn-primary mt-3"
									/>
								</form>
							</div>
						</div>
					</div>
				</div>
				<ToastContainer
					position="top-right"
					autoClose={3000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		societies: state.society.societies,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getSocietiesAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomLabel);
