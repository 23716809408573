import React, { useContext } from "react"; //lazy

/// React router dom
import { Switch, Route } from "react-router-dom"; //BrowserRouter as Router, Redirect

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import Profile from "./layouts/nav/Profile";
/// Dashboard
import Index from "./components/Dashboard/Index";

import Dashboard from "./components/Dashboard/Dashboard";

import SocietyInformation from "./components/Society/SocietyInformation";
import AddEditSocietyInformation from "./components/Society/AddEditSocietyInformation";

import SuperadminDashboard from "./components/SuperAdmin/Dashboard/Dashboard";
import Societies from "./components/SuperAdmin/Society/list/Society";
import SocietyAdd from "./components/SuperAdmin/Society/add/AddSociety";
import SocietyDetails from "./components/SuperAdmin/Society/detail/SocietyDetails";

import SuperadminAdmin from "./components/SuperAdmin/AdminUser/list/AdminUser";
import SuperadminAdminAdd from "./components/SuperAdmin/AdminUser/add/AddAdminUser";
import SuperadminAdminDetails from "./components/SuperAdmin/AdminUser/detail/AdminUserDetails";

import SocietyProfile from "./components/Society/SocietyProfile";

import BillingHeaderMapping from "./components/SuperAdmin/BillingHeaderMapping/list/BillingHeaderMapping";
import AddEditBillingHeaderMapping from "./components/SuperAdmin/BillingHeaderMapping/add/AddBillingHeaderMapping";
import BillingHeaderMappingDetails from "./components/SuperAdmin/BillingHeaderMapping/detail/BillingHeaderMappingDetails";

import CustomLabelMapping from "./components/SuperAdmin/LabelConfig/list/LabelConfig";
import AddCustomLabelMapping from "./components/SuperAdmin/LabelConfig/add/AddLabelConfig";
import CustomLabelDetails from "./components/SuperAdmin/LabelConfig/detail/LabelConfigDetails";

import RequestDemo from "./components/SuperAdmin/RequestDemo/list/RequestDemo";
import RequestDemoDetails from "./components/SuperAdmin/RequestDemo/detail/RequestDemoDetails";

import SocietyContact from "./components/SocietyContact/list/SocietyContact";
import SocietyContactDetails from "./components/SocietyContact/detail/SocietyContactDetails";
import AddEditSocietyContact from "./components/SocietyContact/add/AddSocietyContact";

import SocietyImage from "./components/SocietyImage/list/SocietyImage";
import SocietyImageDetails from "./components/SocietyImage/detail/SocietyImageDetails";
import AddEditSocietyImage from "./components/SocietyImage/add/AddSocietyImage";

import MaintenanceInformation from "./components/Maintenance/MaintenanceInformation";
import AddEditMaintenanceInformation from "./components/Maintenance/AddEditMaintenanceInformation";
import MaintenanceManagement from "./components/Maintenance/MaintenanceManagement";
import FlatManagement from "./components/Maintenance/Flat/list/FlatManagement";
import AddEditFlat from "./components/Maintenance/Flat/add/AddFlatManagement";
import FlatDetail from "./components/Maintenance/Flat/detail/FlatDetailManagement";
import UploadFlat from "./components/Maintenance/Flat/add/UploadFlat";
import AddAssociation from "./components/Maintenance/Flat/AddAssociation";
import Amenity from "./components/Amenties/list/Ammenities";
import AddEditAmenity from "./components/Amenties/add/AddAmmenities";
import AmenityDetails from "./components/Amenties/detail/AmmenitiesDetails";
import ExpenseCategory from "./components/ExpenseCategory/list/ExpenseCategory";
import AddEditExpenseCategory from "./components/ExpenseCategory/add/AddExpenseCategory";
import ExpenseCategoryDetails from "./components/ExpenseCategory/detail/ExpenseCategoryDetails";
import Expense from "./components/ExpenseDetails/list/Expense";
import AddEditExpense from "./components/ExpenseDetails/add/AddExpense";
import ExpenseDetails from "./components/ExpenseDetails/detail/ExpenseDetails";
import BillingAdjustment from "./components/BillingAdjustment/list/BillingAdjustment";
import AddEditBillingAdjustment from "./components/BillingAdjustment/add/AddBillingAdjustment";
import BillingAdjustmentDetails from "./components/BillingAdjustment/detail/BillingAdjustmentDetails";
import Billing from "./components/Billing/list/Billing";
import AddEditBilling from "./components/Billing/add/AddBilling";
import BillingDetails from "./components/Billing/detail/BillingDetails";
import PaymentReceipt from "./components/PaymentReceipts/list/PaymentReceipt";
import AddEditPaymentReceipt from "./components/PaymentReceipts/add/AddPaymentReceipt";
import PaymentReceiptDetails from "./components/PaymentReceipts/detail/PaymentReceiptDetails";
import Payment from "./components/Payments/list/Payment";
import AddEditPayment from "./components/Payments/add/AddPayment";
import PaymentDetails from "./components/Payments/detail/PaymentDetails";
import Vendor from "./components/Vendors/list/Vendor";
import AddEditVendor from "./components/Vendors/add/AddVendor";
import VendorDetails from "./components/Vendors/detail/VendorDetails";
import BillingTemplate from "./components/BillingTemplate/list/BillingTemplate";
import AddEditBillingTemplate from "./components/BillingTemplate/add/AddBillingTemplate";
import BillingTemplateDetails from "./components/BillingTemplate/detail/BillingTemplateDetails";

import News from "./components/News/list/News";
import AddEditNews from "./components/News/add/AddNews";
import NewsDetails from "./components/News/detail/NewsDetails";

import Notice from "./components/Notice/list/Notice";
import AddEditNotice from "./components/Notice/add/AddNotice";
import NoticeDetails from "./components/Notice/detail/NoticeDetails";

import RequestQuery from "./components/RequestQuery/list/RequestQuery";
import AddEditRequestQuery from "./components/RequestQuery/add/AddRequestQuery";
import RequestQueryDetails from "./components/RequestQuery/detail/RequestQueryDetails";

import Amc from "./components/Amc/list/Amc";
import AddEditAmc from "./components/Amc/add/AddAmc";
import AmcDetails from "./components/Amc/detail/AmcDetails";

import Polling from "./components/Polling/list/Polling";
import AddEditPolling from "./components/Polling/add/AddPolling";
import PollingDetails from "./components/Polling/detail/PollingDetails";

import VendorType from "./components/VendorType/list/VendorType";
import AddEditVendorType from "./components/VendorType/add/AddVendorType";
import VendorTypeDetails from "./components/VendorType/detail/VendorTypeDetails";

import AdminUser from "./components/AdminUser/list/AdminUser";
import AddEditAdminUser from "./components/AdminUser/add/AddAdminUser";
import AdminUserDetails from "./components/AdminUser/detail/AdminUserDetails";
import ChangePassword from "./components/AdminUser/ChangePassword";

import User from "./components/User/list/User";
import AddEditUser from "./components/User/add/AddUser";
import UserDetails from "./components/User/detail/UserDetails";
import UploadUser from "./components/User/UploadUser";
import OutstandingDetails from "./components/Reports/OutstandingDetails/OutstandingDetails";
import LeaseholdFlatDetails from "./components/Reports/LeaseholdFlatDetails/LeaseholdFlatDetails";
import IndividualAccountDetails from "./components/Reports/InvidualAccountDetails/InvidualAccountDetails";
import IncomeExpenditure from "./components/Reports/IncomeExpenditure/IncomeExpenditure";
import ExpenseAccount from "./components/Reports/ExpenseAccountLedger/ExpenseAccountLedger";

import Meeting from "./components/Meeting/list/Meeting";
import AddEditMeeting from "./components/Meeting/add/AddMeeting";
import MeetingDetails from "./components/Meeting/detail/MeetingDetails";

import BankDetail from "./components/BankDetails/list/BankDetails";
import AddEditBankDetail from "./components/BankDetails/add/AddBankDetails";
import BankDetailDetails from "./components/BankDetails/detail/BankDetailsDetails";

///Member pages
import MemberDashboard from "./components/Member/Dashboard/Dashboard";
import MemberSocietyInformation from "./components/Member/Society/SocietyInformation";
import MemberNotice from "./components/Member/Notice/list/Notice";
import MemberNoticeDetails from "./components/Member/Notice/detail/NoticeDetails";
import MemberMeeting from "./components/Member/Meeting/list/Meeting";
import MemberMeetingDetails from "./components/Member/Meeting/detail/MeetingDetails";
import MemberBillingAdjustment from "./components/Member/BillingAdjustment/list/BillingAdjustment";
import MemberVendor from "./components/Member/Vendors/list/Vendor";
import MemberRequest from "./components/Member/RequestQuery/list/RequestQuery";
import MemberRequestDetails from "./components/Member/RequestQuery/detail/RequestQueryDetails";
import AddEditMemberRequest from "./components/Member/RequestQuery/add/AddRequestQuery";

import MemberPayment from "./components/Member/Payments/list/Payment";
import AddEditMemberPayment from "./components/Member/Payments/add/AddPayment";
import MemberPaymentDetails from "./components/Member/Payments/detail/PaymentDetails";
import MemberBillingHistory from "./components/Member/Billing/list/Billing";
import MemberPollings from "./components/Member/Polling/list/Polling";
import MemberPollingDetails from "./components/Member/Polling/detail/PollingDetails";
import UserProfile from "./components/Member/UserProfile/Profile";
import EditUserProfile from "./components/Member/UserProfile/EditProfile";
import UserChangePassword from "./components/Member/UserChangePassword";

/* const Index = lazy(() => {
  return new Promise(resolve => {
	setTimeout(() => resolve(import('./components/Dashboard/Index')), 500);
  });
});
 */

// import Posts from "./pages/Posts/Posts";

import { ThemeContext } from "../../context/ThemeContext";

import ScrollToTop from "./layouts/ScrollToTop";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Dashboard },
    { url: "dashboard", component: Dashboard },
    { url: "index", component: Index },
    { url: "profile", component: Profile },
    { url: "change-password", component: ChangePassword },

    /// Society
    { url: "superadmin/dashboard", component: SuperadminDashboard },
    { url: "superadmin/societies", component: Societies },
    { url: "superadmin/add-society", component: SocietyAdd },
    { url: "superadmin/add-society/:id", component: SocietyAdd },
    // { url: "superadmin/societies/add", component: SocietyAdd },
    {
      url: "superadmin/society-details/:id",
      component: SocietyDetails,
    },

    /// Society
    { url: "superadmin/admin", component: SuperadminAdmin },
    { url: "superadmin/add-admin", component: SuperadminAdminAdd },
    { url: "superadmin/add-admin/:id", component: SuperadminAdminAdd },
    {
      url: "superadmin/admin-details/:id",
      component: SuperadminAdminDetails,
    },

    /// Billing Header Mapping
    {
      url: "superadmin/billing-header-mapping",
      component: BillingHeaderMapping,
    },
    {
      url: "superadmin/add-billing-header-mapping",
      component: AddEditBillingHeaderMapping,
    },
    {
      url: "superadmin/add-billing-header-mapping/:id",
      component: AddEditBillingHeaderMapping,
    },
    {
      url: "superadmin/billing-header-mapping-details/:id",
      component: BillingHeaderMappingDetails,
    },
    {
      url: "superadmin/add-custom-label",
      component: AddCustomLabelMapping,
    },
    {
      url: "superadmin/add-custom-label/:id",
      component: AddCustomLabelMapping,
    },
    {
      url: "superadmin/custom-labels",
      component: CustomLabelMapping,
    },
    {
      url: "superadmin/custom-label-details/:id",
      component: CustomLabelDetails,
    },

    {
      url: "superadmin/requestDemo-lists",
      component: RequestDemo,
    },
    {
      url: "superadmin/requestDemo-details/:id",
      component: RequestDemoDetails,
    },

    // Society Information
    { url: "society-information", component: SocietyInformation },
    { url: "add-society-information", component: AddEditSocietyInformation },

    // Society Contact
    { url: "society-contacts", component: SocietyContact },
    { url: "society-contact-details/:id", component: SocietyContactDetails },
    { url: "add-society-contact", component: AddEditSocietyContact },
    { url: "add-society-contact/:id", component: AddEditSocietyContact },

    //Society Image
    { url: "society-images", component: SocietyImage },
    { url: "society-image-details/:id", component: SocietyImageDetails },
    { url: "add-society-image", component: AddEditSocietyImage },
    { url: "add-society-image/:id", component: AddEditSocietyImage },

    //Amenity
    { url: "amenity-lists", component: Amenity },
    { url: "add-amenity", component: AddEditAmenity },
    { url: "add-amenity/:id", component: AddEditAmenity },
    { url: "amenity-details/:id", component: AmenityDetails },

    ///Maintenance
    { url: "maintenance-information", component: MaintenanceInformation },
    {
      url: "add-maintenance-information",
      component: AddEditMaintenanceInformation,
    },
    {
      url: "add-maintenance-information/:id",
      component: AddEditMaintenanceInformation,
    },

    { url: "maintenance-management", component: MaintenanceManagement },
    { url: "flat-management", component: FlatManagement },
    { url: "add-flat", component: AddEditFlat },
    // { url: "add-flat/:societyId/:userId", component: AddEditFlat },
    // { url: "flat-detail/:societyId/:userId", component: FlatDetail },
    { url: "add-flat/:id", component: AddEditFlat },
    { url: "flat-detail/:id", component: FlatDetail },
    { url: "add-association", component: AddAssociation },
    { url: "upload-flat", component: UploadFlat },

    //Expenses Details
    { url: "expense", component: Expense },
    { url: "add-expense", component: AddEditExpense },
    { url: "add-expense/:id", component: AddEditExpense },
    { url: "expense-details/:id", component: ExpenseDetails },

    //Expenses Category
    { url: "expense-category-lists", component: ExpenseCategory },
    { url: "add-expense-category", component: AddEditExpenseCategory },
    { url: "add-expense-category/:id", component: AddEditExpenseCategory },
    { url: "expense-category-details/:id", component: ExpenseCategoryDetails },

    //Billing Adjustment
    { url: "billing-adjustment-lists", component: BillingAdjustment },
    { url: "add-billing-adjustment", component: AddEditBillingAdjustment },
    { url: "add-billing-adjustment/:id", component: AddEditBillingAdjustment },
    {
      url: "billing-adjustment-details/:id",
      component: BillingAdjustmentDetails,
    },

    //Billing
    { url: "billings", component: Billing },
    { url: "add-billing", component: AddEditBilling },
    { url: "add-billing/:id", component: AddEditBilling },
    {
      url: "billing-details/:id",
      component: BillingDetails,
    },

    //Billing Template
    { url: "billing-templates", component: BillingTemplate },
    { url: "add-billing-template", component: AddEditBillingTemplate },
    { url: "add-billing-template/:id", component: AddEditBillingTemplate },
    {
      url: "billing-template-details/:id",
      component: BillingTemplateDetails,
    },

    //Payment Details
    { url: "payments", component: Payment },
    { url: "add-payment", component: AddEditPayment },
    { url: "add-payment/:id", component: AddEditPayment },
    {
      url: "payment-details/:id",
      component: PaymentDetails,
    },

    //Payment Receipts
    { url: "payment-receipts", component: PaymentReceipt },
    { url: "add-payment-receipt", component: AddEditPaymentReceipt },
    { url: "add-payment-receipt/:id", component: AddEditPaymentReceipt },
    {
      url: "payment-receipt-details/:id",
      component: PaymentReceiptDetails,
    },

    //News
    { url: "news", component: News },
    { url: "add-news", component: AddEditNews },
    { url: "add-news/:id", component: AddEditNews },
    {
      url: "news-details/:id",
      component: NewsDetails,
    },

    //Notice
    { url: "notices", component: Notice },
    { url: "add-notice", component: AddEditNotice },
    { url: "add-notice/:id", component: AddEditNotice },
    {
      url: "notice-details/:id",
      component: NoticeDetails,
    },

    //Request Query
    { url: "request-queries", component: RequestQuery },
    { url: "add-request-query", component: AddEditRequestQuery },
    { url: "add-request-query/:id", component: AddEditRequestQuery },
    {
      url: "request-query-details/:id",
      component: RequestQueryDetails,
    },

    //Vendor Type
    { url: "vendor-type", component: VendorType },
    { url: "add-vendor-type", component: AddEditVendorType },
    { url: "add-vendor-type/:id", component: AddEditVendorType },
    {
      url: "vendor-type-details/:id",
      component: VendorTypeDetails,
    },

    //Vendors
    { url: "vendors", component: Vendor },
    { url: "add-vendor", component: AddEditVendor },
    { url: "add-vendor/:id", component: AddEditVendor },
    {
      url: "vendor-details/:id",
      component: VendorDetails,
    },

    //Amc
    { url: "amc", component: Amc },
    { url: "add-amc", component: AddEditAmc },
    { url: "add-amc/:id", component: AddEditAmc },
    {
      url: "amc-details/:id",
      component: AmcDetails,
    },

    //Meeting
    { url: "meetings", component: Meeting },
    { url: "add-meeting", component: AddEditMeeting },
    { url: "add-meeting/:id", component: AddEditMeeting },
    {
      url: "meeting-details/:id",
      component: MeetingDetails,
    },

    //Amc
    { url: "pollings", component: Polling },
    { url: "add-polling", component: AddEditPolling },
    { url: "add-polling/:id", component: AddEditPolling },
    {
      url: "polling-details/:id",
      component: PollingDetails,
    },

    //Admin Users
    { url: "admin", component: AdminUser },
    { url: "add-admin", component: AddEditAdminUser },
    { url: "add-admin/:id", component: AddEditAdminUser },
    {
      url: "admin-details/:id",
      component: AdminUserDetails,
    },

    // Users
    { url: "users", component: User },
    { url: "add-user", component: AddEditUser },
    { url: "add-user/:id", component: AddEditUser },
    {
      url: "user-details/:id",
      component: UserDetails,
    },
    { url: "upload-user", component: UploadUser },

    //Reports
    {
      url: "outstanding-details",
      component: OutstandingDetails,
    },
    {
      url: "leasehold-flat-details",
      component: LeaseholdFlatDetails,
    },
    {
      url: "individual-account-details",
      component: IndividualAccountDetails,
    },
    {
      url: "income-expenditure",
      component: IncomeExpenditure,
    },
    {
      url: "expense-account-ledger",
      component: ExpenseAccount,
    },

    //Bank Details
    { url: "bankdetails", component: BankDetail },
    { url: "add-bankdetail", component: AddEditBankDetail },
    { url: "add-bankdetail/:id", component: AddEditBankDetail },
    {
      url: "bankdetail-details/:id",
      component: BankDetailDetails,
    },

    ///Member Login routes
    { url: "memberDashboard", component: MemberDashboard },
    { url: "member-society-information", component: MemberSocietyInformation },
    { url: "member-notices", component: MemberNotice },
    { url: "member-notice-details/:id", component: MemberNoticeDetails },
    { url: "member-meetings", component: MemberMeeting },
    { url: "member-meeting-details/:id", component: MemberMeetingDetails },
    { url: "member-billingAdjustments", component: MemberBillingAdjustment },
    { url: "member-vendors", component: MemberVendor },
    { url: "member-request-queries", component: MemberRequest },
    {
      url: "member/request-query-details/:id",
      component: MemberRequestDetails,
    },
    { url: "member/add-request-query", component: AddEditMemberRequest },
    { url: "member-payments", component: MemberPayment },
    { url: "member/add-payment", component: AddEditMemberPayment },
    { url: "member/add-payment/:id", component: AddEditMemberPayment },
    { url: "member-payment-details/:id", component: MemberPaymentDetails },
    { url: "member-billings", component: MemberBillingHistory },
    { url: "member-pollings", component: MemberPollings },
    { url: "member-polling-details/:id", component: MemberPollingDetails },
    { url: "userprofile", component: UserProfile },
    { url: "edit-userprofile", component: EditUserProfile },
    { url: "member-change-password", component: UserChangePassword },

    //{ url: "posts", component: Posts },

    // Society By URL
    // { url: "society/profile", component: SocietyProfile },
    { url: ":societyurl", component: SocietyProfile },
  ];
  let path = window.location?.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}
        {/* {<Nav />} */}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      {/* <Setting /> */}
      <ScrollToTop />
    </>
  );
};

export default Markup;
