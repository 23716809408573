import axiosInstance from "../services/AxiosInstance";

export function getMaintenanceMasterDetails(masterId) {
	return axiosInstance.get(`admin/maintenanceMaster/${masterId}`);
}

export function getMaintenanceMasters() {
	return axiosInstance.get(`admin/maintenanceMaster`);
}

export function createMaintenanceMaster(masterData) {
	return axiosInstance.post(`admin/maintenanceMaster`, masterData);
}

export function updateMaintenanceMaster(maintenancemaster, masterId) {
	return axiosInstance.put(
		`admin/maintenanceMaster/${masterId}`,
		maintenancemaster
	);
}

export function deleteMaintenanceMaster(masterId) {
	return axiosInstance.delete(`admin/maintenanceMaster/${masterId}`);
}
