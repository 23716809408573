import {
	CONFIRMED_GET_ADMINUSER_DETAILS,
	CONFIRMED_CREATE_ADMINUSER_ACTION,
	CONFIRMED_GET_ADMINUSERS,
	CONFIRMED_EDIT_ADMINUSER_ACTION,
	CONFIRMED_DELETE_ADMINUSER_ACTION,
	CONFIRMED_EDIT_ADMINUSERPASSWORD_ACTION,
	LOADING,
} from "../actions/AdminUserTypes";

const initialState = {
	adminUsers: [],
	adminUser: {},
};

export default function AdminUserReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_ADMINUSER_DETAILS) {
		return {
			...state,
			adminUser: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_ADMINUSER_ACTION) {
		const adminUsers = [...state.adminUsers.adminUsers];
		const adminUserIndex = adminUsers.findIndex(
			(adminUser) => adminUser.id === actions.payload
		);

		adminUsers.splice(adminUserIndex, 1);

		return {
			...state,
			adminUsers: { adminUsers },
		};
	}

	if (actions.type === CONFIRMED_EDIT_ADMINUSER_ACTION) {
		return {
			...state,
			adminUsers: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_ADMINUSER_ACTION) {
		return {
			...state,
			adminUsers: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_ADMINUSERS) {
		return {
			...state,
			adminUsers: actions.payload,
			loading: false,
		};
	}

	if (actions.type === CONFIRMED_EDIT_ADMINUSERPASSWORD_ACTION) {
		return {
			...state,
			adminUsers: actions.payload,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
