import axiosInstance from "../services/AxiosInstance";

export function getPollingDetails(pollingId) {
	return axiosInstance.get(`admin/poll/${pollingId}`);
}

export function getPollings(societyId) {
	return axiosInstance.get(`admin/polls/${societyId}`);
}

export function createPolling(pollingData) {
	return axiosInstance.post(`admin/poll`, pollingData);
}

export function updatePolling(polling, pollingId) {
	return axiosInstance.put(`admin/poll/${pollingId}`, polling);
}

export function deletePolling(pollingId) {
	return axiosInstance.delete(`admin/poll/${pollingId}`);
}
