import axiosInstance from "../services/AxiosInstance";

export function getAdminUserDetails(adminId) {
	return axiosInstance.get(`admin/adminUser/${adminId}`);
}

export function getAdminUsers() {
	return axiosInstance.get(`admin/adminUser`);
}

export function createAdminUser(adminData) {
	return axiosInstance.post(`admin/adminUser`, adminData);
}

export function updateAdminUser(admin, adminId) {
	return axiosInstance.put(`admin/adminUser/${adminId}`, admin);
}

export function deleteAdminUser(adminId) {
	return axiosInstance.delete(`admin/adminUser/${adminId}`);
}

export function updateAdminPassword(admin) {
	return axiosInstance.put(`admin/password/update`, admin);
}
