import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";

import swal from "sweetalert";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
	getBillingHeadersAction,
	deleteBillingHeaderAction,
} from "../../../../../store/actions/BillingHeaderMappingActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class BillingHeaderMapping extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentBillingHeaders: [],
			currentPage: null,
			totalPages: null,
			societyId: "",
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		this.props.getBillingHeadersAction();
		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.billingHeaders?.labelMapping?.length !==
			this.props?.billingHeaders?.labelMapping?.length
		) {
			this.setState({
				currentBillingHeaders: this.props?.billingHeaders?.labelMapping,
			});
		}
	}

	initData() {
		let currentBillingHeaders =
			this.state?.currentBillingHeaders?.length > 0
				? this.state?.currentBillingHeaders
				: this.props?.billingHeaders?.labelMapping;

		this.setState({ currentBillingHeaders });
	}

	onPageChanged = (data) => {
		const allBillingHeaders = this.props?.billingHeaders?.labelMapping;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentBillingHeaders = allBillingHeaders?.slice(
			offset,
			offset + pageLimit
		);

		this.setState({ currentPage, currentBillingHeaders, totalPages });
	};

	handleSearch(event) {
		let currentBillingHeaders = this.props.billingHeaders?.labelMapping;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentBillingHeaders = currentBillingHeaders.filter((e) =>
				e.societyId.societyName.toLowerCase().match(searchString)
			);
		}
		this.setState({ currentBillingHeaders });
	}

	deleteRecord(id) {
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteBillingHeaderAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		let totalBillingHeaders =
			this.props?.billingHeaders?.labelMapping?.length || 0;

		const { currentBillingHeaders, currentPage, totalPages } = this.state;
		this.state.currentBillingHeaders = this.props.billingHeaders?.labelMapping;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item active>Billing Label Mapping</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Billing Label Mapping</h2>
								</div>

								<div className="col-xl-4 col-sm-12"></div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-billing-header-mapping"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Billing Label Mapping
									</Link>
								</div>
							</div>

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Society Name</th>
											<th>Flat No</th>
											<th>Flat Type</th>
											<th>Flat Area</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentBillingHeaders?.length
											? currentBillingHeaders.map((billingHeader, i) => (
													<tr key={i}>
														<td>{billingHeader?.societyId?.societyName}</td>
														<td>{billingHeader?.flatNo} </td>
														<td>{billingHeader?.flatType}</td>
														<td>{billingHeader?.flatArea}</td>

														<td>
															<div className="d-flex">
																<Link
																	to={`add-billing-header-mapping/${billingHeader?.societyId?._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link>
																<Link
																	to={`billing-header-mapping-details/${billingHeader?.societyId?._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
																<Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={this.deleteRecord.bind(
																		this,
																		billingHeader?._id
																	)}
																>
																	<i className="fa fa-trash"></i>
																</Link>
															</div>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalBillingHeaders ? (
										<Pagination
											totalRecords={totalBillingHeaders}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		billingHeaders: state.billingHeaderMapping?.billingHeaders,
		loading: state?.billingHeaderMapping?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getBillingHeadersAction, deleteBillingHeaderAction },
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BillingHeaderMapping);
