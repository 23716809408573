export const GET_MEETING_DETAILS = "[Meeting Action] Get Meeting";
export const CONFIRMED_GET_MEETING_DETAILS =
	"[Meeting Action] Confirmed Get Meeting";
export const CREATE_MEETING_ACTION = "[Meeting Action] Create Meeting";
export const CONFIRMED_CREATE_MEETING_ACTION =
	"[Meeting Action] Confirmed Create Meeting";
export const GET_MEETINGS = "[Meetings Action] Get Meetings";
export const CONFIRMED_GET_MEETINGS =
	"[Meetings Action] Confirmed Get Meetings";
export const EDIT_MEETING_ACTION = "[Meeting Action] Edit Meeting";
export const CONFIRMED_EDIT_MEETING_ACTION =
	"[Meeting Action] Confirmed Edit Meeting";
export const CONFIRMED_DELETE_MEETING_ACTION =
	"[Meeting Action] Confirmed Delete Meeting";
export const LOADING = "[meeting Loading Action] Confirmed Meeting Loading";
