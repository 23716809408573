import axiosInstance from "../services/AxiosInstance";

export function getPaymentDetails(paymentId) {
	return axiosInstance.get(`admin/paymentDetail/${paymentId}`);
}

export function getPayments(societyId) {
	return axiosInstance.get(`admin/paymentDetails/${societyId}`);
}

export function createPayment(paymentData) {
	return axiosInstance.post(`admin/paymentDetail`, paymentData);
}

export function updatePayment(payment, paymentId) {
	return axiosInstance.put(`admin/paymentDetail/${paymentId}`, payment);
}

export function deletePayment(paymentId) {
	return axiosInstance.delete(`admin/paymentDetail/${paymentId}`);
}

export function getPaymentsByFlat(societyId, flatId) {
	return axiosInstance.get(`admin/paymentDetails/${societyId}/${flatId}`);
}
