export const GET_NEWS_DETAILS = "[News Action] Get News";
export const CONFIRMED_GET_NEWS_DETAILS = "[News Action] Confirmed Get News";
export const CREATE_NEWS_ACTION = "[News Action] Create News";
export const CONFIRMED_CREATE_NEWS_ACTION =
	"[News Action] Confirmed Create News";
export const GET_NEWSES = "[Newses Action] Get Newses";
export const CONFIRMED_GET_NEWSES = "[Newses Action] Confirmed Get Newses";
export const EDIT_NEWS_ACTION = "[News Action] Edit News";
export const CONFIRMED_EDIT_NEWS_ACTION = "[News Action] Confirmed Edit News";
export const CONFIRMED_DELETE_NEWS_ACTION =
	"[News Action] Confirmed Delete News";
