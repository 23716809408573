import axiosInstance from "../services/AxiosInstance";

export function getVendorTypeDetails(vendorId) {
	return axiosInstance.get(`admin/vendorType/${vendorId}`);
}

export function getVendorTypes(societyId) {
	return axiosInstance.get(`admin/vendorTypes/${societyId}`);
}

export function createVendorType(categoryData) {
	return axiosInstance.post(`admin/vendorType`, categoryData);
}

export function updateVendorType(category, vendorId) {
	return axiosInstance.put(`admin/vendorType/${vendorId}`, category);
}

export function deleteVendorType(vendorId) {
	return axiosInstance.delete(`admin/vendorType/${vendorId}`);
}
