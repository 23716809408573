import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

import { getBillingHeaderAction } from "../../../../../store/actions/BillingHeaderMappingActions";

class BillingHeaderMappingDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			billingHeader: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;

		this.props.getBillingHeaderAction(id);

		this.init();
	}

	init() {
		this.state.billingHeader = this.props?.billingHeader[0];
	}

	render() {
		this.state.billingHeader = this.props?.billingHeader[0];

		const flatNo = this.state.billingHeader?.flatNo || "";
		const flatType = this.state.billingHeader?.flatType || "";
		const flatArea = this.state.billingHeader?.flatArea || "";
		const memberName = this.state.billingHeader?.memberName || "";
		const ownerName = this.state.billingHeader?.ownerName || "";
		const ownerEmail = this.state.billingHeader?.ownerEmail || "";
		const contactDetails = this.state.billingHeader?.contactDetails || "";
		const tenantName = this.state.billingHeader?.tenantName || "";
		const tenantContactDetails =
			this.state.billingHeader?.tenantContactDetails || "";
		const twoWheeler = this.state.billingHeader?.twoWheeler || "";
		const twoWheelerParkingCharges =
			this.state.billingHeader?.twoWheelerParkingCharges || "";
		const fourWheeler = this.state.billingHeader?.fourWheeler || "";
		const fourWheelerParkingCharges =
			this.state.billingHeader?.fourWheelerParkingCharges || "";
		const vendorTypeName = this.state.billingHeader?.vendorTypeName || "";
		const vendorName = this.state.billingHeader?.vendorName || "";
		const vendorMobile = this.state.billingHeader?.vendorMobile || "";
		const vendorEmail = this.state.billingHeader?.vendorEmail || "";
		const vendorDetails = this.state.billingHeader?.vendorDetails || "";
		const vendorDescription = this.state.billingHeader?.vendorDescription || "";

		//const status = this.state.billingHeader?.status || "";

		return (
			<>
				<Breadcrumb>
					{/* <Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "} */}
					<Breadcrumb.Item
						linkProps={{ to: "/superadmin/billing-header-mapping" }}
						linkAs={Link}
					>
						Billing Header Mappings
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>
						Billing Header Mapping Details
					</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Billing Header Mapping Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/superadmin/billing-header-mapping"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Flat No</strong>
										<span className="mb-0">{flatNo}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Flat Type</strong>
										<span className="mb-0">{flatType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Flat Area</strong>
										<span className="mb-0">{flatArea}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Member Name</strong>
										<span className="mb-0">{memberName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Owner Name</strong>
										<span className="mb-0">{ownerName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Owner Email</strong>
										<span className="mb-0">{ownerEmail}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Owner Contact</strong>
										<span className="mb-0">{contactDetails}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Tenant Name</strong>
										<span className="mb-0">{tenantName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Tenant Contact</strong>
										<span className="mb-0">{tenantContactDetails}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Two Wheelers</strong>
										<span className="mb-0">{twoWheeler}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Two Wheeler Charges</strong>
										<span className="mb-0">{twoWheelerParkingCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Four Wheeler</strong>
										<span className="mb-0">{fourWheeler}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Four Wheeler Charges</strong>
										<span className="mb-0">{fourWheelerParkingCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Type</strong>
										<span className="mb-0">{vendorTypeName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Name</strong>
										<span className="mb-0">{vendorName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Mobile</strong>
										<span className="mb-0">{vendorMobile}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>vendor Email</strong>
										<span className="mb-0">{vendorEmail}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>vendor Details</strong>
										<span className="mb-0">{vendorDetails}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>vendor Description</strong>
										<span className="mb-0">{vendorDescription}</span>
									</li>

									{/* <li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		billingHeader: state.billingHeaderMapping?.billingHeader,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getBillingHeaderAction }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BillingHeaderMappingDetails);
