import axiosInstance from "../services/AxiosInstance";

export function getMaintenanceDetails(societyId) {
	return axiosInstance.get(`admin/maintenance/${societyId}`);
}

export function getMaintenances() {
	return axiosInstance.get(`admin/maintenance`);
}

export function createMaintenance(maintenanceData) {
	return axiosInstance.post(`admin/maintenance`, maintenanceData);
}

export function updateMaintenance(maintenance, maintenanceId) {
	return axiosInstance.put(`admin/maintenance/${maintenanceId}`, maintenance);
}

export function deleteMaintenance(maintenanceId) {
	return axiosInstance.delete(`admin/maintenance/${maintenanceId}`);
}
