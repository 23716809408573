export const GET_QUERY_DETAILS = "[Query Action] Get Query";
export const CONFIRMED_GET_QUERY_DETAILS = "[Query Action] Confirmed Get Query";
export const CREATE_QUERY_ACTION = "[Query Action] Create Query";
export const CONFIRMED_CREATE_QUERY_ACTION =
	"[Query Action] Confirmed Create Query";
export const GET_QUERIES = "[Queries Action] Get Queries";
export const CONFIRMED_GET_QUERIES = "[Queries Action] Confirmed Get Queries";
export const EDIT_QUERY_ACTION = "[Query Action] Edit Query";
export const CONFIRMED_EDIT_QUERY_ACTION =
	"[Query Action] Confirmed Edit Query";
export const CONFIRMED_DELETE_QUERY_ACTION =
	"[Query Action] Confirmed Delete Query";
export const LOADING =
	"[Request Query Loading Action] Confirmed Request Query Loading";
