import {
	getPaymentDetails,
	getPayments,
	createPayment,
	updatePayment,
	deletePayment,
	getPaymentsByFlat,
} from "../../services/PaymentService";
import {
	CONFIRMED_GET_PAYMENT_DETAILS,
	CONFIRMED_CREATE_PAYMENT_ACTION,
	CONFIRMED_GET_PAYMENTS,
	CONFIRMED_EDIT_PAYMENT_ACTION,
	CONFIRMED_DELETE_PAYMENT_ACTION,
	CONFIRMED_GET_PAYMENTS_BY_FLAT,
	LOADING,
} from "./PaymentTypes";

const userData = JSON.parse(localStorage.getItem("userDetails"));

export function getPaymentAction(paymentId) {
	return (dispatch, getState) => {
		getPaymentDetails(paymentId).then((response) => {
			dispatch(confirmedGetPaymentAction(response.data.paymentDetail));
		});
	};
}

export function confirmedGetPaymentAction(payment) {
	return {
		type: CONFIRMED_GET_PAYMENT_DETAILS,
		payload: payment,
	};
}

export function deletePaymentAction(paymentId, history) {
	return (dispatch, getState) => {
		deletePayment(paymentId).then((response) => {
			dispatch(confirmedDeletePaymentAction(paymentId));
			history.push("/payments");
		});
	};
}

export function confirmedDeletePaymentAction(paymentId) {
	return {
		type: CONFIRMED_DELETE_PAYMENT_ACTION,
		payload: paymentId,
	};
}

export function createPaymentAction(PaymentData, history) {
	return (dispatch, getState) => {
		createPayment(PaymentData).then((response) => {
			const singlePayment = {
				...PaymentData,
				id: response.data._id,
			};
			dispatch(confirmedCreatePaymentAction(singlePayment));
			if (userData.user?.role === "admin") {
				history.push("/payments");
			} else {
				history.push("/member-payments");
			}
			//history.push("/payments");
		});
	};
}

export function getPaymentsAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getPayments(societyId).then((response) => {
			dispatch(confirmedGetPaymentsAction(response.data));
		});
	};
}

export function confirmedGetPaymentsAction(Payments) {
	return {
		type: CONFIRMED_GET_PAYMENTS,
		payload: Payments,
	};
}

export function confirmedCreatePaymentAction(singlePayment) {
	return {
		type: CONFIRMED_CREATE_PAYMENT_ACTION,
		payload: singlePayment,
	};
}

export function confirmedUpdatePaymentAction(Payment) {
	return {
		type: CONFIRMED_EDIT_PAYMENT_ACTION,
		payload: Payment,
	};
}

export function updatePaymentAction(Payment, history, paymentId) {
	return (dispatch, getState) => {
		updatePayment(Payment, paymentId).then((reponse) => {
			dispatch(confirmedUpdatePaymentAction(Payment));
			if (userData.user?.role === "admin") {
				history.push("/payments");
			} else {
				history.push("/member-payments");
			}
		});
	};
}

export function getPaymentsByFlatAction(societyId, flatId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getPaymentsByFlat(societyId, flatId).then((response) => {
			dispatch(confirmedGetPaymentsByFlatAction(response.data));
		});
	};
}

export function confirmedGetPaymentsByFlatAction(Payments) {
	return {
		type: CONFIRMED_GET_PAYMENTS_BY_FLAT,
		payload: Payments,
	};
}
