import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { format } from "date-fns";

import { getAmcAction } from "../../../../store/actions/AmcActions";

class AmcDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			amc: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.amc) {
			this.props.getAmcAction(id);
		}
		this.init();
	}

	init() {
		this.state.amc = this.props?.amc;
	}

	render() {
		this.state.amc = this.props?.amc;

		const vendorType = this.state.amc?.vendorType || "";
		const amcDescription = this.state.amc?.amcDescription || "";
		const vendorName = this.state.amc?.vendorName || "";
		const vendorEmail = this.state.amc?.vendorEmail || "";
		const vendorMobile = this.state.amc?.vendorMobile || "";
		const vendorAddress = this.state.amc?.vendorAddress || "";
		const amcStartDate =
			(this.state.amc?.amcStartDate &&
				format(new Date(this.state.amc?.amcStartDate), "dd-MM-yyyy")) ||
			"";
		const amcEndDate =
			(this.state.amc?.amcEndDate &&
				format(new Date(this.state.amc?.amcEndDate), "dd-MM-yyyy")) ||
			"";
		const status = this.state.amc?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/amc" }} linkAs={Link}>
						AMC Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>AMC Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">AMC Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/amc"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Type</strong>
										<span className="mb-0">{vendorType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>AMC Description</strong>
										<span className="mb-0">{amcDescription}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Name</strong>
										<span className="mb-0">{vendorName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Email</strong>
										<span className="mb-0">{vendorEmail}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Mobile</strong>
										<span className="mb-0">{vendorMobile}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Address</strong>
										<span className="mb-0">{vendorAddress}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>AMC Start Date</strong>
										<span className="mb-0">{amcStartDate}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>AMC End Date</strong>
										<span className="mb-0">{amcEndDate}</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		amc: state.amc?.amc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getAmcAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AmcDetail);
