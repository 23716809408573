import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

import { getVendorAction } from "../../../../store/actions/VendorActions";

class VendorDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vendor: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.vendor) {
			this.props.getVendorAction(id);
		}
		this.init();
	}

	init() {
		this.state.vendor = this.props?.vendor;
	}

	render() {
		this.state.vendor = this.props?.vendor;

		const vendorType = this.state.vendor?.vendorType || "";
		const vendorDescription = this.state.vendor?.vendorDescription || "";
		const vendorName = this.state.vendor?.vendorName || "";
		const vendorEmail = this.state.vendor?.vendorEmail || "";
		const vendorMobile = this.state.vendor?.vendorMobile || "";
		const vendorDetails = this.state.vendor?.vendorDetails || "";
		const status = this.state.vendor?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/vendors" }} linkAs={Link}>
						Vendor Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Vendor Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Vendor Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/vendors"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Type</strong>
										<span className="mb-0">{vendorType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Description</strong>
										<span className="mb-0">{vendorDescription}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Name</strong>
										<span className="mb-0">{vendorName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Email</strong>
										<span className="mb-0">{vendorEmail}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Mobile</strong>
										<span className="mb-0">{vendorMobile}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Vendor Details</strong>
										<span className="mb-0">{vendorDetails}</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		vendor: state.vendor?.vendor,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getVendorAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetail);
