import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import DateFnsUtils from "@date-io/date-fns";
// import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getBankDetailAction,
	createBankDetailAction,
	updateBankDetailAction,
} from "../../../../store/actions/BankDetailsActions";

class AddEditBankDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bankDetail: {},
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.bankDetail) {
				this.props.getBankDetailAction(id);
			}
		} else {
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.bankDetail?.length !== this.props?.bankDetail?.length) {
			this.setState({
				bankDetail: this.props?.bankDetail,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateBankDetailAction(
				values,
				this.props.history,
				this.props?.bankDetail?._id
			);
		} else {
			this.props.createBankDetailAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var bankDetail = this.props?.bankDetail;
		} else {
			bankDetail = "";
		}

		const initialValues = {
			societyId: this.state.societyId,
			accountHolderName: bankDetail ? bankDetail.accountHolderName : "",
			accountNumber: bankDetail ? bankDetail.accountNumber : "",
			IFSC_Code: bankDetail ? bankDetail.IFSC_Code : "",
			bankName: bankDetail ? bankDetail.bankName : "",
			branchName: bankDetail ? bankDetail.branchName : "",
			account_type: bankDetail ? bankDetail.account_type : "",
			// razorPayLinkedAccountId: bankDetail
			// 	? bankDetail.razorPayLinkedAccountId
			// 	: "",
			// is_active: bankDetail ? bankDetail.is_active : false,
			is_verified: bankDetail ? bankDetail.is_verified : false,
			// is_deleted: bankDetail ? bankDetail.is_deleted : false,
		};

		const validationSchema = Yup.object().shape({
			accountHolderName: Yup.string().required("Please Enter Name."),
			accountNumber: Yup.string().required("Please Enter Account No."),
			IFSC_Code: Yup.string().required("Please Enter IFSC Code."),
			bankName: Yup.string().required("Please Enter Bank Name."),
			branchName: Yup.string().required("Please Enter Branch Name."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/bankdetails" }} linkAs={Link}>
						Bank Detail Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Bank Detail</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Bank Detail</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Bank Detail</h3>
						) : (
							<h3 className="pt-3">Add Bank Detail</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/bankdetails"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Account Holder Name *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-accountHolderName"
																	placeholder="Enter a Account Holder Name.."
																	name="accountHolderName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.accountHolderName || ""}
																/>
																{errors.accountHolderName &&
																touched.accountHolderName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.accountHolderName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Account Number *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-accountNumber"
																	placeholder="Enter a Account No."
																	name="accountNumber"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.accountNumber || ""}
																/>
																{errors.accountNumber &&
																touched.accountNumber ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.accountNumber}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">IFSC Code *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-IFSC_Code"
																	placeholder="Enter a IFSC Code."
																	name="IFSC_Code"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.IFSC_Code || ""}
																/>
																{errors.IFSC_Code && touched.IFSC_Code ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.IFSC_Code}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Bank Name *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-bankName"
																	placeholder="Enter a Bank Name."
																	name="bankName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.bankName || ""}
																/>
																{errors.bankName && touched.bankName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.bankName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Branch Name *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-branchName"
																	placeholder="Enter a Branch Name."
																	name="branchName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.branchName || ""}
																/>
																{errors.branchName && touched.branchName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.branchName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Account Type{" "}
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-account_type"
																	placeholder="Enter a Account Type.."
																	name="account_type"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.account_type || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	<option value="Saving" label="Saving" />
																	<option value="Current" label="Current" />
																	<option value="CC" label="CC" />
																</select>
																{errors.account_type && touched.account_type ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.account_type}
																	</div>
																) : null}
															</div>
														</div>
													</div>

													{/* <div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Razorpay Link Id{" "}
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-razorPayLinkedAccountId"
																	placeholder="Enter a Razorpay Link Id."
																	name="razorPayLinkedAccountId"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.razorPayLinkedAccountId || ""}
																/>
																{errors.razorPayLinkedAccountId &&
																touched.razorPayLinkedAccountId ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.razorPayLinkedAccountId}
																	</div>
																) : null}
															</div>
														</div>
													</div> */}
												</div>

												<div className="row">
													<div className="col-xl-6 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.is_verified === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-is_verified"
																	name="is_verified"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.is_verified}
																/>
																<label className="form-check-label">
																	Confirm If The Entered Details are Correct
																</label>
															</div>
														</div>
													</div>
												</div>

												{/* <div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.is_active === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-is_active"
																	name="is_active"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.is_active}
																/>
																<label className="form-check-label">
																	Is Active
																</label>
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.is_verified === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-is_verified"
																	name="is_verified"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.is_verified}
																/>
																<label className="form-check-label">
																	Is Verified
																</label>
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.is_deleted === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-is_deleted"
																	name="is_deleted"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.is_deleted}
																/>
																<label className="form-check-label">
																	Is Deleted
																</label>
															</div>
														</div>
													</div>
												</div> */}

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddBankDetail;
const mapStateToProps = (state) => {
	return {
		bankDetail: state.bankDetail?.bankDetail,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getBankDetailAction,
			createBankDetailAction,
			updateBankDetailAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBankDetail);
