import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import axiosInstance from "../../../../services/AxiosInstance";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

class AddEditUserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAddMode: false,
			societyId: "",
		};
		this.handleEditProfile = this.handleEditProfile.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});
	}

	handleEditProfile = (values) => {
		axiosInstance
			.put(`me/update`, JSON.stringify(values))
			.then((response) => {
				this.props.history.push("/userprofile");
			})
			.catch((error) => console.error(`Error: ${error}`));
	};

	render() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));

		const initialValues = {
			name: userData ? userData.user?.name : "",
			mobile: userData ? userData?.user?.mobile : "",
			email: userData ? userData?.user?.email : "",
			address: userData ? userData?.user?.address : "",
		};

		const validationSchema = Yup.object().shape({
			name: Yup.string().required("Please Enter Name"),
			mobile: Yup.string()
				.matches(phoneRegExp, "Phone number is not valid")
				.min(10, "Min 10 digit")
				.max(10, "Max 10 digit")
				.required("Please Enter Mobile no."),
			email: Yup.string()
				.email("Invalid Email")
				.required("Please Enter Email."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Edit Profile</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Edit Profile</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/userprofile"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Profile
						</Link>
					</div>

					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleEditProfile(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">User Name *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-name"
																	placeholder="Enter a Name.."
																	name="name"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.name || ""}
																/>
																{errors.name && touched.name ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.name}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Email *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-email"
																	placeholder="Enter a Email.."
																	name="email"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.email || ""}
																/>
																{errors.email && touched.email ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.email}
																	</div>
																) : null}
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Mobile *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-mobile"
																	placeholder="Enter a Mobile.."
																	name="mobile"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.mobile || ""}
																/>
																{errors.mobile && touched.mobile ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.mobile}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Address</label>
															<div>
																<textarea
																	type="text"
																	className="form-control"
																	id="val-address"
																	placeholder="Enter a Address.."
																	name="address"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.address || ""}
																	rows="5"
																></textarea>
																{errors.address && touched.address ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.address}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												{/* <div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div> */}

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddFlat;
const mapStateToProps = (state) => {
	return {
		maintenancemaster: state.maintenancemaster?.maintenancemaster,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			// getMaintenanceMasterAction,
			// updateMaintenanceMasterAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUserProfile);
