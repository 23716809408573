import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
//import { format } from "date-fns";

import { getBankDetailAction } from "../../../../store/actions/BankDetailsActions";

class BankDetailDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bankdetail: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (id) {
			this.props.getBankDetailAction(id);
		}
		this.init();
	}

	init() {
		this.state.bankdetail = this.props?.bankdetail;
	}

	render() {
		this.state.bankdetail = this.props?.bankdetail;

		const accountHolderName = this.state.bankdetail?.accountHolderName || "";
		const accountNumber = this.state.bankdetail?.accountNumber || "";
		const IFSC_Code = this.state.bankdetail?.IFSC_Code || "";
		const bankName = this.state.bankdetail?.bankName || "";
		const branchName = this.state.bankdetail?.branchName || "";
		const account_type = this.state.bankdetail?.account_type || "";
		const is_verified = this.state.bankdetail?.is_verified || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/bankdetails" }} linkAs={Link}>
						Bank Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Bank Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Bank Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/bankdetails"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Account Holder Name</strong>
										<span className="mb-0">{accountHolderName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Bank Name</strong>
										<span className="mb-0">{bankName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Branch Name</strong>
										<span className="mb-0">{branchName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Account Number</strong>
										<span className="mb-0">{accountNumber}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>IFSC Code</strong>
										<span className="mb-0">{IFSC_Code}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Account Type</strong>
										<span className="mb-0">{account_type}</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Is Verified</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{is_verified === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		bankdetail: state.bankDetail?.bankDetail,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getBankDetailAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailDetails);
