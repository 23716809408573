import {
	getSocietyDetails,
	getSocieties,
	createSociety,
	updateSociety,
	deleteSociety,
	getSocietyByUrl,
	createSocietyBillingHeader,
	createSocietyContact,
	createSocietyImage,
	deleteSocietyContact,
	deleteSocietyImage,
	updateSocietyContact,
} from "../../services/SocietyService";
import {
	CONFIRMED_GET_SOCIETY_DETAILS,
	CONFIRMED_CREATE_SOCIETY_ACTION,
	CONFIRMED_GET_SOCIETIES,
	CONFIRMED_EDIT_SOCIETY_ACTION,
	CONFIRMED_DELETE_SOCIETY_ACTION,
	//CREATE_SOCIETY_HEADER_ACTION,
	CONFIRMED_CREATE_SOCIETY_HEADER_ACTION,
	//CREATE_SOCIETY_CONTACT_ACTION,
	CONFIRMED_CREATE_SOCIETY_CONTACT_ACTION,
	CONFIRMED_CREATE_SOCIETY_IMAGE_ACTION,
	CONFIRMED_DELETE_SOCIETY_CONTACT_ACTION,
	CONFIRMED_DELETE_SOCIETY_IMAGE_ACTION,
	CONFIRMED_EDIT_SOCIETY_CONTACT_ACTION,
	SOCIETIES_LOADING,
} from "./SocietyTypes";

const userData = JSON.parse(localStorage.getItem("userDetails"));

export function getSocietyByUrlAction(societyurl) {
	return (dispatch, getState) => {
		getSocietyByUrl(societyurl).then((response) => {
			dispatch(confirmedGetSocietyAction(response.data));
		});
	};
}

export function getSocietyAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: SOCIETIES_LOADING });
		getSocietyDetails(societyId).then((response) => {
			dispatch(confirmedGetSocietyAction(response.data));
		});
	};
}

export function confirmedGetSocietyAction(society) {
	return {
		type: CONFIRMED_GET_SOCIETY_DETAILS,
		payload: society,
	};
}

export function deleteSocietyAction(societyId, history) {
	return (dispatch, getState) => {
		deleteSociety(societyId).then((response) => {
			dispatch(confirmedDeleteSocietyAction(societyId));
			history.push("/superadmin/societies");
		});
	};
}

export function confirmedDeleteSocietyAction(SocietyId) {
	return {
		type: CONFIRMED_DELETE_SOCIETY_ACTION,
		payload: SocietyId,
	};
}

export function createSocietyAction(SocietyData, history) {
	return (dispatch, getState) => {
		createSociety(SocietyData).then((response) => {
			const singleSociety = {
				...SocietyData,
				id: response.data._id,
			};
			dispatch(confirmedCreateSocietyAction(singleSociety));
			history.push("/superadmin/societies");
		});
	};
}

export function getSocietiesAction() {
	return (dispatch, getState) => {
		dispatch({ type: SOCIETIES_LOADING });
		getSocieties().then((response) => {
			dispatch(confirmedGetSocietysAction(response.data));
		});
	};
}

export function confirmedCreateSocietyAction(singleSociety) {
	return {
		type: CONFIRMED_CREATE_SOCIETY_ACTION,
		payload: singleSociety,
	};
}

export function confirmedGetSocietysAction(Societys) {
	return {
		type: CONFIRMED_GET_SOCIETIES,
		payload: Societys,
	};
}

export function confirmedUpdateSocietyAction(Society) {
	return {
		type: CONFIRMED_EDIT_SOCIETY_ACTION,
		payload: Society,
	};
}

export function updateSocietyAction(Society, history, societyId) {
	return (dispatch, getState) => {
		updateSociety(Society, societyId).then((reponse) => {
			dispatch(confirmedUpdateSocietyAction(Society));
			if (userData.user?.role === "admin") {
				history.push("/society-information");
			} else if (userData.user?.role === "superAdmin") {
				history.push("/superadmin/societies");
			}
		});
	};
}

export function createSocietyHeaderAction(SocietyData, history) {
	return (dispatch, getState) => {
		createSocietyBillingHeader(SocietyData).then((response) => {
			const singleSocietyHeader = {
				...SocietyData,
				id: response.data._id,
			};
			dispatch(confirmedCreateSocietyHeaderAction(singleSocietyHeader));
			history.push("/admin/societyHeader");
		});
	};
}

export function confirmedCreateSocietyHeaderAction(singleSocietyHeader) {
	return {
		type: CONFIRMED_CREATE_SOCIETY_HEADER_ACTION,
		payload: singleSocietyHeader,
	};
}

export function createSocietyContactAction(SocietyData, history, societyId) {
	return (dispatch, getState) => {
		createSocietyContact(SocietyData, societyId).then((response) => {
			const singleSocietyContact = {
				...SocietyData,
				id: response.data._id,
			};
			dispatch(confirmedCreateSocietyContactAction(singleSocietyContact));
			history.push("/society-contacts");
		});
	};
}

export function confirmedCreateSocietyContactAction(singleSocietyContact) {
	return {
		type: CONFIRMED_CREATE_SOCIETY_CONTACT_ACTION,
		payload: singleSocietyContact,
	};
}

export function createSocietyImageAction(SocietyImageData, history, societyId) {
	return (dispatch, getState) => {
		createSocietyImage(SocietyImageData, societyId).then((response) => {
			const singleSocietyImage = {
				...SocietyImageData,
				id: response.data._id,
			};
			dispatch(confirmedCreateSocietyImageAction(singleSocietyImage));
			history.push("/society-images");
		});
	};
}

export function confirmedCreateSocietyImageAction(singleSocietyImage) {
	return {
		type: CONFIRMED_CREATE_SOCIETY_IMAGE_ACTION,
		payload: singleSocietyImage,
	};
}

export function deleteSocietyContactAction(societyId, contactId, history) {
	return (dispatch, getState) => {
		deleteSocietyContact(societyId, contactId).then((response) => {
			dispatch(confirmedDeleteSocietyContactAction(societyId, contactId));
			history.push("/society-contacts");
		});
	};
}

export function confirmedDeleteSocietyContactAction(SocietyId, contactId) {
	return {
		type: CONFIRMED_DELETE_SOCIETY_CONTACT_ACTION,
		payload: contactId,
	};
}

export function deleteSocietyImageAction(societyId, imageId, history) {
	return (dispatch, getState) => {
		deleteSocietyImage(societyId, imageId).then((response) => {
			dispatch(confirmedDeleteSocietyImageAction(societyId, imageId));
			history.push("/society-images");
		});
	};
}

export function confirmedDeleteSocietyImageAction(SocietyId, imageId) {
	return {
		type: CONFIRMED_DELETE_SOCIETY_IMAGE_ACTION,
		payload: imageId,
	};
}

export function confirmedUpdateSocietyContactAction(Society) {
	return {
		type: CONFIRMED_EDIT_SOCIETY_CONTACT_ACTION,
		payload: Society,
	};
}

export function updateSocietyContactAction(
	Society,
	history,
	societyId,
	contactId
) {
	return (dispatch, getState) => {
		updateSocietyContact(Society, societyId, contactId).then((reponse) => {
			dispatch(confirmedUpdateSocietyContactAction(Society));
			history.push("/society-contacts");
		});
	};
}
