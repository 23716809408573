import {
	CONFIRMED_GET_MAINTENANCEMASTER_DETAILS,
	CONFIRMED_CREATE_MAINTENANCEMASTER_ACTION,
	CONFIRMED_GET_MAINTENANCEMASTERS,
	CONFIRMED_EDIT_MAINTENANCEMASTER_ACTION,
	CONFIRMED_DELETE_MAINTENANCEMASTER_ACTION,
	LOADING,
} from "../actions/MaintenanceMasterTypes";

const initialState = {
	maintenancemasters: [],
	maintenancemaster: {},
};

export default function MaintenanceMasterReducer(
	state = initialState,
	actions
) {
	if (actions.type === CONFIRMED_GET_MAINTENANCEMASTER_DETAILS) {
		return {
			...state,
			maintenancemaster: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_MAINTENANCEMASTER_ACTION) {
		const maintenancemasters = [
			...state.maintenancemaster.maintenancemasters.maintenancemasters,
		];
		const maintenancemasterIndex = maintenancemasters.findIndex(
			(maintenancemaster) => maintenancemaster.id === actions.payload
		);

		maintenancemasters.splice(maintenancemasterIndex, 1);

		return {
			...state,
			maintenancemasters,
		};
	}

	if (actions.type === CONFIRMED_EDIT_MAINTENANCEMASTER_ACTION) {
		// const maintenancemasters = [
		// 	...state.maintenancemaster.maintenancemasters.maintenancemasters,
		// ];
		// const maintenancemasterIndex = maintenancemasters.findIndex(
		// 	(maintenancemaster) => maintenancemaster.id === actions.payload.id
		// );

		// maintenancemasters[maintenancemasterIndex] = actions.payload;
		return {
			...state,
			maintenancemasters: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_MAINTENANCEMASTER_ACTION) {
		return {
			...state,
			maintenancemasters: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_MAINTENANCEMASTERS) {
		return {
			...state,
			maintenancemasters: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
