import {
	getAmenityMasterDetails,
	getAmenityMasters,
	createAmenityMaster,
	updateAmenityMaster,
	deleteAmenityMaster,
} from "../../services/AmenitiesMasterService";
import {
	CONFIRMED_GET_AMENITYMASTER_DETAILS,
	CONFIRMED_CREATE_AMENITYMASTER_ACTION,
	CONFIRMED_GET_AMENITYMASTERS,
	CONFIRMED_EDIT_AMENITYMASTER_ACTION,
	CONFIRMED_DELETE_AMENITYMASTER_ACTION,
	LOADING,
} from "./AmenitiesMasterTypes";

export function getAmenityMasterAction(amenityId) {
	return (dispatch, getState) => {
		getAmenityMasterDetails(amenityId).then((response) => {
			dispatch(confirmedGetAmenityMasterAction(response.data.amenity));
		});
	};
}

export function confirmedGetAmenityMasterAction(amenity) {
	return {
		type: CONFIRMED_GET_AMENITYMASTER_DETAILS,
		payload: amenity,
	};
}

export function deleteAmenityMasterAction(amenityId, history) {
	return (dispatch, getState) => {
		deleteAmenityMaster(amenityId).then((response) => {
			dispatch(confirmedDeleteAmenityMasterAction(amenityId));
			history.push("/amenity-lists");
		});
	};
}

export function confirmedDeleteAmenityMasterAction(AmenityId) {
	return {
		type: CONFIRMED_DELETE_AMENITYMASTER_ACTION,
		payload: AmenityId,
	};
}

export function createAmenityMasterAction(AmenityData, history) {
	return (dispatch, getState) => {
		createAmenityMaster(AmenityData).then((response) => {
			const singleAmenityMaster = {
				...AmenityData,
				id: response.data._id,
			};
			dispatch(confirmedCreateAmenityMasterAction(singleAmenityMaster));
			history.push("/amenity-lists");
		});
	};
}

export function getAmenityMastersAction() {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getAmenityMasters().then((response) => {
			dispatch(confirmedGetAmenityMastersAction(response.data));
		});
	};
}

export function confirmedCreateAmenityMasterAction(singleAmenityMaster) {
	return {
		type: CONFIRMED_CREATE_AMENITYMASTER_ACTION,
		payload: singleAmenityMaster,
	};
}

export function confirmedGetAmenityMastersAction(AmenityMasters) {
	return {
		type: CONFIRMED_GET_AMENITYMASTERS,
		payload: AmenityMasters,
	};
}

export function confirmedUpdateAmenityMasterAction(AmenityMaster) {
	return {
		type: CONFIRMED_EDIT_AMENITYMASTER_ACTION,
		payload: AmenityMaster,
	};
}

export function updateAmenityMasterAction(AmenityMaster, history, amenityId) {
	return (dispatch, getState) => {
		updateAmenityMaster(AmenityMaster, amenityId).then((reponse) => {
			dispatch(confirmedUpdateAmenityMasterAction(AmenityMaster));
			history.push("/amenity-lists");
		});
	};
}
