export const GET_BILLINGTEMPLATE_DETAILS =
	"[Billing Template Action] Get Billing Template";
export const CONFIRMED_GET_BILLINGTEMPLATE_DETAILS =
	"[Billing Template Action] Confirmed Get Billing Template";
export const CREATE_BILLINGTEMPLATE_ACTION =
	"[Billing Template Action] Create Billing Template";
export const CONFIRMED_CREATE_BILLINGTEMPLATE_ACTION =
	"[Billing Template Action] Confirmed Create Billing Template";
export const GET_BILLINGTEMPLATES =
	"[Billing Templates Action] Get Billing Templates";
export const CONFIRMED_GET_BILLINGTEMPLATES =
	"[Billing Templates Action] Confirmed Get Billing Templates";
export const EDIT_BILLINGTEMPLATE_ACTION =
	"[Billing Template Action] Edit Billing Template";
export const CONFIRMED_EDIT_BILLINGTEMPLATE_ACTION =
	"[Billing Template Action] Confirmed Edit Billing Template";
export const CONFIRMED_DELETE_BILLINGTEMPLATE_ACTION =
	"[Billing Template Action] Confirmed Delete Billing Template";
