import {
	getVendorTypeDetails,
	getVendorTypes,
	createVendorType,
	updateVendorType,
	deleteVendorType,
} from "../../services/VendorTypeService";
import {
	CONFIRMED_GET_VENDORTYPE_DETAILS,
	CONFIRMED_CREATE_VENDORTYPE_ACTION,
	CONFIRMED_GET_VENDORTYPES,
	CONFIRMED_EDIT_VENDORTYPE_ACTION,
	CONFIRMED_DELETE_VENDORTYPE_ACTION,
	LOADING,
} from "./VendorTypeTypes";

export function getVendorTypeAction(typeId) {
	return (dispatch, getState) => {
		getVendorTypeDetails(typeId).then((response) => {
			dispatch(confirmedGetVendorTypeAction(response.data.vendorType));
		});
	};
}

export function confirmedGetVendorTypeAction(vendorType) {
	return {
		type: CONFIRMED_GET_VENDORTYPE_DETAILS,
		payload: vendorType,
	};
}

export function deleteVendorTypeAction(typeId, history) {
	return (dispatch, getState) => {
		deleteVendorType(typeId).then((response) => {
			dispatch(confirmedDeleteVendorTypeAction(typeId));
			history.push("/vendor-type");
		});
	};
}

export function confirmedDeleteVendorTypeAction(typeId) {
	return {
		type: CONFIRMED_DELETE_VENDORTYPE_ACTION,
		payload: typeId,
	};
}

export function createVendorTypeAction(VendorTypeData, history) {
	return (dispatch, getState) => {
		createVendorType(VendorTypeData).then((response) => {
			const singleVendorType = {
				...VendorTypeData,
				id: response.data._id,
			};
			dispatch(confirmedCreateVendorTypeAction(singleVendorType));
			history.push("/vendor-type");
		});
	};
}

export function getVendorTypesAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getVendorTypes(societyId).then((response) => {
			dispatch(confirmedGetVendorTypesAction(response.data));
		});
	};
}

export function confirmedCreateVendorTypeAction(singleVendorType) {
	return {
		type: CONFIRMED_CREATE_VENDORTYPE_ACTION,
		payload: singleVendorType,
	};
}

export function confirmedGetVendorTypesAction(VendorTypes) {
	return {
		type: CONFIRMED_GET_VENDORTYPES,
		payload: VendorTypes,
	};
}

export function confirmedUpdateVendorTypeAction(VendorType) {
	return {
		type: CONFIRMED_EDIT_VENDORTYPE_ACTION,
		payload: VendorType,
	};
}

export function updateVendorTypeAction(VendorType, history, typeId) {
	return (dispatch, getState) => {
		updateVendorType(VendorType, typeId).then((reponse) => {
			dispatch(confirmedUpdateVendorTypeAction(VendorType));
			history.push("/vendor-type");
		});
	};
}
