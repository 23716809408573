import { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import Gallery from "react-photo-gallery";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import { getSocietyAction } from "../../../../store/actions/SocietyActions";

// import { format } from "date-fns";

class SocietyInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			societyId: "",
		};
	}

	componentDidMount() {
		let flatData = JSON.parse(localStorage.getItem("userFlatDetails"));

		this.props.getSocietyAction(flatData?.societyId);
	}

	render() {
		const society = this.props.society.society;

		const societyName = society?.societyName || "";
		const societyAddress = society?.societyAddress || "";
		const societyUrl = society?.societyUrl || "";

		const societyMembersCount = society?.societyMembersCount || "";
		const societyParkingCount = society?.societyParkingCount || "";
		const societyBillDueDateinDays = society?.societyBillDueDateinDays || "";
		const societyDefaulterBaseLineAmount =
			society?.societyDefaulterBaseLineAmount || "";
		const amenties = society?.amenties || [];
		const contacts = society?.contactInfo || [];
		const society_images = society?.societyImages || [];

		const images = society_images.map((item) => {
			return {
				original: item.fileurl,
				originalHeight: "300",
				originalWidth: "300",
				thumbnail: item.fileurl,
				thumbnailHeight: "50",
				thumbnailWidth: "50",
			};
		});

		// const newArray = society_images.map((item) => {
		// 	return {
		// 		src: item.fileurl,
		// 		width: 100,
		// 		height: 100,
		// 		sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
		// 	};
		// });

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Society Information</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Society Information</h3>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Name</strong>
										<span className="mb-0">{societyName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Address</strong>
										<span className="mb-0 lineBreak">{societyAddress}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Url</strong>
										<span className="mb-0">{societyUrl}</span>
									</li>
								</ul>
							</div>
							<div className="card-footer pt-0 pb-0 text-center">
								<div className="row">
									<div className="col-3 pt-3 pb-3 border-right">
										<h3 className="mb-1 text-primary">{societyMembersCount}</h3>
										<span>Members</span>
									</div>
									<div className="col-3 pt-3 pb-3 border-right">
										<h3 className="mb-1 text-primary">{societyParkingCount}</h3>
										<span>Parking</span>
									</div>
									<div className="col-3 pt-3 pb-3">
										<h3 className="mb-1 text-primary">
											{societyBillDueDateinDays}
										</h3>
										<span>Bill Due Date</span>
									</div>
									<div className="col-3 pt-3 pb-3">
										<h3 className="mb-1 text-primary">
											{societyDefaulterBaseLineAmount}
										</h3>
										<span>Default baseline Amount</span>
									</div>
								</div>
								<hr />
								{contacts && (
									<div className="row">
										<h3>Society Contacts</h3>
										<Table bordered striped responsive>
											<thead>
												<tr>
													<th>Name</th>
													<th>Mobile</th>
													<th>Email</th>
													<th>Position</th>
												</tr>
											</thead>
											<tbody>
												{contacts?.length
													? contacts.map((contact, i) => (
															<tr key={i}>
																<td>{contact.contactName} </td>
																<td>{contact.mobile}</td>
																<td>{contact.email}</td>
																<td>{contact.position}</td>
															</tr>
													  ))
													: null}
											</tbody>
										</Table>
									</div>
								)}
								<hr />
								<div className="row">
									<div className="col-sm-2"></div>
									<div className="col-sm-8">
										{society_images && (
											<ImageGallery items={images} showPlayButton={false} />
										)}
									</div>
									<div className="col-sm-2"></div>

									{/* {society_images && <Gallery photos={newArray} />} */}
								</div>
								<hr />
								<div className="row">
									<h3>Society Amenties</h3>

									<Table responsive striped bordered hover>
										<tbody>
											{amenties.length > 0 ? (
												amenties
													.filter((i) => i.status === true)
													.map((item, index) => (
														<tr key={index}>
															<td>
																<div className="d-flex align-items-center">
																	{item.status === true ? (
																		<i className="fa fa-check-square text-success mr-1"></i>
																	) : (
																		<i className="fa fa-square mr-1"></i>
																	)}
																</div>
															</td>
															<td style={{ textAlign: "left" }}>
																<i className={`${item.iconName} pr-2`}></i>
																{item.name}
															</td>
														</tr>
													))
											) : (
												<tr>
													<td>No Data</td>
												</tr>
											)}
										</tbody>
									</Table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		society: state.society.society,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getSocietyAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SocietyInformation);
