import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { format } from "date-fns";
import swal from "sweetalert";
import axiosInstance from "../../../../services/AxiosInstance";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getPaymentsAction,
  deletePaymentAction,
} from "../../../../store/actions/PaymentActions";

import ReactExport from "react-data-export";
import { FaFileExcel } from "react-icons/fa";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const thisYear = new Date().getFullYear();
const mnth = new Date();
const thisMonth = monthNames[mnth.getMonth()];

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      societyId: "",
      search: "",
      currentPayments: [],
      currentPage: null,
      totalPages: null,
      selectYear: thisYear,
      selectMonth: thisMonth,
      years: [],
    };
    this.handleChangeYear = this.handleChangeYear.bind(this);
    this.handleChangeMonth = this.handleChangeMonth.bind(this);
    this.showData = this.showData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeYear(event) {
    this.setState({ currentPayments: [], selectYear: event.target.value });
  }

  handleChangeMonth(event) {
    this.setState({ currentPayments: [], selectMonth: event.target.value });
  }

  showData(event) {
    let filteredData = this.props?.payments?.paymentDetails
      ?.filter(
        (item) =>
          item?.year === this.state?.selectYear.toString() &&
          item?.month.slice(0, 3).toLowerCase() ===
            this.state?.selectMonth.slice(0, 3).toLowerCase()
      )
      .slice(0, 5);
    this.setState({
      currentPayments: filteredData,
    });
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem("userDetails"));
    let currentYear = new Date().getFullYear();
    let lastYear = currentYear - 1;
    let nextYear = currentYear + 1;
    let yearsData = [];
    yearsData.push(lastYear, currentYear, nextYear);

    this.setState({
      societyId: userData.user.societyName,
      years: yearsData,
    });

    this.props.getPaymentsAction(userData.user.societyName);

    this.initData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.payments?.paymentDetails?.length !==
      this.props?.payments?.paymentDetails?.length
    ) {
      this.setState({
        currentPayments: this.props?.payments?.paymentDetails,
      });
    }
  }

  initData() {
    let currentPayments =
      this.state?.currentPayments?.length > 0
        ? this.state?.currentPayments?.filter(
            (item) =>
              item?.year === this.state?.selectYear.toString() &&
              item?.month.slice(0, 3).toLowerCase() ===
                this.state?.selectMonth.slice(0, 3).toLowerCase()
          )
        : this.props?.payments?.paymentDetails?.filter(
            (item) =>
              item?.year === this.state?.selectYear.toString() &&
              item?.month.slice(0, 3).toLowerCase() ===
                this.state?.selectMonth.slice(0, 3).toLowerCase()
          );

    this.setState({ currentPayments });
  }

  onPageChanged = (data) => {
    const allPayments = this.props?.payments?.paymentDetails?.filter(
      (item) =>
        item?.year === this.state?.selectYear.toString() &&
        item?.month.slice(0, 3).toLowerCase() ===
          this.state?.selectMonth.slice(0, 3).toLowerCase()
    );

    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    var currentPayments = allPayments?.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentPayments, totalPages });
  };

  handleSearch(event) {
    let currentPayments = this.props.payments?.paymentDetails;
    // Get event value
    let searchValue = event.target.value;

    // Set the state to trigger a re-rendering
    this.setState({ search: searchValue });

    let searchString = searchValue.trim().toLowerCase();
    if (searchString.length > 0) {
      // We are searching. Filter the results.
      currentPayments = currentPayments.filter(
        (e) =>
          e.flatId.toLowerCase().match(searchString) ||
          e.month.toLowerCase().match(searchString) ||
          e.year.toLowerCase().match(searchString)
      );
    }
    this.setState({ currentPayments });
  }

  handleSubmit(event) {
    let filteredDate = this.state?.currentPayments.filter(
      (item) =>
        item?.year === this.state?.selectYear &&
        item?.month.slice(0, 3).toLowerCase() ===
          this.state?.selectMonth.toLowerCase()
    );
    this.setState({ currentPayments: filteredDate });
    event.preventDefault();
  }

  async generateReceipt(id) {
    const payload = {
      paymentDetailsId: id,
    };
    const resp = await axiosInstance
      .post(`admin/paymentReceipt`, payload)
      .then((res) => res?.data)
      .catch((err) => {
        return err;
      });

    if (resp.success === true) {
      toast.success(resp.message);
    } else {
      toast.error("No paymentDetail found with this ID");
    }
  }

  deleteRecord(id) {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this record ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((response) => {
      if (response) {
        this.props.deletePaymentAction(id, this.props.history);
        swal("Your file has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your file is safe!");
      }
    });
  }

  render() {
    let totalPayments =
      this.props?.payments?.paymentDetails?.filter(
        (item) =>
          item?.year === this.state?.selectYear.toString() &&
          item?.month.slice(0, 3).toLowerCase() ===
            this.state?.selectMonth.slice(0, 3).toLowerCase()
      ).length || 0;

    this.state.currentPayments = this.state?.currentPayments?.filter(
      (item) =>
        item?.year === this.state?.selectYear.toString() &&
        item?.month.slice(0, 3).toLowerCase() ===
          this.state?.selectMonth.slice(0, 3).toLowerCase()
    );

    const DataSet = [
      {
        columns: [
          { title: "Flat No" },
          { title: "Owner Name" },
          { title: "Bank Branch" },
          { title: "Payment Mode" },
          { title: "Transaction Details" },
          { title: "Maintenance Month" },
          { title: "Maintenance Year" },
          { title: "Payment Type" },
          { title: "Payment Date" },
          { title: "Amount" },
        ],
        data: this.props.payments?.paymentDetails
          ?.sort((a, b) => a.flatNo.localeCompare(b.flatNo))
          ?.map((data) => [
            { value: data.flatNo },
            { value: data.ownerName },
            { value: data.bankDetails },
            { value: data.paymentMode },
            { value: data.transactionDetails },
            { value: data.month },
            { value: data.year },
            { value: data.paymentType },
            { value: data.date },
            { value: data.amount },
          ]),
      },
    ];

    const { currentPayments, currentPage, totalPages } = this.state;

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
            Dashboard
          </Breadcrumb.Item>{" "}
          <Breadcrumb.Item active>Payment Lists</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-sm-12">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <div className="col-xl-4 col-sm-12 pl-0">
                  <h2 className="card-title">Payments</h2>
                </div>

                <div className="col-xl-4 col-sm-12">
                  {/* <input
									type="text"
									className="form-control mb-2 search-list pull-right"
									placeholder="Search..."
									onChange={(e) => this.handleSearch(e)}
								/> */}
                </div>
                <div className="col-xl-4 col-sm-12">
                  <Link
                    to={"add-payment"}
                    className="btn btn-primary mb-2 pull-right"
                  >
                    Add Payment
                  </Link>
                </div>
              </div>
              <hr />
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-xl-3 col-sm-12 ml-4">
                    <div className="form-group">
                      <div>
                        <select
                          className="form-control"
                          id="selectYear"
                          placeholder="Select a Select Year.."
                          name="selectYear"
                          onChange={this.handleChangeYear}
                          value={this.state.selectYear}
                        >
                          <option value="Select" label="Select" disabled />
                          {this.state.years?.map((opt, i) => (
                            <option key={i} value={opt} label={opt} />
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-12">
                    <div className="form-group">
                      <div>
                        <select
                          className="form-control"
                          id="selectMonth"
                          placeholder="Select a Select Month.."
                          name="selectMonth"
                          onChange={this.handleChangeMonth}
                          value={this.state.selectMonth}
                        >
                          <option
                            value="Select Month"
                            label="Select Month"
                            disabled
                          />
                          <option value="January" label="January" />
                          <option value="February" label="February" />
                          <option value="March" label="March" />
                          <option value="April" label="April" />
                          <option value="May" label="May" />
                          <option value="June" label="June" />
                          <option value="July" label="July" />
                          <option value="August" label="August" />
                          <option value="September" label="September" />
                          <option value="October" label="October" />
                          <option value="November" label="November" />
                          <option value="December" label="December" />
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-12">
                    <Link
                      to="#"
                      className="btn btn-primary"
                      onClick={this.showData}
                    >
                      Show
                    </Link>
                  </div>
                  {/* <div className="col-xl-3 col-sm-12">
									<button className="btn btn-primary">Show</button>
								</div> */}
                </div>
              </form>

              <div>
                <ExcelFile
                  filename={`Payment Details-${this.state.selectMonth}-${this.state.selectYear}`}
                  element={
                    <button type="button" className="btn btn-primary ml-4">
                      <FaFileExcel /> Export to Excel
                    </button>
                  }
                >
                  <ExcelSheet
                    dataSet={DataSet}
                    name={`Payment Details-${this.state.selectMonth}-${this.state.selectYear}`}
                  />
                </ExcelFile>
              </div>
              <div className="card-body pb-0">
                <Table bordered striped responsive id="payments">
                  <thead>
                    <tr>
                      <th>Flat No</th>
                      <th>Owner Name</th>
                      <th>Bank Branch</th>
                      <th>Payment Mode</th>
                      <th>Transaction Details</th>
                      <th>Maintenance Month</th>
                      <th>Maintenance year</th>
                      <th>Payment Type</th>
                      <th>Amount</th>
                      <th>Payment Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPayments?.length
                      ? currentPayments
                          ?.sort((a, b) => a.flatNo.localeCompare(b.flatNo))
                          ?.map((payment, i) => (
                            <tr key={i}>
                              <td>{payment.flatNo} </td>
                              <td>{payment.ownerName}</td>
                              <td>{payment.bankDetails}</td>
                              <td>{payment.paymentMode}</td>
                              <td>{payment.transactionDetails}</td>
                              <td>{payment.month}</td>
                              <td>{payment.year}</td>
                              <td>{payment.paymentType}</td>
                              <td>{payment.amount}</td>
                              <td>
                                {payment.date &&
                                  format(new Date(payment?.date), "dd-MM-yyyy")}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <Link
                                    to="#"
                                    className="btn btn-primary shadow btn-xs sharp mr-1"
                                    onClick={this.generateReceipt.bind(
                                      this,
                                      payment._id
                                    )}
                                  >
                                    <i className="fa fa-file"></i>
                                  </Link>
                                  {payment?.paymentReciptGenerated ===
                                    false && (
                                    <Link
                                      to={`add-payment/${payment._id}`}
                                      className="btn btn-info shadow btn-xs sharp mr-1"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                  )}
                                  <Link
                                    to="#"
                                    className="btn btn-danger shadow btn-xs sharp"
                                    onClick={this.deleteRecord.bind(
                                      this,
                                      payment._id
                                    )}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))
                      : null}
                  </tbody>
                </Table>
                <div className="d-flex flex-row py-4 pull-left">
                  {currentPage && (
                    <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                      Page{" "}
                      <span className="font-weight-bold">{currentPage}</span> /{" "}
                      <span className="font-weight-bold">{totalPages}</span>
                    </span>
                  )}
                </div>
                <div className="d-flex flex-row py-4 pull-right">
                  <label className="p-2">Page:</label>
                  {totalPayments ? (
                    <Pagination
                      totalRecords={totalPayments}
                      pageLimit={5}
                      pageNeighbours={1}
                      onPageChanged={this.onPageChanged}
                    />
                  ) : null}
                </div>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payments: state.payment?.payments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getPaymentsAction, deletePaymentAction },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
