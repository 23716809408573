import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getExpenseAction,
	createExpenseAction,
	updateExpenseAction,
} from "../../../../store/actions/ExpenseActions";
import { getExpenseCategoriesAction } from "../../../../store/actions/ExpenseCategoryActions";
import { getSocietyAction } from "../../../../store/actions/SocietyActions";

class AddEditExpense extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expense: {},
			societyId: "",
			userName: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
			userName: userData.user.userName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.expense) {
				this.props.getExpenseAction(id);
			}
		} else {
		}
		this.props.getExpenseCategoriesAction(userData.user.societyName);
		this.props.getSocietyAction(userData.user.societyName);
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.expense?.length !== this.props?.expense?.length) {
			this.setState({
				expense: this.props?.expense,
			});
		}
	}

	handleAdd = (values) => {
		var formatYear = new Date(values.year).getFullYear().toString();

		Object.assign(values, { year: formatYear }); // Push year

		const id = this.props.match.params.id;
		if (id) {
			values = { ...values, year: formatYear };
			this.props.updateExpenseAction(
				values,
				this.props.history,
				this.props?.expense?._id
			);
		} else {
			this.props.createExpenseAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var expense = this.props?.expense;
		} else {
			expense = "";
		}

		let cat_data = this.props.categories?.expenseCategorys;
		let society_users = this.props.society?.society?.contactInfo;

		const initialValues = {
			societyId: this.state.societyId,
			expenseType: expense ? expense.expenseType : "",
			expenseCategory: expense ? expense?.expenseCategory?._id : "",
			expenseDescription: expense ? expense.expenseDescription : "",
			paymentMode: expense ? expense.paymentMode : "",
			expenseChequeNo: expense ? expense.expenseChequeNo : "",
			expenseBillVoucherNo: expense ? expense.expenseBillVoucherNo : "",
			amount: expense ? expense.amount : 0,
			//preparedBy: expense ? expense.preparedBy : "",
			preparedBy: this.state?.userName,
			approvedBy: expense ? expense?.approvedBy : "",
			expenseBillDate: expense ? expense.expenseBillDate : new Date(),
			paymentDate: expense ? expense.paymentDate : new Date(),
			month: expense ? expense.month : "Select Month",
			year: expense ? expense.year : new Date(),
			transactionDetails: expense ? expense.transactionDetails : "",
			bankDetails: expense ? expense.bankDetails : "",
		};

		const validationSchema = Yup.object().shape({
			expenseType: Yup.string().required("Please Select Expense Type."),
			expenseCategory: Yup.string().required("Please Select Expense Category."),
			expenseDescription: Yup.string().required("Please Enter Expense Desc."),
			paymentMode: Yup.string().required("Please Select Payment Mode."),
			//expenseChequeNo: Yup.string().required("Please Enter Cheque No."),
			expenseBillVoucherNo: Yup.string().required(
				"Please Enter Expense Bill no."
			),
			amount: Yup.string().required("Please Enter Amount."),
			//preparedBy: Yup.string().required("Please Enter Prepared By."),
			approvedBy: Yup.string().required("Please Select Approved By."),
			expenseBillDate: Yup.date().required("Please Select Bill Dt."),
			paymentDate: Yup.date().min(
				Yup.ref("expenseBillDate"),
				"Payment date should be less than Bill date"
			),
			month: Yup.string().required("Please Select Month."),
			year: Yup.string().required("Please Select Year."),
			// transactionDetails: Yup.date().when(
			// 	"paymentMode",
			// 	(paymentMode, schema) => {
			// 		if (paymentMode === "Cash")
			// 			return schema.required("Please Enter Transaction Detail");
			// 	}
			// ),
			// bankDetails: Yup.string().required("Please Enter Bank Details."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/expense" }} linkAs={Link}>
						Expense Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Expense</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Expense</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Expense Details</h3>
						) : (
							<h3 className="pt-3">Add Expense Details</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/expense"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Cash Details Type *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-expenseType"
																	placeholder="Select a expense Type.."
																	name="expenseType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.expenseType || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	<option value="Bill" label="Bill" />
																	<option value="Voucher" label="Voucher" />
																</select>
																{errors.expenseType && touched.expenseType ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.expenseType}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Cash Expense Category *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-expenseCategory"
																	placeholder="Select a expense Category.."
																	name="expenseCategory"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.expenseCategory || "Select"}
																>
																	<option value="Select" label="Select" />
																	{cat_data?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt._id}
																			label={opt.categoryName}
																		/>
																	))}
																</select>
																{errors.expenseCategory &&
																touched.expenseCategory ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.expenseCategory}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Description *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-expenseDescription"
																	placeholder="Enter a Desc.."
																	name="expenseDescription"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.expenseDescription || ""}
																/>
																{errors.expenseDescription &&
																touched.expenseDescription ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.expenseDescription}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Payment Mode *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-paymentMode"
																	placeholder="Select a payment Mode.."
																	name="paymentMode"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.paymentMode || "Select"}
																>
																	<option value="Select" label="Select" />
																	<option value="Cheque" label="Cheque" />
																	<option value="Cash" label="Cash" />
																	<option value="Transfer" label="Transfer" />
																</select>
																{errors.paymentMode && touched.paymentMode ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.paymentMode}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Cheque No.</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-expenseChequeNo"
																	placeholder="Enter a Cheque No.."
																	name="expenseChequeNo"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.expenseChequeNo || ""}
																/>
																{errors.expenseChequeNo &&
																touched.expenseChequeNo ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.expenseChequeNo}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Bill No. *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-expenseBillVoucherNo"
																	placeholder="Enter a Bill No.."
																	name="expenseBillVoucherNo"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.expenseBillVoucherNo || ""}
																/>
																{errors.expenseBillVoucherNo &&
																touched.expenseBillVoucherNo ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.expenseBillVoucherNo}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Amount *</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-amount"
																	placeholder="Enter a Amount.."
																	name="amount"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.amount || ""}
																/>
																{errors.amount && touched.amount ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.amount}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Prepared By *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-preparedBy"
																	placeholder="Enter a Prepared By.."
																	name="preparedBy"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.preparedBy}
																	disabled
																/>
																{errors.preparedBy && touched.preparedBy ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.preparedBy}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Approved By *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-approvedBy"
																	placeholder="Select a Approved by.."
																	name="approvedBy"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.approvedBy || "Select"}
																>
																	<option value="Select" label="Select" />
																	{society_users?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt.contactName}
																			label={opt.contactName}
																		/>
																	))}
																</select>
																{errors.approvedBy && touched.approvedBy ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.approvedBy}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Month *</label>
															<div>
																<select
																	className="form-control"
																	id="val-month"
																	placeholder="Select a Select Month.."
																	name="month"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.month || ""}
																>
																	<option
																		value="Select Month"
																		label="Select Month"
																		disabled
																	/>
																	<option value="January" label="January" />
																	<option value="February" label="February" />
																	<option value="March" label="March" />
																	<option value="April" label="April" />
																	<option value="May" label="May" />
																	<option value="June" label="June" />
																	<option value="July" label="July" />
																	<option value="August" label="August" />
																	<option value="September" label="Septemper" />
																	<option value="October" label="October" />
																	<option value="November" label="November" />
																	<option value="December" label="December" />
																</select>
																{errors.month && touched.month ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.month}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Year *</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		views={["year"]}
																		id="year"
																		//format="yyyy"
																		value={
																			values.year || new Date().getFullYear()
																		}
																		onChange={(e) => setFieldValue("year", e)}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.year && touched.year ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.year}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													{values?.paymentMode === "Cash" && (
														<div className="col-xl-3 col-sm-12">
															<div className="form-group">
																<label className="text-label">
																	Transaction Details
																</label>
																<div>
																	<input
																		type="text"
																		className="form-control"
																		id="val-transactionDetails"
																		placeholder="Enter a Transaction Details.."
																		name="transactionDetails"
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.transactionDetails || ""}
																	/>
																	{errors.transactionDetails &&
																	touched.transactionDetails ? (
																		<div
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.transactionDetails}
																		</div>
																	) : null}
																</div>
															</div>
														</div>
													)}
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Bank Details</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-bankDetails"
																	placeholder="Enter a Bank Details.."
																	name="bankDetails"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.bankDetails || ""}
																/>
																{errors.bankDetails && touched.bankDetails ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.bankDetails}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Bill Date *</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="expenseBillDate"
																		format="dd/MM/yyyy"
																		value={values.expenseBillDate}
																		onChange={(e) =>
																			setFieldValue("expenseBillDate", e)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.expenseBillDate &&
																touched.expenseBillDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.expenseBillDate}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Payment Date *
															</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="paymentDate"
																		format="dd/MM/yyyy"
																		value={values.paymentDate}
																		onChange={(e) =>
																			setFieldValue("paymentDate", e)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.paymentDate && touched.paymentDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.paymentDate}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												{/* <div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div> */}

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddExpense;
const mapStateToProps = (state) => {
	return {
		expense: state.expense?.expense,
		categories: state.expenseCategory?.expenseCategories,
		society: state.society?.society,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getExpenseAction,
			createExpenseAction,
			updateExpenseAction,
			getExpenseCategoriesAction,
			getSocietyAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditExpense);
