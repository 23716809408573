import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { format } from "date-fns";

import { getPaymentAction } from "../../../../store/actions/PaymentActions";

class PaymentDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			payment: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.payment) {
			this.props.getPaymentAction(id);
		}
		this.init();
	}

	init() {
		this.state.payment = this.props?.payment;
	}

	render() {
		this.state.payment = this.props?.payment;

		const flatNo = this.state.payment?.flatNo || "";
		const ownerName = this.state.payment?.ownerName || "";
		const month = this.state.payment?.month || "";
		const year = this.state.payment?.year || "";
		const paymentType = this.state.payment?.paymentType || "";
		const paymentMode = this.state.payment?.paymentMode || "";
		const transactionDetails = this.state.payment?.transactionDetails || "";
		const amount = this.state.payment?.amount || "";
		const bankDetails = this.state.payment?.bankDetails || "";
		const date =
			(this.state.payment?.date &&
				format(new Date(this.state.payment?.date), "dd-MM-yyyy")) ||
			"";
		const status = this.state.payment?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/payments" }} linkAs={Link}>
						Payment Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Payment Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Payment Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/payments"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Flat No</strong>
										<span className="mb-0">{flatNo}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Owner Name</strong>
										<span className="mb-0">{ownerName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Year</strong>
										<span className="mb-0">{year}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Month</strong>
										<span className="mb-0">{month}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Payment Type</strong>
										<span className="mb-0">{paymentType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Payment Mode</strong>
										<span className="mb-0">{paymentMode}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Transaction Details</strong>
										<span className="mb-0">{transactionDetails}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Amount</strong>
										<span className="mb-0">{amount}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Bank Details</strong>
										<span className="mb-0">{bankDetails}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Date</strong>
										<span className="mb-0">{date}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		payment: state.payment?.payment,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getPaymentAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetail);
