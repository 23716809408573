import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import axiosInstance from "../../../../../services/AxiosInstance";

class CustomLabelDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customLabel: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		this.getCustomLabel(id);
	}

	async getCustomLabel(id) {
		const resp = await axiosInstance
			.get(`admin/customLabel/${id}`)
			.then((res) => res?.data)
			.catch((err) => {
				return err;
			});

		this.setState({
			customLabel: resp?.customLabel,
		});
	}

	render() {
		const societyName = this.state?.customLabel?.societyId?.societyName || "";
		const customLabels = this.state?.customLabel?.customLabel || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item
						linkProps={{ to: "/superadmin/custom-labels" }}
						linkAs={Link}
					>
						Custom Label Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Custom Label Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Custom Label Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/superadmin/custom-labels"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society</strong>
										<span className="mb-0">{societyName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Custom Labels</strong>
										<span className="mb-0">
											{customLabels &&
												customLabels.map((itm, i) => {
													return <span key={i}>{itm.label}, </span>;
												})}
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default CustomLabelDetails;
