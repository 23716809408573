import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { format } from "date-fns";

import { getRequestQueryAction } from "../../../../store/actions/RequestQueryActions";

class RequestQueryDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.query) {
			this.props.getRequestQueryAction(id);
		}
		this.init();
	}

	init() {
		this.state.query = this.props?.query;
	}

	render() {
		this.state.query = this.props?.query;

		const flatNo = this.state?.query?.flatNo || "";
		const memberName = this.state?.query?.memberName || "NA";
		const title = this.state?.query?.title || "NA";
		const description = this.state?.query?.description || "NA";
		const commments = this.state?.query?.commments || "NA";
		const date = this.state?.query?.date || "";
		const status = this.state?.query?.status || "Open";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/request-queries" }} linkAs={Link}>
						Request Query Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Request Query Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Request Query Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/request-queries"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Flat No</strong>
										<span className="mb-0">{flatNo}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Member Name</strong>
										<span className="mb-0">{memberName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Title</strong>
										<span className="mb-0">{title}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Description</strong>
										<span className="mb-0">{description}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Comments</strong>
										<span className="mb-0">{commments}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Date</strong>
										<span className="mb-0">
											{date && format(new Date(date), "dd-MM-yyyy")}
										</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Query Status</strong>
										<span className="mb-0">
											{status === "Pending" && (
												<span className="badge badge-rounded badge-primary">
													{status}
												</span>
											)}
											{status === "Open" && (
												<span className="badge badge-rounded badge-warning">
													{status}
												</span>
											)}
											{status === "Closed" && (
												<span className="badge badge-rounded badge-danger">
													{status}
												</span>
											)}
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		query: state.query?.query,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getRequestQueryAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestQueryDetail);
