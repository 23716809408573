import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Editor } from "@tinymce/tinymce-react";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getNoticeAction,
	createNoticeAction,
	updateNoticeAction,
} from "../../../../store/actions/NoticeActions";

class AddEditNotice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notice: {},
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.notice) {
				this.props.getNoticeAction(id);
			}
		} else {
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.notice?.length !== this.props?.notice?.length) {
			this.setState({
				notice: this.props?.notice,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateNoticeAction(
				values,
				this.props.history,
				this.props?.notice?._id
			);
		} else {
			this.props.createNoticeAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var notice = this.props?.notice;
		} else {
			notice = "";
		}

		const initialValues = {
			societyId: this.state.societyId,
			title: notice ? notice.title : "",
			commments: notice ? notice.commments : "",
			date: notice ? notice.date : new Date(),
			status: notice ? notice.status : true,
		};

		const validationSchema = Yup.object().shape({
			title: Yup.string().required("Please Enter Title."),
			commments: Yup.string().required("Please Enter Desc."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/notices" }} linkAs={Link}>
						Notice Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Notice</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Notice</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Notice</h3>
						) : (
							<h3 className="pt-3">Add Notice</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/notices"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Title *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-title"
																	placeholder="Enter a title.."
																	name="title"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.title || ""}
																/>
																{errors.title && touched.title ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.title}
																	</div>
																) : null}
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Date</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="date"
																		format="dd/MM/yyyy"
																		value={values.date}
																		onChange={(e) => setFieldValue("date", e)}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.date && touched.date ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.date}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-8 col-sm-12">
														<div className="form-group">
															<label className="text-label">commments *</label>
															<div>
																<Editor
																	init={{
																		height: 500,
																		menubar: false,
																		plugins: [
																			"advlist autolink lists link image code charmap print preview anchor",
																			"searchreplace visualblocks code fullscreen",
																			"insertdatetime media table paste code help wordcount",
																		],
																		toolbar:
																			"undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \
            																bullist numlist outdent indent | removeformat | help ",
																		content_style: "body { color: #828282 }",
																	}}
																	onEditorChange={(e) =>
																		handleChange({
																			target: {
																				name: "commments",
																				value: e,
																			},
																		})
																	}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.commments || ""}
																/>
																{errors.commments && touched.commments ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.commments}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddNotice;
const mapStateToProps = (state) => {
	return {
		notice: state.notice?.notice,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getNoticeAction,
			createNoticeAction,
			updateNoticeAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditNotice);
