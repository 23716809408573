import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import { format } from "date-fns";
import axiosInstance from "../../../../../services/AxiosInstance";

import razorpay from "../../../Razorpay"; // Razorpay imports

//Toaster Message imports
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { getBillingsByFlatAction } from "../../../../../store/actions/BillingActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class Billing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentBillings: [],
			currentPage: null,
			totalPages: null,
			societyId: "",
			billing: {},
			society: {},
			userId: "",
			name: "",
			email: "",
			contact: "",
		};
	}

	componentDidMount() {
		let flatData = JSON.parse(localStorage.getItem("userFlatDetails"));

		let userData = JSON.parse(localStorage.getItem("userDetails"));

		this.setState({
			userId: userData?.user?._id,
			name: userData?.user?.name,
			email: userData?.user?.email,
			contact: userData?.user?.mobile,
		});

		this.props.getBillingsByFlatAction(flatData?.societyId, flatData?.flatId);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.billings?.billings?.length !==
			this.props?.billings?.billings?.length
		) {
			this.setState({
				currentBillings: this.props?.billings?.billings,
			});
		}
		if (prevProps?.billing?.length !== this.props?.billing?.length) {
			this.setState({
				billing: this.props?.billing,
			});
		}
	}

	initData() {
		let currentBillings =
			this.state?.currentBillings?.length > 0
				? this.state?.currentBillings
				: this.props?.billings?.billings;

		this.setState({ currentBillings });
	}

	onPageChanged = (data) => {
		const allBillings = this.props?.billings?.billings;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentBillings = allBillings?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentBillings, totalPages });
	};

	// handleSearch(event) {
	// 	let currentBillings = this.props.billings?.billings;
	// 	// Get event value
	// 	let searchValue = event.target.value;

	// 	// Set the state to trigger a re-rendering
	// 	this.setState({ search: searchValue });

	// 	let searchString = searchValue.trim().toLowerCase();
	// 	if (searchString.length > 0) {
	// 		// We are searching. Filter the results.
	// 		currentBillings = currentBillings.filter(
	// 			(e) =>
	// 				e.flatId.toLowerCase().match(searchString) ||
	// 				e.maintenanceMonth.toLowerCase().match(searchString) ||
	// 				e.maintenanceYear.toLowerCase().match(searchString)
	// 		);
	// 	}
	// 	this.setState({ currentBillings });
	// }

	loadScript(src) {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

	async displayRazorpay(billingId, societyId, flatId, amt) {
		const payload = {
			amount: amt,
			societyId: societyId,
			billingId: billingId,
			flatId: flatId,
			userId: this.state?.userId,
		};
		const response = await axiosInstance.post("/payment/process", payload);
		const { data } = response;

		const res = await this.loadScript(
			"https://checkout.razorpay.com/v1/checkout.js"
		);

		if (!res) {
			alert("Razorpay SDK failed to load. Are you online?");
			return;
		}

		const options = {
			key: razorpay.dev.RAZORPAY_API_KEY,
			amount: amt * 100,
			currency: "INR",
			name: "Society Care",
			description: "Pay Maintenance",
			image: "",
			order_id: data.order.orderId,
			handler: async (response) => {
				try {
					toast.success("Payment is successfull!");
					const values = {
						razorpay_payment_id: response.razorpay_payment_id,
						razorpay_order_id: response.razorpay_order_id,
						razorpay_signature: response.razorpay_signature,
						//transactionamount: amt,
					};

					axiosInstance
						.put(`/payment/process/${data.order._id}`, values)
						.then((res) => {
							console.log("Success");
						})
						.catch((e) => console.log(e));
				} catch (error) {
					console.log(error);
					toast.error("Payment failed!");
				}
			},
			prefill: {
				name: this.state.name || "",
				email: this.state.email || "",
				contact: this.state.contact || "",
			},
			theme: { color: "#233C8E" },
		};

		const paymentObject = new window.Razorpay(options);
		paymentObject.open();
	}

	render() {
		let totalBillings = this.props?.billings?.billings?.length || 0;

		const { currentBillings, currentPage, totalPages } = this.state;
		this.state.currentBillings = this.props.billings?.billings;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Billing Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Billing History</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									{/* <input
									type="text"
									className="form-control mb-2 search-list pull-right"
									placeholder="Search..."
									onChange={(e) => this.handleSearch(e)}
								/> */}
								</div>
							</div>

							<div className="card-body pb-0">
								<Table bordered striped responsive id="billings">
									<thead>
										<tr>
											<th>Flat No</th>
											<th>Bill No.</th>
											<th>Bill Date</th>
											<th>Maintenance Month</th>
											<th>Maintenance year</th>
											<th>Maintenance Charges</th>
											<th>Sinking Fund</th>
											<th>Repair Fund</th>
											<th>Cidco Charges</th>
											<th>NOC Charges</th>
											<th>Parking Charges</th>
											<th>Festival Charges</th>
											<th>Other Charges</th>
											<th>Total Charges</th>
											<th>Arrears</th>
											<th>Interest</th>
											<th>Grand Total</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentBillings?.length
											? currentBillings.map((billing, i) => (
													<tr key={i}>
														<td>{billing.flatNo}</td>
														<td>{billing.billNo}</td>
														<td style={{ whiteSpace: "nowrap" }}>
															{billing.billDate &&
																format(
																	new Date(billing?.billDate),
																	"dd-MM-yyyy"
																)}
														</td>
														<td>{billing.maintenanceMonth}</td>
														<td>{billing.maintenanceYear}</td>
														<td>{billing.maintenanceCharges}</td>
														<td>{billing.sinkingFund}</td>
														<td>{billing.repairFund}</td>
														<td>{billing.cidcoCharges}</td>
														<td>{billing.nocCharges}</td>
														<td>{billing.parkingCharges}</td>
														<td>{billing.festivalCharges}</td>
														<td>{billing.otherCharges}</td>
														<td>{billing.totalCharges}</td>
														<td>{billing.arrears}</td>
														<td>{billing.interest}</td>
														<td>{billing.totalAmount}</td>
														<td>
															<div>
																<button
																	className="btn btn-primary shadow btn-xs"
																	onClick={(e) => {
																		this.displayRazorpay(
																			billing._id,
																			billing.societyId,
																			billing.flatId,
																			billing.totalAmount
																		);
																	}}
																>
																	Pay
																</button>
															</div>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalBillings ? (
										<Pagination
											totalRecords={totalBillings}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
								<ToastContainer
									position="top-right"
									autoClose={3000}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		billings: state.billing?.userbillings,
		loading: state?.billing?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getBillingsByFlatAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
