import { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik } from "formik";
import * as Yup from "yup";

import {
  getSocietyAction,
  updateSocietyAction,
} from "../../../store/actions/SocietyActions";
import { getAmenityMastersAction } from "../../../store/actions/AmenitiesMasterActions";

const AminityItem = ({ item, index, handleChange, handleBlur }) => {
  return (
    <tr>
      <td>
        <div className="form-check">
          <input
            checked={item.status === true}
            type="checkbox"
            name={`amenties[${index}].status`}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </td>
      <td>
        <i className={`${item.webIcon} pr-2`}></i>
        {item.name}
      </td>
    </tr>
  );
};

const validationSchema = Yup.object().shape({
  societyName: Yup.string().required("Please enter a society name"),
  societyAddress: Yup.string().required("Please enter a society address"),
  societyUrl: Yup.string().required("Please enter a society Url"),
  societyMembersCount: Yup.string().required(
    "Please enter a society member count"
  ),
  societyActivationYear: Yup.string().required(
    "Please enter a society Activation year"
  ),
  societyActivationMonth: Yup.string().required(
    "Please enter a society Activation Month"
  ),
  amenties: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      webIcon: Yup.string(),
      status: Yup.boolean(),
    })
  ),
  societySubscriptionStartDate: Yup.date(),
  societySubscriptionEndDate: Yup.date().min(
    Yup.ref("societySubscriptionStartDate"),
    "End date can't be before Start date"
  ),
});

class AddEditSocietyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      societyId: "",
    };
    this.handleEditSociety = this.handleEditSociety.bind(this);
    this.initForm();
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem("userDetails"));
    this.setState({
      societyId: userData.user.societyName,
    });

    if (this.props.society) {
      this.props.getSocietyAction(userData.user.societyName); //Dev
    }
    this.props.getAmenityMastersAction();
  }

  componentDidUpdate() {
    this.initForm();
  }

  initForm() {
    let userData = JSON.parse(localStorage.getItem("userDetails"));

    const { society } = this.props;
    const amenityData = this.props?.amenities?.amenities;

    this.initialValues = {
      societyId: userData.user.societyName,
      societyName: society ? society.societyName : "",
      societyAddress: society ? society.societyAddress : "",
      societyUrl: society ? society.societyUrl : "",
      societyEmail: society ? society.societyEmail : "",
      societyRegistrationNo: society ? society.societyRegistrationNo : "",
      societyMembersCount: society ? society.societyMembersCount : 0,
      societyActivationYear: society ? society.societyActivationYear : true,
      societyActivationMonth: society ? society.societyActivationMonth : "",
      societySubscriptionStartDate: society
        ? society.societySubscriptionStartDate
        : Date,
      societySubscriptionEndDate: society
        ? society.societySubscriptionEndDate
        : Date,
      societyBillDueDateinDays: society ? society.societyBillDueDateinDays : 0,
      societyDefaulterBaseLineAmount: society
        ? society.societyDefaulterBaseLineAmount
        : 0,
      societyParkingCount: society ? society.societyParkingCount : 0,
      //amenties: this.mapAmenityStatus(),
      amenties: amenityData ? this.mapAmenityStatus(amenityData) : [],
    };
  }

  // mapAmenityStatus() {  // For dummy amenities list
  // 	const { society } = this.props;
  // 	return society?.amenties?.length
  // 		? amenties.map((item) => {
  // 				let found = society.amenties.find((itm) => itm.name === item.name);
  // 				if (found) item.status = found.status;
  // 				return item;
  // 		  })
  // 		: [...amenties];
  // }

  mapAmenityStatus(amenityData) {
    const { society } = this.props;

    return society?.amenties?.length
      ? amenityData?.map((item) => {
          let found = society.amenties.find((itm) => itm.name === item.name);
          if (found) item.status = found.status;
          return item;
        })
      : [...amenityData];
  }

  handleEditSociety = (values) => {
    this.props.updateSocietyAction(
      values,
      this.props.history,
      this.props?.society?._id
    );
  };

  render() {
    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
            Dashboard
          </Breadcrumb.Item>{" "}
          <Breadcrumb.Item
            linkProps={{ to: "/society-information" }}
            linkAs={Link}
          >
            Society Information
          </Breadcrumb.Item>{" "}
          <Breadcrumb.Item active>Edit Society Information</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row">
          <div className="col-md-6  col-sm-12">
            <h3 className="pt-3">Edit Society Information</h3>
          </div>
          <div className="col-md-6 col-sm-12">
            <Link
              to={"society-information"}
              className="btn btn-primary mb-2 pull-right"
            >
              Back to Lists
            </Link>
          </div>

          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="form-validation">
                  <Formik
                    initialValues={this.initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        //alert(JSON.stringify(values, null, 2));
                        this.handleEditSociety(values);
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      dirty,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Society Name*
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-societyName"
                                  placeholder="Enter a Society name.."
                                  name="societyName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.societyName}
                                />
                                {errors.societyName && touched.societyName ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.societyName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Society Address *
                              </label>
                              <div>
                                <textarea
                                  type="textarea"
                                  className="form-control"
                                  id="val-societyAddress"
                                  name="societyAddress"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.societyAddress}
                                  placeholder="Enter Society Address."
                                  rows="5"
                                ></textarea>
                                {errors.societyAddress &&
                                touched.societyAddress ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.societyAddress}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Society URL *
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-societyUrl"
                                  placeholder="Enter a society url.."
                                  name="societyUrl"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.societyUrl}
                                />
                                {errors.societyUrl && touched.societyUrl ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.societyUrl}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Society Email
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-societyUrl"
                                  placeholder="Enter a society Email.."
                                  name="societyEmail"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.societyEmail}
                                />
                                {errors.societyEmail && touched.societyEmail ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.societyEmail}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Society Registration No.
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-societyRegistrationNo"
                                  placeholder="Enter a society Registration No.."
                                  name="societyRegistrationNo"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.societyRegistrationNo}
                                />
                                {errors.societyRegistrationNo &&
                                touched.societyRegistrationNo ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.societyRegistrationNo}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Number of Member *
                              </label>
                              <div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="val-societyMembersCount"
                                  placeholder="Enter a member count.."
                                  name="societyMembersCount"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.societyMembersCount}
                                />
                                {errors.societyMembersCount &&
                                touched.societyMembersCount ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.societyMembersCount}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Bill Due Date in Days
                              </label>
                              <div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="val-societyBillDueDateinDays"
                                  placeholder="Enter a society Bill Due DateinDays.."
                                  name="societyBillDueDateinDays"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.societyBillDueDateinDays}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Default Base Line Amount
                              </label>
                              <div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="val-societyDefaulterBaseLineAmount"
                                  placeholder="Enter a society Defaulter BaseLineAmount.."
                                  name="societyDefaulterBaseLineAmount"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.societyDefaulterBaseLineAmount}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Parking Count
                              </label>
                              <div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="val-societyParkingCount"
                                  placeholder="Enter a Parking Count.."
                                  name="societyParkingCount"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.societyParkingCount}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Subscription Start Date
                              </label>
                              <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    id="societySubscriptionStartDate"
                                    format="dd/MM/yyyy"
                                    value={values.societySubscriptionStartDate}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "societySubscriptionStartDate",
                                        e
                                      )
                                    }
                                    onBlur={handleBlur}
                                    disabled
                                  />
                                </MuiPickersUtilsProvider>
                                {errors.societySubscriptionStartDate &&
                                touched.societySubscriptionStartDate ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.societySubscriptionStartDate}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Subscription End Date
                              </label>
                              <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    id="societySubscriptionEndDate"
                                    format="dd/MM/yyyy"
                                    value={values.societySubscriptionEndDate}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "societySubscriptionEndDate",
                                        e
                                      )
                                    }
                                    onBlur={handleBlur}
                                    disabled
                                  />
                                </MuiPickersUtilsProvider>
                                {errors.societySubscriptionEndDate &&
                                touched.societySubscriptionEndDate ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.societySubscriptionEndDate}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row p-3">
                          <h3>Society Amenities</h3>
                          <Table responsive striped bordered hover>
                            <tbody>
                              {values?.amenties?.length
                                ? values?.amenties?.map((item, index) => {
                                    return (
                                      <AminityItem
                                        key={"aminity-" + index}
                                        item={item}
                                        index={index}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                      />
                                    );
                                  })
                                : null}
                            </tbody>
                          </Table>
                        </div>

                        <button
                          type="submit"
                          className="btn mr-2 btn-primary"
                          disabled={isSubmitting}
                        >
                          Save
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

//export default AddFlat;
const mapStateToProps = (state) => {
  return {
    society: state.society?.society?.society,
    amenities: state.amenity?.amenitymasters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getSocietyAction, updateSocietyAction, getAmenityMastersAction },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditSocietyInformation);
