import {
	CONFIRMED_GET_MEETING_DETAILS,
	CONFIRMED_CREATE_MEETING_ACTION,
	CONFIRMED_GET_MEETINGS,
	CONFIRMED_EDIT_MEETING_ACTION,
	CONFIRMED_DELETE_MEETING_ACTION,
	LOADING,
} from "../actions/MeetingTypes";

const initialState = {
	meetings: [],
	meeting: {},
};

export default function MeetingReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_MEETING_DETAILS) {
		return {
			...state,
			meeting: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_MEETING_ACTION) {
		const meetings = [...state.meetings.meetings];
		const meetingIndex = meetings.findIndex(
			(meeting) => meeting.id === actions.payload
		);

		meetings.splice(meetingIndex, 1);

		return {
			...state,
			meetings: { meetings },
		};
	}

	if (actions.type === CONFIRMED_EDIT_MEETING_ACTION) {
		return {
			...state,
			meetings: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_MEETING_ACTION) {
		return {
			...state,
			meetings: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_MEETINGS) {
		return {
			...state,
			meetings: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
