import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { format } from "date-fns";
import ReactHtmlParser from "react-html-parser";

import { getMeetingAction } from "../../../../store/actions/MeetingActions";

class MeetingDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			meeting: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.meeting) {
			this.props.getMeetingAction(id);
		}
		this.init();
	}

	init() {
		this.state.meeting = this.props?.meeting;
	}

	render() {
		this.state.meeting = this.props?.meeting;

		const meetingType = this.state.meeting?.meetingType || "";
		const meetingAgenda = this.state.meeting?.meetingAgenda || "";
		const meetingDate = this.state.meeting?.meetingDate || "";
		const meetingMinutes = this.state.meeting?.meetingMinutes || "NA";
		const meetingResolution = this.state.meeting?.meetingResolution || "NA";
		const status = this.state.meeting?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/meetings" }} linkAs={Link}>
						Meeting Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Meeting Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Meeting Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/meetings"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Meeting Type</strong>
										<span className="mb-0">{meetingType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Meeting Agenda</strong>
										<span className="mb-0">
											{meetingAgenda && ReactHtmlParser(meetingAgenda)}
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Meeting Date</strong>
										<span className="mb-0">
											{meetingDate &&
												format(new Date(meetingDate), "dd-MM-yyyy")}
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Meeting Minutes</strong>
										<span className="mb-0">
											{meetingMinutes && ReactHtmlParser(meetingMinutes)}
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Meeting Resolution</strong>
										<span className="mb-0">
											{meetingResolution && ReactHtmlParser(meetingResolution)}
										</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		meeting: state.meeting?.meeting,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getMeetingAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingDetail);
