import {
	CONFIRMED_GET_VENDOR_DETAILS,
	CONFIRMED_CREATE_VENDOR_ACTION,
	CONFIRMED_GET_VENDORS,
	CONFIRMED_EDIT_VENDOR_ACTION,
	CONFIRMED_DELETE_VENDOR_ACTION,
	LOADING,
} from "../actions/VendorTypes";

const initialState = {
	vendors: [],
	vendor: {},
};

export default function VendorReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_VENDOR_DETAILS) {
		return {
			...state,
			vendor: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_VENDOR_ACTION) {
		const vendors = [...state.vendors.vendors];
		const vendorIndex = vendors.findIndex(
			(vendor) => vendor.id === actions.payload
		);

		vendors.splice(vendorIndex, 1);

		return {
			...state,
			vendors: { vendors },
		};
	}

	if (actions.type === CONFIRMED_EDIT_VENDOR_ACTION) {
		return {
			...state,
			vendors: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_VENDOR_ACTION) {
		return {
			...state,
			vendors: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_VENDORS) {
		return {
			...state,
			vendors: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
