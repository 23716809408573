import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import userSample from "../../../../userExcelFile/society_user.xlsx";
//import readXlsxFile from "read-excel-file";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { uploadUserAction } from "./../../../store/actions/UserActions";

class UploadUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			societyId: "",
			currentFile: undefined,
			userData: [],
			selectedFile: "",
		};
		this.selectFile = this.selectFile.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});
	}

	selectFile(e) {
		this.setState({
			selectedFile: e.target.files[0],
		});
	}

	upload = (e) => {
		e.preventDefault();

		let formData = new FormData();
		formData.append("societyId", this.state?.societyId);
		formData.append("file", this.state.selectedFile);

		this.props.uploadUserAction(formData, this.props.history);
	};

	render() {
		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/users" }} linkAs={Link}>
						Users Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Upload Users</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Upload Users</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/users"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<form>
										<div className="row">
											<div className="col-8">
												<label className="btn btn-default p-0">
													<input
														type="file"
														name="file"
														id="input"
														onChange={this.selectFile}
													/>
												</label>
											</div>
											{this.state.selectedFile ? (
												<div className="col-4">
													<button
														className="btn btn-success btn-sm"
														onClick={this.upload}
													>
														Upload
													</button>
												</div>
											) : (
												<div className="col-4">
													<button
														className="btn btn-success btn-sm"
														disabled
														onClick={this.upload}
													>
														Upload
													</button>
												</div>
											)}
										</div>
										<div>
											<Button
												style={{
													background: "none",
													padding: "5px",
													border: "none",
													textDecoration: "underline",
												}}
											>
												<a href={userSample} download="User Excel">
													Download Sample File
												</a>
											</Button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<ToastContainer
						position="top-right"
						autoClose={3000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</div>
			</>
		);
	}
}

//export default UploadUser;
const mapStateToProps = (state) => {
	console.log("mapStateToProps", state);
	return {
		user: state.user?.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			uploadUserAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadUser);
