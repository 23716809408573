import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { format } from "date-fns";
import ReactHtmlParser from "react-html-parser";

import { getNoticeAction } from "../../../../../store/actions/NoticeActions";

class NoticeDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notice: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.notice) {
			this.props.getNoticeAction(id);
		}
		this.init();
	}

	init() {
		this.state.notice = this.props?.notice;
	}

	render() {
		this.state.notice = this.props?.notice;

		const title = this.state.notice?.title || "";
		const commments = this.state.notice?.commments || "";
		const date = this.state.notice?.date || "";
		const status = this.state.notice?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/member-notices" }} linkAs={Link}>
						Notice Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Notice Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Notice Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/member-notices"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Title</strong>
										<span className="mb-0">{title}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Comments</strong>
										<span className="mb-0">
											{commments && ReactHtmlParser(commments)}
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Date</strong>
										<span className="mb-0">
											{date && format(new Date(date), "dd-MM-yyyy")}
										</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		notice: state.notice?.notice,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getNoticeAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeDetail);
