import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import swal from "sweetalert";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
	getVendorTypesAction,
	deleteVendorTypeAction,
} from "../../../../store/actions/VendorTypeActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class VendorType extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentVendorTypes: [],
			currentPage: null,
			totalPages: null,
			societyId: "",
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		this.props.getVendorTypesAction(userData.user.societyName);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.vendorTypes?.vendorTypes?.length !==
			this.props?.vendorTypes?.vendorTypes?.length
		) {
			this.setState({
				currentVendorTypes: this.props?.vendorTypes?.vendorTypes,
			});
		}
	}

	initData() {
		let currentVendorTypes =
			this.state?.currentVendorTypes?.length > 0
				? this.state?.currentVendorTypes
				: this.props?.vendorTypes?.vendorTypes;

		this.setState({ currentVendorTypes });
	}

	onPageChanged = (data) => {
		const allVendorTypes = this.props?.vendorTypes?.vendorTypes;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentVendorTypes = allVendorTypes?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentVendorTypes, totalPages });
	};

	handleSearch(event) {
		let currentVendorTypes = this.props.vendorTypes?.vendorTypes;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentVendorTypes = currentVendorTypes.filter((e) =>
				e.vendorTypeName.toLowerCase().match(searchString)
			);
			this.setState({ currentVendorTypes });
		} else {
			let arrayLength = currentVendorTypes.slice(0, 5);
			this.setState({ currentVendorTypes: arrayLength });
		}
		//this.setState({ currentVendorTypes });
	}

	deleteRecord(id) {
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteVendorTypeAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		let totalVendorTypes = this.props?.vendorTypes?.vendorTypes?.length || 0;

		const { currentVendorTypes, currentPage, totalPages } = this.state;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Vendor Type</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Vendor Type Lists</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-vendor-type"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Vendor Type
									</Link>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Vendor Type Name</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentVendorTypes?.length
											? currentVendorTypes
													?.sort((a, b) =>
														a.vendorTypeName.localeCompare(b.vendorTypeName)
													)
													.map((vendor, i) => (
														<tr key={i}>
															<td>{vendor.vendorTypeName} </td>

															<td>
																<div className="d-flex">
																	<Link
																		to={`add-vendor-type/${vendor._id}`}
																		className="btn btn-info shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-pencil"></i>
																	</Link>
																	<Link
																		to={`vendor-type-details/${vendor._id}`}
																		className="btn btn-primary shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-th"></i>
																	</Link>
																	<Link
																		to="#"
																		className="btn btn-danger shadow btn-xs sharp"
																		onClick={this.deleteRecord.bind(
																			this,
																			vendor._id
																		)}
																	>
																		<i className="fa fa-trash"></i>
																	</Link>
																</div>
															</td>
														</tr>
													))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalVendorTypes ? (
										<Pagination
											totalRecords={totalVendorTypes}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		vendorTypes: state?.vendorType?.vendorTypes,
		loading: state?.vendorType?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getVendorTypesAction, deleteVendorTypeAction },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorType);
