import axiosInstance from "../services/AxiosInstance";

export function getExpenseCategoryDetails(categoryId) {
	return axiosInstance.get(`admin/expenseCategory/${categoryId}`);
}

export function getExpenseCategories(societyId) {
	return axiosInstance.get(`admin/expenseCategorys/${societyId}`);
}

export function createExpenseCategory(categoryData) {
	return axiosInstance.post(`admin/expenseCategory`, categoryData);
}

export function updateExpenseCategory(category, categoryId) {
	return axiosInstance.put(`admin/expenseCategory/${categoryId}`, category);
}

export function deleteExpenseCategory(categoryId) {
	return axiosInstance.delete(`admin/expenseCategory/${categoryId}`);
}
