//import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";

import axiosInstance from "../services/AxiosInstance";

export function signUp(name, email, mobile, address, password) {
	const postData = {
		name,
		email,
		mobile,
		address,
		password,
		returnSecureToken: true,
	};
	return axiosInstance.post(`register`, postData);
}

// export function signUp(name, email, mobile, password) {
//     //axios call

//     const postData = {
//         name,
//         email,
//         mobile,
//         password,
//         returnSecureToken: true,
//     };

//     return axiosInstance.post(`/register`, postData);

//     // return axios.post(
//     //     `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
//     //     postData,
//     // );
// }

export function login(email, password) {
	const postData = {
		email,
		password,
	};

	return axiosInstance.post(`login`, postData);

	// return axios.post(
	//     `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
	//     postData,
	// );
}

export function adminLogin(userName, password) {
	const postData = {
		userName,
		password,
	};

	return axiosInstance.post(`admin/login`, postData);
}

export function otpLogin(mobile, otp, session_id) {
	const postData = {
		mobile,
		otp,
		session_id,
	};

	return axiosInstance.post(`user/verify`, postData);
}

export function formatError(errorResponse) {
	switch (errorResponse.error.message) {
		case "EMAIL_EXISTS":
			// return 'Email already exists';
			swal("Oops", "Email already exists", "error");
			break;
		case "EMAIL_NOT_FOUND":
			//return 'Email not found';
			swal("Oops", "Email not found", "error", { button: "Try Again!" });
			break;
		// swal('Oops',  "There is no user record corresponding to this identifier. The user may have been deleted.");
		case "INVALID_PASSWORD":
			//return 'Invalid Password';
			swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
			break;
		case "USER_DISABLED":
			return "User Disabled";

		default:
			return "";
	}
}

export function saveTokenInLocalStorage(tokenDetails) {
	// console.log("tokenDetails", tokenDetails);

	const data = {
		expiresIn: 1000000 * 1000,
		expireDate: new Date(new Date().getTime() + 1000000 * 1000),
		idToken: tokenDetails.token,
		user: tokenDetails.user,
	};
	//console.log("saveTokenInLocalStorage.data", data);
	// tokenDetails.expireDate = new Date(
	//     new Date().getTime() + tokenDetails.expiresIn * 1000,
	// );
	localStorage.setItem("userDetails", JSON.stringify(data));
}

export function runLogoutTimer(dispatch, timer, history) {
	setTimeout(() => {
		dispatch(logout(history));
	}, timer);
}

export function checkAutoLogin(dispatch, history) {
	const tokenDetailsString = localStorage.getItem("userDetails");
	let tokenDetails = "";
	if (!tokenDetailsString) {
		dispatch(logout(history));
		return;
	}

	tokenDetails = JSON.parse(tokenDetailsString);
	//console.log("checkAutoLogin.tokenDetails", tokenDetails);
	let expireDate = new Date(tokenDetails.expireDate);
	let todaysDate = new Date();

	if (todaysDate > expireDate) {
		dispatch(logout(history));
		return;
	}
	dispatch(loginConfirmedAction(tokenDetails));

	const timer = expireDate.getTime() - todaysDate.getTime();
	runLogoutTimer(dispatch, timer, history);
}
