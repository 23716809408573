import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../Pagination";
import swal from "sweetalert";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
	getFlatsAction,
	deleteFlatAction,
} from "../../../../../store/actions/FlatManagementActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class FlatManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentFlats: [],
			currentPage: null,
			totalPages: null,
			societyId: "",
			userId: "",
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
			userId: userData?.user?._id,
		});

		this.props.getFlatsAction(userData.user.societyName);
		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.flats?.flats?.length !== this.props?.flats?.flats?.length) {
			this.setState({
				currentFlats: this.props?.flats?.flats,
			});
		}
	}

	initData() {
		let currentFlats =
			this.state?.currentFlats?.length > 0
				? this.state?.currentFlats
				: this.props?.flats?.flats;

		this.setState({ currentFlats });
	}

	onPageChanged = (data) => {
		const allFlats = this.props?.flats?.flats;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentFlats = allFlats?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentFlats, totalPages });
	};

	handleSearch(event) {
		let currentFlats = this.props.flats?.flats;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();

		if (searchString.length > 0) {
			// We are searching. Filter the results.
			//If page wise search we want then replace flats with currentFlats
			currentFlats = currentFlats?.filter(
				(e) =>
					e?.flatNo?.toLowerCase().match(searchString) ||
					e?.ownerName?.toLowerCase().match(searchString)
			);
			this.setState({
				currentFlats,
			});
		} else {
			let arrayLength = currentFlats?.slice(0, 5);
			this.setState({ currentFlats: arrayLength });
		}
		//this.setState({ currentFlats });
	}

	deleteRecord(id) {
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteFlatAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		const totalFlats = this.props?.flats?.flats?.length || 0;

		const { currentFlats, currentPage, totalPages } = this.state;
		this.state.currentFlats = this.props.flats?.flats?.slice(0, 5);

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Flat Management</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-3 col-sm-12 pl-0">
									<h2 className="card-title">Society Flat Lists</h2>
								</div>

								<div className="col-xl-3 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								<div className="col-xl-3 col-sm-12">
									<Link
										to={"upload-flat"}
										className="btn btn-primary mb-2 pull-right"
									>
										Upload Flats
									</Link>
								</div>
								<div className="col-xl-3 col-sm-12">
									<Link
										to={"add-flat"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add New Flat
									</Link>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Flat No.</th>
											<th>Owner Name</th>
											<th>Two Wheeler</th>
											<th>Four Wheeler</th>
											<th>Other Charges</th>
											<th>Other Charges Descriptioin</th>
											<th>Flat Area</th>
											<th>Flat Type</th>
											<th>Is On Rent</th>
											<th>Active</th>
											<th>Interested In EBill</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentFlats &&
											currentFlats
												?.sort((a, b) => a?.flatNo?.localeCompare(b?.flatNo))
												?.map((flat, i) => (
													<tr key={i}>
														<td>{flat.flatNo}</td>
														<td>{flat.ownerName}</td>
														<td>{flat.twoWheeler || 0}</td>
														<td>{flat.fourWheeler || 0}</td>
														<td>{flat.otherCharges || 0}</td>
														<td>{flat.otherChargesDescription || "NA"}</td>
														<td>{flat.flatArea || 0}</td>
														<td>{flat.flatType}</td>
														<td>
															<div className="d-flex align-items-center">
																{flat.isOnRent === true ? (
																	<i className="fa fa-check-square text-success mr-1"></i>
																) : (
																	<i className="fa fa-square mr-1"></i>
																)}
															</div>
														</td>
														<td>
															<div className="d-flex align-items-center">
																{flat.status === true ? (
																	<i className="fa fa-check-square text-success mr-1"></i>
																) : (
																	<i className="fa fa-square mr-1"></i>
																)}
															</div>
														</td>
														<td>
															<div className="d-flex align-items-center">
																{flat.ebillSubscribed === true ? (
																	<i className="fa fa-check-square text-success mr-1"></i>
																) : (
																	<i className="fa fa-square mr-1"></i>
																)}
															</div>
														</td>
														<td>
															<div className="d-flex">
																{/* {flat?.userId[0] ? (
																	<Link
																		to={`add-flat/${flat.societyId}/${flat.userId[0]}`}
																		className="btn btn-info shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-pencil"></i>
																	</Link>
																) : null} */}
																{/* {flat?.userId[0] ? (
																	<Link
																		to={`flat-detail/${flat.societyId}/${flat.userId[0]}`}
																		className="btn btn-primary shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-th"></i>
																	</Link>
																) : null} */}
																<Link
																	to={`add-flat/${flat._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link>
																<Link
																	to={`flat-detail/${flat._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>

																<Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={this.deleteRecord.bind(
																		this,
																		flat._id
																	)}
																>
																	<i className="fa fa-trash"></i>
																</Link>
															</div>
														</td>
													</tr>
												))}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalFlats > 5 ? (
										<Pagination
											totalRecords={totalFlats}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		flats: state.flat?.flats,
		loading: state.flat?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getFlatsAction, deleteFlatAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FlatManagement);
