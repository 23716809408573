import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getVendorAction,
	createVendorAction,
	updateVendorAction,
} from "../../../../store/actions/VendorActions";
import { getVendorTypesAction } from "../../../../store/actions/VendorTypeActions";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

class AddEditVendor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vendor: {},
			vendorTypes: [],
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.vendor) {
				this.props.getVendorAction(id);
			}
		} else {
		}
		this.props.getVendorTypesAction(userData.user.societyName);
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.vendor?.length !== this.props?.vendor?.length) {
			this.setState({
				vendor: this.props?.vendor,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateVendorAction(
				values,
				this.props.history,
				this.props?.vendor?._id
			);
		} else {
			this.props.createVendorAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var vendor = this.props?.vendor;
		} else {
			vendor = "";
		}

		let type_data = this.props.vendorTypes?.vendorTypes;

		const initialValues = {
			societyId: this.state.societyId,
			vendorType: vendor ? vendor.vendorType : "",
			vendorDescription: vendor ? vendor.vendorDescription : "",
			vendorName: vendor ? vendor.vendorName : "",
			vendorEmail: vendor ? vendor.vendorEmail : "",
			vendorMobile: vendor ? vendor.vendorMobile : "",
			vendorDetails: vendor ? vendor.vendorDetails : "",
			status: vendor ? vendor.status : true,
		};

		const validationSchema = Yup.object().shape({
			vendorType: Yup.string().required("Please Select Vendor type."),
			vendorName: Yup.string().required("Please Enter Vendor Name."),
			vendorMobile: Yup.string()
				.matches(phoneRegExp, "Phone number is not valid")
				.min(10, "Min 10 digit")
				.max(10, "Max 10 digit")
				.required("Please Enter Mobile."),
			vendorEmail: Yup.string()
				//.required("Please Enter Email.")
				.email("Please Enter Valid Email."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/vendors" }} linkAs={Link}>
						Vendor Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Vendor</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Vendor</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Add Vendor Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/vendors"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Vendor Type *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-vendorType"
																	placeholder="Select a vendor Type.."
																	name="vendorType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorType || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	{type_data?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt.vendorTypeName}
																			label={opt.vendorTypeName}
																		/>
																	))}
																</select>
																{errors.vendorType && touched.vendorType ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorType}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Vendor Name *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-vendorName"
																	placeholder="Enter a Vendor Name.."
																	name="vendorName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorName || ""}
																/>
																{errors.vendorName && touched.vendorName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Vendor Description
															</label>
															<div>
																<textarea
																	type="text"
																	className="form-control"
																	id="val-vendorDescription"
																	placeholder="Enter a vendor Description.."
																	name="vendorDescription"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorDescription || ""}
																	rows="4"
																></textarea>
																{errors.vendorDescription &&
																touched.vendorDescription ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorDescription}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Vendor Email</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-vendorEmail"
																	placeholder="Enter a vendor Email.."
																	name="vendorEmail"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorEmail || ""}
																/>
																{errors.vendorEmail && touched.vendorEmail ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorEmail}
																	</div>
																) : null}
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Vendor Mobile *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-vendorMobile"
																	placeholder="Enter a vendor Mobile.."
																	name="vendorMobile"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorMobile || ""}
																/>
																{errors.vendorMobile && touched.vendorMobile ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorMobile}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Vendor Details
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-vendorDetails"
																	placeholder="Enter a vendor Details.."
																	name="vendorDetails"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.vendorDetails || ""}
																/>
																{errors.vendorDetails &&
																touched.vendorDetails ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.vendorDetails}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddVendor;
const mapStateToProps = (state) => {
	return {
		vendor: state.vendor?.vendor,
		vendorTypes: state?.vendorType?.vendorTypes,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getVendorAction,
			createVendorAction,
			updateVendorAction,
			getVendorTypesAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditVendor);
