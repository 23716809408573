import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class BarChart1 extends Component {
	render() {
		const data = {
			defaultFontFamily: "Poppins",
			labels: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			datasets: [
				{
					label: "Collection",
					data: [65, 59, 80, 81, 56, 55, 40, 80, 81, 56, 55, 40],
					borderColor: "rgba(64, 24, 157, 1)",
					borderWidth: "0",
					backgroundColor: "#233C8E",
				},
				{
					label: "Outstanding",
					data: [25, 59, 30, 11, 26, 35, 20, 10, 31, 26, 15, 30],
					borderColor: "rgba(64, 24, 157, 1)",
					borderWidth: "0",
					backgroundColor: "#EC932F",
				},
			],
		};

		const options = {
			legend: false,
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
						},
					},
				],
				xAxes: [
					{
						// Change here
						barPercentage: 0.5,
					},
				],
			},
		};

		return (
			<>
				{/* <Bar data={data} height={150} options={options} /> */}
				<Bar data={data} height={150} />
			</>
		);
	}
}

export default BarChart1;
