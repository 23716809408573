import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Editor } from "@tinymce/tinymce-react";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getMeetingAction,
	createMeetingAction,
	updateMeetingAction,
} from "../../../../store/actions/MeetingActions";

class AddEditMeeting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			meeting: {},
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.meeting) {
				this.props.getMeetingAction(id);
			}
		} else {
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.meeting?.length !== this.props?.meeting?.length) {
			this.setState({
				meeting: this.props?.meeting,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateMeetingAction(
				values,
				this.props.history,
				this.props?.meeting?._id
			);
		} else {
			this.props.createMeetingAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var meeting = this.props?.meeting;
		} else {
			meeting = "";
		}

		const initialValues = {
			societyId: this.state.societyId,
			meetingType: meeting ? meeting.meetingType : "",
			meetingAgenda: meeting ? meeting.meetingAgenda : "",
			meetingDate: meeting ? meeting.meetingDate : new Date(),
			meetingMinutes: meeting ? meeting.meetingMinutes : "",
			meetingResolution: meeting ? meeting.meetingResolution : "",
			status: meeting ? meeting.status : true,
		};

		const validationSchema = Yup.object().shape({
			meetingType: Yup.string().required("Please Select Meeting Type."),
			meetingAgenda: Yup.string().required("Please Enter Meeting Agenda."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/meetings" }} linkAs={Link}>
						Meeting Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Meeting</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Meeting</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Meeting</h3>
						) : (
							<h3 className="pt-3">Add Meeting</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/meetings"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Meeting Type *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-meetingType"
																	placeholder="Select a expense Type.."
																	name="meetingType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.meetingType || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	<option
																		value="General Meeting"
																		label="General Meeting"
																	/>
																	<option
																		value="Committee Meeting"
																		label="Committee Meeting"
																	/>
																	<option value="Ad-hoc" label="Ad-hoc" />
																	<option value="AGM" label="AGM" />
																	<option value="Other" label="Other" />
																</select>
																{errors.meetingType && touched.meetingType ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.meetingType}
																	</div>
																) : null}
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Meeting Date</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="meetingDate"
																		format="dd/MM/yyyy"
																		value={values.meetingDate}
																		onChange={(e) =>
																			setFieldValue("meetingDate", e)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.meetingDate && touched.meetingDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.meetingDate}
																	</div>
																) : null}
															</div>
														</div>
													</div>

													{/* <div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Meeting Minutes
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-meetingMinutes"
																	placeholder="Enter a Meeting Minutes.."
																	name="meetingMinutes"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.meetingMinutes || ""}
																/>
																{errors.meetingMinutes &&
																touched.meetingMinutes ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.meetingMinutes}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Meeting Resolution
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-meetingResolution"
																	placeholder="Enter a Meeting Resolution.."
																	name="meetingResolution"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.meetingResolution || ""}
																/>
																{errors.meetingResolution &&
																touched.meetingResolution ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.meetingResolution}
																	</div>
																) : null}
															</div>
														</div>
													</div> */}
												</div>

												<div className="row">
													<div className="col-xl-8 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Meeting Agenda *
															</label>
															<div>
																<Editor
																	init={{
																		height: 500,
																		menubar: false,
																		plugins: [
																			"advlist autolink lists link image code charmap print preview anchor",
																			"searchreplace visualblocks code fullscreen",
																			"insertdatetime media table paste code help wordcount",
																		],
																		toolbar:
																			"undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help ",
																		content_style: "body { color: #828282 }",
																	}}
																	onEditorChange={(e) =>
																		handleChange({
																			target: {
																				name: "meetingAgenda",
																				value: e,
																			},
																		})
																	}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.meetingAgenda || ""}
																/>
																{errors.meetingAgenda &&
																touched.meetingAgenda ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.meetingAgenda}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xl-8 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Meeting Minutes{" "}
															</label>
															<div>
																<Editor
																	init={{
																		height: 500,
																		menubar: false,
																		plugins: [
																			"advlist autolink lists link image code charmap print preview anchor",
																			"searchreplace visualblocks code fullscreen",
																			"insertdatetime media table paste code help wordcount",
																		],
																		toolbar:
																			"undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help ",
																		content_style: "body { color: #828282 }",
																	}}
																	onEditorChange={(e) =>
																		handleChange({
																			target: {
																				name: "meetingMinutes",
																				value: e,
																			},
																		})
																	}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.meetingMinutes || ""}
																/>
																{errors.meetingMinutes &&
																touched.meetingMinutes ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.meetingMinutes}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xl-8 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Meeting Resolution
															</label>
															<div>
																<Editor
																	init={{
																		height: 500,
																		menubar: false,
																		plugins: [
																			"advlist autolink lists link image code charmap print preview anchor",
																			"searchreplace visualblocks code fullscreen",
																			"insertdatetime media table paste code help wordcount",
																		],
																		toolbar:
																			"undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help ",
																		content_style: "body { color: #828282 }",
																	}}
																	onEditorChange={(e) =>
																		handleChange({
																			target: {
																				name: "meetingResolution",
																				value: e,
																			},
																		})
																	}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.meetingResolution || ""}
																/>
																{errors.meetingResolution &&
																touched.meetingResolution ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.meetingResolution}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddMeeting;
const mapStateToProps = (state) => {
	return {
		meeting: state.meeting?.meeting,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getMeetingAction,
			createMeetingAction,
			updateMeetingAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditMeeting);
