import React from "react"; // ,{ useContext }
import Index from "./app/jsx/index";
//import { withResizeDetector } from "react-resize-detector";
import { Route, Switch, withRouter } from "react-router-dom"; //Redirect,
import { lazy, Suspense, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { checkAutoLogin } from "./app/services/AuthService";
import { isAuthenticated } from "./app/store/selectors/AuthSelectors";
import Nav from "./app/jsx/layouts/nav";

//import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.css";
import SocietyProfile from "./app/jsx/components/Society/SocietyProfile";

import { ToastContainer } from "react-toastify"; //toast
import "react-toastify/dist/ReactToastify.css";

//const Home = lazy(() => import('./jsx/components/Dashboard/Index'));
const Home = lazy(() => import("./app/jsx/components/Home/Home"));
const SignUp = lazy(() => import("./app/jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./app/jsx/pages/ForgotPassword"));
//const Login = lazy(() => import('./jsx/pages/Login'));

const Login = lazy(() => {
	return new Promise((resolve) => {
		setTimeout(() => resolve(import("./app/jsx/pages/Login")), 500);
	});
});

function App(props) {
	//const {menuToggle} = useContext(ThemeContext);
	// console.log("App-props");
	// console.log(props);
	const dispatch = useDispatch();
	useEffect(() => {
		checkAutoLogin(dispatch, props.history);
	}, []);

	let routes = (
		<Switch>
			<Route exact path="/home" component={Home} />
			<Route exact path="/login" component={Login} />
			<Route exact path="/page-register" component={SignUp} />
			<Route exact path="/page-forgot-password" component={ForgotPassword} />
			<Route exact path="/:societyurl" component={SocietyProfile} />
			<Route exact path="/" component={Home} />
		</Switch>
	);

	let path = window.location?.pathname;
	path = path.split("/");
	path = path[path.length - 1];

	const excludeHeader = ["login", "page-register", "page-forgot-password"];

	let pagePath = excludeHeader.some((r) => path.indexOf(r) >= 0);

	if (props.isAuthenticated) {
		return (
			<div>
				{!pagePath && <Nav isAuthenticated={props.isAuthenticated} />}
				{/* <Nav isAuthenticated={props.isAuthenticated} /> */}
				<Suspense
					fallback={
						<div id="preloader">
							<div className="waviy">
								<span style={{ "--i": 1 }}>L</span>
								<span style={{ "--i": 2 }}>o</span>
								<span style={{ "--i": 3 }}>a</span>
								<span style={{ "--i": 4 }}>d</span>
								<span style={{ "--i": 5 }}>i</span>
								<span style={{ "--i": 6 }}>n</span>
								<span style={{ "--i": 7 }}>g</span>
								<span style={{ "--i": 8 }}>.</span>
								<span style={{ "--i": 9 }}>.</span>
								<span style={{ "--i": 10 }}>.</span>
							</div>
						</div>
					}
				>
					<Index />
				</Suspense>
			</div>
		);
	} else {
		return (
			<>
				{!pagePath && <Nav isAuthenticated={props.isAuthenticated} />}
				<Suspense
					fallback={
						<div id="preloader">
							<div className="waviy">
								<span style={{ "--i": 1 }}>L</span>
								<span style={{ "--i": 2 }}>o</span>
								<span style={{ "--i": 3 }}>a</span>
								<span style={{ "--i": 4 }}>d</span>
								<span style={{ "--i": 5 }}>i</span>
								<span style={{ "--i": 6 }}>n</span>
								<span style={{ "--i": 7 }}>g</span>
								<span style={{ "--i": 8 }}>.</span>
								<span style={{ "--i": 9 }}>.</span>
								<span style={{ "--i": 10 }}>.</span>
							</div>
						</div>
					}
				>
					{routes}
				</Suspense>
				<ToastContainer />
			</>
		);
	}
}

//export default withResizeDetector(App);

/*  const mapStateToProps = (state) => {
	return {
		isAuthenticated: isAuthenticated(state),
	};
}; 

export default withResizeDetector(App);  */

const mapStateToProps = (state) => {
	return {
		isAuthenticated: isAuthenticated(state),
	};
};

export default withRouter(connect(mapStateToProps)(App));
