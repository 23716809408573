const razorpay = {
	dev: {
		RAZORPAY_API_KEY: "rzp_test_PFIxGSEmWo1f9K",
		RAZORPAY_SECRET_KEY: "IIRacYrVk6Cn94O0VKOLY02X",
		RAZORPAY_API_URL: "https://api.razorpay.com/v1",
		RAZORPAY_API_URL_FULL:
			"https://rzp_test_PFIxGSEmWo1f9K:IIRacYrVk6Cn94O0VKOLY02X@api.razorpay.com/v1",
	}, // Key for Dev
	prod: {
		RAZORPAY_API_KEY: "",
		RAZORPAY_SECRET_KEY: "",
		RAZORPAY_API_URL: "",
		RAZORPAY_API_URL_FULL: "",
	}, // Key for Production
};

export default razorpay;
