import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import swal from "sweetalert";

import {
	getAdminUsersAction,
	deleteAdminUserAction,
} from "../../../../store/actions/AdminUserActions";

class AdminUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentAdmins: [],
			currentPage: null,
			totalPages: null,
			societyId: "",
			role: "",
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
			role: userData.user.role,
		});

		this.props.getAdminUsersAction();

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.admins?.adminUsers?.length !==
			this.props?.admins?.adminUsers?.length
		) {
			this.setState({
				currentAdmins: this.props?.admins?.adminUsers?.filter(
					(item) => item?.societyName?._id === this.state.societyId
				),
			});
		}
	}

	initData() {
		let currentAdmins =
			this.state?.currentAdmins?.length > 0
				? this.state?.currentAdmins?.filter(
						(item) => item?.societyName?._id === this.state?.societyId
				  )
				: this.props?.admins?.adminUsers?.filter(
						(item) => item?.societyName?._id === this.state?.societyId
				  );

		this.setState({ currentAdmins });
	}

	onPageChanged = (data) => {
		const allAdmins = this.props?.admins?.adminUsers?.filter(
			(item) => item?.societyName?._id === this.state.societyId
		);

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentAdmins = allAdmins?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentAdmins, totalPages });
	};

	handleSearch(event) {
		let currentAdmins = this.props.admins?.adminUsers?.filter(
			(item) => item?.societyName?._id === this.state.societyId
		);
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentAdmins = currentAdmins?.filter((e) =>
				e.userName.toLowerCase().match(searchString)
			);
			this.setState({ currentAdmins });
		} else {
			let arrayLength = currentAdmins.slice(0, 5);
			this.setState({ currentAdmins: arrayLength });
		}
		//this.setState({ currentAdmins });
	}

	deleteRecord(id) {
		//this.props.deleteAdminUserAction(id, this.props.history);
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteAdminUserAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		let totalAdmins =
			this.props?.admins?.adminUsers?.filter(
				(item) => item?.societyName?._id === this.state.societyId
			).length || 0;

		const { currentAdmins, currentPage, totalPages } = this.state;

		return (
			<>
				<Breadcrumb>
					{this.state?.role !== "superAdmin" && (
						<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
							Dashboard
						</Breadcrumb.Item>
					)}
					<Breadcrumb.Item active>Admin User Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Admin Lists</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								{/* <div className="col-xl-4 col-sm-12">
								<Link
									to={"add-admin"}
									className="btn btn-primary mb-2 pull-right"
								>
									Add Admin
								</Link>
							</div> */}
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>User Name</th>
											<th>Society Name</th>
											<th>Role</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentAdmins?.length
											? currentAdmins
													?.sort((a, b) => a.userName.localeCompare(b.userName))
													.map((admin, i) => (
														<tr key={i}>
															<td>{admin.userName} </td>
															<td>{admin.societyName?.societyName} </td>
															<td>{admin.role} </td>
															<td>
																<div className="d-flex">
																	{this.state?.role === "superAdmin" && (
																		<Link
																			to={`add-admin/${admin._id}`}
																			className="btn btn-info shadow btn-xs sharp mr-1"
																		>
																			<i className="fa fa-pencil"></i>
																		</Link>
																	)}
																	<Link
																		to={`admin-details/${admin._id}`}
																		className="btn btn-primary shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-th"></i>
																	</Link>
																	{this.state?.role === "superAdmin" && (
																		<Link
																			to="#"
																			className="btn btn-danger shadow btn-xs sharp"
																			onClick={this.deleteRecord.bind(
																				this,
																				admin._id
																			)}
																		>
																			<i className="fa fa-trash"></i>
																		</Link>
																	)}
																</div>
															</td>
														</tr>
													))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalAdmins ? (
										<Pagination
											totalRecords={totalAdmins}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		admins: state?.admin?.adminUsers,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getAdminUsersAction, deleteAdminUserAction },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUser);
