import {
	getRequestQueryDetails,
	getRequestQueries,
	createRequestQuery,
	updateRequestQuery,
	deleteRequestQuery,
} from "../../services/RequestQueryService";
import {
	CONFIRMED_GET_QUERY_DETAILS,
	CONFIRMED_CREATE_QUERY_ACTION,
	CONFIRMED_GET_QUERIES,
	CONFIRMED_EDIT_QUERY_ACTION,
	CONFIRMED_DELETE_QUERY_ACTION,
	LOADING,
} from "./RequestQueryTypes";

export function getRequestQueryAction(queryId) {
	return (dispatch, getState) => {
		getRequestQueryDetails(queryId).then((response) => {
			dispatch(confirmedGetRequestQueryAction(response.data.queries));
		});
	};
}

export function confirmedGetRequestQueryAction(query) {
	return {
		type: CONFIRMED_GET_QUERY_DETAILS,
		payload: query,
	};
}

export function deleteRequestQueryAction(queryId, history) {
	return (dispatch, getState) => {
		deleteRequestQuery(queryId).then((response) => {
			dispatch(confirmedDeleteRequestQueryAction(queryId));
			history.push("/request-queries");
		});
	};
}

export function confirmedDeleteRequestQueryAction(queryId) {
	return {
		type: CONFIRMED_DELETE_QUERY_ACTION,
		payload: queryId,
	};
}

export function createRequestQueryAction(QueryData, history) {
	return (dispatch, getState) => {
		createRequestQuery(QueryData).then((response) => {
			const singleQuery = {
				...QueryData,
				id: response.data._id,
			};
			dispatch(confirmedCreateRequestQueryAction(singleQuery));
			history.push("/member-request-queries");
		});
	};
}

export function confirmedCreateRequestQueryAction(singleQuery) {
	return {
		type: CONFIRMED_CREATE_QUERY_ACTION,
		payload: singleQuery,
	};
}

export function getRequestQueriesAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getRequestQueries(societyId).then((response) => {
			dispatch(confirmedGetRequestQueriesAction(response.data));
		});
	};
}

export function confirmedGetRequestQueriesAction(Queries) {
	return {
		type: CONFIRMED_GET_QUERIES,
		payload: Queries,
	};
}

export function confirmedUpdateRequestQueryAction(Query) {
	return {
		type: CONFIRMED_EDIT_QUERY_ACTION,
		payload: Query,
	};
}

export function updateRequestQueryAction(Query, history, queryId) {
	return (dispatch, getState) => {
		updateRequestQuery(Query, queryId).then((reponse) => {
			dispatch(confirmedUpdateRequestQueryAction(Query));
			history.push("/request-queries");
		});
	};
}
