import {
	CONFIRMED_GET_NEWS_DETAILS,
	CONFIRMED_CREATE_NEWS_ACTION,
	CONFIRMED_GET_NEWSES,
	CONFIRMED_EDIT_NEWS_ACTION,
	CONFIRMED_DELETE_NEWS_ACTION,
} from "../actions/NewsTypes";

const initialState = {
	newses: [],
	news: {},
};

export default function NewsReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_NEWS_DETAILS) {
		return {
			...state,
			news: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_NEWS_ACTION) {
		const newses = [...state.newses.news];
		const newsIndex = newses.findIndex((news) => news.id === actions.payload);

		newses.splice(newsIndex, 1);

		const news = newses;

		return {
			...state,
			newses: { news },
		};
	}

	if (actions.type === CONFIRMED_EDIT_NEWS_ACTION) {
		return {
			...state,
			newses: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_NEWS_ACTION) {
		return {
			...state,
			newses: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_NEWSES) {
		return {
			...state,
			newses: actions.payload,
		};
	}

	return state;
}
