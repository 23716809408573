// import swal from "sweetalert";
// import { Redirect } from "react-router-dom";
import {
	formatError,
	login,
	runLogoutTimer,
	saveTokenInLocalStorage,
	signUp,
	adminLogin,
	otpLogin,
} from "../../services/AuthService";

import {
	SIGNUP_CONFIRMED_ACTION,
	SIGNUP_FAILED_ACTION,
	LOGIN_CONFIRMED_ACTION,
	LOGIN_FAILED_ACTION,
	LOADING_TOGGLE_ACTION,
	LOGOUT_ACTION,
} from "./AuthTypes";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function signupAction(name, email, mobile, address, password, history) {
	return (dispatch) => {
		signUp(name, email, mobile, address, password)
			.then((response) => {
				saveTokenInLocalStorage(response.data);
				runLogoutTimer(
					dispatch,
					1000 * 1000,
					// response.data.expiresIn * 1000,
					history
				);
				dispatch(confirmedSignupAction(response.data));
				history.push("/");
			})
			.catch((error) => {
				const errorMessage = formatError(error.response.data);
				dispatch(signupFailedAction(errorMessage));
			});
	};
}

export function logout(history) {
	localStorage.removeItem("userDetails");
	localStorage.removeItem("userFlatDetails");
	//history.push('/login');
	history.push("/");

	return {
		type: LOGOUT_ACTION,
	};
}

export function loginAction(email, password, history) {
	return (dispatch) => {
		login(email, password)
			.then((response) => {
				saveTokenInLocalStorage(response.data);
				runLogoutTimer(
					dispatch,
					1000 * 1000,
					//response.data.expiresIn * 1000,
					history
				);
				dispatch(loginConfirmedAction(response.data));
				history.push("/memberDashboard");
				window.location.reload();
			})
			.catch((error) => {
				const errorMessage = formatError(error.response.data);
				dispatch(loginFailedAction(errorMessage));
			});
	};
}

export function adminLoginAction(userName, password, history) {
	return (dispatch) => {
		adminLogin(userName, password)
			.then((response) => {
				saveTokenInLocalStorage(response.data);
				runLogoutTimer(
					dispatch,
					1000 * 1000,
					//response.data.expiresIn * 1000,
					history
				);
				dispatch(loginConfirmedAction(response.data));
				if (response.data?.user?.role === "admin") {
					history.push("/");
				} else {
					history.push("/superadmin/societies");
				}
				// history.push("/");
				window.location.reload();
			})
			.catch((error) => {
				const errorMessage = formatError(error.response.data);
				dispatch(loginFailedAction(errorMessage));
				toast.error(error.response.data.errMessage);
			});
	};
}

export function otpLoginAction(mobile, otp, session_id, history) {
	return (dispatch) => {
		otpLogin(mobile, otp, session_id)
			.then((response) => {
				saveTokenInLocalStorage(response.data);
				runLogoutTimer(dispatch, 1000 * 1000, history);
				dispatch(loginConfirmedAction(response.data));
				history.push("/memberDashboard");
				window.location.reload();
			})
			.catch((error) => {
				const errorMessage = formatError(error.response.data);
				dispatch(loginFailedAction(errorMessage));
				toast.error(error?.response?.data?.errMessage);
			});
	};
}

export function loginFailedAction(data) {
	return {
		type: LOGIN_FAILED_ACTION,
		payload: data,
	};
}

export function loginConfirmedAction(data) {
	return {
		type: LOGIN_CONFIRMED_ACTION,
		payload: data,
	};
}

export function confirmedSignupAction(payload) {
	return {
		type: SIGNUP_CONFIRMED_ACTION,
		payload,
	};
}

export function signupFailedAction(message) {
	return {
		type: SIGNUP_FAILED_ACTION,
		payload: message,
	};
}

export function loadingToggleAction(status) {
	return {
		type: LOADING_TOGGLE_ACTION,
		payload: status,
	};
}
