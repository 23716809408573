import { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Breadcrumb } from "react-bootstrap";
import Pagination from "../../../components/Pagination";
import { format } from "date-fns";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import axiosInstance from "../../../../services/AxiosInstance";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class LeaseFlatDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			societyId: "",
			currentLeaseFlats: [],
			currentPage: null,
			totalPages: null,
			loading: false,
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});
		this.getLeaseFlatDetails(userData.user.societyName);
	}

	async getLeaseFlatDetails(societyId) {
		this.setState({
			loading: true,
		});
		const { data } = await axiosInstance
			.get(`admin/report/rented/${societyId}`)
			.then((result) => result);

		this.setState({
			currentLeaseFlats: data?.flat,
			loading: false,
		});
	}

	onPageChanged = async (data) => {
		await this.getLeaseFlatDetails(this.state.societyId);
		const allLeaseFlats = this.state.currentLeaseFlats;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentLeaseFlats = allLeaseFlats?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentLeaseFlats, totalPages });
	};

	handleSearch(event) {
		let currentLeaseFlats = this.state.currentLeaseFlats;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();

		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentLeaseFlats = currentLeaseFlats?.filter(
				(e) =>
					e.flatNo?.toLowerCase().match(searchString) ||
					e.ownerName?.toLowerCase().match(searchString) ||
					e.contactDetails?.toLowerCase().match(searchString)
			);
			this.setState({ currentLeaseFlats });
		} else {
			let arrLength = this.getLeaseFlatDetails(this.state.societyId);

			this.setState({ currentLeaseFlats: arrLength });
		}
	}

	render() {
		let totalLeaseFlats = this.state?.currentLeaseFlats?.length || 0;

		const { currentLeaseFlats, currentPage, totalPages } = this.state;

		return (
			<>
				{this.state?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Leasehold Flat Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Lease Flat Details</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Flat No</th>
											<th>Flat Area</th>
											<th>Owner Name</th>
											<th>Owner Contact</th>
											<th>Owner Email</th>
											<th>Is On Rent</th>
											<th>Tenant Name</th>
											<th>Tenant Contact Details</th>
											<th>Lease Start Date</th>
											<th>Lease Expiry Date</th>
											<th>Two Wheeler</th>
											<th>Four Wheeler</th>
											<th>Outstanding Amount</th>
										</tr>
									</thead>
									<tbody>
										{currentLeaseFlats?.length
											? currentLeaseFlats.map((outstanding, i) => (
													<tr key={i}>
														<td>{outstanding?.flatNo} </td>
														<td>{outstanding?.flatArea}</td>
														<td>{outstanding?.ownerName}</td>
														<td>{outstanding?.contactDetails}</td>
														<td>{outstanding?.ownerEmail}</td>
														<td>
															<div className="d-flex align-items-center">
																{outstanding?.isOnRent === true ? (
																	<i className="fa fa-check-square text-success mr-1"></i>
																) : (
																	<i className="fa fa-square mr-1"></i>
																)}
															</div>
														</td>
														<td>{outstanding?.tenantName}</td>
														<td>{outstanding?.tenantContactDetails}</td>
														<td>
															{outstanding?.leaseStartDate &&
																format(
																	new Date(outstanding?.leaseStartDate),
																	"dd-MM-yyyy"
																)}
														</td>
														<td>
															{outstanding?.leaseExpiryDate &&
																format(
																	new Date(outstanding?.leaseExpiryDate),
																	"dd-MM-yyyy"
																)}
														</td>
														<td>{outstanding?.twoWheeler}</td>
														<td>{outstanding?.fourWheeler}</td>
														<td>{outstanding.outStandingAmount}</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalLeaseFlats ? (
										<Pagination
											totalRecords={totalLeaseFlats}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default LeaseFlatDetails;
