import {
	CONFIRMED_GET_REQUESTDEMO_DETAILS,
	CONFIRMED_CREATE_REQUESTDEMO_ACTION,
	CONFIRMED_GET_REQUESTDEMOS,
	CONFIRMED_EDIT_REQUESTDEMO_ACTION,
	CONFIRMED_DELETE_REQUESTDEMO_ACTION,
	REQUESTDEMOS_LOADING,
} from "../actions/RequestDemoTypes";

const initialState = {
	requestDemos: [],
	requestDemo: {},
};

export default function RequestDemoReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_REQUESTDEMO_DETAILS) {
		return {
			...state,
			requestDemo: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_REQUESTDEMO_ACTION) {
		const requestDemos = [...state.requestDemos.requestDemos];
		const requestDemoIndex = requestDemos.findIndex(
			(requestDemo) => requestDemo.id === actions.payload
		);

		requestDemos.splice(requestDemoIndex, 1);

		return {
			...state,
			requestDemos: { requestDemos },
		};
	}

	if (actions.type === CONFIRMED_EDIT_REQUESTDEMO_ACTION) {
		return {
			...state,
			requestDemos: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_REQUESTDEMO_ACTION) {
		return {
			...state,
			requestDemos: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_REQUESTDEMOS) {
		return {
			...state,
			requestDemos: actions.payload,
			loading: false,
		};
	}

	if (actions.type === REQUESTDEMOS_LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
