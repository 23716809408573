export const GET_BANKDETAILS_DETAILS = "[BankDetail Action] Get BankDetail";
export const CONFIRMED_GET_BANKDETAILS_DETAILS =
	"[BankDetail Action] Confirmed Get BankDetail";
export const CREATE_BANKDETAILS_ACTION =
	"[BankDetail Action] Create BankDetail";
export const CONFIRMED_CREATE_BANKDETAILS_ACTION =
	"[BankDetail Action] Confirmed Create BankDetail";
export const GET_BANKDETAILS = "[BankDetails Action] Get BankDetails";
export const CONFIRMED_GET_BANKDETAILS =
	"[BankDetails Action] Confirmed Get BankDetails";
export const EDIT_BANKDETAILS_ACTION = "[BankDetail Action] Edit BankDetail";
export const CONFIRMED_EDIT_BANKDETAILS_ACTION =
	"[BankDetail Action] Confirmed Edit BankDetail";
export const CONFIRMED_DELETE_BANKDETAILS_ACTION =
	"[BankDetail Action] Confirmed Delete BankDetail";
