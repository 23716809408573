import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getSocietyByUrlAction } from "../../../store/actions/SocietyActions";

const AppProfile = ({ society, getSocietyByUrlAction }) => {
	const [societyObj, setSocietyObj] = useState();
	const { societyurl } = useParams();

	useEffect(() => {
		getSocietyByUrlAction(societyurl);
	}, []);

	useEffect(() => {
		setSocietyObj(society?.society);
	}, [society]);

	const options = {
		settings: {
			overlayColor: "#000000",
		},
	};

	const amenities = societyObj?.amenties?.filter(
		(item) => item.status === true
	);

	return (
		<Fragment>
			{societyObj && (
				<>
					<div className="row">
						<div className="col-lg-12">
							<div className="profile card card-body px-3 pt-3 pb-0">
								<div className="profile-head">
									<div className="photo-content">
										<div className="cover-photo">
											<img
												src={societyObj?.societyImages[0]?.fileurl}
												//className="img-fluid"
												alt="profile"
												height="300"
												style={{ width: "100%" }}
											/>
										</div>
									</div>
									<div className="card-header border-0 pb-0">
										<h5 className="text-primary d-inline">
											Society Information
										</h5>
									</div>
									<div className="profile-info">
										<div className="profile-details">
											<div className="profile-name px-3 pt-2">
												<h4 className="text-primary mb-0">
													<div>Society Name: </div>
												</h4>
												<p>{societyObj.societyName}</p>
											</div>
											<div className="profile-name px-3 pt-2">
												<h4 className="text-primary mb-0">
													<div>Society URL: </div>
												</h4>
												<p>{societyObj.societyUrl}</p>
											</div>
											<div className="profile-name px-2 pt-2">
												<h4 className="text-primary mb-0">
													<div>Society Address:</div>
												</h4>
												<p>{societyObj.societyAddress}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="row">
								<div className="col-lg-12">
									<div className="card">
										<div className="card-body">
											<div className="profile-statistics">
												<div className="text-center">
													<div className="row">
														<div className="col">
															<h3 className="m-b-0">
																{societyObj.societyMembersCount}
															</h3>
															<span>Total Members</span>
														</div>
														<div className="col">
															<h3 className="m-b-0">
																{societyObj.societyParkingCount}
															</h3>{" "}
															<span>Total Parking</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="card">
										<div className="card-header border-0 pb-0">
											<h5 className="text-primary d-inline">
												Society Ammenties
											</h5>
										</div>
										<div className="card-body pt-3">
											<div>
												{amenities?.map((amenity, i) => (
													<ul className="amenities" key={i}>
														<li>
															<div className="icon-box style1">
																<i
																	className={amenity?.webIcon}
																	aria-hidden="true"
																></i>
																{amenity?.name}
															</div>
														</li>
													</ul>
												))}
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="card">
										<div className="card-header border-0 pb-0">
											<h5 className="text-primary d-inline">Society Images</h5>
										</div>
										<div className="card-body pt-3">
											<div className="profile-interest ">
												<SRLWrapper options={options}>
													<div className="row sp4">
														{societyObj?.societyImages?.map((images, i) => (
															<div
																className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1"
																key={i}
															>
																<a href={images.fileurl}>
																	{" "}
																	<img
																		src={images.fileurl}
																		alt={images.title}
																		className="img-fluid"
																	/>{" "}
																</a>
															</div>
														))}
													</div>
												</SRLWrapper>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		society: state?.society?.society,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getSocietyByUrlAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppProfile);
