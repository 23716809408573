import {
	CONFIRMED_GET_AMC_DETAILS,
	CONFIRMED_CREATE_AMC_ACTION,
	CONFIRMED_GET_AMCS,
	CONFIRMED_EDIT_AMC_ACTION,
	CONFIRMED_DELETE_AMC_ACTION,
} from "../actions/AmcTypes";

const initialState = {
	amcs: [],
	amc: {},
};

export default function AmcReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_AMC_DETAILS) {
		return {
			...state,
			amc: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_AMC_ACTION) {
		const amcs = [...state.amcs.amcs];
		const amcIndex = amcs.findIndex((amc) => amc.id === actions.payload);

		amcs.splice(amcIndex, 1);

		return {
			...state,
			amcs: { amcs },
		};
	}

	if (actions.type === CONFIRMED_EDIT_AMC_ACTION) {
		return {
			...state,
			amcs: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_AMC_ACTION) {
		return {
			...state,
			amcs: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_AMCS) {
		return {
			...state,
			amcs: actions.payload,
		};
	}

	return state;
}
