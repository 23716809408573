import {
	CONFIRMED_GET_DASHBOARD_DETAILS,
	LOADING,
} from "../../actions/Member/DashboardTypes";

const initialState = {
	dashboard: [],
};

export default function DashboardReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_DASHBOARD_DETAILS) {
		return {
			...state,
			dashboard: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
