import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getBillingAction,
	createBillingAction,
	updateBillingAction,
} from "../../../../store/actions/BillingActions";
import { getFlatsAction } from "../../../../store/actions/FlatManagementActions";

class AddEditBilling extends Component {
	constructor(props) {
		super(props);
		this.state = {
			billing: {},
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.billing) {
				this.props.getBillingAction(id);
			}
		} else {
		}
		this.props.getFlatsAction(userData.user.societyName);
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.billing?.length !== this.props?.billing?.length) {
			this.setState({
				billing: this.props?.billing,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateBillingAction(
				values,
				this.props.history,
				this.props?.billing?._id
			);
		} else {
			this.props.createBillingAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var billing = this.props?.billing;
		} else {
			billing = [];
		}

		let flat_data = this.props.flats?.flats;

		const initialValues = {
			societyId: this.state.societyId,
			flatId: billing ? billing.flatId : "",
			maintenanceMonth: billing ? billing.maintenanceMonth : "",
			maintenanceYear: billing ? billing.maintenanceYear : "",
			maintenanceCharges: billing ? billing.maintenanceCharges : 0,
			sinkingFund: billing ? billing.sinkingFund : 0,
			repairFund: billing ? billing.repairFund : 0,
			cidcoCharges: billing ? billing.cidcoCharges : 0,
			parkingCharges: billing ? billing.parkingCharges : 0,
			festivalCharges: billing ? billing.festivalCharges : 0,
			nocCharges: billing ? billing.nocCharges : 0,
			otherCharges: billing ? billing.otherCharges : 0,
			totalCharges: billing ? billing.totalCharges : 0,
			arrears: billing ? billing.arrears : 0,
			interest: billing ? billing.interest : 0,
			totalAmount: billing ? billing.totalAmount : 0,
			status: billing ? billing.status : true,
		};

		const validationSchema = Yup.object().shape({
			flatId: Yup.string().required("Please Select Flat Name."),
			//adjustmentType: Yup.string().required("Please Select Adjustment Type."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/billings" }} linkAs={Link}>
						Billing Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Billing</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Billing</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Add Billing</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/billings"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											// const payload = {
											// 	...values,
											// 	flatId: values.flatId,
											// 	flatNo: values.flatId,
											// };
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Flat Name</label>
															<div>
																<select
																	className="form-control"
																	id="val-flatId"
																	placeholder="Select a Flat Name.."
																	name="flatId"
																	onChange={handleChange}
																	//onChange={setFieldValue}
																	onBlur={handleBlur}
																	value={values.flatId || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	{flat_data?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt._id}
																			//label={opt.flatNo}
																		>
																			{opt.flatNo}
																		</option>
																	))}
																</select>
																{errors.flatId && touched.flatId ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.flatId}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Maintenance Year
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-maintenanceYear"
																	placeholder="Enter a maintenance Year.."
																	name="maintenanceYear"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.maintenanceYear || ""}
																/>
																{errors.maintenanceYear &&
																touched.maintenanceYear ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.maintenanceYear}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Maintenance Month
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-maintenanceMonth"
																	placeholder="Enter a maintenance Month.."
																	name="maintenanceMonth"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.maintenanceMonth || ""}
																/>
																{errors.maintenanceMonth &&
																touched.maintenanceMonth ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.maintenanceMonth}
																	</div>
																) : null}
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Maintenance Charges
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-maintenanceCharges"
																	placeholder="Enter a maintenance Charges.."
																	name="maintenanceCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.maintenanceCharges || 0}
																/>
																{errors.maintenanceCharges &&
																touched.maintenanceCharges ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.maintenanceCharges}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Sinking Fund</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-sinkingFund"
																	placeholder="Enter a sinkingFund.."
																	name="sinkingFund"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.sinkingFund || 0}
																/>
																{errors.sinkingFund && touched.sinkingFund ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.sinkingFund}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Repair Fund</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-repairFund"
																	placeholder="Enter a repair Fund.."
																	name="repairFund"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.repairFund || 0}
																/>
																{errors.repairFund && touched.repairFund ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.repairFund}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																CIDCO Charges
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-cidcoCharges"
																	placeholder="Enter a cidco Charges.."
																	name="cidcoCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.cidcoCharges || 0}
																/>
																{errors.cidcoCharges && touched.cidcoCharges ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.cidcoCharges}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Parking Charges
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-parkingCharges"
																	placeholder="Enter a parking Charges.."
																	name="parkingCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.parkingCharges || 0}
																/>
																{errors.parkingCharges &&
																touched.parkingCharges ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.parkingCharges}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Festival Charges
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-festivalCharges"
																	placeholder="Enter a festival Charges.."
																	name="festivalCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.festivalCharges || 0}
																/>
																{errors.festivalCharges &&
																touched.festivalCharges ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.festivalCharges}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">NOC Charges</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-nocCharges"
																	placeholder="Enter a noc Charges.."
																	name="nocCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.nocCharges || 0}
																/>
																{errors.nocCharges && touched.nocCharges ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.nocCharges}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Other Charges
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-otherCharges"
																	placeholder="Enter a other Charges.."
																	name="otherCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.otherCharges || 0}
																/>
																{errors.otherCharges && touched.otherCharges ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.otherCharges}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Total Charges
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-totalCharges"
																	placeholder="Enter a total Charges.."
																	name="totalCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.totalCharges || 0}
																/>
																{errors.totalCharges && touched.totalCharges ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.totalCharges}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Arrears</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-arrears"
																	placeholder="Enter a arrears.."
																	name="arrears"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.arrears || 0}
																/>
																{errors.arrears && touched.arrears ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.arrears}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Interest</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-interest"
																	placeholder="Enter a interest.."
																	name="interest"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.interest || 0}
																/>
																{errors.interest && touched.interest ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.interest}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Total Amount</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-totalAmount"
																	placeholder="Enter a total Amount.."
																	name="totalAmount"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.totalAmount || 0}
																/>
																{errors.totalAmount && touched.totalAmount ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.totalAmount}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddBilling;
const mapStateToProps = (state) => {
	return {
		billing: state.billing?.billing,
		flats: state.flat?.flats,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getBillingAction,
			createBillingAction,
			updateBillingAction,
			getFlatsAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBilling);
