import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
//import Pagination from "../../Pagination";
import swal from "sweetalert";
import axiosInstance from "../../../../services/AxiosInstance";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
	getFlatsAction,
	updateFlatAssociationAction,
} from "../../../../store/actions/FlatManagementActions";
import { getUsersAction } from "../../../../store/actions/UserActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class AddAssociation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentFlats: [],
			currentUsers: [],
			users: [],
			currentPage: null,
			totalPages: null,
			societyId: "",
			userId: "",
			name: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSearchUser = this.handleSearchUser.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
			userId: userData?.user?._id,
		});

		this.props.getFlatsAction(userData.user.societyName);
		this.props.getUsersAction(userData.user.societyName);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.flats?.flats?.length !== this.props?.flats?.flats?.length) {
			this.setState({
				currentFlats: this.props?.flats?.flats,
			});
		}
		if (prevProps?.users?.users?.length !== this.props?.users?.users?.length) {
			this.setState({
				currentUsers: this.props?.users?.users,
			});
		}
	}

	initData() {
		let currentFlats =
			this.state?.currentFlats?.length > 0
				? this.state?.currentFlats
				: this.props?.flats?.flats;
		let currentUsers =
			this.state?.currentUsers?.length > 0
				? this.state?.currentUsers
				: this.props?.users?.users;

		this.setState({ currentFlats, currentUsers });
	}

	onPageChanged = (data) => {
		const allFlats = this.props?.flats?.flats;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentFlats = allFlats?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentFlats, totalPages });
	};

	handleSearchByMobile(event) {
		this.searchValue = event.target.value;
		// this.setState({
		// 	search: searchValue,
		// });
	}

	handleChange(event) {
		if (this.state.search) {
			const currentFlats = this.props?.flats?.flats?.filter(
				(item) =>
					item.flatNo === this.state.search ||
					item.ownerName.toLowerCase() === this.state.search.toLowerCase()
			);
			this.setState({
				name: event.target.value,
				currentFlats: currentFlats,
			});
		} else {
			this.setState({
				name: event.target.value,
				//currentFlats: currentFlats,
			});
		}
	}

	async handleSearchUser(e) {
		e.preventDefault();
		const userData = await axiosInstance
			.get(`admin/users/mobile/${this.searchValue}`)
			.then((res) => res?.data);

		if (this.state.search) {
			const currentFlats = this.props?.flats?.flats?.filter(
				(item) =>
					item.flatNo === this.state.search ||
					item.ownerName.toLowerCase() === this.state.search.toLowerCase()
			);
			this.setState({
				users: userData?.user,
				currentFlats: currentFlats,
			});
		} else {
			this.setState({
				users: userData?.user,
			});
		}
		// const currentFlats = this.props?.flats?.flats?.filter(
		// 	(item) =>
		// 		item.flatNo === this.state.search ||
		// 		item.ownerName.toLowerCase() === this.state.search.toLowerCase()
		// );

		// this.setState({
		// 	users: userData?.user,
		// 	currentFlats: currentFlats,
		// });
	}

	saveRecord(id) {
		const values = {
			userId: this.state.name,
		};

		swal({
			title: "Are you sure?",
			text: "Are you sure you want to associate this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.updateFlatAssociationAction(values, this.props.history, id);
				swal("Your flat has been associated!", {
					icon: "success",
				});
				this.setState({
					search: "",
				});
				this.initData();
				window.location.reload();
			} else {
				swal("Your process is cancelled!");
			}
		});
	}

	handleSearch(event) {
		let currentFlats = this.props.flats?.flats;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();

		if (searchString.length > 0) {
			// We are searching. Filter the results.
			//If page wise search we want then replace flats with currentFlats
			currentFlats = currentFlats?.filter(
				(e) =>
					e?.flatNo?.toLowerCase().match(searchString) ||
					e?.ownerName?.toLowerCase().match(searchString)
			);
		}
		this.setState({ currentFlats });
	}

	async deleteAssociation(userId, flatId) {
		const payload = {
			userId: userId,
		};

		const resp = await axiosInstance
			.put(`admin/flat/flatassociation/remove/${flatId}`, payload)
			.then((res) => res?.data)
			.catch((err) => {
				return err;
			});

		if (resp.success === true) {
			window.location.reload();
			toast.success("Successfully deleted associated user");
		} else {
			toast.error("Error deleting this record!");
		}
	}

	render() {
		//let totalFlats = this.props?.flats?.flats?.length || 0;

		const { currentFlats } = this.state; // currentPage, totalPages
		this.state.currentFlats = this.props.flats?.flats;
		this.state.currentUsers = this.props?.users?.users;

		let user_data = this.state?.users;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Add Flat Association</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Add Flat Association</h2>
								</div>
								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search By Flat no. & Name..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Flat No.</th>
											<th>Owner Name</th>
											<th>Enter Mobile No.</th>
											<th>User Name</th>
											<th>Associated Users</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentFlats &&
											currentFlats
												?.sort((a, b) => a.flatNo.localeCompare(b.flatNo))
												.map((flat, i) => (
													<tr key={i}>
														<td>{flat.flatNo} </td>
														<td>{flat.ownerName}</td>
														<td>
															<form onSubmit={this.handleSearchUser}>
																<div className="row">
																	<div className="col-sm-9">
																		<input
																			type="text"
																			className="form-control mb-2 search-list pull-right"
																			placeholder="Search User By Mobile..."
																			onChange={(e) =>
																				this.handleSearchByMobile(e)
																			}
																		/>
																	</div>
																	<div className="col-sm-3 pt-2 pl-0">
																		<button className="search-button btn btn-primary shadow btn-xs">
																			<i className="fa fa-search"></i>
																		</button>
																	</div>
																</div>
															</form>
														</td>
														<td>
															<div>
																<select
																	className="form-control"
																	id="val-name"
																	placeholder="Select User.."
																	name="name"
																	onChange={this.handleChange}
																>
																	<option value="Select" label="Select">
																		Select
																	</option>
																	{user_data && user_data?.length > 0
																		? user_data?.map((opt, i) => (
																				<option key={i} value={opt._id}>
																					{opt.name}
																				</option>
																		  ))
																		: null}
																</select>
															</div>
														</td>
														{flat?.userId.length > 0 ? (
															<td>
																{flat?.userId.length > 0 &&
																	flat?.userId.map((associateuser, i) => (
																		<div
																			key={i}
																			className="main-associate-user"
																		>
																			<div className="associate-user">
																				{this.state?.currentUsers
																					?.filter(
																						(item) =>
																							item?._id === associateuser
																					)
																					.map((user) => user?.name)}
																				{/* {associateuser} */}
																			</div>

																			<div>
																				<Link
																					className="delete-association"
																					onClick={this.deleteAssociation.bind(
																						this,
																						associateuser,
																						flat._id
																					)}
																				>
																					<i className="fa fa-window-close"></i>
																				</Link>
																			</div>
																		</div>
																	))}

																{/* <div>
																	{this.state?.currentUsers
																		?.filter(
																			(item) => item?._id === flat?.userId[0]
																		)
																		.map((user) => user?.name)}

																	<Link
																		className="delete-association"
																		onClick={this.deleteAssociation.bind(
																			this,
																			flat.userId[0],
																			flat._id
																		)}
																	>
																		<i className="fa fa-window-close"></i>
																	</Link>
																</div> */}
															</td>
														) : (
															<td></td>
														)}
														<td>
															<div className="d-flex">
																{this.state?.name !== "" ? (
																	<Link
																		to="#"
																		className="btn btn-primary shadow btn-xs"
																		onClick={this.saveRecord.bind(
																			this,
																			flat._id
																		)}
																	>
																		Save
																	</Link>
																) : (
																	<Link
																		to="#"
																		className="btn btn-primary shadow btn-xs"
																		onClick={this.saveRecord.bind(
																			this,
																			flat._id
																		)}
																		style={{ pointerEvents: "none" }}
																	>
																		Save
																	</Link>
																)}
															</div>
														</td>
													</tr>
												))}
									</tbody>
								</Table>
								{/* <div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalFlats ? (
										<Pagination
											totalRecords={totalFlats}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div> */}
								<ToastContainer
									position="top-right"
									autoClose={3000}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		flats: state.flat?.flats,
		users: state?.user?.users,
		loading: state?.flat?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getFlatsAction, updateFlatAssociationAction, getUsersAction }, /// getUsersAction,
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAssociation);
