import { Component } from "react";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { format } from "date-fns";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import axiosInstance from "../../../../services/AxiosInstance";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class OutstandingDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			societyId: "",
			currentOutstandings: [],
			currentPage: null,
			totalPages: null,
			loading: false,
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		this.getOutstandingDetails(userData.user.societyName);
	}

	async getOutstandingDetails(societyId) {
		this.setState({
			loading: true,
		});
		const { data } = await axiosInstance
			.get(`admin/report/groupbyFlat/${societyId}`)
			.then((result) => result);

		this.setState({
			currentOutstandings: data?.outstanding,
			loading: false,
		});
	}

	onPageChanged = async (data) => {
		await this.getOutstandingDetails(this.state.societyId);

		const allOutstandings = this.state.currentOutstandings;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentOutstandings = allOutstandings?.slice(
			offset,
			offset + pageLimit
		);

		this.setState({ currentPage, currentOutstandings, totalPages });
	};

	handleSearch(event) {
		let currentOutstandings = this.state.currentOutstandings;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentOutstandings = this.state?.currentOutstandings?.filter(
				(e) =>
					e?.flat[0]?.flatNo?.toLowerCase().match(searchString) ||
					e?.flat[0]?.ownerName?.toLowerCase().match(searchString) ||
					e?.flat[0]?.contactDetails?.toLowerCase().match(searchString)
			);
			this.setState({ currentOutstandings });
		} else {
			let arrLength = this.getOutstandingDetails(this.state.societyId);
			this.setState({ currentOutstandings: arrLength });
		}
	}

	render() {
		let totalOutstandings = this.state.currentOutstandings?.length || 0;

		const { currentOutstandings, currentPage, totalPages } = this.state;

		return (
			<>
				{this.state?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Outstanding Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Outstanding Details</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Flat No</th>
											<th>Flat Area</th>
											<th>Owner Name</th>
											<th>Owner Contact</th>
											<th>Owner Email</th>
											<th>Is On Rent</th>
											<th>Tenant Name</th>
											<th>Tenant Contact Details</th>
											<th>Lease Start Date</th>
											<th>Lease Expiry Date</th>
											<th>Two Wheeler</th>
											<th>Four Wheeler</th>
											<th>Credit Amount</th>
											<th>Debit Amount</th>
											<th>Outstanding Amount</th>
										</tr>
									</thead>
									<tbody>
										{currentOutstandings?.length
											? currentOutstandings.map((outstanding, i) => (
													<tr key={i}>
														<td>{outstanding.flat[0]?.flatNo} </td>
														<td>{outstanding.flat[0]?.flatArea}</td>
														<td>{outstanding.flat[0]?.ownerName}</td>
														<td>{outstanding.flat[0]?.contactDetails}</td>
														<td>{outstanding.flat[0]?.ownerEmail}</td>
														<td>
															<div className="d-flex align-items-center">
																{outstanding.flat[0]?.isOnRent === true ? (
																	<i className="fa fa-check-square text-success mr-1"></i>
																) : (
																	<i className="fa fa-square mr-1"></i>
																)}
															</div>
														</td>
														<td>{outstanding.flat[0]?.tenantName}</td>
														<td>{outstanding.flat[0]?.tenantContactDetails}</td>
														<td>
															{outstanding.flat[0]?.leaseStartDate &&
																format(
																	new Date(
																		outstanding?.flat[0]?.leaseStartDate
																	),
																	"dd-MM-yyyy"
																)}
														</td>
														<td>
															{outstanding.flat[0]?.leaseExpiryDate &&
																format(
																	new Date(
																		outstanding?.flat[0]?.leaseExpiryDate
																	),
																	"dd-MM-yyyy"
																)}
														</td>
														<td>{outstanding.flat[0]?.twoWheeler}</td>
														<td>{outstanding.flat[0]?.fourWheeler}</td>
														<td>{outstanding?.creditAmount}</td>
														<td>{outstanding.debitAmount}</td>
														<td>{outstanding.outStandingAmount}</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalOutstandings ? (
										<Pagination
											totalRecords={totalOutstandings}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default OutstandingDetails;
