import { CONFIRMED_GET_DASHBOARD_DETAILS } from '../actions/DashboardTypes';

const initialState = {
    dashboard: [],
};

export default function DashboardReducer(state = initialState, actions) {

    if (actions.type === CONFIRMED_GET_DASHBOARD_DETAILS) {
        return {
            ...state,
            dashboard: actions.payload,
        };
    }
    return state;
}
