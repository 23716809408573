import { getDashboardDetails } from "../../../services/Member/DashboardService";
import { CONFIRMED_GET_DASHBOARD_DETAILS, LOADING } from "./DashboardTypes"; //GET_DASHBOARD_DETAILS

export function getDashboardAction(societyId, flatId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getDashboardDetails(societyId, flatId).then((response) => {
			let dashboard = response.data;
			dispatch(confirmedGetDashboardAction(dashboard));
		});
	};
}

export function confirmedGetDashboardAction(dashboard) {
	return {
		type: CONFIRMED_GET_DASHBOARD_DETAILS,
		payload: dashboard,
	};
}
