import {
	CONFIRMED_GET_USER_DETAILS,
	CONFIRMED_CREATE_USER_ACTION,
	CONFIRMED_GET_USERS,
	CONFIRMED_EDIT_USER_ACTION,
	CONFIRMED_DELETE_USER_ACTION,
	CONFIRMED_UPDATE_PASSWORD_ACTION,
	CONFIRMED_UPLOAD_USER_ACTION,
	LOADING,
} from "../actions/UserTypes";

const initialState = {
	users: [],
	user: {},
};

export default function UserReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_USER_DETAILS) {
		return {
			...state,
			user: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_USER_ACTION) {
		const users = [...state.users.users];
		const userIndex = users.findIndex((user) => user.id === actions.payload);

		users.splice(userIndex, 1);

		return {
			...state,
			users: { users },
		};
	}

	if (actions.type === CONFIRMED_EDIT_USER_ACTION) {
		return {
			...state,
			users: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_USER_ACTION) {
		return {
			...state,
			users: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_USERS) {
		return {
			...state,
			users: actions.payload,
			loading: false,
		};
	}

	if (actions.type === CONFIRMED_UPDATE_PASSWORD_ACTION) {
		return {
			...state,
			users: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_UPLOAD_USER_ACTION) {
		return {
			...state,
			users: actions.payload,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
