import {
	getBillingHeaderMappingDetails,
	getBillingHeaderMappings,
	createBillingHeaderMapping,
	updateBillingHeaderMapping,
	deleteBillingHeaderMapping,
} from "../../services/BillingHeaderMappingService";
import {
	CONFIRMED_GET_BILLINGHEADER_DETAILS,
	CONFIRMED_CREATE_BILLINGHEADER_ACTION,
	CONFIRMED_GET_BILLINGHEADERS,
	CONFIRMED_EDIT_BILLINGHEADER_ACTION,
	CONFIRMED_DELETE_BILLINGHEADER_ACTION,
	LOADING,
} from "./BillingHeaderMappingTypes";

export function getBillingHeaderAction(societyId) {
	return (dispatch, getState) => {
		getBillingHeaderMappingDetails(societyId).then((response) => {
			dispatch(confirmedGetBillingHeaderAction(response.data.labelMapping));
		});
	};
}

export function confirmedGetBillingHeaderAction(billing) {
	return {
		type: CONFIRMED_GET_BILLINGHEADER_DETAILS,
		payload: billing,
	};
}

export function deleteBillingHeaderAction(billingId, history) {
	return (dispatch, getState) => {
		deleteBillingHeaderMapping(billingId).then((response) => {
			dispatch(confirmedDeleteBillingHeaderAction(billingId));
			history.push("/superadmin/billing-header-mapping");
		});
	};
}

export function confirmedDeleteBillingHeaderAction(billingId) {
	return {
		type: CONFIRMED_DELETE_BILLINGHEADER_ACTION,
		payload: billingId,
	};
}

export function createBillingHeaderAction(BillingHeaderData, history) {
	return (dispatch, getState) => {
		createBillingHeaderMapping(BillingHeaderData).then((response) => {
			const singleBillingHeader = {
				...BillingHeaderData,
				id: response.data._id,
			};
			dispatch(confirmedCreateBillingHeaderAction(singleBillingHeader));
			history.push("/superadmin/billing-header-mapping");
		});
	};
}

export function getBillingHeadersAction() {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getBillingHeaderMappings().then((response) => {
			dispatch(confirmedGetBillingHeadersAction(response.data));
		});
	};
}

export function confirmedCreateBillingHeaderAction(singleBillingHeader) {
	return {
		type: CONFIRMED_CREATE_BILLINGHEADER_ACTION,
		payload: singleBillingHeader,
	};
}

export function confirmedGetBillingHeadersAction(BillingHeaders) {
	return {
		type: CONFIRMED_GET_BILLINGHEADERS,
		payload: BillingHeaders,
	};
}

export function confirmedUpdateBillingHeaderAction(BillingHeader) {
	return {
		type: CONFIRMED_EDIT_BILLINGHEADER_ACTION,
		payload: BillingHeader,
	};
}

export function updateBillingHeaderAction(BillingHeader, history, billingId) {
	return (dispatch, getState) => {
		updateBillingHeaderMapping(BillingHeader, billingId).then((reponse) => {
			dispatch(confirmedUpdateBillingHeaderAction(BillingHeader));
			history.push("/superadmin/billing-header-mapping");
		});
	};
}
