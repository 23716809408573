import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getBillingAdjustmentAction,
	createBillingAdjustmentAction,
	updateBillingAdjustmentAction,
} from "../../../../store/actions/BillingAdjustmentActions";
import { getFlatsAction } from "../../../../store/actions/FlatManagementActions";

class AddEditBillingAdjustment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			billingAdjustment: {},
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.billingAdjustment) {
				this.props.getBillingAdjustmentAction(id); //Local
			}
		} else {
		}

		this.props.getFlatsAction(userData.user.societyName);
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.billingAdjustment?.length !==
			this.props?.billingAdjustment?.length
		) {
			this.setState({
				billingAdjustment: this.props?.billingAdjustment,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;

		var e = document.getElementById("val-flatId"); // To get the Flat NO from Flat id
		var flatText = e.options[e.selectedIndex].text;

		// values.append("flatNo", flatText);
		if (id) {
			this.props.updateBillingAdjustmentAction(
				values,
				this.props.history,
				this.props?.billingAdjustment?._id
			);
		} else {
			Object.assign(values, { flatNo: flatText }); // Push flat no

			this.props.createBillingAdjustmentAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var billingAdjustment = this.props?.billingAdjustment;
		} else {
			billingAdjustment = "";
		}

		let flat_data = this.props.flats?.flats;

		const initialValues = {
			//societyId: "614867364cb64178e46f26d5", // Local
			societyId: this.state.societyId,
			flatId: billingAdjustment ? billingAdjustment.flatId : "",
			//flatNo: flatText ? flatText : "",
			adjustmentType: billingAdjustment ? billingAdjustment.adjustmentType : "",
			adjustmentDescription: billingAdjustment
				? billingAdjustment.adjustmentDescription
				: "",
			amount: billingAdjustment ? billingAdjustment.amount : "",
		};

		const validationSchema = Yup.object().shape({
			flatId: Yup.string().required("Please Select Flat Name."),
			adjustmentType: Yup.string().required("Please Select Adjustment Type."),
			adjustmentDescription: Yup.string().required(
				"Please Enter Adjustment Desc."
			),
			amount: Yup.string().required("Please Enter amount."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item
						linkProps={{ to: "/billing-adjustment-lists" }}
						linkAs={Link}
					>
						Billing Adjustment Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Billing Adjustment</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Billing Adjustment</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Add Billing Adjustment</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/billing-adjustment-lists"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Flat No. *</label>
															<div>
																<select
																	className="form-control"
																	id="val-flatId"
																	placeholder="Select a Flat Name.."
																	name="flatId"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.flatId || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	{flat_data?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt._id}
																			//label={opt.flatNo}
																		>
																			{opt.flatNo}
																		</option>
																	))}
																</select>
																{errors.flatId && touched.flatId ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.flatId}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Adjustment Type *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-adjustmentType"
																	placeholder="Select a Adjustment Type.."
																	name="adjustmentType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.adjustmentType || "Select"}
																>
																	<option value="Select" label="Select" />
																	<option value="Credit" label="Credit" />
																	<option value="Debit" label="Debit" />
																</select>
																{errors.adjustmentType &&
																touched.adjustmentType ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.adjustmentType}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Adjustment Description *
															</label>
															<div>
																<textarea
																	type="text"
																	className="form-control"
																	id="val-adjustmentDescription"
																	placeholder="Enter a Desc.."
																	name="adjustmentDescription"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.adjustmentDescription || ""}
																	rows="3"
																></textarea>
																{errors.adjustmentDescription &&
																touched.adjustmentDescription ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.adjustmentDescription}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Amount *</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-amount"
																	placeholder="Enter a Amount.."
																	name="amount"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.amount || ""}
																/>
																{errors.amount && touched.amount ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.amount}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												{/* <div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div> */}

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddBillingAdjustment;
const mapStateToProps = (state) => {
	return {
		billingAdjustment: state.billingAdjustment?.billingAdjustment,
		flats: state.flat?.flats,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getBillingAdjustmentAction,
			createBillingAdjustmentAction,
			updateBillingAdjustmentAction,
			getFlatsAction,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddEditBillingAdjustment);
