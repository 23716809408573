import {
	getExpenseCategoryDetails,
	getExpenseCategories,
	createExpenseCategory,
	updateExpenseCategory,
	deleteExpenseCategory,
} from "../../services/ExpenseCategoryService";
import {
	CONFIRMED_GET_EXPENSECATEGORY_DETAILS,
	CONFIRMED_CREATE_EXPENSECATEGORY_ACTION,
	CONFIRMED_GET_EXPENSECATEGORIES,
	CONFIRMED_EDIT_EXPENSECATEGORY_ACTION,
	CONFIRMED_DELETE_EXPENSECATEGORY_ACTION,
	LOADING,
} from "./ExpenseCategoryTypes";

export function getExpenseCategoryAction(expenseId) {
	return (dispatch, getState) => {
		getExpenseCategoryDetails(expenseId).then((response) => {
			dispatch(
				confirmedGetExpenseCategoryAction(response.data.expenseCategory)
			);
		});
	};
}

export function confirmedGetExpenseCategoryAction(expenseCategory) {
	return {
		type: CONFIRMED_GET_EXPENSECATEGORY_DETAILS,
		payload: expenseCategory,
	};
}

export function deleteExpenseCategoryAction(expenseId, history) {
	return (dispatch, getState) => {
		deleteExpenseCategory(expenseId).then((response) => {
			dispatch(confirmedDeleteExpenseCategoryAction(expenseId));
			history.push("/expense-category-lists");
		});
	};
}

export function confirmedDeleteExpenseCategoryAction(expenseId) {
	return {
		type: CONFIRMED_DELETE_EXPENSECATEGORY_ACTION,
		payload: expenseId,
	};
}

export function createExpenseCategoryAction(ExpenseData, history) {
	return (dispatch, getState) => {
		createExpenseCategory(ExpenseData).then((response) => {
			const singleExpenseCategory = {
				...ExpenseData,
				id: response.data._id,
			};
			dispatch(confirmedCreateExpenseCategoryAction(singleExpenseCategory));
			history.push("/expense-category-lists");
		});
	};
}

export function getExpenseCategoriesAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getExpenseCategories(societyId).then((response) => {
			dispatch(confirmedGetExpenseCategoriesAction(response.data));
		});
	};
}

export function confirmedCreateExpenseCategoryAction(singleExpenseCategory) {
	return {
		type: CONFIRMED_CREATE_EXPENSECATEGORY_ACTION,
		payload: singleExpenseCategory,
	};
}

export function confirmedGetExpenseCategoriesAction(ExpenseCategories) {
	return {
		type: CONFIRMED_GET_EXPENSECATEGORIES,
		payload: ExpenseCategories,
	};
}

export function confirmedUpdateExpenseCategoryAction(ExpenseCategory) {
	return {
		type: CONFIRMED_EDIT_EXPENSECATEGORY_ACTION,
		payload: ExpenseCategory,
	};
}

export function updateExpenseCategoryAction(
	ExpenseCategory,
	history,
	expenseId
) {
	return (dispatch, getState) => {
		updateExpenseCategory(ExpenseCategory, expenseId).then((reponse) => {
			dispatch(confirmedUpdateExpenseCategoryAction(ExpenseCategory));
			history.push("/expense-category-lists");
		});
	};
}
