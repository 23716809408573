import {
	getMaintenanceMasterDetails,
	getMaintenanceMasters,
	createMaintenanceMaster,
	updateMaintenanceMaster,
	deleteMaintenanceMaster,
} from "../../services/MaintenanceMasterService";
import {
	CONFIRMED_GET_MAINTENANCEMASTER_DETAILS,
	CONFIRMED_CREATE_MAINTENANCEMASTER_ACTION,
	CONFIRMED_GET_MAINTENANCEMASTERS,
	CONFIRMED_EDIT_MAINTENANCEMASTER_ACTION,
	CONFIRMED_DELETE_MAINTENANCEMASTER_ACTION,
	LOADING,
} from "./MaintenanceMasterTypes";

export function getMaintenanceMasterAction(masterId) {
	return (dispatch, getState) => {
		getMaintenanceMasterDetails(masterId).then((response) => {
			dispatch(
				confirmedGetMaintenanceMasterAction(response.data.maintenance[0])
			);
		});
	};
}

export function confirmedGetMaintenanceMasterAction(master) {
	return {
		type: CONFIRMED_GET_MAINTENANCEMASTER_DETAILS,
		payload: master,
	};
}

export function deleteMaintenanceMasterAction(masterId, history) {
	return (dispatch, getState) => {
		deleteMaintenanceMaster(masterId).then((response) => {
			dispatch(confirmedDeleteMaintenanceMasterAction(masterId));
			history.push("/maintenance-information");
		});
	};
}

export function confirmedDeleteMaintenanceMasterAction(MasterId) {
	return {
		type: CONFIRMED_DELETE_MAINTENANCEMASTER_ACTION,
		payload: MasterId,
	};
}

export function createMaintenanceMasterAction(MasterData, history) {
	return (dispatch, getState) => {
		createMaintenanceMaster(MasterData).then((response) => {
			const singleMaintenanceMaster = {
				...MasterData,
				id: response.data._id,
			};
			dispatch(confirmedCreateMaintenanceMasterAction(singleMaintenanceMaster));
			history.push("/maintenance-information");
		});
	};
}

export function getMaintenanceMastersAction() {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getMaintenanceMasters().then((response) => {
			dispatch(confirmedGetMaintenanceMastersAction(response.data));
		});
	};
}

export function confirmedCreateMaintenanceMasterAction(
	singleMaintenanceMaster
) {
	return {
		type: CONFIRMED_CREATE_MAINTENANCEMASTER_ACTION,
		payload: singleMaintenanceMaster,
	};
}

export function confirmedGetMaintenanceMastersAction(MaintenanceMasters) {
	return {
		type: CONFIRMED_GET_MAINTENANCEMASTERS,
		payload: MaintenanceMasters,
	};
}

export function confirmedUpdateMaintenanceMasterAction(MaintenanceMaster) {
	return {
		type: CONFIRMED_EDIT_MAINTENANCEMASTER_ACTION,
		payload: MaintenanceMaster,
	};
}

export function updateMaintenanceMasterAction(
	MaintenanceMaster,
	history,
	masterId
) {
	return (dispatch, getState) => {
		updateMaintenanceMaster(MaintenanceMaster, masterId).then((reponse) => {
			dispatch(confirmedUpdateMaintenanceMasterAction(MaintenanceMaster));
			history.push("/maintenance-information");
		});
	};
}
