import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getAmenityMasterAction,
	createAmenityMasterAction,
	updateAmenityMasterAction,
} from "../../../../store/actions/AmenitiesMasterActions";

class AddEditAmenity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			amenity: {},
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (id) {
			if (this.props.amenity) {
				this.props.getAmenityMasterAction(id); //Local
			}
		} else {
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.amenity?.length !== this.props?.amenity?.length) {
			this.setState({
				amenity: this.props?.amenity,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateAmenityMasterAction(
				values,
				this.props.history,
				this.props?.amenity?._id
			);
		} else {
			this.props.createAmenityMasterAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var amenity = this.props?.amenity;
		} else {
			amenity = "";
		}

		const initialValues = {
			name: amenity ? amenity.name : "",
			icon: amenity ? amenity.icon : "",
			webIcon: amenity ? amenity.webIcon : "",
			status: amenity ? amenity.status : true,
		};

		const validationSchema = Yup.object().shape({
			name: Yup.string().required("Please Enter Name."),
			//icon: Yup.string().required("Please provide a Icon"),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/amenity-lists" }} linkAs={Link}>
						Amenity Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Amenity</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Amenity</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Amenity</h3>
						) : (
							<h3 className="pt-3">Add Amenity</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/amenity-lists"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Name *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-name"
																	placeholder="Enter a Name.."
																	name="name"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.name || ""}
																/>
																{errors.name && touched.name ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.name}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Icon </label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-icon"
																	placeholder="Enter a Icon.."
																	name="icon"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.icon || ""}
																/>
																{errors.icon && touched.icon ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.icon}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Web Icon </label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-webIcon"
																	placeholder="Enter a Web Icon.."
																	name="webIcon"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.webIcon || ""}
																/>
																{errors.webIcon && touched.webIcon ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.webIcon}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddAmenity;
const mapStateToProps = (state) => {
	return {
		amenity: state.amenity?.amenitymaster,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getAmenityMasterAction,
			createAmenityMasterAction,
			updateAmenityMasterAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAmenity);
