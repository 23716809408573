import axiosInstance from "../services/AxiosInstance";

export function getMeetingDetails(meetingId) {
	return axiosInstance.get(`admin/meeting/${meetingId}`);
}

export function getMeetings(societyId) {
	return axiosInstance.get(`admin/meetings/${societyId}`);
}

export function createMeeting(meetingData) {
	return axiosInstance.post(`admin/meeting`, meetingData);
}

export function updateMeeting(meeting, meetingId) {
	return axiosInstance.put(`admin/meeting/${meetingId}`, meeting);
}

export function deleteMeeting(meetingId) {
	return axiosInstance.delete(`admin/meeting/${meetingId}`);
}
