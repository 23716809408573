import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { format } from "date-fns";
import swal from "sweetalert";

import {
	getAmcsAction,
	deleteAmcAction,
} from "../../../../store/actions/AmcActions";

class Amc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentAmcs: [],
			currentPage: null,
			totalPages: null,
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));

		this.props.getAmcsAction(userData.user.societyName);
		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.amcs?.amcs?.length !== this.props?.amcs?.amcs?.length) {
			this.setState({
				currentAmcs: this.props?.amcs?.amcs,
			});
		}
	}

	initData() {
		let currentAmcs =
			this.state?.currentAmcs?.length > 0
				? this.state?.currentAmcs
				: this.props?.amcs?.amcs;

		this.setState({ currentAmcs });
	}

	onPageChanged = (data) => {
		const allAmcs = this.props?.amcs?.amcs;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentAmcs = allAmcs?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentAmcs, totalPages });
	};

	handleSearch(event) {
		let currentAmcs = this.props.amcs?.amcs;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentAmcs = currentAmcs.filter(
				(e) =>
					e.vendorType.toLowerCase().match(searchString) ||
					e.vendorName.toLowerCase().match(searchString) ||
					e.vendorEmail.toLowerCase().match(searchString) ||
					e.vendorMobile.toLowerCase().match(searchString)
			);
			this.setState({ currentAmcs });
		} else {
			let arrayLength = currentAmcs.slice(0, 5);
			this.setState({ currentAmcs: arrayLength });
		}
		//this.setState({ currentAmcs });
	}

	deleteRecord(id) {
		//this.props.deleteAmcAction(id, this.props.history);
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteAmcAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		let totalAmcs = this.props?.amcs?.amcs?.length || 0;

		const { currentAmcs, currentPage, totalPages } = this.state;
		this.state.currentAmcs = this.props.amcs?.amcs;

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>AMC Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Amc Lists</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-amc"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add AMC
									</Link>
								</div>
							</div>

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Vendor Type</th>
											<th>Vendor Name</th>
											<th>Vendor Mobile</th>
											<th>Vendor Email</th>
											<th>Vendor Address</th>
											<th>AMC Description</th>
											<th>AMC Start Date</th>
											<th>AMC End Date</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentAmcs?.length
											? currentAmcs.map((vendor, i) => (
													<tr key={i}>
														<td>{vendor.vendorType} </td>
														<td>{vendor.vendorName}</td>
														<td>{vendor.vendorMobile}</td>
														<td>{vendor.vendorEmail}</td>
														<td>{vendor.vendorAddress}</td>
														<td>{vendor.amcDescription}</td>
														<td>
															{vendor.amcStartDate &&
																format(
																	new Date(vendor?.amcStartDate),
																	"dd-MM-yyyy"
																)}
														</td>
														<td>
															{vendor.amcEndDate &&
																format(
																	new Date(vendor?.amcEndDate),
																	"dd-MM-yyyy"
																)}
														</td>
														<td>
															<div className="d-flex">
																<Link
																	to={`add-amc/${vendor._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link>
																<Link
																	to={`amc-details/${vendor._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
																<Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={this.deleteRecord.bind(
																		this,
																		vendor._id
																	)}
																>
																	<i className="fa fa-trash"></i>
																</Link>
															</div>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalAmcs ? (
										<Pagination
											totalRecords={totalAmcs}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		amcs: state.amc?.amcs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getAmcsAction, deleteAmcAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Amc);
