import {
	getAdminUserDetails,
	getAdminUsers,
	createAdminUser,
	updateAdminUser,
	deleteAdminUser,
	updateAdminPassword,
} from "../../services/AdminUserService";
import {
	CONFIRMED_GET_ADMINUSER_DETAILS,
	CONFIRMED_CREATE_ADMINUSER_ACTION,
	CONFIRMED_GET_ADMINUSERS,
	CONFIRMED_EDIT_ADMINUSER_ACTION,
	CONFIRMED_DELETE_ADMINUSER_ACTION,
	CONFIRMED_EDIT_ADMINUSERPASSWORD_ACTION,
	LOADING,
} from "./AdminUserTypes";

import { toast } from "react-toastify"; //ToastContainer
import "react-toastify/dist/ReactToastify.css";

export function getAdminUserAction(adminId) {
	return (dispatch, getState) => {
		getAdminUserDetails(adminId).then((response) => {
			dispatch(confirmedGetAdminUserAction(response.data.adminUser));
		});
	};
}

export function confirmedGetAdminUserAction(adminUser) {
	return {
		type: CONFIRMED_GET_ADMINUSER_DETAILS,
		payload: adminUser,
	};
}

export function deleteAdminUserAction(adminId, history) {
	return (dispatch, getState) => {
		deleteAdminUser(adminId).then((response) => {
			dispatch(confirmedDeleteAdminUserAction(adminId));
			history.push("/superadmin/admin");
		});
	};
}

export function confirmedDeleteAdminUserAction(adminId) {
	return {
		type: CONFIRMED_DELETE_ADMINUSER_ACTION,
		payload: adminId,
	};
}

export function createAdminUserAction(AdminUserData, history) {
	return (dispatch, getState) => {
		createAdminUser(AdminUserData).then((response) => {
			const singleAdminUser = {
				...AdminUserData,
				id: response.data._id,
			};
			dispatch(confirmedCreateAdminUserAction(singleAdminUser));
			history.push("/superadmin/admin");
		});
	};
}

export function getAdminUsersAction() {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getAdminUsers().then((response) => {
			dispatch(confirmedGetAdminUsersAction(response.data));
		});
	};
}

export function confirmedCreateAdminUserAction(singleAdminUser) {
	return {
		type: CONFIRMED_CREATE_ADMINUSER_ACTION,
		payload: singleAdminUser,
	};
}

export function confirmedGetAdminUsersAction(AdminUsers) {
	return {
		type: CONFIRMED_GET_ADMINUSERS,
		payload: AdminUsers,
	};
}

export function confirmedUpdateAdminUserAction(AdminUser) {
	return {
		type: CONFIRMED_EDIT_ADMINUSER_ACTION,
		payload: AdminUser,
	};
}

export function updateAdminUserAction(AdminUser, history, adminId) {
	return (dispatch, getState) => {
		updateAdminUser(AdminUser, adminId).then((reponse) => {
			dispatch(confirmedUpdateAdminUserAction(AdminUser));
			history.push("/superadmin/admin");
		});
	};
}

export function confirmedUpdateAdminPasswordAction(AdminUser) {
	return {
		type: CONFIRMED_EDIT_ADMINUSERPASSWORD_ACTION,
		payload: AdminUser,
	};
}

export function updateAdminPasswordAction(AdminUser, history, adminId) {
	return (dispatch, getState) => {
		updateAdminPassword(AdminUser, adminId)
			.then((response) => {
				dispatch(confirmedUpdateAdminPasswordAction(AdminUser));
				if (response.status === 200) {
					toast.success("Password is changed successfully!");
					history.push("/login");
					localStorage.removeItem("userDetails");
					window.location.reload();
				} else {
					toast.error(response.errMessage);
				}
			})
			.catch((error) => {
				console.log(error.response);
				toast.error(error?.response?.data?.errMessage);
			});
	};
}
