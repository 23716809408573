import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import axiosInstance from "../../../../services/AxiosInstance";

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			profile: {},
			societyId: "",
			userRole: "",
			id: "",
		};
	}

	componentDidMount() {
		this.getProfile();
	}

	async getProfile() {
		const { data } = await axiosInstance.get(`me`).then((result) => result);

		this.setState({
			profile: data?.user,
		});
	}

	render() {
		const profileData = this.state.profile;

		const name = profileData?.name || "";
		const mobile = profileData?.mobile || "";
		const email = profileData?.email || "";
		const address = profileData?.address || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>My Profile</Breadcrumb.Item>
				</Breadcrumb>

				<div className="row">
					<div className="col-xl-4 col-sm-12">
						<h2 className="card-title">My Profile</h2>
					</div>
					<div className="col-xl-8 col-sm-12">
						<Link
							to={"edit-userprofile"}
							className="btn btn-primary mb-2 pull-right"
						>
							Edit
						</Link>
					</div>
					<div className="col-lg-12">
						<div className="profile card card-body px-3 p-5">
							<div className="profile-personal-info">
								<h4
									className="text-primary mb-4"
									style={{ textDecoration: "underline" }}
								>
									Personal Information:{" "}
								</h4>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">
											{" "}
											Name<span className="pull-right">:</span>
										</h5>
									</div>
									<div className="col-9">
										<span>{name}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">
											Email<span className="pull-right">:</span>
										</h5>
									</div>
									<div className="col-9">
										<span>{email}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">
											Mobile<span className="pull-right">:</span>
										</h5>
									</div>
									<div className="col-9">
										<span>{mobile}</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">
											Address<span className="pull-right">:</span>
										</h5>
									</div>
									<div className="col-9">
										<span>{address}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Profile;
