import { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getSocietyAction } from "../../../store/actions/SocietyActions";

import { format } from "date-fns";

class SocietyInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			societyId: "",
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		this.props.getSocietyAction(userData.user.societyName);

		// if (this.props.society) {
		// 	//this.props.getSocietyAction("614867364cb64178e46f26d5"); //Local
		// 	this.props.getSocietyAction("6154745b4879d03cc65a584d"); // Dev Society Id
		// }
	}

	render() {
		const society = this.props.society.society;

		const societyName = society?.societyName || "";
		const societyAddress = society?.societyAddress || "";
		const societyUrl = society?.societyUrl || "";
		const societySubscriptionStartDate =
			(society?.societySubscriptionStartDate &&
				format(
					new Date(society?.societySubscriptionStartDate),
					"dd-MM-yyyy"
				)) ||
			"";
		const societySubscriptionEndDate =
			(society?.societySubscriptionEndDate &&
				format(new Date(society?.societySubscriptionEndDate), "dd-MM-yyyy")) ||
			"";
		const societyMembersCount = society?.societyMembersCount || "";
		const societyParkingCount = society?.societyParkingCount || "";
		const societyBillDueDateinDays = society?.societyBillDueDateinDays || "";
		const societyDefaulterBaseLineAmount =
			society?.societyDefaulterBaseLineAmount || "";
		const amenties = society?.amenties || [];

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Society Information</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Society Information</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"add-society-information"}
							className="btn btn-primary mb-2 pull-right"
						>
							Edit
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Name</strong>
										<span className="mb-0">{societyName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Address</strong>
										<span className="mb-0 lineBreak">{societyAddress}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Url</strong>
										<span className="mb-0">{societyUrl}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Subscription Start Date</strong>
										<span className="mb-0">{societySubscriptionStartDate}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Subscription End Date</strong>
										<span className="mb-0">{societySubscriptionEndDate}</span>
									</li>
								</ul>
							</div>
							<div className="card-footer pt-0 pb-0 text-center">
								<div className="row">
									<div className="col-3 pt-3 pb-3 border-right">
										<h3 className="mb-1 text-primary">{societyMembersCount}</h3>
										<span>Members</span>
									</div>
									<div className="col-3 pt-3 pb-3 border-right">
										<h3 className="mb-1 text-primary">{societyParkingCount}</h3>
										<span>Parking</span>
									</div>
									<div className="col-3 pt-3 pb-3">
										<h3 className="mb-1 text-primary">
											{societyBillDueDateinDays}
										</h3>
										<span>Bill Due Date</span>
									</div>
									<div className="col-3 pt-3 pb-3">
										<h3 className="mb-1 text-primary">
											{societyDefaulterBaseLineAmount}
										</h3>
										<span>Default baseline Amount</span>
									</div>
								</div>
								<hr />
								<div className="row p-3">
									<h3>Society Amenties</h3>

									<Table responsive striped bordered hover>
										<tbody>
											{amenties.length > 0 ? (
												amenties
													.filter((i) => i.status === true)
													.map((item, index) => (
														<tr key={index}>
															<td>
																<div className="d-flex align-items-center">
																	{item.status === true ? (
																		<i className="fa fa-check-square text-success mr-1"></i>
																	) : (
																		<i className="fa fa-square mr-1"></i>
																	)}
																</div>
															</td>
															<td style={{ textAlign: "left" }}>
																<i className={`${item.webIcon} pr-2`}></i>
																{item.name}
															</td>
														</tr>
													))
											) : (
												<tr>
													<td>No Data</td>
												</tr>
											)}
										</tbody>
									</Table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		society: state.society.society,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getSocietyAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SocietyInformation);
