import {
	CONFIRMED_GET_BILLING_DETAILS,
	CONFIRMED_CREATE_BILLING_ACTION,
	CONFIRMED_GET_BILLINGS,
	CONFIRMED_EDIT_BILLING_ACTION,
	CONFIRMED_DELETE_BILLING_ACTION,
	CONFIRMED_GET_BILLINGS_BY_FLAT,
	BILLINGS_LOADING,
} from "../actions/BillingTypes";

const initialState = {
	billings: [],
	billing: {},
};

export default function BillingReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_BILLING_DETAILS) {
		return {
			...state,
			billing: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_BILLING_ACTION) {
		const billings = [...state.billing.billings.billings];
		const billingIndex = billings.findIndex(
			(billing) => billing.id === actions.payload
		);

		billings.splice(billingIndex, 1);

		return {
			...state,
			billings,
		};
	}

	if (actions.type === CONFIRMED_EDIT_BILLING_ACTION) {
		return {
			...state,
			billings: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_BILLING_ACTION) {
		return {
			...state,
			billings: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_BILLINGS) {
		return {
			...state,
			billings: actions.payload,
			loading: false,
		};
	}

	if (actions.type === CONFIRMED_GET_BILLINGS_BY_FLAT) {
		return {
			...state,
			userbillings: actions.payload,
			loading: false,
		};
	}

	if (actions.type === BILLINGS_LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
