import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
//import { format } from "date-fns";
import swal from "sweetalert";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
	getBillingAdjustmentsAction,
	deleteBillingAdjustmentAction,
} from "../../../../store/actions/BillingAdjustmentActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class BillingAdjustment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentBillingAdjustments: [],
			currentPage: null,
			totalPages: null,
			selectYear: "2021",
			selectMonth: "Jan",
			societyId: "",
		};
		this.handleChangeYear = this.handleChangeYear.bind(this);
		this.handleChangeMonth = this.handleChangeMonth.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChangeYear(event) {
		this.setState({ selectYear: event.target.value });
	}

	handleChangeMonth(event) {
		this.setState({ selectMonth: event.target.value });
	}

	handleSubmit(event) {
		let filteredDate = this.state?.currentBillingAdjustments.filter(
			(item) =>
				item?.year === this.state?.selectYear &&
				item?.month === this.state?.selectMonth
		);
		this.setState({ currentBillingAdjustments: filteredDate });

		event.preventDefault();
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		this.props.getBillingAdjustmentsAction(userData.user.societyName);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.billingAdjustments?.billAdjustments?.length !==
			this.props?.billingAdjustments?.billAdjustments?.length
		) {
			this.setState({
				currentBillingAdjustments: this.props?.billingAdjustments
					?.billAdjustments,
			});
		}
	}

	initData() {
		let currentBillingAdjustments =
			this.state?.currentBillingAdjustments?.length > 0
				? this.state?.currentBillingAdjustments
				: this.props?.billingAdjustments?.billAdjustments;

		this.setState({ currentBillingAdjustments });
	}

	onPageChanged = (data) => {
		const allBillingAdjustments = this.props?.billingAdjustments
			?.billAdjustments;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentBillingAdjustments = allBillingAdjustments?.slice(
			offset,
			offset + pageLimit
		);

		this.setState({ currentPage, currentBillingAdjustments, totalPages });
	};

	handleSearch(event) {
		let currentBillingAdjustments = this.props.billingAdjustments
			?.billAdjustments;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentBillingAdjustments = currentBillingAdjustments?.filter(
				(e) =>
					e?.flatNo?.toLowerCase().match(searchString) ||
					e?.adjustmentType?.toLowerCase().match(searchString) ||
					e?.adjustmentDescription?.toLowerCase().match(searchString)
			);
			this.setState({ currentBillingAdjustments });
		} else {
			let arrayLength = currentBillingAdjustments?.slice(0, 5);
			this.setState({ currentBillingAdjustments: arrayLength });
		}
		//this.setState({ currentBillingAdjustments });
	}

	deleteRecord(id) {
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteBillingAdjustmentAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		let totalBillingAdjustments =
			this.props?.billingAdjustments?.billAdjustments?.length || 0;

		const { currentBillingAdjustments, currentPage, totalPages } = this.state;
		this.state.currentBillingAdjustments = this.props.billingAdjustments?.billAdjustments?.slice(
			0,
			5
		);

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Billing Adjustments</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Billing Adjustment</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-billing-adjustment"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Billing Adjustment
									</Link>
								</div>
							</div>
							<hr />
							{/* <form onSubmit={this.handleSubmit}>
							<div className="row">
								<div className="col-xl-3 col-sm-12 ml-4">
									<div className="form-group">
										<div>
											<select
												className="form-control"
												id="selectYear"
												placeholder="Select a Select Year.."
												name="selectYear"
												onChange={this.handleChangeYear}
												value={this.state.selectYear}
											>
												<option value="Select" label="Select" disabled />
												<option value="2020" label="2020" />
												<option value="2021" label="2021" />
											</select>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-12">
									<div className="form-group">
										<div>
											<select
												className="form-control"
												id="selectMonth"
												placeholder="Select a Select Month.."
												name="selectMonth"
												onChange={this.handleChangeMonth}
												value={this.state.selectMonth}
											>
												<option
													value="Select Month"
													label="Select Month"
													disabled
												/>
												<option value="Jan" label="Jan" />
												<option value="Feb" label="Feb" />
											</select>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-12">
									<button className="btn btn-primary">Show</button>
								</div>
							</div>
						</form> */}

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Flat No</th>
											<th>Adjustment Type</th>
											<th>Adjustment Description</th>
											<th>Amount</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentBillingAdjustments?.length
											? currentBillingAdjustments.map((adjustment, i) => (
													<tr key={i}>
														<td>{adjustment.flatNo} </td>
														<td>{adjustment.adjustmentType}</td>
														<td>{adjustment.adjustmentDescription}</td>
														<td>{adjustment.amount}</td>

														<td>
															<div className="d-flex">
																{/* <Link
																to={`add-billing-adjustment/${adjustment._id}`}
																className="btn btn-info shadow btn-xs sharp mr-1"
															>
																<i className="fa fa-pencil"></i>
															</Link> */}
																<Link
																	to={`billing-adjustment-details/${adjustment._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
																<Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={this.deleteRecord.bind(
																		this,
																		adjustment._id
																	)}
																>
																	<i className="fa fa-trash"></i>
																</Link>
															</div>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalBillingAdjustments ? (
										<Pagination
											totalRecords={totalBillingAdjustments}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		billingAdjustments: state.billingAdjustment?.billingAdjustments,
		loading: state?.billingAdjustment?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getBillingAdjustmentsAction, deleteBillingAdjustmentAction },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAdjustment);
