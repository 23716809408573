import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import swal from "sweetalert";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
	getSocietyAction,
	deleteSocietyImageAction,
} from "../../../../store/actions/SocietyActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class SocietyImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			allSocietyImages: [],
			currentImages: [],
			currentPage: null,
			totalPages: null,
			images: [],
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		this.props.getSocietyAction(userData.user.societyName);
		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.images?.society?.societyImages?.length !==
			this.props?.images?.society?.societyImages?.length
		) {
			this.setState({
				currentImages: this.props?.images?.society?.societyImages,
			});
		}
	}

	initData() {
		this.state.images = this.props?.images?.society?.societyImages;

		this.state.currentImages =
			this.state?.currentImages?.length > 0
				? this.state?.currentImages
				: this.state.images;

		this.state.totalImages = this.state.images?.length;

		if (this.state.totalImages === 0) return null;

		let searchString = this.state.search.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			//If page wise search we want then replace images with currentImages
			this.state.currentImages = this.state.images.filter((e) =>
				e.title.toLowerCase().match(searchString)
			);
		}
	}

	onPageChanged = (data) => {
		const allSocietyImages = this.props?.images?.society?.societyImages;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentImages = allSocietyImages?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentImages, totalPages });
	};

	deleteRecord(id) {
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteSocietyImageAction(
					this.state.societyId,
					id,
					this.props.history
				);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		const { currentImages, currentPage, totalPages, totalImages } = this.state;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Society Image</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Society Image Lists</h2>
								</div>

								<div className="col-xl-4 col-sm-12"></div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-society-image"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Society Image
									</Link>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Image</th>
											<th>Image Title</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentImages &&
											currentImages.map((image, i) => (
												<tr key={i}>
													<td>
														<img
															src={image.fileurl}
															height="80"
															width="100"
															alt={image.name}
														/>
													</td>
													<td>
														{image.title
															.split(".")
															.slice(0, -1)
															.join(".")}
													</td>
													<td>
														<div className="d-flex">
															<Link
																to="#"
																className="btn btn-danger shadow btn-xs sharp"
																onClick={this.deleteRecord.bind(
																	this,
																	image._id
																)}
															>
																<i className="fa fa-trash"></i>
															</Link>
														</div>
													</td>
												</tr>
											))}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalImages ? (
										<Pagination
											totalRecords={totalImages}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		images: state.society?.society,
		loading: state.society?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getSocietyAction, deleteSocietyImageAction },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(SocietyImage);
