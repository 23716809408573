import {
	CONFIRMED_GET_EXPENSE_DETAILS,
	CONFIRMED_CREATE_EXPENSE_ACTION,
	CONFIRMED_GET_EXPENSES,
	CONFIRMED_EDIT_EXPENSE_ACTION,
	CONFIRMED_DELETE_EXPENSE_ACTION,
} from "../actions/ExpenseTypes";

const initialState = {
	expenses: [],
	expense: {},
};

export default function ExpenseReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_EXPENSE_DETAILS) {
		return {
			...state,
			expense: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_EXPENSE_ACTION) {
		const expenses = [...state.expenses.expenses];
		const expenseIndex = expenses.findIndex(
			(expense) => expense.id === actions.payload
		);

		expenses.splice(expenseIndex, 1);

		return {
			...state,
			expenses: { expenses },
		};
	}

	if (actions.type === CONFIRMED_EDIT_EXPENSE_ACTION) {
		return {
			...state,
			expenses: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_EXPENSE_ACTION) {
		return {
			...state,
			expenses: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_EXPENSES) {
		return {
			...state,
			expenses: actions.payload,
		};
	}

	return state;
}
