export const GET_MAINTENANCE_DETAILS = "[Maintenance Action] Get Maintenance";
export const CONFIRMED_GET_MAINTENANCE_DETAILS =
	"[Maintenance Action] Confirmed Get Maintenance";
export const CREATE_MAINTENANCE_ACTION =
	"[Maintenance Action] Create Maintenance";
export const CONFIRMED_CREATE_MAINTENANCE_ACTION =
	"[Maintenance Action] Confirmed Create Maintenance";
export const GET_MAINTENANCES = "[Maintenance Action] Get Maintenances";
export const CONFIRMED_GET_MAINTENANCES =
	"[Maintenance Action] Confirmed Get Maintenances";
export const EDIT_MAINTENANCE_ACTION = "[Maintenance Action] Edit Maintenance";
export const CONFIRMED_EDIT_MAINTENANCE_ACTION =
	"[Maintenance Action] Confirmed Edit Maintenance";
export const CONFIRMED_DELETE_MAINTENANCE_ACTION =
	"[Maintenance Action] Confirmed Delete Maintenance";
export const LOADING =
	"[Maintenance Loading Action] Confirmed Maintenance Loading";
