export const GET_BILLING_DETAILS = "[Billing Action] Get Billing";
export const CONFIRMED_GET_BILLING_DETAILS =
	"[Billing Action] Confirmed Get Billing";
export const CREATE_BILLING_ACTION = "[Billing Action] Create Billing";
export const CONFIRMED_CREATE_BILLING_ACTION =
	"[Billing Action] Confirmed Create Billing";
export const GET_BILLINGS = "[Billings Action] Get Billings";
export const CONFIRMED_GET_BILLINGS =
	"[Billings Action] Confirmed Get Billings";
export const EDIT_BILLING_ACTION = "[Billing Action] Edit Billing";
export const CONFIRMED_EDIT_BILLING_ACTION =
	"[Billing Action] Confirmed Edit Billing";
export const CONFIRMED_DELETE_BILLING_ACTION =
	"[Billing Action] Confirmed Delete Billing";
export const GET_BILLINGS_BY_FLAT =
	"[Billings By Flat Action] Get Billings By Flat";
export const CONFIRMED_GET_BILLINGS_BY_FLAT =
	"[Billings By Flat Action] Confirmed Get Billings By Flat";
export const BILLINGS_LOADING = "[Billings Loader] Billings Loader";
