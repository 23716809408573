import {
	CONFIRMED_GET_FLAT_DETAILS,
	CONFIRMED_CREATE_FLAT_ACTION,
	CONFIRMED_GET_FLATS,
	CONFIRMED_EDIT_FLAT_ACTION,
	CONFIRMED_DELETE_FLAT_ACTION,
	CONFIRMED_GET_FLAT_SOCIETY_USER_DETAILS,
	CONFIRMED_EDIT_FLAT_ASSOCIATION_ACTION,
	CONFIRMED_UPLOAD_FLAT_ACTION,
	LOADING,
} from "../actions/FlatManagementTypes";

const initialState = {
	flats: [],
	flat: {},
};

export default function FlatReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_FLAT_DETAILS) {
		return {
			...state,
			flat: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_FLAT_ACTION) {
		const flats = [...state.flats.flats];
		const flatIndex = flats.findIndex((flat) => flat.id === actions.payload);

		flats.splice(flatIndex, 1);

		return {
			...state,
			flats: { flats },
		};
	}

	if (actions.type === CONFIRMED_EDIT_FLAT_ACTION) {
		const flats = [...state.flats?.flats];
		const flatIndex = flats.findIndex((flat) => flat.id === actions.payload.id);

		flats[flatIndex] = actions.payload;
		return {
			...state,
			flats,
		};
	}

	if (actions.type === CONFIRMED_CREATE_FLAT_ACTION) {
		return {
			...state,
			flats: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_FLATS) {
		return {
			...state,
			flats: actions.payload,
			loading: false,
		};
	}

	if (actions.type === CONFIRMED_GET_FLAT_SOCIETY_USER_DETAILS) {
		return {
			...state,
			flat: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_EDIT_FLAT_ASSOCIATION_ACTION) {
		const flats = [...state?.flats?.flats];
		// const flatIndex = flats.findIndex((flat) => flat.id === actions.payload.id);

		// flats[flatIndex] = actions.payload;
		return {
			...state,
			flats: { flats },
		};
	}

	if (actions.type === CONFIRMED_UPLOAD_FLAT_ACTION) {
		return {
			...state,
			flats: actions.payload,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
