import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { format } from "date-fns";

import { getSocietyAction } from "../../../../../store/actions/SocietyActions";

class SocietyDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			society: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.society) {
			this.props.getSocietyAction(id);
		}
		this.init();
	}

	init() {
		this.state.society = this.props?.society?.society;
	}

	render() {
		this.state.society = this.props?.society?.society;

		const societyName = this.state.society?.societyName || "";
		const societyUrl = this.state.society?.societyUrl || "";
		const societyAddress = this.state.society?.societyAddress || "";
		const societyMembersCount = this.state.society?.societyMembersCount || "";
		const societyActivationYear =
			this.state.society?.societyActivationYear || "";
		const societyActivationMonth =
			this.state.society?.societyActivationMonth || "";
		const societySubscriptionStartDate =
			this.state.society?.societySubscriptionStartDate || "";
		const societySubscriptionEndDate =
			this.state.society?.societySubscriptionEndDate || "";
		const status = this.state.society?.status || "";

		return (
			<>
				<Breadcrumb>
					{/* <Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "} */}
					<Breadcrumb.Item
						linkProps={{ to: "/superadmin/societies" }}
						linkAs={Link}
					>
						Society Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Society Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Society Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/superadmin/societies"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Name</strong>
										<span className="mb-0">{societyName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society URL</strong>
										<span className="mb-0">{societyUrl}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Address</strong>
										<span className="mb-0">{societyAddress}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Members Count</strong>
										<span className="mb-0">{societyMembersCount}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Activation Year</strong>
										<span className="mb-0">{societyActivationYear}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Activation Month</strong>
										<span className="mb-0">{societyActivationMonth}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Subscription Start Date</strong>
										<span className="mb-0">
											{societySubscriptionStartDate &&
												format(
													new Date(societySubscriptionStartDate),
													"dd-MM-yyyy"
												)}
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Subscription End Date</strong>
										<span className="mb-0">
											{societySubscriptionEndDate &&
												format(
													new Date(societySubscriptionEndDate),
													"dd-MM-yyyy"
												)}
										</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		society: state.society?.society,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getSocietyAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SocietyDetail);
