export const GET_POLLING_DETAILS = "[Polling Action] Get Polling";
export const CONFIRMED_GET_POLLING_DETAILS =
	"[Polling Action] Confirmed Get Polling";
export const CREATE_POLLING_ACTION = "[Polling Action] Create Polling";
export const CONFIRMED_CREATE_POLLING_ACTION =
	"[Polling Action] Confirmed Create Polling";
export const GET_POLLINGS = "[Pollings Action] Get Pollings";
export const CONFIRMED_GET_POLLINGS =
	"[Pollings Action] Confirmed Get Pollings";
export const EDIT_POLLING_ACTION = "[Polling Action] Edit Polling";
export const CONFIRMED_EDIT_POLLING_ACTION =
	"[Polling Action] Confirmed Edit Polling";
export const CONFIRMED_DELETE_POLLING_ACTION =
	"[Polling Action] Confirmed Delete Polling";
export const LOADING = "[Polling Loading Action] Confirmed Polling Loading";
