import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../services/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class RequestDemoModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
		};
		this.requestDemo = this.requestDemo.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		this.requestDemo();
	}

	requestDemo() {
		this.setState({ modal: !this.state.modal });
	}

	handleAdd = async (values) => {
		const resp = await axiosInstance
			.post(`admin/requestDemo`, values)
			.then((res) => res?.data)
			.catch((err) => {
				return err;
			});

		if (resp.success === true) {
			toast.success("Successfully added Request Demo!");
		} else {
			toast.error("Error Requesting Demo!");
		}

		// this.props.updateSocietyAction(
		// 	values,
		// 	this.props.history,
		// 	this.props?.society?._id
		// );
		this.requestDemo();
	};

	render() {
		const validationSchema = Yup.object().shape({
			name: Yup.string()
				.min(1, "Your username must consist of at least 1 characters ")
				.max(50, "Your username must consist of max 50 characters ")
				.required("Please enter a username"),
			mobile: Yup.string().required("Please provide a mobile"),
			email: Yup.string()
				.email("Enter Valid Email")
				.required("Please provide a email"),
			societyName: Yup.string()
				.min(1, "Your societyname must be at least 1 characters long")
				.max(50, "Your societyname must be max 50 characters long")
				.required("Please provide a societyname"),
			// societyAddress: Yup.string()
			// 	.min(1, "Your societyaddress must be at least 1 characters long")
			// 	.max(100, "Your societyaddress must be max 100 characters long")
			// 	.required("Please provide a societyaddress"),
		});

		return (
			<div>
				<button
					className="btn req-demo modal_request_demo_trigger"
					onClick={this.requestDemo}
					style={{ padding: "10px 20px", borderRadius: "10px" }}
				>
					Request Demo
				</button>
				<Modal show={this.state.modal} onHide={this.requestDemo}>
					<Modal.Header>
						<h3>Request a Demo</h3>
						<button
							type="button"
							className="close"
							aria-label="Close"
							onClick={this.requestDemo}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</Modal.Header>
					<Modal.Body className="p-1">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="form-validation">
											<Formik
												initialValues={{
													name: "",
													mobile: "",
													email: "",
													societyName: "",
													societyAddress: "",
												}}
												validationSchema={validationSchema}
												onSubmit={(values, { setSubmitting }) => {
													setTimeout(() => {
														this.handleAdd(values);
														setSubmitting(false);
													}, 400);
												}}
											>
												{({
													values,
													errors,
													handleChange,
													handleBlur,
													handleSubmit,
													isSubmitting,
												}) => (
													<form onSubmit={handleSubmit}>
														<div className="row">
															<div className="col-lg-6">
																<div
																	className={`form-group ${
																		values.name
																			? errors.name
																				? "is-invalid"
																				: "is-valid"
																			: ""
																	}`}
																>
																	<label className="text-label">
																		Full Name *
																	</label>
																	<div>
																		<input
																			type="text"
																			className="form-control"
																			id="val-name"
																			placeholder="Enter a name.."
																			name="name"
																			onChange={handleChange}
																			onBlur={handleBlur}
																			value={values.name}
																		/>
																		<div
																			id="val-name-error"
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.name && errors.name}
																		</div>

																		<div
																			id="val-name-error"
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		/>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div
																	className={`form-group ${
																		values.mobile
																			? errors.mobile
																				? "is-invalid"
																				: "is-valid"
																			: ""
																	}`}
																>
																	<label className="text-label">
																		Mobile no. *
																	</label>
																	<div>
																		<input
																			type="number"
																			className="form-control"
																			id="val-mobile"
																			name="mobile"
																			onChange={handleChange}
																			onBlur={handleBlur}
																			value={values.mobile}
																			placeholder="Enter mobile no."
																		/>

																		<div
																			id="val-mobile-error"
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.mobile && errors.mobile}
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-lg-6">
																<div
																	className={`form-group ${
																		values.email
																			? errors.email
																				? "is-invalid"
																				: "is-valid"
																			: ""
																	}`}
																>
																	<label className="text-label">Email *</label>
																	<div>
																		<input
																			type="text"
																			className="form-control"
																			id="val-email"
																			placeholder="Enter a Email.."
																			name="email"
																			onChange={handleChange}
																			onBlur={handleBlur}
																			value={values.email}
																		/>
																		<div
																			id="val-email-error"
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.email && errors.email}
																		</div>

																		<div
																			id="val-email-error"
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		/>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div
																	className={`form-group ${
																		values.societyName
																			? errors.societyName
																				? "is-invalid"
																				: "is-valid"
																			: ""
																	}`}
																>
																	<label className="text-label">
																		Society Name *
																	</label>
																	<div>
																		<input
																			type="text"
																			className="form-control"
																			id="val-societyName"
																			placeholder="Enter a society Name.."
																			name="societyName"
																			onChange={handleChange}
																			onBlur={handleBlur}
																			value={values.societyName}
																		/>
																		<div
																			id="val-societyName-error"
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.societyName && errors.societyName}
																		</div>

																		<div
																			id="val-societyName-error"
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		/>
																	</div>
																</div>
															</div>
														</div>
														<div
															className={`form-group ${
																values.societyAddress
																	? errors.societyAddress
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Society Address
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-societyAddress"
																	placeholder="Enter a society Address.."
																	name="societyAddress"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyAddress}
																/>
																<div
																	id="val-societyAddress-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.societyAddress &&
																		errors.societyAddress}
																</div>

																<div
																	id="val-societyAddress-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>

														<button
															type="submit"
															className="btn mr-2 btn-primary"
															disabled={isSubmitting}
														>
															Send Request
														</button>
													</form>
												)}
											</Formik>
										</div>
									</div>
								</div>
							</div>
							<ToastContainer
								position="top-right"
								autoClose={3000}
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
							/>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
export default RequestDemoModal;
