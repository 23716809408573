import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
//import { format } from "date-fns";

import { getBillingTemplatesAction } from "../../../../store/actions/BillingTemplateActions";

class BillingTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentTemplates: [],
			currentPage: null,
			totalPages: null,
			selectYear: "2021",
			selectMonth: "Jan",
			societyId: "",
		};
		this.handleChangeYear = this.handleChangeYear.bind(this);
		this.handleChangeMonth = this.handleChangeMonth.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChangeYear(event) {
		this.setState({ selectYear: event.target.value });
	}

	handleChangeMonth(event) {
		this.setState({ selectMonth: event.target.value });
	}

	handleSubmit(event) {
		let filteredDate = this.state?.currentTemplates.filter(
			(item) =>
				item?.year === this.state?.selectYear &&
				item?.month === this.state?.selectMonth
		);
		this.setState({ currentTemplates: filteredDate });

		event.preventDefault();
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		this.props.getBillingTemplatesAction(userData.user.societyName);
		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.templates?.templates?.length !==
			this.props?.templates?.templates?.length
		) {
			this.setState({
				currentTemplates: this.props?.templates?.templates,
			});
		}
	}

	initData() {
		let currentTemplates =
			this.state?.currentTemplates?.length > 0
				? this.state?.currentTemplates
				: this.props?.templates?.templates;

		this.setState({ currentTemplates });
	}

	onPageChanged = (data) => {
		const allTemplates = this.props?.templates?.templates;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentTemplates = allTemplates?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentTemplates, totalPages });
	};

	handleSearch(event) {
		let currentTemplates = this.props.templates?.templates;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentTemplates = currentTemplates.filter(
				(e) =>
					e.templateType.toLowerCase().match(searchString) ||
					e.templateSubType.toLowerCase().match(searchString)
			);
		}
		this.setState({ currentTemplates });
	}

	render() {
		let totalTemplates = this.props?.templates?.templates?.length || 0;

		const { currentTemplates, currentPage, totalPages } = this.state;
		this.state.currentTemplates = this.props.templates?.templates;

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Billing Template</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Billing Template Details</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									{/* <input
									type="text"
									className="form-control mb-2 search-list pull-right"
									placeholder="Search..."
									onChange={(e) => this.handleSearch(e)}
								/> */}
								</div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-billing-template"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Billing Template
									</Link>
								</div>
							</div>

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Template Type</th>
											<th>Template Sub Type</th>
											<th>Template</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentTemplates?.length
											? currentTemplates.map((template, i) => (
													<tr key={i}>
														<td>{template.templateType} </td>
														<td>{template.templateSubType}</td>
														<td>{template.template}</td>

														<td>
															<div className="d-flex">
																<Link
																	to={`add-billing-template/${template._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link>
																<Link
																	to={`billing-template-details/${template._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
																<Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																>
																	<i className="fa fa-trash"></i>
																</Link>
															</div>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalTemplates ? (
										<Pagination
											totalRecords={totalTemplates}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		templates: state.template?.templates,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getBillingTemplatesAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingTemplate);
