import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getExpenseCategoryAction,
	createExpenseCategoryAction,
	updateExpenseCategoryAction,
} from "../../../../store/actions/ExpenseCategoryActions";

class AddEditExpenseCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expenseCategory: {},
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.expenseCategory) {
				this.props.getExpenseCategoryAction(id);
			}
		} else {
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.expenseCategory?.length !== this.props?.expenseCategory?.length
		) {
			this.setState({
				expenseCategory: this.props?.expenseCategory,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateExpenseCategoryAction(
				values,
				this.props.history,
				this.props?.expenseCategory?._id
			);
		} else {
			this.props.createExpenseCategoryAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var expenseCategory = this.props?.expenseCategory;
		} else {
			expenseCategory = "";
		}

		const initialValues = {
			societyId: this.state.societyId,
			categoryName: expenseCategory ? expenseCategory.categoryName : "",
		};

		const validationSchema = Yup.object().shape({
			categoryName: Yup.string().required("Please Enter Name."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item
						linkProps={{ to: "/expense-category-lists" }}
						linkAs={Link}
					>
						Expense Category Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Expense Category</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Expense Category</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Expense Category</h3>
						) : (
							<h3 className="pt-3">Add Expense Category</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/expense-category-lists"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Category Name *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-categoryName"
																	placeholder="Enter a Name.."
																	name="categoryName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.categoryName || ""}
																/>
																{errors.categoryName && touched.categoryName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.categoryName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												{/* <div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div> */}

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddExpenseCategory;
const mapStateToProps = (state) => {
	return {
		expenseCategory: state.expenseCategory?.expenseCategory,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getExpenseCategoryAction,
			createExpenseCategoryAction,
			updateExpenseCategoryAction,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddEditExpenseCategory);
