import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb, Table } from "react-bootstrap";
import { format } from "date-fns";

import { getPollingAction } from "../../../../store/actions/PollingActions";

class PollingDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			polling: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.polling) {
			this.props.getPollingAction(id);
		}
		this.init();
	}

	init() {
		this.state.polling = this.props?.polling;
	}

	render() {
		this.state.polling = this.props?.polling;

		const pollDescription = this.state.polling?.pollDescription || "";
		const pollStartDate = this.state.polling?.pollStartDate || "";
		const pollEndDate = this.state.polling?.pollEndDate || "";
		const pollOptions = this.state.polling?.pollOptions || "";
		const status = this.state.polling?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/pollings" }} linkAs={Link}>
						Polling Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Polling Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Polling Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/pollings"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<h4
									className="pt-3 pb-3"
									style={{ textDecoration: "underline" }}
								>
									Polling Results:{" "}
								</h4>
								<div className="row">
									<Table bordered striped responsive>
										<thead>
											<tr>
												<th>Options</th>
												<th>Votes</th>
											</tr>
										</thead>
										<tbody>
											{pollOptions?.length
												? pollOptions.map((opt, i) => (
														<tr key={i}>
															<td>{opt.option} </td>
															<td>{opt.votes}</td>
														</tr>
												  ))
												: null}
										</tbody>
									</Table>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Polling Description</strong>
										<span className="mb-0">{pollDescription}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Polling Start Date</strong>
										<span className="mb-0">
											{pollStartDate &&
												format(new Date(pollStartDate), "dd-MM-yyyy")}
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Polling End Date</strong>
										<span className="mb-0">
											{pollEndDate &&
												format(new Date(pollEndDate), "dd-MM-yyyy")}
										</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		polling: state.polling?.polling,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getPollingAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PollingDetail);
