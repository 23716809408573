export const GET_VENDOR_DETAILS = "[Vendor Action] Get Vendor";
export const CONFIRMED_GET_VENDOR_DETAILS =
	"[Vendor Action] Confirmed Get Vendor";
export const CREATE_VENDOR_ACTION = "[Vendor Action] Create Vendor";
export const CONFIRMED_CREATE_VENDOR_ACTION =
	"[Vendor Action] Confirmed Create Vendor";
export const GET_VENDORS = "[Vendors Action] Get Vendors";
export const CONFIRMED_GET_VENDORS = "[Vendors Action] Confirmed Get Vendors";
export const EDIT_VENDOR_ACTION = "[Vendor Action] Edit Vendor";
export const CONFIRMED_EDIT_VENDOR_ACTION =
	"[Vendor Action] Confirmed Edit Vendor";
export const CONFIRMED_DELETE_VENDOR_ACTION =
	"[Vendor Action] Confirmed Delete Vendor";
export const LOADING = "[Vendor Loading Action] Confirmed Vendor Loading";
