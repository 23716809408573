import {
	getPollingDetails,
	getPollings,
	createPolling,
	updatePolling,
	deletePolling,
} from "../../services/PollingService";
import {
	CONFIRMED_GET_POLLING_DETAILS,
	CONFIRMED_CREATE_POLLING_ACTION,
	CONFIRMED_GET_POLLINGS,
	CONFIRMED_EDIT_POLLING_ACTION,
	CONFIRMED_DELETE_POLLING_ACTION,
	LOADING,
} from "./PollingTypes";

const userData = JSON.parse(localStorage.getItem("userDetails"));

export function getPollingAction(pollingId) {
	return (dispatch, getState) => {
		getPollingDetails(pollingId).then((response) => {
			dispatch(confirmedGetPollingAction(response.data.poll));
		});
	};
}

export function confirmedGetPollingAction(polling) {
	return {
		type: CONFIRMED_GET_POLLING_DETAILS,
		payload: polling,
	};
}

export function deletePollingAction(pollingId, history) {
	return (dispatch, getState) => {
		deletePolling(pollingId).then((response) => {
			dispatch(confirmedDeletePollingAction(pollingId));
			history.push("/pollings");
		});
	};
}

export function confirmedDeletePollingAction(pollingId) {
	return {
		type: CONFIRMED_DELETE_POLLING_ACTION,
		payload: pollingId,
	};
}

export function createPollingAction(PollingData, history) {
	return (dispatch, getState) => {
		createPolling(PollingData).then((response) => {
			const singlePolling = {
				...PollingData,
				id: response.data._id,
			};
			dispatch(confirmedCreatePollingAction(singlePolling));
			history.push("/pollings");
		});
	};
}

export function confirmedCreatePollingAction(singlePolling) {
	return {
		type: CONFIRMED_CREATE_POLLING_ACTION,
		payload: singlePolling,
	};
}

export function getPollingsAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getPollings(societyId).then((response) => {
			dispatch(confirmedGetPollingsAction(response.data));
		});
	};
}

export function confirmedGetPollingsAction(Pollings) {
	return {
		type: CONFIRMED_GET_POLLINGS,
		payload: Pollings,
	};
}

export function confirmedUpdatePollingAction(Polling) {
	return {
		type: CONFIRMED_EDIT_POLLING_ACTION,
		payload: Polling,
	};
}

export function updatePollingAction(Polling, history, pollingId) {
	return (dispatch, getState) => {
		updatePolling(Polling, pollingId).then((reponse) => {
			dispatch(confirmedUpdatePollingAction(Polling));
			if (userData.user?.role === "admin") {
				history.push("/pollings");
			} else {
				history.push("/member-pollings");
			}
		});
	};
}
