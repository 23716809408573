import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
	getMaintenanceAction,
	deleteMaintenanceAction,
} from "../../../store/actions/MaintenanceActions";
import Pagination from "../../components/Pagination";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class MaintenanceInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			allLists: [],
			currentLists: [],
			currentPage: null,
			totalPages: null,
			societyId: "",
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		this.props.getMaintenanceAction(userData.user.societyName);
		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.maintenance?.maintenances?.length !==
			this.props?.maintenance?.maintenances?.length
		) {
			this.setState({
				currentLists: this.props?.maintenance?.maintenances,
			});
		}
	}

	initData() {
		let currentLists =
			this.state?.currentLists?.length > 0
				? this.state?.currentLists
				: this.props?.maintenance?.maintenances;

		this.setState({ currentLists });
	}

	onPageChanged = (data) => {
		const allLists = this.props.maintenance?.maintenances;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentLists = allLists?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentLists, totalPages });
	};

	handleSearch(event) {
		let currentLists = this.props.maintenance?.maintenances;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();

		if (searchString.length > 0) {
			// We are searching. Filter the results.
			//If page wise search we want then replace maintenances with currentLists
			currentLists = currentLists
				.filter((e) => e.flatNo.toLowerCase().match(searchString))
				.slice(0, 5);
			this.setState({
				currentLists,
			});
		} else {
			let arrayLength = currentLists.slice(0, 5);
			this.setState({ currentLists: arrayLength });
		}
		//this.setState({ currentLists });
	}

	// deleteRecord(id) {

	// 	this.props.deleteMaintenanceAction(id, this.props.history);
	// }
	deleteRecord(id) {
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteMaintenanceAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		const totalLists = this.props.maintenance?.maintenances?.length;

		const { currentLists, currentPage, totalPages } = this.state;
		this.state.currentLists = this.props.maintenance?.maintenances?.slice(0, 5);

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Maintenance Management</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Maintenance Management</h2>
								</div>
								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
							</div>

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Flat No.</th>
											<th>Residential Charges</th>
											<th>Parking Charges</th>
											<th>Repair Fund</th>
											<th>Sinking Fund</th>
											<th>Festival Charges</th>
											<th>Cidco Charges</th>
											<th>NOC Charges</th>
											<th>Other Charges</th>
											<th>Arrears</th>
											<th>Description</th>
											<th>Total</th>
											{/* <th>Action</th> */}
										</tr>
									</thead>
									<tbody>
										{currentLists &&
											currentLists
												?.sort((a, b) => a.flatNo.localeCompare(b.flatNo))
												.map((maintenance, i) => (
													<tr key={i}>
														<td>{maintenance.flatNo} </td>
														<td>{maintenance.maintenanceCharges}</td>
														<td>{maintenance.parkingCharges}</td>
														<td>{maintenance.repairFund}</td>
														<td>{maintenance.sinkingFund}</td>
														<td>{maintenance.festivalCharges}</td>
														<td>{maintenance.cidcoCharges}</td>
														<td>{maintenance.nocCharges}</td>
														<td>{maintenance.otherCharges}</td>
														<td>{maintenance.arrears}</td>
														<td>{maintenance.description}</td>
														<td>{maintenance.totalAmount}</td>
														{/* <td>{maintenance.totalAmount.toFixed(2)}</td> */}
														{/* <td>
															<Link
																to="#"
																className="btn btn-danger shadow btn-xs sharp"
																onClick={this.deleteRecord.bind(
																	this,
																	maintenance._id
																)}
															>
																<i className="fa fa-trash"></i>
															</Link>
														</td> */}
													</tr>
												))}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalLists ? (
										<Pagination
											totalRecords={totalLists}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		maintenance: state?.maintenance?.maintenance,
		loading: state?.maintenance?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getMaintenanceAction, deleteMaintenanceAction },
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MaintenanceInformation);
