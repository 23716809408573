export const GET_SOCIETY_DETAILS = "[Society Action] Get Society";
export const CONFIRMED_GET_SOCIETY_DETAILS =
	"[Society Action] Confirmed Get Society";
export const CREATE_SOCIETY_ACTION = "[Society Action] Create Society";
export const CONFIRMED_CREATE_SOCIETY_ACTION =
	"[Society Action] Confirmed Create Society";
export const GET_SOCIETIES = "[Society Action] Get Societies";
export const CONFIRMED_GET_SOCIETIES =
	"[Society Action] Confirmed Get Societies";
export const EDIT_SOCIETY_ACTION = "[Society Action] Edit Society";
export const CONFIRMED_EDIT_SOCIETY_ACTION =
	"[Society Action] Confirmed Edit Society";
export const CONFIRMED_DELETE_SOCIETY_ACTION =
	"[Society Action] Confirmed Delete Society";

export const CREATE_SOCIETY_HEADER_ACTION =
	"[Society Header Action] Create Society Header";
export const CONFIRMED_CREATE_SOCIETY_HEADER_ACTION =
	"[Society Header Action] Confirmed Create Header Society";

export const CREATE_SOCIETY_CONTACT_ACTION =
	"[Society Contact Action] Create Society Contact";
export const CONFIRMED_CREATE_SOCIETY_CONTACT_ACTION =
	"[Society Contact Action] Confirmed Create Contact Society";
export const CREATE_SOCIETY_IMAGE_ACTION =
	"[Society Contact Action] Create Society Contact";
export const CONFIRMED_CREATE_SOCIETY_IMAGE_ACTION =
	"[Society Image Action] Confirmed Create Image Society";
export const CONFIRMED_DELETE_SOCIETY_CONTACT_ACTION =
	"[Society Contact Action] Confirmed Delete Society Contact";
export const CONFIRMED_DELETE_SOCIETY_IMAGE_ACTION =
	"[Society Image Action] Confirmed Delete Society Image";
export const EDIT_SOCIETY_CONTACT_ACTION =
	"[Society Contact Action] Edit Society Contact";
export const CONFIRMED_EDIT_SOCIETY_CONTACT_ACTION =
	"[Society Contact Action] Confirmed Edit Contact Society";
export const SOCIETIES_LOADING =
	"[Society Loading Action] Confirmed Society Loading";
