import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

import { getBillingAction } from "../../../../store/actions/BillingActions";

class BillingDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			billing: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.billing) {
			this.props.getBillingAction(id);
		}
		this.init();
	}

	init() {
		this.state.billing = this.props?.billing;
	}

	render() {
		this.state.billing = this.props?.billing;

		const flatNo = this.state.billing?.flatNo || "";
		const maintenanceMonth = this.state.billing?.maintenanceMonth || "";
		const maintenanceYear = this.state.billing?.maintenanceYear || "";
		const maintenanceCharges = this.state.billing?.maintenanceCharges || "";
		const sinkingFund = this.state.billing?.sinkingFund || "";
		const repairFund = this.state.billing?.repairFund || "";
		const cidcoCharges = this.state.billing?.cidcoCharges || "";
		const parkingCharges = this.state.billing?.parkingCharges || "";
		const festivalCharges = this.state.billing?.festivalCharges || "";
		const nocCharges = this.state.billing?.nocCharges || "";
		const otherCharges = this.state.billing?.otherCharges || "";
		const totalCharges = this.state.billing?.totalCharges || "";
		const arrears = this.state.billing?.arrears || "";
		const interest = this.state.billing?.interest || "";
		const totalAmount = this.state.billing?.totalAmount || "";
		const status = this.state.billing?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item
						linkProps={{ to: "/payment-receipts" }}
						linkAs={Link}
					>
						Payment Receipt Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Payment Receipt Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Billing Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/billings"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Flat No</strong>
										<span className="mb-0">{flatNo}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Maintenance Month</strong>
										<span className="mb-0">{maintenanceMonth}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Maintenance Year</strong>
										<span className="mb-0">{maintenanceYear}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Maintenance Charges</strong>
										<span className="mb-0">{maintenanceCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Sinking Fund</strong>
										<span className="mb-0">{sinkingFund}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Repair Fund</strong>
										<span className="mb-0">{repairFund}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>CIDCO Charges</strong>
										<span className="mb-0">{cidcoCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Parking Charges</strong>
										<span className="mb-0">{parkingCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Festival Charges</strong>
										<span className="mb-0">{festivalCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>NOC Charges</strong>
										<span className="mb-0">{nocCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Other Charges</strong>
										<span className="mb-0">{otherCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Total Charges</strong>
										<span className="mb-0">{totalCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Arrears</strong>
										<span className="mb-0">{arrears}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Interest</strong>
										<span className="mb-0">{interest}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Total Amount</strong>
										<span className="mb-0">{totalAmount}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		billing: state.billing?.billing,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getBillingAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetail);
