/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useState, useContext } from "react"; //useEffect
import { Modal } from "react-bootstrap";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  FaHandshake,
  FaClipboardList,
  FaMoneyBill,
  FaCubes,
  FaComment,
  FaBuilding,
  FaRupeeSign,
  FaFileAlt,
  FaPoll,
} from "react-icons/fa";
//import profile from "../../../images/Untitled-1.jpg";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const userRole = JSON.parse(localStorage.getItem("userDetails"));

  const [newProject, setNewProject] = useState(false);
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  // useEffect(() => {
  // 	var btn = document.querySelector(".nav-control");
  // 	var aaa = document.querySelector("#main-wrapper");
  // 	function toggleFunc() {
  // 		return aaa.classList.toggle("menu-toggle");
  // 	}
  // 	btn.addEventListener("click", toggleFunc);

  // 	//sidebar icon Heart blast
  // 	var handleheartBlast = document.querySelector(".heart");
  // 	function heartBlast() {
  // 		return handleheartBlast.classList.toggle("heart-blast");
  // 	}

  // 	handleheartBlast.addEventListener("click", heartBlast);
  // }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location?.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let dashBoard = [""],
    society = [
      "society-information",
      "society-images",
      "billing-receipt-template",
      "society-contacts",
    ],
    maintenance = [
      "maintenence-information",
      "flat-management",
      "add-association",
      "maintenance-management",
    ],
    superAdmin = [
      "dashboard",
      "societies",
      "society-admin-user",
      "billing-header-mapping",
      "custom-labels",
      "amenity-lists",
      "requestDemos",
    ],
    expenses = ["expense", "expense-category-lists"],
    billings = ["billings", "bill-adjustment-lists"],
    payments = ["payments", "payment-receipts"],
    notifications = ["notices", "request-queries"], ///, "news"
    vendors = ["vendors", "vendor-type"],
    meetings = ["meetings", "minutes-of-meeting"],
    banks = ["bankdetails", "minutes-of-meeting"],
    reports = [
      "outstanding-details",
      "leashhold-flat-details",
      "individual-account-details",
      "expense-account-ledger",
      "income-expenditure",
    ],
    users = ["admin", "users"],
    amc = ["amc"],
    pollings = ["pollings"],
    /// Member Routes
    memberDashboard = ["memberDashboard"],
    memberSocietyInformation = ["member-society-information"],
    memberBillings = ["member-billings"],
    memberPayments = ["member-payments"],
    memberBillingAdjustments = ["member-billingAdjustments"],
    memberMeetings = ["member-meetings"],
    memberNotices = ["member-notices"],
    memberPollings = ["member-pollings"],
    memberRequests = ["member-request-queries"],
    memberVendors = ["member-vendors"];
  // deshBoard = [
  // 	"index",
  // 	"dashboard-dark",
  // 	"projects",
  // 	"contacts",
  // 	"task-list",
  // 	"calendar",
  // 	"messages",
  // ],
  // app = [
  // 	"app-profile",
  // 	"post-details",
  // 	"app-calender",
  // 	"email-compose",
  // 	"email-inbox",
  // 	"email-read",
  // 	"ecom-product-grid",
  // 	"ecom-product-list",
  // 	"ecom-product-order",
  // 	"ecom-checkout",
  // 	"ecom-invoice",
  // 	"ecom-customers",
  // 	"post-details",
  // 	"ecom-product-detail",
  // ],
  // email = ["email-compose", "email-inbox", "email-read"],
  // shop = [
  // 	"ecom-product-grid",
  // 	"ecom-product-list",
  // 	"ecom-product-list",
  // 	"ecom-product-order",
  // 	"ecom-checkout",
  // 	"ecom-invoice",
  // 	"ecom-customers",
  // 	"ecom-product-detail",
  // ],
  // charts = [
  // 	"chart-rechart",
  // 	"chart-flot",
  // 	"chart-chartjs",
  // 	"chart-chartist",
  // 	"chart-sparkline",
  // 	"chart-apexchart",
  // ],
  // bootstrap = [
  // 	"ui-accordion",
  // 	"ui-badge",
  // 	"ui-alert",
  // 	"ui-button",
  // 	"ui-modal",
  // 	"ui-button-group",
  // 	"ui-list-group",
  // 	"ui-media-object",
  // 	"ui-card",
  // 	"ui-carousel",
  // 	"ui-dropdown",
  // 	"ui-popover",
  // 	"ui-progressbar",
  // 	"ui-tab",
  // 	"ui-typography",
  // 	"ui-pagination",
  // 	"ui-grid",
  // ],
  // plugins = [
  // 	"uc-select2",
  // 	"uc-nestable",
  // 	"uc-sweetalert",
  // 	"uc-toastr",
  // 	"uc-noui-slider",
  // 	"map-jqvmap",
  // 	"uc-lightgallery",
  // 	"todo",
  // ],
  // redux = ["redux-form", "redux-wizard", "posts"],
  // widget = ["widget-basic"],
  // forms = [
  // 	"form-element",
  // 	"form-wizard",
  // 	"form-editor-summernote",
  // 	"form-pickers",
  // 	"form-validation-jquery",
  // ],
  // table = [
  // 	"table-bootstrap-basic",
  // 	"table-datatable-basic",
  // 	"sorting-table",
  // 	"filtering-table",
  // ],
  // pages = ["page-register", "page-login", "page-lock-screen"],
  // error = [
  // 	"page-error-400",
  // 	"page-error-403",
  // 	"page-error-404",
  // 	"page-error-500",
  // 	"page-error-503",
  // ];
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      {/* <!-- Add Project --> */}
      <Modal
        className="modal fade"
        id="addProjectSidebar"
        show={newProject}
        onHide={setNewProject}
      >
        <div className="" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create Project</h5>
              <button
                type="button"
                className="close"
                onClick={() => setNewProject(false)}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label className="text-black font-w500">Project Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Deadline</label>
                  <input type="date" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Client Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <button type="button" className="btn btn-primary">
                    CREATE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <PerfectScrollbar className="deznav-scroll">
        {/* <div className="main-profile">
			<img src={profile} alt="" />
			<Link to={"#"}><i className="fa fa-cog" aria-hidden="true"></i></Link>
			<h5 className="mb-0 fs-20 text-black "><span className="font-w400">Hello,</span> Marquez</h5>
			<p className="mb-0 fs-14 font-w400">marquezzzz@mail.com</p>
	  </div> */}
        {/* <Link
					to={"#"}
					className="add-project-sidebar btn btn-primary"
					onClick={() => setNewProject(true)}
				>
					+ New Project
				</Link> */}
        <MM className="metismenu" id="menu">
          {/* <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#">
							{" "}
							<i className="flaticon-047-home"></i>
							<span className="nav-text">Dashboard</span>
						</Link>
						<ul>
							<li>
								<Link
									className={`${path === "index" ? "mm-active" : ""}`}
									to="/index"
								>
									Dashboard Light
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "dashboard-dark" ? "mm-active" : ""}`}
									to="/dashboard-dark"
								>
									Dashboard Dark
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "projects" ? "mm-active" : ""}`}
									to="/projects"
								>
									Project
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "contacts" ? "mm-active" : ""}`}
									to="/contacts"
								>
									Contacts
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "task-list" ? "mm-active" : ""}`}
									to="/task-list"
								>
									Task List
								</Link>{" "}
							</li>
							<li>
								<Link
									className={`${path === "calendar" ? "mm-active" : ""}`}
									to="/calendar"
								>
									Calendar
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "messages" ? "mm-active" : ""}`}
									to="/messages"
								>
									Messages
								</Link>
							</li>
						</ul>
					</li> */}

          {userRole?.user?.role === "admin" && (
            <>
              <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
                <Link to="" className="ai-icon">
                  <i className="flaticon-047-home"></i>
                  <span className="nav-text">Home</span>
                </Link>
              </li>
              <li className={`${society.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-building"></i>
                  <span className="nav-text">Society</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${
                        path === "society-information" ? "mm-active" : ""
                      }`}
                      to="/society-information"
                    >
                      Society Information
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "society-images" ? "mm-active" : ""
                      }`}
                      to="/society-images"
                    >
                      Society Images
                    </Link>
                  </li>
                  {/* <li>
										<Link
											className={`${
												path === "billing-templates" ? "mm-active" : ""
											}`}
											to="/billing-templates"
										>
											Billing / Receipt Template
										</Link>
									</li> */}
                  <li>
                    <Link
                      className={`${
                        path === "society-contacts" ? "mm-active" : ""
                      }`}
                      to="/society-contacts"
                    >
                      Society Contacts
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`${banks.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-bank"></i>
                  <span className="nav-text">Bank Management</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === "bankdetails" ? "mm-active" : ""}`}
                      to="/bankdetails"
                    >
                      Bank Details
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`${users.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-users"></i>
                  <span className="nav-text">Society Users</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === "admin" ? "mm-active" : ""}`}
                      to="/admin"
                    >
                      Admin User
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${path === "users" ? "mm-active" : ""}`}
                      to="/users"
                    >
                      Member User
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`${maintenance.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-cogs"></i>
                  <span className="nav-text">Maintenance</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${
                        path === "maintenance-information" ? "mm-active" : ""
                      }`}
                      to="/maintenance-information"
                    >
                      Maintenance Information
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "flat-management" ? "mm-active" : ""
                      }`}
                      to="/flat-management"
                    >
                      Flat Management
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "add-association" ? "mm-active" : ""
                      }`}
                      to="/add-association"
                    >
                      Flat Association
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "maintenance-management" ? "mm-active" : ""
                      }`}
                      to="/maintenance-management"
                    >
                      Maintenance management
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={`${billings.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-file"></i>
                  <span className="nav-text">Billings</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === "billings" ? "mm-active" : ""}`}
                      to="/billings"
                    >
                      Billing Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "billing-adjustment-lists" ? "mm-active" : ""
                      }`}
                      to="/billing-adjustment-lists"
                    >
                      Billing Adjustment
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`${payments.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-dollar"></i>
                  <span className="nav-text">Payments</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === "payments" ? "mm-active" : ""}`}
                      to="/payments"
                    >
                      Payment Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "payment-receipts" ? "mm-active" : ""
                      }`}
                      to="/payment-receipts"
                    >
                      Payment Receipts
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`${expenses.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-rupee"></i>
                  <span className="nav-text">Expenses</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === "expense" ? "mm-active" : ""}`}
                      to="/expense"
                    >
                      Expense Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "expense-category-lists" ? "mm-active" : ""
                      }`}
                      to="/expense-category-lists"
                    >
                      Expense Category
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`${notifications.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-comment"></i>
                  <span className="nav-text">Notifications</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === "notices" ? "mm-active" : ""}`}
                      to="/notices"
                    >
                      Notices
                    </Link>
                  </li>
                  {/* <li>
										<Link
											className={`${path === "news" ? "mm-active" : ""}`}
											to="/news"
										>
											News
										</Link>
									</li> */}
                  <li>
                    <Link
                      className={`${
                        path === "request-queries" ? "mm-active" : ""
                      }`}
                      to="/request-queries"
                    >
                      Request Queries
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={`${vendors.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-user-circle"></i>
                  <span className="nav-text">Vendors</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === "vendor-type" ? "mm-active" : ""}`}
                      to="/vendor-type"
                    >
                      Vendor Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${path === "vendors" ? "mm-active" : ""}`}
                      to="/vendors"
                    >
                      Vendors
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`${meetings.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-comments"></i>
                  <span className="nav-text">Meetings</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === "meetings" ? "mm-active" : ""}`}
                      to="/meetings"
                    >
                      Meetings Master
                    </Link>
                  </li>
                  {/* <li>
										<Link
											className={`${
												path === "minutes-of-meeting" ? "mm-active" : ""
											}`}
											to="/minutes-of-meeting"
										>
											Minutes of Meeting
										</Link>
									</li> */}
                </ul>
              </li>

              <li className={`${reports.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  {" "}
                  <i className="fa fa-envelope"></i>
                  <span className="nav-text">Reports</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${
                        path === "outstanding-details" ? "mm-active" : ""
                      }`}
                      to="/outstanding-details"
                    >
                      Outstanding Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "leasehold-flat-details" ? "mm-active" : ""
                      }`}
                      to="/leasehold-flat-details"
                    >
                      LeaseHold Flat Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "individual-account-details" ? "mm-active" : ""
                      }`}
                      to="/individual-account-details"
                    >
                      Individual Account Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "expense-account-ledger" ? "mm-active" : ""
                      }`}
                      to="/expense-account-ledger"
                    >
                      Expense Account Ledger
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === "income-expenditure" ? "mm-active" : ""
                      }`}
                      to="/income-expenditure"
                    >
                      Income & Expenditure
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`${amc.includes(path) ? "mm-active" : ""}`}>
                <Link to="/amc" className="ai-icon">
                  <i className="fa fa-file"></i>
                  <span className="nav-text">AMC / Contract Details</span>
                </Link>
              </li>

              <li className={`${pollings.includes(path) ? "mm-active" : ""}`}>
                <Link to="/pollings" className="ai-icon">
                  {/* <i className="fa fa-balance-scale"></i> */}
                  <FaPoll className="icon-style" />
                  <span className="nav-text">Polling Details</span>
                </Link>
              </li>
            </>
          )}

          {userRole?.user?.role === "superAdmin" && (
            <li className={`${superAdmin.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                {" "}
                <i className="fa fa-user"></i>
                <span className="nav-text">Super Admin</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "dashboard" ? "mm-active" : ""}`}
                    to="/superadmin/dashboard"
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "societies" ? "mm-active" : ""}`}
                    to="/superadmin/societies"
                  >
                    Societies Lists
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "admin" ? "mm-active" : ""}`}
                    to="/superadmin/admin"
                  >
                    Society Admin User
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "billing-header-mapping" ? "mm-active" : ""
                    }`}
                    to="/superadmin/billing-header-mapping"
                  >
                    Billing Label Mapping
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "custom-labels" ? "mm-active" : ""}`}
                    to="/superadmin/custom-labels"
                  >
                    Society Custom Billing Headers
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "amenity-lists" ? "mm-active" : ""}`}
                    to="/amenity-lists"
                  >
                    Amenity Lists
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "requestDemos" ? "mm-active" : ""}`}
                    to="/superadmin/requestDemo-lists"
                  >
                    Request Demo
                  </Link>
                </li>
              </ul>
            </li>
          )}

          {userRole?.user?.role !== "superAdmin" &&
            userRole?.user?.role !== "admin" && (
              <>
                <li
                  className={`${
                    memberDashboard.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link to="/memberDashboard" className="ai-icon">
                    <i className="flaticon-047-home"></i>
                    <span className="nav-text">Dashboard</span>
                  </Link>
                </li>

                <li
                  className={`${
                    memberSocietyInformation.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link to="/member-society-information" className="ai-icon">
                    <FaBuilding className="icon-style" />
                    <span className="nav-text">Society Information</span>
                  </Link>
                </li>
                <li
                  className={`${
                    memberBillings.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link to="/member-billings" className="ai-icon">
                    <FaFileAlt className="icon-style" />
                    <span className="nav-text">Billing History</span>
                  </Link>
                </li>
                <li
                  className={`${
                    memberPayments.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link to="/member-payments" className="ai-icon">
                    <FaRupeeSign className="icon-style" />
                    <span className="nav-text">Payment History</span>
                  </Link>
                </li>
                <li
                  className={`${
                    memberBillingAdjustments.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link to="/member-billingAdjustments" className="ai-icon">
                    {/* <i className="fa fa-comment"></i> */}
                    <FaMoneyBill className="icon-style" />
                    <span className="nav-text">Billing Adjustment</span>
                  </Link>
                </li>

                <li
                  className={`${
                    memberMeetings.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link to="/member-meetings" className="ai-icon">
                    {/* <i className="fa fa-handshake"></i> */}
                    <FaHandshake className="icon-style" />
                    <span className="nav-text">Meeting</span>
                  </Link>
                </li>
                <li
                  className={`${
                    memberNotices.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link to="/member-notices" className="ai-icon">
                    {/* <i className="fa fa-elementor"></i> */}
                    <FaClipboardList className="icon-style" />
                    <span className="nav-text">Notice</span>
                  </Link>
                </li>
                <li
                  className={`${
                    memberPollings.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link to="/member-pollings" className="ai-icon">
                    <FaPoll className="icon-style" />
                    <span className="nav-text">Polling</span>
                  </Link>
                </li>
                <li
                  className={`${
                    memberRequests.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link to="/member-request-queries" className="ai-icon">
                    <FaComment className="icon-style" />
                    <span className="nav-text">Request Query</span>
                  </Link>
                </li>
                <li
                  className={`${
                    memberVendors.includes(path) ? "mm-active" : ""
                  }`}
                >
                  <Link to="/member-vendors" className="ai-icon">
                    <FaCubes className="icon-style" />
                    <span className="nav-text">Vendor</span>
                  </Link>
                </li>
              </>
            )}

          {/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#">
							<i className="flaticon-025-dashboard"></i>
							<span className="nav-text">Apps</span>
						</Link>
						<ul>
							<li>
								<Link
									className={`${path === "app-profile" ? "mm-active" : ""}`}
									to="/app-profile"
								>
									Profile
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "post-details" ? "mm-active" : ""}`}
									to="/post-details"
								>
									Post Details
								</Link>
							</li>
							<li className={`${email.includes(path) ? "mm-active" : ""}`}>
								<Link className="has-arrow" to="#">
									Email
								</Link>
								<ul className={`${email.includes(path) ? "mm-show" : ""}`}>
									<li>
										<Link
											className={`${
												path === "email-compose" ? "mm-active" : ""
											}`}
											to="/email-compose"
										>
											Compose
										</Link>
									</li>
									<li>
										<Link
											className={`${path === "email-inbox" ? "mm-active" : ""}`}
											to="/email-inbox"
										>
											Inbox
										</Link>
									</li>
									<li>
										<Link
											className={`${path === "email-read" ? "mm-active" : ""}`}
											to="/email-read"
										>
											Read
										</Link>
									</li>
								</ul>
							</li>
							<li>
								<Link
									className={`${path === "app-calender" ? "mm-active" : ""}`}
									to="/app-calender"
								>
									Calendar
								</Link>
							</li>
							<li className={`${shop.includes(path) ? "mm-active" : ""}`}>
								<Link className="has-arrow" to="#">
									Shop
								</Link>
								<ul className={`${shop.includes(path) ? "mm-show" : ""}`}>
									<li>
										<Link
											className={`${
												path === "ecom-product-grid" ? "mm-active" : ""
											}`}
											to="/ecom-product-grid"
										>
											Product Grid
										</Link>
									</li>
									<li>
										<Link
											className={`${
												path === "ecom-product-list" ? "mm-active" : ""
											}`}
											to="/ecom-product-list"
										>
											Product List
										</Link>
									</li>
									<li>
										<Link
											className={`${
												path === "ecom-product-detail" ? "mm-active" : ""
											}`}
											to="/ecom-product-detail"
										>
											Product Details
										</Link>
									</li>
									<li>
										<Link
											className={`${
												path === "ecom-product-order" ? "mm-active" : ""
											}`}
											to="/ecom-product-order"
										>
											Order
										</Link>
									</li>
									<li>
										<Link
											className={`${
												path === "ecom-checkout" ? "mm-active" : ""
											}`}
											to="/ecom-checkout"
										>
											Checkout
										</Link>
									</li>
									<li>
										<Link
											className={`${
												path === "ecom-invoice" ? "mm-active" : ""
											}`}
											to="/ecom-invoice"
										>
											Invoice
										</Link>
									</li>
									<li>
										<Link
											className={`${
												path === "ecom-customers" ? "mm-active" : ""
											}`}
											to="/ecom-customers"
										>
											Customers
										</Link>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li className={`${charts.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#">
							<i className="flaticon-041-graph"></i>
							<span className="nav-text">Charts</span>
						</Link>
						<ul>
							<li>
								<Link
									className={`${path === "chart-rechart" ? "mm-active" : ""}`}
									to="/chart-rechart"
								>
									RechartJs
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "chart-chartjs" ? "mm-active" : ""}`}
									to="/chart-chartjs"
								>
									Chartjs
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "chart-chartist" ? "mm-active" : ""}`}
									to="/chart-chartist"
								>
									Chartist
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "chart-sparkline" ? "mm-active" : ""}`}
									to="/chart-sparkline"
								>
									Sparkline
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "chart-apexchart" ? "mm-active" : ""}`}
									to="/chart-apexchart"
								>
									Apexchart
								</Link>
							</li>
						</ul>
					</li>
					<li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#">
							<i className="flaticon-086-star"></i>
							<span className="nav-text">Bootstrap</span>
						</Link>
						<ul>
							<li>
								<Link
									className={`${path === "ui-accordion" ? "mm-active" : ""}`}
									to="/ui-accordion"
								>
									Accordion
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-alert" ? "mm-active" : ""}`}
									to="/ui-alert"
								>
									Alert
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-badge" ? "mm-active" : ""}`}
									to="/ui-badge"
								>
									Badge
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-button" ? "mm-active" : ""}`}
									to="/ui-button"
								>
									Button
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-modal" ? "mm-active" : ""}`}
									to="/ui-modal"
								>
									Modal
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-button-group" ? "mm-active" : ""}`}
									to="/ui-button-group"
								>
									Button Group
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-list-group" ? "mm-active" : ""}`}
									to="/ui-list-group"
								>
									List Group
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-media-object" ? "mm-active" : ""}`}
									to="/ui-media-object"
								>
									Media Object
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-card" ? "mm-active" : ""}`}
									to="/ui-card"
								>
									Cards
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-carousel" ? "mm-active" : ""}`}
									to="/ui-carousel"
								>
									Carousel
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-dropdown" ? "mm-active" : ""}`}
									to="/ui-dropdown"
								>
									Dropdown
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-popover" ? "mm-active" : ""}`}
									to="/ui-popover"
								>
									Popover
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-progressbar" ? "mm-active" : ""}`}
									to="/ui-progressbar"
								>
									Progressbar
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-tab" ? "mm-active" : ""}`}
									to="/ui-tab"
								>
									Tab
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-typography" ? "mm-active" : ""}`}
									to="/ui-typography"
								>
									Typography
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-pagination" ? "mm-active" : ""}`}
									to="/ui-pagination"
								>
									{" "}
									Pagination
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "ui-grid" ? "mm-active" : ""}`}
									to="/ui-grid"
								>
									Grid
								</Link>
							</li>
						</ul>
					</li>
					<li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#">
							<i className="flaticon-045-heart"></i>
							<span className="nav-text">Plugins</span>
						</Link>
						<ul>
							<li>
								<Link
									className={`${path === "uc-select2" ? "mm-active" : ""}`}
									to="/uc-select2"
								>
									Select 2
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "uc-nestable" ? "mm-active" : ""}`}
									to="/uc-nestable"
								>
									Nestable
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "uc-noui-slider" ? "mm-active" : ""}`}
									to="/uc-noui-slider"
								>
									Noui Slider
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "uc-sweetalert" ? "mm-active" : ""}`}
									to="/uc-sweetalert"
								>
									Sweet Alert
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "uc-toastr" ? "mm-active" : ""}`}
									to="/uc-toastr"
								>
									Toastr
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "map-jqvmap" ? "mm-active" : ""}`}
									to="/map-jqvmap"
								>
									Jqv Map
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "uc-lightgallery" ? "mm-active" : ""}`}
									to="/uc-lightgallery"
								>
									Light Gallery
								</Link>
							</li>
						</ul>
					</li>
					<li className={`${redux.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#">
							<i className="flaticon-068-plus"></i>
							<span className="nav-text">Redux</span>
						</Link>
						<ul>
							<li>
								<Link
									className={`${path === "posts" ? "mm-active" : ""}`}
									to="/posts"
								>
									Posts
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "redux-form" ? "mm-active" : ""}`}
									to="/form-redux"
								>
									Redux Form
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "redux-wizard" ? "mm-active" : ""}`}
									to="/form-redux-wizard"
								>
									Redux Wizard
								</Link>
							</li>
						</ul>
					</li>
					<li className={`${widget.includes(path) ? "mm-active" : ""}`}>
						<Link to="widget-basic" className="ai-icon">
							<i className="flaticon-013-checkmark"></i>
							<span className="nav-text">Widget</span>
						</Link>
					</li>
					<li className={`${forms.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#">
							<i className="flaticon-072-printer"></i>
							<span className="nav-text forms">Forms</span>
						</Link>
						<ul>
							<li>
								<Link
									className={`${path === "form-element" ? "mm-active" : ""}`}
									to="/form-element"
								>
									Form Elements
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "form-wizard" ? "mm-active" : ""}`}
									to="/form-wizard"
								>
									Wizard
								</Link>
							</li>
							<li>
								<Link
									className={`${
										path === "form-editor-summernote" ? "mm-active" : ""
									}`}
									to="/form-editor-summernote"
								>
									Summernote
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "form-pickers" ? "mm-active" : ""}`}
									to="/form-pickers"
								>
									Pickers
								</Link>
							</li>
							<li>
								<Link
									className={`${
										path === "form-validation-jquery" ? "mm-active" : ""
									}`}
									to="/form-validation-jquery"
								>
									Jquery Validate
								</Link>
							</li>
						</ul>
					</li>
					<li className={`${table.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#">
							<i className="flaticon-017-clipboard"></i>
							<span className="nav-text">Table</span>
						</Link>
						<ul>
							<li>
								<Link
									className={`${
										path === "table-bootstrap-basic" ? "mm-active" : ""
									}`}
									to="/table-bootstrap-basic"
								>
									Bootstrap
								</Link>
							</li>
							<li>
								<Link
									className={`${
										path === "table-datatable-basic" ? "mm-active" : ""
									}`}
									to="/table-datatable-basic"
								>
									Datatable
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "sorting-table" ? "mm-active" : ""}`}
									to="/sorting-table"
								>
									Sorting Table
								</Link>
							</li>
							<li>
								<Link
									className={`${path === "filtering-table" ? "mm-active" : ""}`}
									to="/filtering-table"
								>
									Filtering Table
								</Link>
							</li>
						</ul>
					</li>
					<li className={`${pages.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#">
							<i className="flaticon-022-copy"></i>
							<span className="nav-text">Pages</span>
						</Link>
						<ul>
							
							<li className={`${error.includes(path) ? "mm-active" : ""}`}>
								<Link className="has-arrow" to="#">
									Error
								</Link>
								<ul>
									<li>
										<Link
											className={`${
												path === "page-error-400" ? "mm-active" : ""
											}`}
											to="/page-error-400"
										>
											Error 400
										</Link>
									</li>
									<li>
										<Link
											className={`${
												path === "page-error-403" ? "mm-active" : ""
											}`}
											to="/page-error-403"
										>
											Error 403
										</Link>
									</li>
									<li>
										<Link
											className={`${
												path === "page-error-404" ? "mm-active" : ""
											}`}
											to="/page-error-404"
										>
											Error 404
										</Link>
									</li>
									<li>
										<Link
											className={`${
												path === "page-error-500" ? "mm-active" : ""
											}`}
											to="/page-error-500"
										>
											Error 500
										</Link>
									</li>
									<li>
										<Link
											className={`${
												path === "page-error-503" ? "mm-active" : ""
											}`}
											to="/page-error-503"
										>
											Error 503
										</Link>
									</li>
								</ul>
							</li>
							<li>
								{" "}
								<Link
									className={`${
										path === "page-lock-screen" ? "mm-active" : ""
									}`}
									to="/page-lock-screen"
								>
									Lock Screen
								</Link>
							</li>
						</ul>
					</li>*/}
        </MM>
        <div className="copyright">
          <p>
            <strong>
              SocietyCare{" "}
              {userRole?.user?.role !== "superAdmin" &&
                userRole?.user?.role !== "admin" && <>Member </>}
              {userRole?.user?.role === "superAdmin" && <>Super Admin </>}
              {userRole?.user?.role === "admin" && <>Admin </>}
              Dashboard
            </strong>{" "}
            © 2021 All Rights Reserved
          </p>
          <p className="fs-12">
            Made with <span className="heart"></span> by PeractoInfotech Pvt Ltd
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
