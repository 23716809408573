import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getPaymentAction,
	createPaymentAction,
	updatePaymentAction,
} from "../../../../store/actions/PaymentActions";
import { getFlatsAction } from "../../../../store/actions/FlatManagementActions";

class AddEditPayment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			payment: {},
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.payment) {
				this.props.getPaymentAction(id); //Local
			}
		} else {
		}
		this.props.getFlatsAction(userData.user.societyName);
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.payment?.length !== this.props?.payment?.length) {
			this.setState({
				payment: this.props?.payment,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;

		var e = document.getElementById("val-flatId"); // To get the Flat NO from Flat id
		var flatText = e.options[e.selectedIndex].text;

		var formatYear = new Date(values.year).getFullYear().toString();

		Object.assign(values, { year: formatYear }); // Push year

		if (id) {
			values = { ...values, year: formatYear };
			this.props.updatePaymentAction(
				values,
				this.props.history,
				this.props?.payment?._id
			);
		} else {
			Object.assign(values, { flatNo: flatText }); // Push flat no
			this.props.createPaymentAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var payment = this.props?.payment;
		} else {
			payment = "";
		}

		let flat_data = this.props.flats?.flats;

		const initialValues = {
			societyId: this.state?.societyId,
			flatId: payment ? payment.flatId : "",

			year: payment ? payment.year : new Date(),
			paymentType: payment ? payment.paymentType : "",
			paymentMode: payment ? payment.paymentMode : "",
			amount: payment ? payment.amount : 0,
			bankDetails: payment ? payment.bankDetails : "",
			date: payment ? payment.date : new Date(),
			transactionDetails: payment ? payment.transactionDetails : "",
			status: payment ? payment.status : true,
		};

		const validationSchema = Yup.object().shape({
			flatId: Yup.string().required("Please Select Flat No."),

			paymentType: Yup.string().required("Please Select Payment Type."),
			paymentMode: Yup.string().required("Please Select Payment Mode."),
			amount: Yup.string().required("Please Enter Amount."),
			transactionDetails: Yup.string().required(
				"Please Enter Transaction Details."
			),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/payments" }} linkAs={Link}>
						Payment Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Payment</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add payment</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Payment Details</h3>
						) : (
							<h3 className="pt-3">Add Payment Details</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/payments"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Flat No. *</label>
															<div>
																<select
																	className="form-control"
																	id="val-flatId"
																	placeholder="Select a Flat Name.."
																	name="flatId"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.flatId || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	{flat_data?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt._id}
																			//label={opt.flatNo}
																		>
																			{opt.flatNo}
																		</option>
																	))}
																</select>
																{errors.flatId && touched.flatId ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.flatId}
																	</div>
																) : null}
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Payment Type *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-paymentType"
																	placeholder="Select a payment Type.."
																	name="paymentType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.paymentType || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	<option
																		value="Maintenance"
																		label="Maintenance"
																	/>
																	<option value="NOC" label="NOC" />
																	<option value="Other" label="other" />
																</select>
																{errors.paymentType && touched.paymentType ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.paymentType}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Payment Mode *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-paymentMode"
																	placeholder="Select a payment mode.."
																	name="paymentMode"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.paymentMode || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	<option value="Cheque" label="Cheque" />
																	<option value="Cash" label="Cash" />
																	<option value="Transfer" label="Transfer" />
																</select>
																{errors.paymentMode && touched.paymentMode ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.paymentMode}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Transaction Details *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-transactionDetails"
																	placeholder="Enter a transaction Details.."
																	name="transactionDetails"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.transactionDetails || ""}
																/>
																{errors.transactionDetails &&
																touched.transactionDetails ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.transactionDetails}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Amount *</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-amount"
																	placeholder="Enter a Amount.."
																	name="amount"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.amount}
																/>
																{errors.amount && touched.amount ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.amount}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Bank Details</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-bankDetails"
																	placeholder="Enter a Bank Details.."
																	name="bankDetails"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.bankDetails || ""}
																/>
																{errors.bankDetails && touched.bankDetails ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.bankDetails}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Date</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="date"
																		format="dd/MM/yyyy"
																		value={values.date}
																		onChange={(e) => setFieldValue("date", e)}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.date && touched.date ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.date}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddPayment;
const mapStateToProps = (state) => {
	return {
		payment: state.payment?.payment,
		flats: state.flat?.flats,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getPaymentAction,
			createPaymentAction,
			updatePaymentAction,
			getFlatsAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPayment);
