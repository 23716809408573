import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { getRequestDemosAction } from "../../../../../store/actions/RequestDemoActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class RequestDemo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentRequestDemos: [],
			currentPage: null,
			totalPages: null,
		};
	}

	componentDidMount() {
		this.props.getRequestDemosAction();

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.requestDemos?.requestDemos?.length !==
			this.props?.requestDemos?.requestDemos?.length
		) {
			this.setState({
				currentRequestDemos: this.props?.requestDemos?.requestDemos,
			});
		}
	}

	initData() {
		let currentRequestDemos =
			this.state?.currentRequestDemos?.length > 0
				? this.state?.currentRequestDemos
				: this.props?.requestDemos?.requestDemos;

		this.setState({ currentRequestDemos });
	}

	onPageChanged = (data) => {
		const allRequestDemos = this.props?.requestDemos?.requestDemos;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentRequestDemos = allRequestDemos?.slice(
			offset,
			offset + pageLimit
		);

		this.setState({ currentPage, currentRequestDemos, totalPages });
	};

	handleSearch(event) {
		let currentRequestDemos = this.props.requestDemos?.requestDemos;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentRequestDemos = currentRequestDemos.filter(
				(e) =>
					e.societyName.toLowerCase().match(searchString) ||
					e.name.toLowerCase().match(searchString) ||
					e.mobile.toLowerCase().match(searchString)
			);
			this.setState({ currentRequestDemos });
		} else {
			let arrayLength = currentRequestDemos.slice(0, 5);
			this.setState({ currentRequestDemos: arrayLength });
		}
	}

	// deleteRecord(id) {
	// 	swal({
	// 		title: "Are you sure?",
	// 		text: "Are you sure you want to delete this record ?",
	// 		icon: "warning",
	// 		buttons: true,
	// 		dangerMode: true,
	// 	}).then((response) => {
	// 		if (response) {
	// 			this.props.deleteSocietyAction(id, this.props.history);
	// 			swal("Poof! Your file has been deleted!", {
	// 				icon: "success",
	// 			});
	// 		} else {
	// 			swal("Your file is safe!");
	// 		}
	// 	});
	// }

	render() {
		let totalRequestDemos = this.props?.requestDemos?.requestDemos?.length || 0;

		const { currentRequestDemos, currentPage, totalPages } = this.state;
		this.state.currentRequestDemos = this.props.requestDemos?.requestDemos;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					{/* <Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "} */}
					<Breadcrumb.Item active>Request Demo Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Request Demo Lists</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								{/* <div className="col-xl-4 col-sm-12">
									<Link
										to={"add-society"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Request Demo
									</Link>
								</div> */}
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Society Name</th>
											<th>Society Address</th>
											<th>Contact Name</th>
											<th>Mobile</th>
											<th>Email</th>
											<th>Status</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentRequestDemos?.length
											? currentRequestDemos.map((request, i) => (
													<tr key={i}>
														<td>{request.societyName}</td>
														<td>{request.societyAddress}</td>
														<td>{request.name} </td>
														<td>{request.mobile}</td>
														<td>{request.email}</td>
														<td>
															{request.status === "Demo Requested" && (
																<span className="badge badge-rounded badge-primary">
																	{request.status}
																</span>
															)}
															{request.status === "Demo Scheduled" && (
																<span className="badge badge-rounded badge-info">
																	{request.status}
																</span>
															)}
															{request.status === "Demo Given" && (
																<span className="badge badge-rounded badge-warning">
																	{request.status}
																</span>
															)}
															{request.status === "Approval Pending" && (
																<span className="badge badge-rounded badge-danger">
																	{request.status}
																</span>
															)}

															{request.status === "Completed" && (
																<span className="badge badge-rounded badge-success">
																	{request.status}
																</span>
															)}
														</td>
														<td>
															<div className="d-flex">
																{/* <Link
																	to={`add-society/${society._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link> */}
																<Link
																	to={`requestDemo-details/${request._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
																{/* <Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={this.deleteRecord.bind(
																		this,
																		society._id
																	)}
																>
																	<i className="fa fa-trash"></i>
																</Link> */}
															</div>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalRequestDemos ? (
										<Pagination
											totalRecords={totalRequestDemos}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		requestDemos: state.requestDemo?.requestDemos,
		loading: state?.requestDemo?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getRequestDemosAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDemo);
