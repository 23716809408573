import { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Breadcrumb } from "react-bootstrap";
import Pagination from "../../../components/Pagination";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik } from "formik"; //Field
import * as Yup from "yup";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import axiosInstance from "../../../../services/AxiosInstance";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

const initialValues = {
	expenseCategory: "all",
	startDate: new Date(),
	endDate: new Date(),
};

const validationSchema = Yup.object().shape({
	expenseCategory: Yup.string().required("Please Select Expense Category."),
	startDate: Yup.date().required("Please Select Start Dt."),
	endDate: Yup.date()
		.min(Yup.ref("startDate"), "End date can't be before Start date")
		.required("Please Select End Date"),
});

class ExpenseAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentExpenseAccounts: [],
			currentPage: null,
			totalPages: null,
			categories: [],
			loading: false,
			handleAddValues: {},
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.getExpenseCategories(userData.user.societyName);
	}

	async getExpenseCategories(societyId) {
		const { data } = await axiosInstance
			.get(`admin/expenseCategorys/${societyId}`)
			.then((result) => result);

		this.setState({
			categories: data.expenseCategorys,
		});
	}

	handleAdd = (values) => {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
			handleAddValues: values,
		});

		this.getExpenseAccount(userData.user.societyName, values);
	};

	async getExpenseAccount(societyId, values) {
		const payload = {
			expenseCategory: values.expenseCategory,
			startDate: values.startDate,
			endDate: values.endDate,
		};
		this.setState({
			loading: true,
		});
		const { data } = await axiosInstance
			.post(`admin/report/expense/${societyId}`, payload)
			.then((result) => result);

		this.setState({
			currentExpenseAccounts: data?.expenses,
			loading: false,
		});
	}

	onPageChanged = async (data) => {
		await this.getExpenseAccount(
			this.state.societyId,
			this.state.handleAddValues
		);
		const allExpenseAccounts = this.state.currentExpenseAccounts;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentExpenseAccounts = allExpenseAccounts?.slice(
			offset,
			offset + pageLimit
		);

		this.setState({ currentPage, currentExpenseAccounts, totalPages });
	};

	handleSearch(event) {
		let currentExpenseAccounts = this.state.currentExpenseAccounts;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentExpenseAccounts = currentExpenseAccounts.filter((e) =>
				e.category?.categoryName.toLowerCase().match(searchString)
			);
		}
		this.setState({ currentExpenseAccounts });
	}

	render() {
		let totalExpenseAccounts = this.state?.currentExpenseAccounts?.length || 0;

		const { currentExpenseAccounts, currentPage, totalPages } = this.state;

		let category_data = this.state.categories;

		return (
			<>
				{this.state?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Expense Account Ledger</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Expense Account Ledgers</h2>
								</div>

								{/* <div className="col-xl-4 col-sm-12">
								<input
									type="text"
									className="form-control mb-2 search-list pull-right"
									placeholder="Search..."
									onChange={(e) => this.handleSearch(e)}
								/>
							</div> */}
							</div>
							<hr />
							<div className="form-validation">
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									enableReinitialize={true}
									onSubmit={(values, { setSubmitting }) => {
										setTimeout(() => {
											this.handleAdd(values);
											setSubmitting(false);
										}, 400);
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										setFieldValue,
									}) => (
										<form onSubmit={handleSubmit}>
											<div className="row">
												<div className="col-xl-1 col-sm-12"></div>
												<div className="col-xl-3 col-sm-12">
													<div className="form-group">
														<label className="text-label">
															Expense Category
														</label>
														<div>
															<select
																className="form-control"
																id="val-expenseCategory"
																placeholder="Select a Expense Category.."
																name="expenseCategory"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.expenseCategory}
															>
																{/* <option
																	value="Select"
																	label="Select"
																	disabled
																/> */}
																<option value="all" label="All" />
																{category_data?.map((opt) => (
																	<option key={opt._id} value={opt._id}>
																		{opt.categoryName}
																	</option>
																))}
															</select>
															{errors.expenseCategory &&
															touched.expenseCategory ? (
																<div
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.expenseCategory}
																</div>
															) : null}
														</div>
													</div>
												</div>
												<div className="col-xl-3 col-sm-12">
													<div className="form-group">
														<label className="text-label">Start Date</label>
														<div>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<DatePicker
																	id="startDate"
																	format="dd/MM/yyyy"
																	value={values.startDate}
																	onChange={(e) =>
																		setFieldValue("startDate", e)
																	}
																	onBlur={handleBlur}
																/>
															</MuiPickersUtilsProvider>
															{errors.startDate && touched.startDate ? (
																<div
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.startDate}
																</div>
															) : null}
														</div>
													</div>
												</div>
												<div className="col-xl-3 col-sm-12">
													<div className="form-group">
														<label className="text-label">End Date</label>
														<div>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<DatePicker
																	id="endDate"
																	format="dd/MM/yyyy"
																	value={values.endDate}
																	onChange={(e) => setFieldValue("endDate", e)}
																	onBlur={handleBlur}
																/>
															</MuiPickersUtilsProvider>
															{errors.endDate && touched.endDate ? (
																<div
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.endDate}
																</div>
															) : null}
														</div>
													</div>
												</div>

												<div className="col-xl-1 col-sm-12">
													<button className="btn btn-primary">Show</button>
												</div>
												<div className="col-xl-1 col-sm-12"></div>
											</div>
										</form>
									)}
								</Formik>
							</div>
							<hr />

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Category Name</th>
											<th>Amount</th>
											<th>Date</th>
										</tr>
									</thead>
									<tbody>
										{currentExpenseAccounts?.length
											? currentExpenseAccounts.map((expense, i) => (
													<tr key={i}>
														<td>{expense?.category?.categoryName} </td>
														<td>{expense?.Amount}</td>

														<td>
															{expense?.month &&
																format(new Date(expense?.month), "dd-MM-yyyy")}
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalExpenseAccounts ? (
										<Pagination
											totalRecords={totalExpenseAccounts}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default ExpenseAccount;
