import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
//import ChatBox from "../ChatBox";

const FastoNav = ({
	isAuthenticated,
	user,
	title,
	onClick: ClickToAddEvent,
	onClick2,
	onClick3,
}) => {
	// console.log("FastoNav.isAuthenticated", isAuthenticated, user);
	const [toggle, setToggle] = useState("");
	const onClick = (name) => setToggle(toggle === name ? "" : name);
	return (
		<Fragment>
			<NavHader />
			{/* <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} /> */}
			<Header
				//onNote={() => onClick("chatbox")}
				//onNotification={() => onClick("notification")}
				onProfile={() => onClick("profile")}
				toggle={toggle}
				title={title}
				//onBox={() => onClick("box")}
				onClick={() => ClickToAddEvent()}
			/>
			{isAuthenticated ? <SideBar /> : ""}
			{/* <SideBar /> */}
		</Fragment>
	);
};

export default FastoNav;
