import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Editor } from "@tinymce/tinymce-react";

import { Formik } from "formik"; //Field
import * as Yup from "yup";

import {
	getBillingTemplateAction,
	createBillingTemplateAction,
	updateBillingTemplateAction,
} from "../../../../store/actions/BillingTemplateActions";

class AddEditBillingTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			billingTemplate: {},
			temp: "",
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.billingTemplate) {
				this.props.getBillingTemplateAction(id); //Local
			}
		} else {
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.billingTemplate?.length !== this.props?.billingTemplate?.length
		) {
			this.setState({
				billingTemplate: this.props?.billingTemplate,
			});
		}
	}

	handleAdd = (values) => {
		// const id = this.props.match.params.id;
		// if (id) {
		// 	this.props.updateBillingTemplateAction(
		// 		values,
		// 		this.props.history,
		// 		this.props?.billingTemplate?._id
		// 	);
		// } else {
		// 	this.props.createBillingTemplateAction(values, this.props.history);
		// }
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var billingTemplate = this.props?.billingTemplate;
		} else {
			billingTemplate = [];
		}

		const initialValues = {
			societyId: this.state.societyId,
			templateType: billingTemplate ? billingTemplate.templateType : "",
			templateSubType: billingTemplate ? billingTemplate.templateSubType : "",
			template: billingTemplate ? billingTemplate.template : "",
			// status: billingTemplate ? billingTemplate.status : true,
		};

		const validationSchema = Yup.object().shape({
			templateType: Yup.string().required("Please Select Template Type."),
			templateSubType: Yup.string().required(
				"Please Select Template Sub Type."
			),
			template: Yup.string().required("Please Enter Template."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item
						linkProps={{ to: "/billing-templates" }}
						linkAs={Link}
					>
						Billing Template Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Billing Template</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Billing Template</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Add Billing Template</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/billing-templates"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Template Type *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-templateType"
																	placeholder="Select a Template Type.."
																	name="templateType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.templateType || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	<option value="Credit" label="Credit" />
																	<option value="Debit" label="Debit" />
																</select>
																{errors.templateType && touched.templateType ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.templateType}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Template Sub Type *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-templateSubType"
																	placeholder="Select a Template Type.."
																	name="templateSubType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.templateSubType || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	<option value="Header" label="Header" />
																	<option value="Footer" label="Footer" />
																</select>
																{errors.templateSubType &&
																touched.templateSubType ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.templateSubType}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-8 col-sm-12">
														<div className="form-group">
															<label className="text-label">Template *</label>
															<div>
																<Editor
																	//initialValue={values.template || ""}
																	init={{
																		height: 500,
																		menubar: false,
																		plugins: [
																			"advlist autolink lists link image code charmap print preview anchor",
																			"searchreplace visualblocks code fullscreen",
																			"insertdatetime media table paste code help wordcount",
																		],
																		toolbar:
																			"undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help ",
																		content_style: "body { color: #828282 }",
																	}}
																	onEditorChange={(e) =>
																		handleChange({
																			target: { name: "template", value: e },
																		})
																	}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.template || ""}
																/>
																{errors.template && touched.template ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.template}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												{/* <div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div> */}

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddBillingTemplate;
const mapStateToProps = (state) => {
	return {
		billingTemplate: state.billingTemplate?.billingTemplate,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getBillingTemplateAction,
			createBillingTemplateAction,
			updateBillingTemplateAction,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddEditBillingTemplate);
