import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import { format } from "date-fns";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { getPollingsAction } from "../../../../../store/actions/PollingActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class Polling extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentPollings: [],
			currentPage: null,
			totalPages: null,
		};
	}

	componentDidMount() {
		let flatData = JSON.parse(localStorage.getItem("userFlatDetails"));

		this.props.getPollingsAction(flatData?.societyId);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.pollings?.polls?.length !== this.props?.pollings?.polls?.length
		) {
			this.setState({
				currentPollings: this.props?.pollings?.polls,
			});
		}
	}

	initData() {
		let currentPollings =
			this.state?.currentPollings?.length > 0
				? this.state?.currentPollings
				: this.props?.pollings?.polls;

		this.setState({ currentPollings });
	}

	onPageChanged = (data) => {
		const allPollings = this.props?.pollings?.polls;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentPollings = allPollings?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentPollings, totalPages });
	};

	handleSearch(event) {
		let currentPollings = this.props.pollings?.polls;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentPollings = currentPollings.filter(
				(e) =>
					e.pollDescription.toLowerCase().match(searchString) ||
					(e.pollStartDate &&
						format(new Date(e.pollStartDate), "dd-MM-yyyy")
							.toLowerCase()
							.match(searchString)) ||
					(e.pollEndDate &&
						format(new Date(e.pollEndDate), "dd-MM-yyyy")
							.toLowerCase()
							.match(searchString))
			);
			this.setState({ currentPollings });
		} else {
			let arrayLength = currentPollings.slice(0, 5);
			this.setState({ currentPollings: arrayLength });
		}
		//this.setState({ currentPollings });
	}

	render() {
		let totalPollings = this.props?.pollings?.polls?.length || 0;

		const { currentPollings, currentPage, totalPages } = this.state;
		this.state.currentPollings = this.props.pollings?.polls;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Polling Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Polling Lists</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Polling Description</th>
											<th>Polling Start Date</th>
											<th>Polling End Date</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentPollings?.length
											? currentPollings.map((polling, i) => (
													<tr key={i}>
														<td>{polling.pollDescription} </td>
														<td>
															{polling.pollStartDate &&
																format(
																	new Date(polling?.pollStartDate),
																	"dd-MM-yyyy"
																)}
														</td>
														<td>
															{polling.pollEndDate &&
																format(
																	new Date(polling?.pollEndDate),
																	"dd-MM-yyyy"
																)}
														</td>
														<td>
															<div className="d-flex">
																{/* <Link
																	to={`add-polling/${polling._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link> */}
																<Link
																	to={`member-polling-details/${polling._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
															</div>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalPollings ? (
										<Pagination
											totalRecords={totalPollings}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		pollings: state.polling?.pollings,
		loading: state?.polling?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getPollingsAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Polling);
