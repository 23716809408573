import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

import { getMaintenanceMasterAction } from "../../../store/actions/MaintenanceMasterActions";

class MaintenanceInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			societyId: "",
		};
	}
	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		this.props.getMaintenanceMasterAction(userData.user.societyName);
	}

	render() {
		const maintenanceMaster = this.props?.maintenancemaster;
		const maintenanceType = maintenanceMaster?.maintenanceType || "";
		const maintenancePeriod = maintenanceMaster?.maintenancePeriod || "";
		const residentialCharges = maintenanceMaster?.residentialCharges || "";
		const commercialCharges = maintenanceMaster?.commercialCharges || "";
		const twoWheelerParkingCharges =
			maintenanceMaster?.twoWheelerParkingCharges || "";
		const fourWheelerParkingCharges =
			maintenanceMaster?.fourWheelerParkingCharges || "";
		const festivalChargesRequired =
			maintenanceMaster?.festivalChargesRequired || "";
		const festivalCharges = maintenanceMaster?.festivalCharges || "";
		const sinkingFundCharges = maintenanceMaster?.sinkingFundCharges || "";
		const repairCharges = maintenanceMaster?.repairCharges || "";
		const cidcoChargesRequired = maintenanceMaster?.cidcoChargesRequired || "";
		const cidcoChargesPercent = maintenanceMaster?.cidcoChargesPercent || "";
		const nocChargesCaltype = maintenanceMaster?.nocChargesCaltype || "";
		const nocCharges = maintenanceMaster?.nocCharges || "";
		const arrearsInterestType = maintenanceMaster?.arrearsInterestType || "";
		const arrearsInterest = maintenanceMaster?.arrearsInterest || "";
		const status = maintenanceMaster?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Maintenance Information</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Maintenance Information</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						{!this.props.maintenancemaster ? (
							<Link
								to={"add-maintenance-information"}
								className="btn btn-primary mb-2 pull-right"
							>
								Add
							</Link>
						) : (
							<Link
								to={`add-maintenance-information/${this.props.maintenancemaster._id}`}
								className="btn btn-primary mb-2 pull-right"
							>
								Edit
							</Link>
						)}
					</div>
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between bg-blue">
										<strong className="pl-2">Particulars</strong>
										<strong className="pr-2">Values</strong>
									</li>
								</ul>
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Maintenance Type</strong>
										<span className="mb-0">{maintenanceType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Maintenance Periods</strong>
										<span className="mb-0 lineBreak">{maintenancePeriod}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Residential Charges</strong>
										<span className="mb-0">{residentialCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Commercial Charges</strong>
										<span className="mb-0">{commercialCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Two Wheeler Parking charges</strong>
										<span className="mb-0">{twoWheelerParkingCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Four Wheeler Parking Charges</strong>
										<span className="mb-0">{fourWheelerParkingCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Festival Charges Required</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{festivalChargesRequired === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Festival Charges</strong>
										<span className="mb-0">{festivalCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Sinking Fund Charges</strong>
										<span className="mb-0">{sinkingFundCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Repair Charges Percent</strong>
										<span className="mb-0">{repairCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Cidco Charges Required</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{cidcoChargesRequired === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Cidco Charges Percent</strong>
										<span className="mb-0">{cidcoChargesPercent}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>NOC Charges Calculation Type</strong>
										<span className="mb-0">{nocChargesCaltype}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Non Occupancy Charges</strong>
										<span className="mb-0">{nocCharges}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Arrears Interest Type</strong>
										<span className="mb-0">{arrearsInterestType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Arrears Interest Value</strong>
										<span className="mb-0">{arrearsInterest}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		maintenancemaster: state.maintenancemaster.maintenancemaster,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getMaintenanceMasterAction }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MaintenanceInformation);
