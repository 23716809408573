import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";

import {
	getPollingAction,
	createPollingAction,
	updatePollingAction,
} from "../../../../store/actions/PollingActions";

class AddEditPolling extends Component {
	constructor(props) {
		super(props);
		this.state = {
			polling: {},
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.polling) {
				this.props.getPollingAction(id);
			}
		} else {
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.polling?.length !== this.props?.polling?.length) {
			this.setState({
				polling: this.props?.polling,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updatePollingAction(
				values,
				this.props.history,
				this.props?.polling?._id
			);
		} else {
			this.props.createPollingAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var polling = this.props?.polling;
		} else {
			polling = "";
		}

		const initialValues = {
			societyId: this.state.societyId,
			pollDescription: polling ? polling.pollDescription : "",
			pollStartDate: polling ? polling.pollStartDate : new Date(),
			pollEndDate: polling ? polling.pollEndDate : new Date(),
			pollOptions: polling ? polling.pollOptions : "",
			status: polling ? polling.status : true,
		};

		const validationSchema = Yup.object().shape({
			pollDescription: Yup.string().required("Please Enter Poll Description."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/pollings" }} linkAs={Link}>
						Polling Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Polling</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Polling</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Polling</h3>
						) : (
							<h3 className="pt-3">Add Polling</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/pollings"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Polling Description *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-pollDescription"
																	placeholder="Enter a Poll Desc.."
																	name="pollDescription"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.pollDescription || ""}
																/>
																{errors.pollDescription &&
																touched.pollDescription ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.pollDescription}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Polling Start Date
															</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="pollStartDate"
																		format="dd/MM/yyyy"
																		value={values.pollStartDate}
																		onChange={(e) =>
																			setFieldValue("pollStartDate", e)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.pollStartDate &&
																touched.pollStartDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.pollStartDate}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Polling End Date
															</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="pollEndDate"
																		format="dd/MM/yyyy"
																		value={values.pollEndDate}
																		onChange={(e) =>
																			setFieldValue("pollEndDate", e)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.pollEndDate && touched.pollEndDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.pollEndDate}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<FieldArray
														name="pollOptions"
														render={(arrayHelpers) => (
															<div className="pollOptionInput">
																{values.pollOptions &&
																values.pollOptions.length > 0 ? (
																	values.pollOptions.map((poll, index) => (
																		<div key={index}>
																			<Field
																				name={`pollOptions.${index}.option`}
																				placeholder="Options"
																			/>

																			<button
																				type="button"
																				onClick={() =>
																					arrayHelpers.remove(index)
																				} // remove a friend from the list
																			>
																				-
																			</button>
																			<button
																				type="button"
																				onClick={() =>
																					arrayHelpers.insert(index, "")
																				} // insert an empty string at a position
																			>
																				+
																			</button>
																		</div>
																	))
																) : (
																	<button
																		type="button"
																		onClick={() => arrayHelpers.push("")}
																	>
																		{/* show this when user has removed all friends from the list */}
																		Click to Add Polling Option
																	</button>
																)}
															</div>
														)}
													/>
												</div>

												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddPolling;
const mapStateToProps = (state) => {
	return {
		polling: state.polling?.polling,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getPollingAction,
			createPollingAction,
			updatePollingAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPolling);
