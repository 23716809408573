import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import { format } from "date-fns";
import ReactHtmlParser from "react-html-parser";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { getMeetingsAction } from "../../../../../store/actions/MeetingActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class Meeting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentMeetings: [],
			currentPage: null,
			totalPages: null,
		};
	}

	componentDidMount() {
		let flatData = JSON.parse(localStorage.getItem("userFlatDetails"));

		this.props.getMeetingsAction(flatData?.societyId);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.meetings?.meetings?.length !==
			this.props?.meetings?.meetings?.length
		) {
			this.setState({
				currentMeetings: this.props?.meetings?.meetings,
			});
		}
	}

	initData() {
		let currentMeetings =
			this.state?.currentMeetings?.length > 0
				? this.state?.currentMeetings
				: this.props?.meetings?.meetings;

		this.setState({ currentMeetings });
	}

	onPageChanged = (data) => {
		const allMeetings = this.props?.meetings?.meetings;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentMeetings = allMeetings?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentMeetings, totalPages });
	};

	handleSearch(event) {
		let currentMeetings = this.props.meetings?.meetings;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentMeetings = currentMeetings.filter(
				(e) =>
					e.meetingType.toLowerCase().match(searchString) ||
					e.meetingAgenda.toLowerCase().match(searchString) ||
					e.meetingDate.toLowerCase().match(searchString)
			);
			this.setState({ currentMeetings });
		} else {
			let arrayLength = currentMeetings.slice(0, 5);
			this.setState({ currentMeetings: arrayLength });
		}
		//this.setState({ currentMeetings });
	}

	render() {
		let totalMeetings = this.props?.meetings?.meeting?.length || 0;

		const { currentMeetings, currentPage, totalPages } = this.state;
		this.state.currentMeetings = this.props.meetings?.meetings;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Meeting Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Meeting Lists</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Meeting Type</th>
											<th>Meeting Agenda</th>
											<th>Meeting Date</th>
											{/* <th>Meeting Minutes</th>
										<th>Meeting Resolution</th> */}
											{/* <th>Action</th> */}
										</tr>
									</thead>
									<tbody>
										{currentMeetings?.length
											? currentMeetings.map((meeting, i) => (
													<tr key={i}>
														<td>{meeting.meetingType} </td>
														<td>
															{meeting.meetingAgenda &&
																ReactHtmlParser(meeting.meetingAgenda)}
														</td>
														<td>
															{meeting.meetingDate &&
																format(
																	new Date(meeting?.meetingDate),
																	"dd-MM-yyyy"
																)}
														</td>
														{/* <td>{meeting.meetingMinutes}</td>
													<td>{meeting.meetingResolution}</td> */}
														{/* <td>
															<div className="d-flex">
																<Link
																	to={`add-meeting/${meeting._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link>
																<Link
																	to={`meeting-details/${meeting._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
																<Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={this.deleteRecord.bind(
																		this,
																		meeting._id
																	)}
																>
																	<i className="fa fa-trash"></i>
																</Link>
															</div>
														</td> */}
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalMeetings ? (
										<Pagination
											totalRecords={totalMeetings}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		meetings: state.meeting?.meetings,
		loading: state?.meeting?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getMeetingsAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Meeting);
