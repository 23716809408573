import {
	CONFIRMED_GET_PAYMENTRECEIPT_DETAILS,
	CONFIRMED_CREATE_PAYMENTRECEIPT_ACTION,
	CONFIRMED_GET_PAYMENTRECEIPTS,
	CONFIRMED_EDIT_PAYMENTRECEIPT_ACTION,
	CONFIRMED_DELETE_PAYMENTRECEIPT_ACTION,
} from "../actions/PaymentReceiptTypes";

const initialState = {
	paymentReceipts: [],
	paymentReceipt: {},
};

export default function PaymentReceiptReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_PAYMENTRECEIPT_DETAILS) {
		return {
			...state,
			paymentReceipt: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_PAYMENTRECEIPT_ACTION) {
		const paymentReceipts = [
			...state.paymentReceipt.paymentReceipts.paymentReceipts,
		];
		const paymentReceiptIndex = paymentReceipts.findIndex(
			(paymentReceipt) => paymentReceipt.id === actions.payload
		);

		paymentReceipts.splice(paymentReceiptIndex, 1);

		return {
			...state,
			paymentReceipts,
		};
	}

	if (actions.type === CONFIRMED_EDIT_PAYMENTRECEIPT_ACTION) {
		return {
			...state,
			paymentReceipts: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_PAYMENTRECEIPT_ACTION) {
		return {
			...state,
			paymentReceipts: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_PAYMENTRECEIPTS) {
		return {
			...state,
			paymentReceipts: actions.payload,
		};
	}

	return state;
}
