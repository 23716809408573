import {
	CONFIRMED_GET_AMENITYMASTER_DETAILS,
	CONFIRMED_CREATE_AMENITYMASTER_ACTION,
	CONFIRMED_GET_AMENITYMASTERS,
	CONFIRMED_EDIT_AMENITYMASTER_ACTION,
	CONFIRMED_DELETE_AMENITYMASTER_ACTION,
	LOADING,
} from "../actions/AmenitiesMasterTypes";

const initialState = {
	amenitymasters: [],
	amenitymaster: {},
};

export default function AmenityMasterReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_AMENITYMASTER_DETAILS) {
		return {
			...state,
			amenitymaster: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_AMENITYMASTER_ACTION) {
		const amenitymasters = [...state.amenitymasters.amenities];
		const amenitymasterIndex = amenitymasters.findIndex(
			(amenitymaster) => amenitymaster.id === actions.payload
		);

		amenitymasters.splice(amenitymasterIndex, 1);
		const amenities = amenitymasters;

		return {
			...state,
			amenitymasters: { amenities },
		};
	}

	if (actions.type === CONFIRMED_EDIT_AMENITYMASTER_ACTION) {
		// const maintenancemasters = [
		// 	...state.maintenancemaster.maintenancemasters.maintenancemasters,
		// ];
		// const maintenancemasterIndex = maintenancemasters.findIndex(
		// 	(maintenancemaster) => maintenancemaster.id === actions.payload.id
		// );

		// maintenancemasters[maintenancemasterIndex] = actions.payload;
		return {
			...state,
			amenitymasters: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_AMENITYMASTER_ACTION) {
		return {
			...state,
			amenitymasters: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_AMENITYMASTERS) {
		return {
			...state,
			amenitymasters: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
