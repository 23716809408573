import {
	CONFIRMED_GET_QUERY_DETAILS,
	CONFIRMED_CREATE_QUERY_ACTION,
	CONFIRMED_GET_QUERIES,
	CONFIRMED_EDIT_QUERY_ACTION,
	CONFIRMED_DELETE_QUERY_ACTION,
	LOADING,
} from "../actions/RequestQueryTypes";

const initialState = {
	queries: [],
	query: {},
};

export default function RequestQueryReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_QUERY_DETAILS) {
		return {
			...state,
			query: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_QUERY_ACTION) {
		const queries = [...state.queries.queriess];
		const queryIndex = queries.findIndex(
			(query) => query.id === actions.payload
		);

		queries.splice(queryIndex, 1);

		const queriess = queries;

		return {
			...state,
			queries: { queriess },
		};
	}

	if (actions.type === CONFIRMED_EDIT_QUERY_ACTION) {
		return {
			...state,
			queries: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_QUERY_ACTION) {
		return {
			...state,
			queries: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_QUERIES) {
		return {
			...state,
			queries: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
