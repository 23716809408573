export const GET_BILLINGADJUSTMENT_DETAILS =
	"[Billing Adjustment Action] Get Billing Adjustment";
export const CONFIRMED_GET_BILLINGADJUSTMENT_DETAILS =
	"[Billing Adjustment Action] Confirmed Get Billing Adjustment";
export const CREATE_BILLINGADJUSTMENT_ACTION =
	"[Billing Adjustment Action] Create Billing Adjustment";
export const CONFIRMED_CREATE_BILLINGADJUSTMENT_ACTION =
	"[Billing Adjustment Action] Confirmed Create Billing Adjustment";
export const GET_BILLINGADJUSTMENTS =
	"[Billing Adjustments Action] Get Billing Adjustments";
export const CONFIRMED_GET_BILLINGADJUSTMENTS =
	"[Billing Adjustments Action] Confirmed Get Billing Adjustments";
export const EDIT_BILLINGADJUSTMENT_ACTION =
	"[Billing Adjustment Action] Edit Billing Adjustment";
export const CONFIRMED_EDIT_BILLINGADJUSTMENT_ACTION =
	"[Billing Adjustment Action] Confirmed Edit Billing Adjustment";
export const CONFIRMED_DELETE_BILLINGADJUSTMENT_ACTION =
	"[Billing Adjustment Action] Confirmed Delete Billing Adjustment";
export const GET_BILLINGADJUSTMENTSBYFLAT =
	"[Billing Adjustments By Flat Action] Get Billing Adjustments By Flat";
export const CONFIRMED_GET_BILLINGADJUSTMENTSBYFLAT =
	"[Billing Adjustments By Flat Action] Confirmed Get Billing Adjustments By Flat";
export const LOADING = "[Billing Adjustment Loading Action] Confirmed Loading";
