export const GET_MAINTENANCEMASTER_DETAILS =
	"[Maintenance Master Action] Get Maintenance Master";
export const CONFIRMED_GET_MAINTENANCEMASTER_DETAILS =
	"[Maintenance Master Action] Confirmed Get Maintenance Master";
export const CREATE_MAINTENANCEMASTER_ACTION =
	"[Maintenance Master Action] Create Maintenance Master";
export const CONFIRMED_CREATE_MAINTENANCEMASTER_ACTION =
	"[Maintenance Master Action] Confirmed Create Maintenance Master";
export const GET_MAINTENANCEMASTERS =
	"[Maintenance Master Action] Get Maintenance Masters";
export const CONFIRMED_GET_MAINTENANCEMASTERS =
	"[Maintenance Master Action] Confirmed Get Maintenance Masters";
export const EDIT_MAINTENANCEMASTER_ACTION =
	"[Maintenance Master Action] Edit Maintenance Master";
export const CONFIRMED_EDIT_MAINTENANCEMASTER_ACTION =
	"[Maintenance Master Action] Confirmed Edit Maintenance Master";
export const CONFIRMED_DELETE_MAINTENANCEMASTER_ACTION =
	"[Maintenance Master Action] Confirmed Delete Maintenance Master";
export const LOADING =
	"[Maintenance Master Loading Action] Confirmed Maintenance Master Loading";
