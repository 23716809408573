import axiosInstance from "../services/AxiosInstance";

export function getSocietyByUrl(societyurl) {
	return axiosInstance.get(`society/${societyurl}`);
}

export function getSocietyDetails(societyId) {
	return axiosInstance.get(`admin/society/${societyId}`);
}

export function getSocieties() {
	return axiosInstance.get(`admin/society`);
}

export function createSociety(societyData) {
	return axiosInstance.post(`admin/society`, societyData);
}

export function updateSociety(society, societyId) {
	return axiosInstance.put(`admin/society/${societyId}`, society);
}

export function deleteSociety(societyId) {
	return axiosInstance.delete(`admin/society/${societyId}`);
}

export function createSocietyBillingHeader(societyHeaderData) {
	return axiosInstance.post(`admin/societyHeader`, societyHeaderData);
}

export function createSocietyContact(societyContactData, societyId) {
	return axiosInstance.post(
		`admin/society/contacts/${societyId}`,
		societyContactData
	);
}

export function createSocietyImage(societyImageData, societyId) {
	return axiosInstance.post(
		`admin/society/images/${societyId}`,
		societyImageData
	);
}

export function deleteSocietyContact(societyId, contactId) {
	return axiosInstance.delete(
		`admin/society/contacts/delete/${societyId}/${contactId}`
	);
}

export function deleteSocietyImage(societyId, imageId) {
	return axiosInstance.delete(
		`admin/society/images/delete/${societyId}/${imageId}`
	);
}

export function updateSocietyContact(society, societyId, contactId) {
	return axiosInstance.put(
		`admin/society/contacts/${societyId}/${contactId}`,
		society
	);
}
