import {
	getMaintenanceDetails,
	getMaintenances,
	createMaintenance,
	updateMaintenance,
	deleteMaintenance,
	//getMaintenanceMaster,
} from "../../services/MaintenanceService";
import {
	CONFIRMED_GET_MAINTENANCE_DETAILS,
	CONFIRMED_CREATE_MAINTENANCE_ACTION,
	CONFIRMED_GET_MAINTENANCES,
	CONFIRMED_EDIT_MAINTENANCE_ACTION,
	CONFIRMED_DELETE_MAINTENANCE_ACTION,
	LOADING,
} from "./MaintenanceTypes";

export function getMaintenanceAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getMaintenanceDetails(societyId).then((response) => {
			dispatch(confirmedGetMaintenanceAction(response.data));
		});
	};
}

export function confirmedGetMaintenanceAction(maintenance) {
	return {
		type: CONFIRMED_GET_MAINTENANCE_DETAILS,
		payload: maintenance,
	};
}

export function deleteMaintenanceAction(maintenanceId, history) {
	return (dispatch, getState) => {
		deleteMaintenance(maintenanceId).then((response) => {
			dispatch(confirmedDeleteMaintenanceAction(maintenanceId));
			history.push("/maintenance-management");
		});
	};
}

export function confirmedDeleteMaintenanceAction(MaintenanceId) {
	return {
		type: CONFIRMED_DELETE_MAINTENANCE_ACTION,
		payload: MaintenanceId,
	};
}

export function createMaintenanceAction(MaintenanceData, history) {
	return (dispatch, getState) => {
		createMaintenance(MaintenanceData).then((response) => {
			const singleMaintenance = {
				...MaintenanceData,
				id: response.data._id,
			};
			dispatch(confirmedCreateMaintenanceAction(singleMaintenance));
			history.push("/maintenance-management");
		});
	};
}

export function getMaintenancesAction() {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getMaintenances().then((response) => {
			dispatch(confirmedGetMaintenancesAction(response.data));
		});
	};
}

export function confirmedCreateMaintenanceAction(singleMaintenance) {
	return {
		type: CONFIRMED_CREATE_MAINTENANCE_ACTION,
		payload: singleMaintenance,
	};
}

export function confirmedGetMaintenancesAction(Maintenances) {
	return {
		type: CONFIRMED_GET_MAINTENANCES,
		payload: Maintenances,
	};
}

export function confirmedUpdateMaintenanceAction(Maintenance) {
	return {
		type: CONFIRMED_EDIT_MAINTENANCE_ACTION,
		payload: Maintenance,
	};
}

export function updateMaintenanceAction(Maintenance, history) {
	return (dispatch, getState) => {
		updateMaintenance(Maintenance, Maintenance.id).then((reponse) => {
			dispatch(confirmedUpdateMaintenanceAction(Maintenance));
			history.push("/maintenance-management");
		});
	};
}
