export const GET_REQUESTDEMO_DETAILS = "[RequestDemo Action] Get RequestDemo";
export const CONFIRMED_GET_REQUESTDEMO_DETAILS =
	"[RequestDemo Action] Confirmed Get RequestDemo";
export const CREATE_REQUESTDEMO_ACTION =
	"[RequestDemo Action] Create RequestDemo";
export const CONFIRMED_CREATE_REQUESTDEMO_ACTION =
	"[RequestDemo Action] Confirmed Create RequestDemo";
export const GET_REQUESTDEMOS = "[RequestDemos Action] Get RequestDemos";
export const CONFIRMED_GET_REQUESTDEMOS =
	"[RequestDemos Action] Confirmed Get RequestDemos";
export const EDIT_REQUESTDEMO_ACTION = "[RequestDemo Action] Edit RequestDemo";
export const CONFIRMED_EDIT_REQUESTDEMO_ACTION =
	"[RequestDemo Action] Confirmed Edit RequestDemo";
export const CONFIRMED_DELETE_REQUESTDEMO_ACTION =
	"[RequestDemo Action] Confirmed Delete RequestDemo";
export const REQUESTDEMOS_LOADING =
	"[RequestDemos Loading Action] RequestDemos Loading";
