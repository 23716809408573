import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { format } from "date-fns";
//import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { FaFilePdf } from "react-icons/fa";
import societylogo from "../../../../images/logo-full.jpg";
import { ToastContainer, toast } from "react-toastify";

import {
	getPaymentReceiptsAction,
	getPaymentReceiptAction,
} from "../../../../store/actions/PaymentReceiptActions";

import ReactExport from "react-data-export";
import { FaFileExcel } from "react-icons/fa";
import axiosInstance from "../../../../services/AxiosInstance";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const thisYear = new Date().getFullYear();
const mnth = new Date();
const thisMonth = monthNames[mnth.getMonth()];

class PaymentReceipt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentPaymentReceipts: [],
			currentPage: null,
			totalPages: null,
			selectYear: thisYear,
			selectMonth: thisMonth,
			societyId: "",
			years: [],
		};
		this.handleChangeYear = this.handleChangeYear.bind(this);
		this.handleChangeMonth = this.handleChangeMonth.bind(this);
		this.showData = this.showData.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChangeYear(event) {
		this.setState({
			currentPaymentReceipts: [],
			selectYear: event.target.value,
		});
	}

	handleChangeMonth(event) {
		this.setState({
			currentPaymentReceipts: [],
			selectMonth: event.target.value,
		});
	}

	showData(event) {
		let filteredData = this.props?.paymentReceipts?.paymentReceipts
			?.filter(
				(item) =>
					item?.year === this.state?.selectYear.toString() &&
					item?.month.slice(0, 3).toLowerCase() ===
						this.state?.selectMonth.slice(0, 3).toLowerCase()
			)
			.slice(0, 5);
		this.setState({
			currentPaymentReceipts: filteredData,
		});
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		let currentYear = new Date().getFullYear();
		let lastYear = currentYear - 1;
		let nextYear = currentYear + 1;
		let yearsData = [];
		yearsData.push(lastYear, currentYear, nextYear);

		this.setState({
			societyId: userData.user.societyName,
			years: yearsData,
		});

		// this.props.getPaymentReceiptsAction(
		// 	userData.user.societyName,
		// 	this.state.selectYear,
		// 	this.state.selectMonth
		// );
		this.props.getPaymentReceiptsAction(userData.user.societyName);
		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.paymentReceipts?.paymentReceipts?.length !==
			this.props?.paymentReceipts?.paymentReceipts?.length
		) {
			this.setState({
				currentPaymentReceipts: this.props?.paymentReceipts?.paymentReceipts,
			});
		}
	}

	initData() {
		let currentPaymentReceipts =
			this.state?.currentPaymentReceipts?.length > 0
				? this.state?.currentPaymentReceipts?.filter(
						(item) =>
							item?.year === this.state?.selectYear.toString() &&
							item?.month.slice(0, 3).toLowerCase() ===
								this.state?.selectMonth.slice(0, 3).toLowerCase()
				  )
				: this.props?.paymentReceipts?.paymentReceipts?.filter(
						(item) =>
							item?.year === this.state?.selectYear.toString() &&
							item?.month.slice(0, 3).toLowerCase() ===
								this.state?.selectMonth.slice(0, 3).toLowerCase()
				  );

		this.setState({ currentPaymentReceipts });
	}

	onPageChanged = (data) => {
		const allPaymentReceipts = this.props?.paymentReceipts?.paymentReceipts?.filter(
			(item) =>
				item?.year === this.state?.selectYear.toString() &&
				item?.month.slice(0, 3).toLowerCase() ===
					this.state?.selectMonth.slice(0, 3).toLowerCase()
		);

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentPaymentReceipts = allPaymentReceipts?.slice(
			offset,
			offset + pageLimit
		);

		this.setState({ currentPage, currentPaymentReceipts, totalPages });
	};

	handleSearch(event) {
		let currentPaymentReceipts = this.props.paymentReceipts?.paymentReceipts;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentPaymentReceipts = currentPaymentReceipts.filter(
				(e) =>
					e.flatNo.toLowerCase().match(searchString) ||
					e.maintenanceMonth.toLowerCase().match(searchString) ||
					e.maintenanceYear.toLowerCase().match(searchString)
			);
		}
		this.setState({ currentPaymentReceipts });
	}

	handleSubmit(event) {
		//event.preventDefault();
		let filteredDate = this.state?.currentPaymentReceipts.filter(
			(item) =>
				item?.year === this.state?.selectYear &&
				item?.month.slice(0, 3).toLowerCase() ===
					this.state?.selectMonth.toLowerCase()
		);

		this.setState({ currentPaymentReceipts: filteredDate });
		event.preventDefault();
	}

	//Below code is to convert amount to words
	numberToEnglish(n, custom_join_character) {
		var string = n.toString(),
			units,
			tens,
			scales,
			start,
			end,
			chunks,
			chunksLen,
			chunk,
			ints,
			i,
			word,
			words;

		var and = custom_join_character || "and";

		/* Is number zero? */
		if (parseInt(string) === 0) {
			return "zero";
		}

		/* Array of units as words */
		units = [
			"",
			"One",
			"Two",
			"Three",
			"Four",
			"Five",
			"Six",
			"Seven",
			"Eight",
			"Nine",
			"Ten",
			"Eleven",
			"Twelve",
			"Thirteen",
			"Fourteen",
			"Fifteen",
			"Sixteen",
			"Seventeen",
			"Eighteen",
			"Nineteen",
		];

		/* Array of tens as words */
		tens = [
			"",
			"",
			"Twenty",
			"Thirty",
			"Forty",
			"Fifty",
			"Sixty",
			"Seventy",
			"Eighty",
			"Ninety",
		];

		/* Array of scales as words */
		scales = [
			"",
			"Thousand",
			"Million",
			"Billion",
			"Trillion",
			"Quadrillion",
			"Quintillion",
			"Sextillion",
			"Septillion",
			"Octillion",
			"Nonillion",
			"Decillion",
			"Undecillion",
			"Duodecillion",
			"Tredecillion",
			"Quatttuor-decillion",
			"Quindecillion",
			"Sexdecillion",
			"Septen-decillion",
			"Octodecillion",
			"Novemdecillion",
			"Vigintillion",
			"Centillion",
		];

		/* Split user arguemnt into 3 digit chunks from right to left */
		start = string.length;
		chunks = [];
		while (start > 0) {
			end = start;
			chunks.push(string.slice((start = Math.max(0, start - 3)), end));
		}

		/* Check if function has enough scale words to be able to stringify the user argument */
		chunksLen = chunks.length;
		if (chunksLen > scales.length) {
			return "";
		}

		/* Stringify each integer in each chunk */
		words = [];
		for (i = 0; i < chunksLen; i++) {
			chunk = parseInt(chunks[i]);

			if (chunk) {
				/* Split chunk into array of individual integers */
				ints = chunks[i]
					.split("")
					.reverse()
					.map(parseFloat);

				/* If tens integer is 1, i.e. 10, then add 10 to units integer */
				if (ints[1] === 1) {
					ints[0] += 10;
				}

				/* Add scale word if chunk is not zero and array item exists */
				if ((word = scales[i])) {
					words.push(word);
				}

				/* Add unit word if array item exists */
				if ((word = units[ints[0]])) {
					words.push(word);
				}

				/* Add tens word if array item exists */
				if ((word = tens[ints[1]])) {
					words.push(word);
				}

				/* Add 'and' string after units or tens integer if: */
				if (ints[0] || ints[1]) {
					/* Chunk has a hundreds integer or chunk is the first of multiple chunks */
					if (ints[2] || (!i && chunksLen)) {
						words.push(and);
					}
				}

				/* Add hundreds word if array item exists */
				if ((word = units[ints[2]])) {
					words.push(word + " Hundred");
				}
			}
		}

		return words.reverse().join(" ");
	}

	//Generate PDF code below
	async generatePDF(id) {
		const { data } = await axiosInstance
			.get(`admin/paymentReceipt/${id}`)
			.then((result) => result);

		this.setState({
			paymentReceipt: data?.paymentReceipt,
		});

		const societyData = await axiosInstance
			.get(`admin/society/${this.state.societyId}`)
			.then((result) => result);

		this.setState({
			society: societyData?.data?.society,
		});

		const TotalInWords = this.numberToEnglish(
			this.state.paymentReceipt?.amount,
			"and"
		);

		var doc = new jsPDF("p", "pt", "A4");

		doc.setFont("Poppins", "normal", 400);
		doc.text(200, 20, this.state?.society?.societyName);
		doc.text(
			20,
			40,
			`Payment Receipt For The Month of ${this.state.paymentReceipt?.month},${this.state.paymentReceipt?.year}`
		);
		doc.text(400, 40, `Flat No.: `);
		doc.text(500, 40, `${this.state.paymentReceipt?.flatNo || "NA"}`);

		doc.line(20, 60, 600, 60, "S");
		doc.text(20, 80, "Particulars: ").setFont("Poppins", "normal", 600);
		doc.text(500, 80, "Values").setFont("Poppins", "normal", 600);
		doc.line(20, 100, 600, 100, "S");
		// doc.text(20, 120, `Flat No.: `);
		// doc.text(500, 120, `${this.state.paymentReceipt?.flatNo || "NA"}`);
		doc.text(20, 120, `Payment Type: `);
		doc.text(500, 120, `${this.state.paymentReceipt?.paymentType || "NA"}`);
		doc.text(20, 140, `Payment Mode: `);
		doc.text(500, 140, `${this.state.paymentReceipt?.paymentMode || "NA"}`);
		doc.text(20, 160, `Payment Date: `);
		doc.text(
			500,
			160,
			`${(this.state.paymentReceipt?.date &&
				format(new Date(this.state.paymentReceipt?.date), "dd-MM-yyyy")) ||
				"NA"}`
		);
		doc.text(20, 180, `Bank Details: `);
		doc.text(500, 180, `${this.state.paymentReceipt?.bankDetails || "NA"}`);
		doc.text(20, 200, `Amount: `);
		doc.text(500, 200, `${this.state.paymentReceipt?.amount || 0}`);
		doc.line(20, 220, 600, 220, "S");
		doc.text(400, 240, `Grand Total: `);
		doc.text(500, 240, `${this.state.paymentReceipt?.amount || 0}`);
		doc.text(20, 280, `Amount in Words: ${TotalInWords} Only.`);
		doc.text(450, 710, `Powered by`);
		doc.addImage(societylogo, format, 450, 720, 100, 100, "", "NONE", 0);
		doc.save(
			`${this.state.paymentReceipt?.month}-${this.state.paymentReceipt?.year}-Payment Receipt-${this.state.paymentReceipt?.flatNo}.pdf`
		);
	}

	async generateReceipt(id) {
		const payload = {
		  paymentDetailsId: id,
		};
		const resp = await axiosInstance
		  .post(`admin/paymentReceipt`, payload)
		  .then((res) => res?.data)
		  .catch((err) => {
			return err;
		  });
	
		if (resp.success === true) {
		  toast.success(resp.message);
		} else {
		  toast.error("No paymentDetail found with this ID");
		}
	  }

	render() {
		let totalPaymentReceipts =
			this.props?.paymentReceipts?.paymentReceipts?.filter(
				(item) =>
					item?.year === this.state?.selectYear.toString() &&
					item?.month.slice(0, 3).toLowerCase() ===
						this.state?.selectMonth.slice(0, 3).toLowerCase()
			)?.length || 0;

		this.state.currentPaymentReceipts = this.state?.currentPaymentReceipts?.filter(
			(item) =>
				item?.year === this.state?.selectYear.toString() &&
				item?.month.slice(0, 3).toLowerCase() ===
					this.state?.selectMonth.slice(0, 3).toLowerCase()
		);
		const { currentPaymentReceipts, currentPage, totalPages } = this.state;

		// const { currentPaymentReceipts, currentPage, totalPages } = this.state;
		// this.state.currentPaymentReceipts = this.props.paymentReceipts?.paymentReceipts;

		// let filteredDate = this.state?.currentPaymentReceipts?.filter(
		// 	(item) =>
		// 		item?.year === this.state?.selectYear.toString() &&
		// 		item?.month.slice(0, 3).toLowerCase() ===
		// 			this.state?.selectMonth.slice(0, 3).toLowerCase()
		// );

		const DataSet = [
			{
				columns: [
					{ title: "Flat No" },
					{ title: "Bank Details" },
					{ title: "Maintenance Month" },
					{ title: "Maintenance Year" },
					{ title: "Payment Type" },
					{ title: "Payment Mode" },
					{ title: "Amount" },
				],
				data: this.props.paymentReceipts?.paymentReceipts
					?.filter(
						(item) =>
							item?.year === this.state?.selectYear.toString() &&
							item?.month.slice(0, 3).toLowerCase() ===
								this.state?.selectMonth.slice(0, 3).toLowerCase()
					)
					?.sort((a, b) => a?.flatNo.localeCompare(b?.flatNo))
					?.map((data) => [
						{ value: data.flatNo },
						{ value: data.bankDetails },
						{ value: data.month },
						{ value: data.year },
						{ value: data.paymentType },
						{ value: data.paymentMode },
						{ value: data.amount },
					]),
			},
		];

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Payment Receipts</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Payment Receipts</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									{/* <input
									type="text"
									className="form-control mb-2 search-list pull-right"
									placeholder="Search..."
									onChange={(e) => this.handleSearch(e)}
								/> */}
								</div>
								<div className="col-xl-4 col-sm-12">
									<Link
									to={"add-payment-receipt"}
									className="btn btn-primary mb-2 pull-right"
								>
									Add Payment Receipt
								</Link>
								</div>
							</div>
							<hr />
							<form onSubmit={this.handleSubmit}>
								<div className="row">
									<div className="col-xl-3 col-sm-12 ml-4">
										<div className="form-group">
											<div>
												<select
													className="form-control"
													id="selectYear"
													placeholder="Select a Select Year.."
													name="selectYear"
													onChange={this.handleChangeYear}
													value={this.state.selectYear}
												>
													<option value="Select" label="Select" disabled />
													{this.state.years?.map((opt, i) => (
														<option key={i} value={opt} label={opt} />
													))}
												</select>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-sm-12">
										<div className="form-group">
											<div>
												<select
													className="form-control"
													id="selectMonth"
													placeholder="Select a Select Month.."
													name="selectMonth"
													onChange={this.handleChangeMonth}
													value={this.state.selectMonth}
												>
													<option
														value="Select Month"
														label="Select Month"
														disabled
													/>
													<option value="January" label="January" />
													<option value="February" label="February" />
													<option value="March" label="March" />
													<option value="April" label="April" />
													<option value="May" label="May" />
													<option value="June" label="June" />
													<option value="July" label="July" />
													<option value="August" label="August" />
													<option value="September" label="September" />
													<option value="October" label="October" />
													<option value="November" label="November" />
													<option value="December" label="December" />
												</select>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-sm-12">
										<Link
											to="#"
											className="btn btn-primary"
											onClick={this.showData}
										>
											Show
										</Link>
									</div>
									{/* <div className="col-xl-3 col-sm-12">
										<button className="btn btn-primary">Show</button>
									</div> */}
								</div>
							</form>

							<div>
								<ExcelFile
									filename={`Payment Receipts-${this.state.selectMonth}-${this.state.selectYear}`}
									element={
										<button type="button" className="btn btn-primary ml-4">
											<FaFileExcel /> Export to Excel
										</button>
									}
								>
									<ExcelSheet
										dataSet={DataSet}
										name={`Payment Receipts-${this.state.selectMonth}-${this.state.selectYear}`}
									/>
								</ExcelFile>
							</div>

							<div className="card-body pb-0">
								<Table bordered striped responsive id="paymentReceipts">
									<thead>
										<tr>
											<th>Flat No</th>
											<th>Bank Detail</th>
											<th>Maintenance Month</th>
											<th>Maintenance year</th>
											<th>Payment Type</th>
											<th>Payment Mode</th>
											<th>Amount</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentPaymentReceipts?.length
											? currentPaymentReceipts
													?.sort((a, b) => a?.flatNo.localeCompare(b?.flatNo))
													?.map((payment, i) => (
														<tr key={i}>
															<td>{payment.flatNo} </td>
															<td>{payment.bankDetails} </td>
															<td>{payment.month}</td>
															<td>{payment.year}</td>
															<td>{payment.paymentType}</td>
															<td>{payment.paymentMode}</td>
															<td>{payment.amount}</td>
															<td>
																<div className="d-flex">
																	{/* <Link
																		to={`add-payment-receipt/${payment._id}`}
																		className="btn btn-info shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-pencil"></i>
																	</Link> */}
																	{/* <Link
																to={`payment-receipt-details/${payment._id}`}
																className="btn btn-primary shadow btn-xs sharp mr-1"
															>
																<i className="fa fa-th"></i>
															</Link>  */}
																	<Link
																		to="#"
																		className="btn btn-primary shadow btn-xs sharp mr-1"
																		onClick={this.generateReceipt.bind(
																		this,
																		payment._id
																		)}
																	>
																		<i className="fa fa-file"></i>
																	</Link>
																	{payment?.paymentReciptGenerated && (
																		<>{payment?.paymentReciptGenerated === "true" ? (
																		<Link
																			to="#"
																			className="btn btn-danger shadow btn-xs sharp"
																			onClick={this.generatePDF.bind(
																				this,
																				payment._id
																			)}
																		>
																			<FaFilePdf />
																		</Link>
																		) : (
																			<></>
																		)}</>
																		
																	)}
																	{/* {(payment?.paymentReciptGenerated || payment?.paymentReciptGenerated === 'true' ) && (
																		
																	)} */}
																	
																</div>
															</td>
														</tr>
													))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalPaymentReceipts ? (
										<Pagination
											totalRecords={totalPaymentReceipts}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		paymentReceipts: state.paymentReceipt?.paymentReceipts,
		paymentReceipt: state.paymentReceipt?.paymentReceipt,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getPaymentReceiptsAction, getPaymentReceiptAction },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReceipt);
