import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

import { getExpenseCategoryAction } from "../../../../store/actions/ExpenseCategoryActions";

class ExpenseCategoryDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expenseCategory: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.expenseCategory) {
			this.props.getExpenseCategoryAction(id);
		}
		this.init();
	}

	init() {
		this.state.expenseCategory = this.props?.expenseCategory;
	}

	render() {
		this.state.expenseCategory = this.props?.expenseCategory;

		const name = this.state.expenseCategory?.categoryName || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item
						linkProps={{ to: "/expense-category-lists" }}
						linkAs={Link}
					>
						Expense Category Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Expense Category Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Expense Category Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/expense-category-lists"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Category Name</strong>
										<span className="mb-0">{name}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		expenseCategory: state.expenseCategory?.expenseCategory,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getExpenseCategoryAction }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ExpenseCategoryDetail);
