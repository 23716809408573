export const GET_ADMINUSER_DETAILS = "[Admin User Action] Get Admin User";
export const CONFIRMED_GET_ADMINUSER_DETAILS =
	"[Admin User Action] Confirmed Get Admin User";
export const CREATE_ADMINUSER_ACTION = "[Admin User Action] Create Admin User";
export const CONFIRMED_CREATE_ADMINUSER_ACTION =
	"[Admin User Action] Confirmed Create Admin User";
export const GET_ADMINUSERS = "[Admin Users Action] Get Admin Users";
export const CONFIRMED_GET_ADMINUSERS =
	"[Admin Users Action] Confirmed Get Admin Users";
export const EDIT_ADMINUSER_ACTION = "[Admin User Action] Edit Admin User";
export const CONFIRMED_EDIT_ADMINUSER_ACTION =
	"[Admin User Action] Confirmed Edit Admin User";
export const CONFIRMED_DELETE_ADMINUSER_ACTION =
	"[Admin User Action] Confirmed Delete Admin User";
export const EDIT_ADMINUSERPASSWORD_ACTION =
	"[Admin Password Action] Edit Admin Password";
export const CONFIRMED_EDIT_ADMINUSERPASSWORD_ACTION =
	"[Admin Password Action] Confirmed Edit Admin Password";
export const LOADING = "[Admin User Loading] Confirmed Admin User Loading";
