import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

import { getAdminUserAction } from "../../../../store/actions/AdminUserActions";

class AdminUserDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			admin: {},
			userRole: "",
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
			userRole: userData.user.role,
		});

		const id = this.props.match.params.id;
		if (this.props.admin) {
			this.props.getAdminUserAction(id); //For Local
		}
		this.init();
	}

	init() {
		this.state.admin = this.props?.admin;
	}

	render() {
		this.state.admin = this.props?.admin;

		const userName = this.state.admin?.userName || "";
		const societyName = this.state.admin?.societyName?.societyName || "";
		const role = this.state.admin?.role || "";

		return (
			<>
				<Breadcrumb>
					{this.state?.userRole !== "superAdmin" && (
						<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
							Dashboard
						</Breadcrumb.Item>
					)}
					<Breadcrumb.Item linkProps={{ to: "/admin" }} linkAs={Link}>
						Admin User Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Admin User Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Admin Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/admin"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>User Name</strong>
										<span className="mb-0">{userName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Society Name</strong>
										<span className="mb-0">{societyName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>User Role</strong>
										<span className="mb-0">{role}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		admin: state.admin?.adminUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getAdminUserAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserDetail);
