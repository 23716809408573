import {
	getBillingTemplateDetails,
	getBillingTemplates,
	createBillingTemplate,
	updateBillingTemplate,
	deleteBillingTemplate,
} from "../../services/BillingTemplateService";
import {
	CONFIRMED_GET_BILLINGTEMPLATE_DETAILS,
	CONFIRMED_CREATE_BILLINGTEMPLATE_ACTION,
	CONFIRMED_GET_BILLINGTEMPLATES,
	CONFIRMED_EDIT_BILLINGTEMPLATE_ACTION,
	CONFIRMED_DELETE_BILLINGTEMPLATE_ACTION,
} from "./BillingTemplateTypes";

export function getBillingTemplateAction(billingTemplateId) {
	return (dispatch, getState) => {
		getBillingTemplateDetails(billingTemplateId).then((response) => {
			dispatch(
				confirmedGetBillingTemplateAction(response.data.billingTemplate)
			);
		});
	};
}

export function confirmedGetBillingTemplateAction(billing) {
	return {
		type: CONFIRMED_GET_BILLINGTEMPLATE_DETAILS,
		payload: billing,
	};
}

export function deleteBillingTemplateAction(billingTemplateId, history) {
	return (dispatch, getState) => {
		deleteBillingTemplate(billingTemplateId).then((response) => {
			dispatch(confirmedDeleteBillingTemplateAction(billingTemplateId));
			history.push("/billing-templates");
		});
	};
}

export function confirmedDeleteBillingTemplateAction(billingTemplateId) {
	return {
		type: CONFIRMED_DELETE_BILLINGTEMPLATE_ACTION,
		payload: billingTemplateId,
	};
}

export function createBillingTemplateAction(TemplateData, history) {
	return (dispatch, getState) => {
		createBillingTemplate(TemplateData).then((response) => {
			const singleTemplate = {
				...TemplateData,
				id: response.data._id,
			};
			dispatch(confirmedCreateBillingTemplateAction(singleTemplate));
			history.push("/billing-templates");
		});
	};
}

export function getBillingTemplatesAction(societyId) {
	return (dispatch, getState) => {
		getBillingTemplates(societyId).then((response) => {
			dispatch(confirmedGetBillingTemplatesAction(response.data));
		});
	};
}

export function confirmedCreateBillingTemplateAction(singleTemplate) {
	return {
		type: CONFIRMED_CREATE_BILLINGTEMPLATE_ACTION,
		payload: singleTemplate,
	};
}

export function confirmedGetBillingTemplatesAction(Templates) {
	return {
		type: CONFIRMED_GET_BILLINGTEMPLATES,
		payload: Templates,
	};
}

export function confirmedUpdateBillingTemplateAction(Template) {
	return {
		type: CONFIRMED_EDIT_BILLINGTEMPLATE_ACTION,
		payload: Template,
	};
}

export function updateBillingTemplateAction(
	Template,
	history,
	billingTemplateId
) {
	return (dispatch, getState) => {
		updateBillingTemplate(Template, billingTemplateId).then((reponse) => {
			dispatch(confirmedUpdateBillingTemplateAction(Template));
			history.push("/billing-templates");
		});
	};
}
