import axiosInstance from "../services/AxiosInstance";

export function getVendorDetails(vendorId) {
	return axiosInstance.get(`admin/vendor/${vendorId}`);
}

export function getVendors(societyId) {
	return axiosInstance.get(`admin/vendors/${societyId}`);
}

export function createVendor(vendorData) {
	return axiosInstance.post(`admin/vendor`, vendorData);
}

export function updateVendor(vendor, vendorId) {
	return axiosInstance.put(`admin/vendor/${vendorId}`, vendor);
}

export function deleteVendor(vendorId) {
	return axiosInstance.delete(`admin/vendor/${vendorId}`);
}
