import axiosInstance from "../services/AxiosInstance";

export function getAmenityMasterDetails(amenityId) {
	return axiosInstance.get(`admin/amenity/${amenityId}`);
}

export function getAmenityMasters() {
	return axiosInstance.get(`admin/amenity`);
}

export function createAmenityMaster(amenityData) {
	return axiosInstance.post(`admin/amenity`, amenityData);
}

export function updateAmenityMaster(amenity, amenityId) {
	return axiosInstance.put(`admin/amenity/${amenityId}`, amenity);
}

export function deleteAmenityMaster(amenityId) {
	return axiosInstance.delete(`admin/amenity/${amenityId}`);
}
