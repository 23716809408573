import axiosInstance from "../services/AxiosInstance";

export function getBillingAdjustmentDetails(billingId) {
	return axiosInstance.get(`admin/billAdjustment/${billingId}`);
}

export function getBillingAdjustments(societyId) {
	return axiosInstance.get(`admin/billAdjustments/${societyId}`);
}

export function createBillingAdjustment(adjustmentData) {
	return axiosInstance.post(`admin/billAdjustment`, adjustmentData);
}

export function updateBillingAdjustment(adjustment, billingId) {
	return axiosInstance.put(`admin/billAdjustment/${billingId}`, adjustment);
}

export function deleteBillingAdjustment(billingId) {
	return axiosInstance.delete(`admin/billAdjustment/${billingId}`);
}

export function getBillingAdjustmentsByFlat(flatId) {
	return axiosInstance.get(`admin/billAdjustments/flat/${flatId}`);
}
