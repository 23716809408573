import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify"; //toast
import "react-toastify/dist/ReactToastify.css";

import { updateAdminPasswordAction } from "../../../store/actions/AdminUserActions";

class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			societyId: "",
			userId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
			userId: userData.user._id,
		});
	}

	handleAdd = (values) => {
		this.props.updateAdminPasswordAction(values, this.props.history);
	};

	render() {
		const initialValues = {
			userId: this.state.userId,
			oldPassword: "",
			password: "",
		};

		const validationSchema = Yup.object().shape({
			oldPassword: Yup.string().required("Please Enter Old Password."),
			password: Yup.string().required("Please Enter Password."),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Change Password</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Change Password</h3>
					</div>

					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Old Password *
															</label>
															<div>
																<input
																	type="password"
																	className="form-control"
																	id="val-oldPassword"
																	placeholder="Enter a Old Password.."
																	name="oldPassword"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.oldPassword || ""}
																/>
																{errors.oldPassword && touched.oldPassword ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.oldPassword}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Password *</label>
															<div>
																<input
																	type="password"
																	className="form-control"
																	id="val-password"
																	placeholder="Enter a Password.."
																	name="password"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.password || ""}
																/>
																{errors.password && touched.password ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.password}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
								<ToastContainer
									position="top-right"
									autoClose={3000}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default Change Password;
const mapStateToProps = (state) => {
	return {
		user: state.user?.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateAdminPasswordAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
