import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { format } from "date-fns";

import { getExpenseAction } from "../../../../store/actions/ExpenseActions";

class ExpenseDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expense: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.expense) {
			this.props.getExpenseAction(id);
		}
		this.init();
	}

	init() {
		this.state.expense = this.props?.expense;
	}

	render() {
		this.state.expense = this.props?.expense;

		const expenseType = this.state.expense?.expenseType || "";
		const expenseCategory =
			this.state.expense?.expenseCategory?.categoryName || "";
		const expenseDescription = this.state.expense?.expenseDescription || "";
		const expenseBillVoucherNo = this.state.expense?.expenseBillVoucherNo || "";
		const expenseBillDate =
			(this.state.expense?.expenseBillDate &&
				format(new Date(this.state.expense?.expenseBillDate), "dd-MM-yyyy")) ||
			"";
		const paymentMode = this.state.expense?.paymentMode || "";
		const paymentDate =
			(this.state.expense?.paymentDate &&
				format(new Date(this.state.expense?.paymentDate), "dd-MM-yyyy")) ||
			"";
		const amount = this.state.expense?.amount || "";
		const preparedBy = this.state.expense?.preparedBy || "";
		const approvedBy = this.state.expense?.approvedBy || "";
		const status = this.state.expense?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/expense" }} linkAs={Link}>
						Expense Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Expense Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Expense Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/expense"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Expense Type</strong>
										<span className="mb-0">{expenseType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Expense Category Name</strong>
										<span className="mb-0">{expenseCategory}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Expense Description</strong>
										<span className="mb-0">{expenseDescription}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Bill No.</strong>
										<span className="mb-0">{expenseBillVoucherNo}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Bill Date</strong>
										<span className="mb-0">{expenseBillDate}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Payment Mode</strong>
										<span className="mb-0">{paymentMode}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Payment Date</strong>
										<span className="mb-0">{paymentDate}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Amount</strong>
										<span className="mb-0">{amount}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Prepared By</strong>
										<span className="mb-0">{preparedBy}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Approved By</strong>
										<span className="mb-0">{approvedBy}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		expense: state.expense?.expense,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getExpenseAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDetail);
