import {
	CONFIRMED_GET_VENDORTYPE_DETAILS,
	CONFIRMED_CREATE_VENDORTYPE_ACTION,
	CONFIRMED_GET_VENDORTYPES,
	CONFIRMED_EDIT_VENDORTYPE_ACTION,
	CONFIRMED_DELETE_VENDORTYPE_ACTION,
	LOADING,
} from "../actions/VendorTypeTypes";

const initialState = {
	vendorTypes: [],
	vendorType: {},
};

export default function VendorTypeReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_VENDORTYPE_DETAILS) {
		return {
			...state,
			vendorType: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_VENDORTYPE_ACTION) {
		const vendorTypes = [...state.vendorTypes.vendorTypes];
		const vendorTypeIndex = vendorTypes.findIndex(
			(vendorType) => vendorType.id === actions.payload
		);

		vendorTypes.splice(vendorTypeIndex, 1);

		return {
			...state,
			vendorTypes: { vendorTypes },
		};
	}

	if (actions.type === CONFIRMED_EDIT_VENDORTYPE_ACTION) {
		return {
			...state,
			vendorTypes: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_VENDORTYPE_ACTION) {
		return {
			...state,
			vendorTypes: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_VENDORTYPES) {
		return {
			...state,
			vendorTypes: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
