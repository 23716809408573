import axiosInstance from "../services/AxiosInstance";

export function getExpenseDetails(expId) {
	return axiosInstance.get(`admin/expense/${expId}`);
}

export function getExpenses(societyId) {
	return axiosInstance.get(`admin/expenses/${societyId}`);
}

export function createExpense(expenseData) {
	return axiosInstance.post(`admin/expense`, expenseData);
}

export function updateExpense(expense, expId) {
	return axiosInstance.put(`admin/expense/${expId}`, expense);
}

export function deleteExpense(expId) {
	return axiosInstance.delete(`admin/expense/${expId}`);
}
