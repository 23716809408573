import axiosInstance from "../services/AxiosInstance";

export function getBillingHeaderMappingDetails(societyId) {
	return axiosInstance.get(`admin/labelMapping/${societyId}`);
}

export function getBillingHeaderMappings() {
	return axiosInstance.get(`admin/labelMapping`);
}

export function createBillingHeaderMapping(billingHeaderData) {
	return axiosInstance.post(`admin/labelMapping`, billingHeaderData);
}

export function updateBillingHeaderMapping(billingHeader, billingId) {
	return axiosInstance.put(`admin/labelMapping/${billingId}`, billingHeader);
}

export function deleteBillingHeaderMapping(billingId) {
	return axiosInstance.delete(`admin/labelMapping/${billingId}`);
}
