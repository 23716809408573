import {
	getPaymentReceiptDetails,
	getPaymentReceipts,
	createPaymentReceipt,
	updatePaymentReceipt,
	deletePaymentReceipt,
} from "../../services/PaymentReceiptService";
import {
	CONFIRMED_GET_PAYMENTRECEIPT_DETAILS,
	CONFIRMED_CREATE_PAYMENTRECEIPT_ACTION,
	CONFIRMED_GET_PAYMENTRECEIPTS,
	CONFIRMED_EDIT_PAYMENTRECEIPT_ACTION,
	CONFIRMED_DELETE_PAYMENTRECEIPT_ACTION,
} from "./PaymentReceiptTypes";

export function getPaymentReceiptAction(paymentId) {
	return (dispatch, getState) => {
		getPaymentReceiptDetails(paymentId).then((response) => {
			dispatch(confirmedGetPaymentReceiptAction(response.data.paymentReceipt));
		});
	};
}

export function confirmedGetPaymentReceiptAction(paymentReceipt) {
	return {
		type: CONFIRMED_GET_PAYMENTRECEIPT_DETAILS,
		payload: paymentReceipt,
	};
}

export function deletePaymentReceiptAction(paymentId, history) {
	return (dispatch, getState) => {
		deletePaymentReceipt(paymentId).then((response) => {
			dispatch(confirmedDeletePaymentReceiptAction(paymentId));
			history.push("/payment-receipts");
		});
	};
}

export function confirmedDeletePaymentReceiptAction(paymentId) {
	return {
		type: CONFIRMED_DELETE_PAYMENTRECEIPT_ACTION,
		payload: paymentId,
	};
}

export function createPaymentReceiptAction(PaymentReceiptData, history) {
	return (dispatch, getState) => {
		createPaymentReceipt(PaymentReceiptData).then((response) => {
			const singlePaymentReceipt = {
				...PaymentReceiptData,
				id: response.data._id,
			};
			dispatch(confirmedCreatePaymentReceiptAction(singlePaymentReceipt));
			history.push("/payment-receipts");
		});
	};
}

// export function getPaymentReceiptsAction(societyId, year, month) {
// 	return (dispatch, getState) => {
// 		getPaymentReceipts(societyId, year, month).then((response) => {
// 			dispatch(confirmedGetPaymentReceiptsAction(response.data));
// 		});
// 	};
// }
export function getPaymentReceiptsAction(societyId) {
	return (dispatch, getState) => {
		getPaymentReceipts(societyId).then((response) => {
			dispatch(confirmedGetPaymentReceiptsAction(response.data));
		});
	};
}

export function confirmedGetPaymentReceiptsAction(PaymentReceipts) {
	return {
		type: CONFIRMED_GET_PAYMENTRECEIPTS,
		payload: PaymentReceipts,
	};
}

export function confirmedCreatePaymentReceiptAction(singlePaymentReceipt) {
	return {
		type: CONFIRMED_CREATE_PAYMENTRECEIPT_ACTION,
		payload: singlePaymentReceipt,
	};
}

export function confirmedUpdatePaymentReceiptAction(PaymentReceipt) {
	return {
		type: CONFIRMED_EDIT_PAYMENTRECEIPT_ACTION,
		payload: PaymentReceipt,
	};
}

export function updatePaymentReceiptAction(PaymentReceipt, history, paymentId) {
	return (dispatch, getState) => {
		updatePaymentReceipt(PaymentReceipt, paymentId).then((reponse) => {
			dispatch(confirmedUpdatePaymentReceiptAction(PaymentReceipt));
			history.push("/payment-receipts");
		});
	};
}
