import {
	CONFIRMED_GET_SOCIETY_DETAILS,
	CONFIRMED_CREATE_SOCIETY_ACTION,
	CONFIRMED_GET_SOCIETIES,
	CONFIRMED_EDIT_SOCIETY_ACTION,
	CONFIRMED_DELETE_SOCIETY_ACTION,
	CONFIRMED_CREATE_SOCIETY_HEADER_ACTION,
	CONFIRMED_CREATE_SOCIETY_CONTACT_ACTION,
	CONFIRMED_CREATE_SOCIETY_IMAGE_ACTION,
	CONFIRMED_DELETE_SOCIETY_CONTACT_ACTION,
	CONFIRMED_DELETE_SOCIETY_IMAGE_ACTION,
	CONFIRMED_EDIT_SOCIETY_CONTACT_ACTION,
	SOCIETIES_LOADING,
} from "../actions/SocietyTypes";

const initialState = {
	societies: [],
	society: {},
	societyHeader: {},
	societyContact: {},
};

export default function SocietyReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_SOCIETY_DETAILS) {
		return {
			...state,
			society: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_SOCIETY_ACTION) {
		const societies = [...state.society.societies.societies];
		const societyIndex = societies.findIndex(
			(society) => society.id === actions.payload
		);

		societies.splice(societyIndex, 1);

		return {
			...state,
			societies,
		};
	}

	if (actions.type === CONFIRMED_EDIT_SOCIETY_ACTION) {
		// const societies = [...state.society.societies.societies];
		// const societyIndex = societies.findIndex(
		//     (society) => society.id === actions.payload.id,
		// );

		// societies[societyIndex] = actions.payload;
		return {
			...state,
			societies: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_SOCIETY_ACTION) {
		// const societies = [...state.societies];
		// societies.push(actions.payload);

		return {
			...state,
			societies: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_SOCIETIES) {
		return {
			...state,
			societies: actions.payload,
			loading: false,
		};
	}

	if (actions.type === CONFIRMED_CREATE_SOCIETY_HEADER_ACTION) {
		return {
			...state,
			societyHeader: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_SOCIETY_CONTACT_ACTION) {
		return {
			...state,
			societyContact: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_SOCIETY_IMAGE_ACTION) {
		return {
			...state,
			societies: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_SOCIETY_CONTACT_ACTION) {
		const societies = [...state.society.society.contactInfo];

		const societyIndex = societies.findIndex(
			(society) => society._id === actions.payload
		);

		societies.splice(societyIndex, 1);

		const society = {
			contactInfo: societies,
		};

		return {
			...state,
			society: { society },
		};
	}

	if (actions.type === CONFIRMED_DELETE_SOCIETY_IMAGE_ACTION) {
		const societies = [...state.society.society.societyImages];

		const societyIndex = societies.findIndex(
			(society) => society._id === actions.payload
		);

		societies.splice(societyIndex, 1);

		const society = {
			societyImages: societies,
		};

		return {
			...state,
			society: { society },
		};
	}

	if (actions.type === CONFIRMED_EDIT_SOCIETY_CONTACT_ACTION) {
		return {
			...state,
			societies: actions.payload,
		};
	}

	if (actions.type === SOCIETIES_LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
