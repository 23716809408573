import axiosInstance from "../services/AxiosInstance";

export function getNoticeDetails(noticeId) {
	return axiosInstance.get(`admin/notice/${noticeId}`);
}

export function getNotices(societyId) {
	return axiosInstance.get(`admin/notices/${societyId}`);
}

export function createNotice(noticeData) {
	return axiosInstance.post(`admin/notice`, noticeData);
}

export function updateNotice(notice, noticeId) {
	return axiosInstance.put(`admin/notice/${noticeId}`, notice);
}

export function deleteNotice(noticeId) {
	return axiosInstance.delete(`admin/notice/${noticeId}`);
}
