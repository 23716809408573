import { getDashboardDetails } from "../../services/DashboardService";
import { CONFIRMED_GET_DASHBOARD_DETAILS } from "./DashboardTypes"; //GET_DASHBOARD_DETAILS

export function getDashboardAction(societyId) {
	return (dispatch, getState) => {
		getDashboardDetails(societyId).then((response) => {
			let dashboard = response.data;
			dispatch(confirmedGetDashboardAction(dashboard));
		});
	};
}

export function confirmedGetDashboardAction(dashboard) {
	return {
		type: CONFIRMED_GET_DASHBOARD_DETAILS,
		payload: dashboard,
	};
}
