export const GET_AMENITYMASTER_DETAILS =
	"[Amenity Master Action] Get Aminity Master";
export const CONFIRMED_GET_AMENITYMASTER_DETAILS =
	"[Aminity Master Action] Confirmed Get Aminity Master";
export const CREATE_AMENITYMASTER_ACTION =
	"[Aminity Master Action] Create Aminity Master";
export const CONFIRMED_CREATE_AMENITYMASTER_ACTION =
	"[Aminity Master Action] Confirmed Create Aminity Master";
export const GET_AMENITYMASTERS = "[Aminity Master Action] Get Aminity Masters";
export const CONFIRMED_GET_AMENITYMASTERS =
	"[Aminity Master Action] Confirmed Get Aminity Masters";
export const EDIT_AMENITYMASTER_ACTION =
	"[Aminity Master Action] Edit Aminity Master";
export const CONFIRMED_EDIT_AMENITYMASTER_ACTION =
	"[Aminity Master Action] Confirmed Edit Aminity Master";
export const CONFIRMED_DELETE_AMENITYMASTER_ACTION =
	"[Aminity Master Action] Confirmed Delete Aminity Master";
export const LOADING = "[Amenity Loading Action] Confirmed Amenity Loading";
