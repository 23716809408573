import { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Breadcrumb } from "react-bootstrap";
import Pagination from "../../../components/Pagination";
//import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik } from "formik"; //Field
import * as Yup from "yup";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import axiosInstance from "../../../../services/AxiosInstance";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

const initialValues = {
	startDate: new Date(),
	endDate: new Date(),
};

const validationSchema = Yup.object().shape({
	startDate: Yup.date().required("Please Select Start Dt."),
	endDate: Yup.date()
		.min(Yup.ref("startDate"), "End date can't be before Start date")
		.required("Please Select End Date"),
});

class IncomeExpenditures extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentIncomeExpenditures: [],
			currentPage: null,
			totalPages: null,
			flats: [],
			loading: false,
		};
	}

	componentDidMount() {
		// let userData = JSON.parse(localStorage.getItem("userDetails"));
		// this.getFlats(userData.user.societyName);
	}

	// async getFlats(societyId) {
	// 	const { data } = await axiosInstance
	// 		.get(`admin/flats/${societyId}`)
	// 		.then((result) => result);
	// 	console.log("data", data);
	// 	this.setState({
	// 		flats: data.flats,
	// 	});
	// }

	handleAdd = (values) => {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.getIncomeExpenditures(userData.user.societyName, values);
	};

	async getIncomeExpenditures(societyId, values) {
		const payload = {
			//flatId: values.flatId,
			startDate: values.startDate,
			endDate: values.endDate,
		};
		this.setState({
			loading: true,
		});
		const { data } = await axiosInstance
			.post(`admin/report/incomeexpense/${societyId}`, payload)
			.then((result) => result);

		this.setState({
			currentIncomeExpenditures: data?.balanceSheet,
			loading: false,
		});
	}

	onPageChanged = (data) => {
		const allIncomeExpenditures = this.state.currentIncomeExpenditures;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentIncomeExpenditures = allIncomeExpenditures?.slice(
			offset,
			offset + pageLimit
		);

		this.setState({ currentPage, currentIncomeExpenditures, totalPages });
	};

	handleSearch(event) {
		let currentIncomeExpenditures = this.state.currentIncomeExpenditures;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentIncomeExpenditures = currentIncomeExpenditures.filter(
				(e) =>
					e.flatNo.toLowerCase().match(searchString) ||
					e.ownerName.toLowerCase().match(searchString) ||
					e.contactDetails.toLowerCase().match(searchString)
			);
		}
		this.setState({ currentIncomeExpenditures });
	}

	render() {
		let totalIncomeExpenditures =
			this.state?.currentIncomeExpenditures?.length || 0;

		const { currentIncomeExpenditures, currentPage, totalPages } = this.state;

		//let flat_data = this.state.flats;

		return (
			<>
				{this.state?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Income and Expenditure</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Income and Expenditure</h2>
								</div>
							</div>
							<hr />
							<div className="form-validation">
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									enableReinitialize={true}
									onSubmit={(values, { setSubmitting }) => {
										setTimeout(() => {
											this.handleAdd(values);
											setSubmitting(false);
										}, 400);
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										setFieldValue,
									}) => (
										<form onSubmit={handleSubmit}>
											<div className="row">
												<div className="col-xl-1 col-sm-12"></div>

												<div className="col-xl-3 col-sm-12">
													<div className="form-group">
														<label className="text-label">Start Date</label>
														<div>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<DatePicker
																	id="startDate"
																	format="dd/MM/yyyy"
																	value={values.startDate}
																	onChange={(e) =>
																		setFieldValue("startDate", e)
																	}
																	onBlur={handleBlur}
																/>
															</MuiPickersUtilsProvider>
															{errors.startDate && touched.startDate ? (
																<div
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.startDate}
																</div>
															) : null}
														</div>
													</div>
												</div>
												<div className="col-xl-3 col-sm-12">
													<div className="form-group">
														<label className="text-label">End Date</label>
														<div>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<DatePicker
																	id="endDate"
																	format="dd/MM/yyyy"
																	value={values.endDate}
																	onChange={(e) => setFieldValue("endDate", e)}
																	onBlur={handleBlur}
																/>
															</MuiPickersUtilsProvider>
															{errors.endDate && touched.endDate ? (
																<div
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.endDate}
																</div>
															) : null}
														</div>
													</div>
												</div>

												<div className="col-xl-1 col-sm-12">
													<button className="btn btn-primary">Show</button>
												</div>
												<div className="col-xl-1 col-sm-12"></div>
											</div>
										</form>
									)}
								</Formik>
							</div>
							<hr />

							<div className="card-body pb-0 row">
								<div className="col-xl-6 col-sm-12 p-0">
									<Table bordered striped responsive>
										<thead>
											<tr>
												<th colSpan="2" style={{ textAlign: "center" }}>
													Expenses
												</th>
											</tr>
											<tr>
												<th style={{ textAlign: "center" }}>Category Name</th>
												<th style={{ textAlign: "center" }}>Amount</th>
											</tr>
										</thead>
										<tbody>
											{currentIncomeExpenditures.expenses?.length
												? currentIncomeExpenditures.expenses.map((exp, i) => (
														<tr key={i}>
															<td>{exp?.category?.categoryName} </td>
															<td>{exp?.amount}</td>
															{/* <td>{exp?.income?.paymenttype}</td>
													<td>{exp?.income?.amount}</td> */}
														</tr>
												  ))
												: null}
										</tbody>
										{/* <tbody>
									{currentIncomeExpenditures.income?.length
										? currentIncomeExpenditures.income.map((inc, i) => (
												<tr key={i}>
													<td>{inc?.paymenttype}</td>
													<td>{inc?.amount}</td>
												</tr>
										  ))
										: null}
								</tbody> */}
									</Table>
								</div>
								<div className="col-xl-6 col-sm-12 p-0">
									<Table bordered striped responsive>
										<thead>
											<tr>
												<th colSpan="2" style={{ textAlign: "center" }}>
													Income
												</th>
											</tr>
											<tr>
												<th style={{ textAlign: "center" }}>Category Name</th>
												<th style={{ textAlign: "center" }}>Amount</th>
											</tr>
										</thead>
										<tbody>
											{currentIncomeExpenditures.income?.length
												? currentIncomeExpenditures.income.map((inc, i) => (
														<tr key={i}>
															<td>{inc?.paymenttype}</td>
															<td>{inc?.amount}</td>
														</tr>
												  ))
												: null}
										</tbody>
									</Table>
								</div>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalIncomeExpenditures ? (
										<Pagination
											totalRecords={totalIncomeExpenditures}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default IncomeExpenditures;
