import {
	getMeetingDetails,
	getMeetings,
	createMeeting,
	updateMeeting,
	deleteMeeting,
} from "../../services/MeetingService";
import {
	CONFIRMED_GET_MEETING_DETAILS,
	CONFIRMED_CREATE_MEETING_ACTION,
	CONFIRMED_GET_MEETINGS,
	CONFIRMED_EDIT_MEETING_ACTION,
	CONFIRMED_DELETE_MEETING_ACTION,
	LOADING,
} from "./MeetingTypes";

export function getMeetingAction(meetingId) {
	return (dispatch, getState) => {
		getMeetingDetails(meetingId).then((response) => {
			dispatch(confirmedGetMeetingAction(response.data.meeting));
		});
	};
}

export function confirmedGetMeetingAction(meeting) {
	return {
		type: CONFIRMED_GET_MEETING_DETAILS,
		payload: meeting,
	};
}

export function deleteMeetingAction(meetingId, history) {
	return (dispatch, getState) => {
		deleteMeeting(meetingId).then((response) => {
			dispatch(confirmedDeleteMeetingAction(meetingId));
			history.push("/meetings");
		});
	};
}

export function confirmedDeleteMeetingAction(meetingId) {
	return {
		type: CONFIRMED_DELETE_MEETING_ACTION,
		payload: meetingId,
	};
}

export function createMeetingAction(MeetingData, history) {
	return (dispatch, getState) => {
		createMeeting(MeetingData).then((response) => {
			const singleMeeting = {
				...MeetingData,
				id: response.data._id,
			};
			dispatch(confirmedCreateMeetingAction(singleMeeting));
			history.push("/meetings");
		});
	};
}

export function confirmedCreateMeetingAction(singleMeeting) {
	return {
		type: CONFIRMED_CREATE_MEETING_ACTION,
		payload: singleMeeting,
	};
}

export function getMeetingsAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getMeetings(societyId).then((response) => {
			dispatch(confirmedGetMeetingsAction(response.data));
		});
	};
}

export function confirmedGetMeetingsAction(Meetings) {
	return {
		type: CONFIRMED_GET_MEETINGS,
		payload: Meetings,
	};
}

export function confirmedUpdateMeetingAction(Meeting) {
	return {
		type: CONFIRMED_EDIT_MEETING_ACTION,
		payload: Meeting,
	};
}

export function updateMeetingAction(Meeting, history, meetingId) {
	return (dispatch, getState) => {
		updateMeeting(Meeting, meetingId).then((reponse) => {
			dispatch(confirmedUpdateMeetingAction(Meeting));
			history.push("/meetings");
		});
	};
}
