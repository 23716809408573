import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import axiosInstance from "../../../services/AxiosInstance";

//import profile from "../../../images/profile/profile.png";

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			profile: {},
			societyId: "",
			userRole: "",
			id: "",
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
			userRole: userData.user.role,
			id: userData.user._id,
		});
		this.getProfile();
	}

	async getProfile() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		const id = userData.user._id;
		const { data } = await axiosInstance
			.get(`admin/adminUser/${id}`)
			.then((result) => result);

		this.setState({
			profile: data?.adminUser,
		});
	}

	render() {
		const profileData = this.state.profile;

		const userName = profileData?.userName || "";
		const role = profileData?.role || "";
		const societyName = profileData?.societyName?.societyName || "";
		const societyImage = profileData?.societyName?.societyImages[0] || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Profile</Breadcrumb.Item>
				</Breadcrumb>

				<div className="row">
					<div className="col-lg-12">
						<div className="profile card card-body px-3 pt-3 pb-0">
							<div className="profile-head">
								<div className="photo-content">
									<div className="cover-photo">
										<img
											src={societyImage.fileurl}
											alt={societyImage.title}
											height="300"
											style={{ width: "100%" }}
										/>
									</div>
								</div>
								{/* <div className="profile-info">
									<div className="profile-details">
										<div className="profile-name px-3 pt-2">
											<h4 className="text-primary mb-0">Name</h4>
											<p>{userName}</p>
										</div>
										<div className="profile-email px-2 pt-2">
											<h4 className="text-muted mb-0">Role</h4>
											<p>{role}</p>
										</div>
										<div className="profile-email px-2 pt-2">
											<h4 className="text-muted mb-0">Society Name</h4>
											<p>{societyName}</p>
										</div>
									</div>
								</div> */}

								<div className="profile-personal-info p-5">
									<h4
										className="text-primary mb-4"
										style={{ textDecoration: "underline" }}
									>
										Personal Information:{" "}
									</h4>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">
												{" "}
												Name<span className="pull-right">:</span>
											</h5>
										</div>
										<div className="col-9">
											<span>{userName}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">
												Role<span className="pull-right">:</span>
											</h5>
										</div>
										<div className="col-9">
											<span>{role}</span>
										</div>
									</div>
									{role !== "superAdmin" && (
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">
													Society Name<span className="pull-right">:</span>
												</h5>
											</div>
											<div className="col-9">
												<span>{societyName}</span>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Profile;
