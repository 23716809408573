import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
	//baseURL: `http://localhost:8000/api/v1/`, // Local api
	//baseURL: `http://52.66.158.80:8000/api/v1`, // Dev api
	//baseURL: `http://13.235.245.14:8000/api/v1`, // Dev api
	baseURL: `https://societycare.in/api`, // Prod api
});

axiosInstance.interceptors.request.use((config) => {
	const state = store.getState();
	const token = state.auth.auth.idToken;
	config.params = config.params || {};
	//console.log('axiosInstance.state', state.auth.auth.idToken);
	//config.params["token"] = token;
	config.headers["token"] = token;
	//console.log(config);
	return config;
});

export default axiosInstance;
