import {
	getVendorDetails,
	getVendors,
	createVendor,
	updateVendor,
	deleteVendor,
} from "../../services/VendorService";
import {
	CONFIRMED_GET_VENDOR_DETAILS,
	CONFIRMED_CREATE_VENDOR_ACTION,
	CONFIRMED_GET_VENDORS,
	CONFIRMED_EDIT_VENDOR_ACTION,
	CONFIRMED_DELETE_VENDOR_ACTION,
	LOADING,
} from "./VendorTypes";

export function getVendorAction(vendorId) {
	return (dispatch, getState) => {
		getVendorDetails(vendorId).then((response) => {
			dispatch(confirmedGetVendorAction(response.data.vendor));
		});
	};
}

export function confirmedGetVendorAction(vendor) {
	return {
		type: CONFIRMED_GET_VENDOR_DETAILS,
		payload: vendor,
	};
}

export function deleteVendorAction(vendorId, history) {
	return (dispatch, getState) => {
		deleteVendor(vendorId).then((response) => {
			dispatch(confirmedDeleteVendorAction(vendorId));
			history.push("/vendors");
		});
	};
}

export function confirmedDeleteVendorAction(vendorId) {
	return {
		type: CONFIRMED_DELETE_VENDOR_ACTION,
		payload: vendorId,
	};
}

export function createVendorAction(VendorData, history) {
	return (dispatch, getState) => {
		createVendor(VendorData).then((response) => {
			const singleVendor = {
				...VendorData,
				id: response.data._id,
			};
			dispatch(confirmedCreateVendorAction(singleVendor));
			history.push("/vendors");
		});
	};
}

export function getVendorsAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getVendors(societyId).then((response) => {
			dispatch(confirmedGetVendorsAction(response.data));
		});
	};
}

export function confirmedGetVendorsAction(Vendors) {
	return {
		type: CONFIRMED_GET_VENDORS,
		payload: Vendors,
	};
}

export function confirmedCreateVendorAction(singleVendor) {
	return {
		type: CONFIRMED_CREATE_VENDOR_ACTION,
		payload: singleVendor,
	};
}

export function confirmedUpdateVendorAction(Vendor) {
	return {
		type: CONFIRMED_EDIT_VENDOR_ACTION,
		payload: Vendor,
	};
}

export function updateVendorAction(Vendor, history, vendorId) {
	return (dispatch, getState) => {
		updateVendor(Vendor, vendorId).then((reponse) => {
			dispatch(confirmedUpdateVendorAction(Vendor));
			history.push("/vendors");
		});
	};
}
