import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik } from "formik";
import * as Yup from "yup";

import {
  //getFlatSocietyUserAction,
  getFlatAction,
  createFlatAction,
  updateFlatAction,
} from "../../../../../store/actions/FlatManagementActions";
import { getUsersAction } from "../../../../../store/actions/UserActions";

import { ToastContainer } from "react-toastify"; //toast
import "react-toastify/dist/ReactToastify.css";

class AddEditFlat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flat: {},
      societyId: "",
      userId: "",
    };

    this.handleAdd = this.handleAdd.bind(this);
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem("userDetails"));
    this.setState({
      societyId: userData.user.societyName,
    });

    //const userId = this.props.match.params.userId;
    const id = this.props.match.params.id;
    if (id) {
      //this.props.getFlatSocietyUserAction(userData.user.societyName, userId);
      this.props.getFlatAction(id);
    }
    this.props.getUsersAction(userData.user.societyName);
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.flat?.length !== this.props?.flat?.length) {
      this.setState({
        flat: this.props?.flat,
      });
    }
  }

  handleAdd = (values) => {
    const id = this.props.match.params.id;
    if (id) {
      this.props.updateFlatAction(
        values,
        this.props.history,
        this.props?.flat?._id
      );
    } else {
      this.props.createFlatAction(values, this.props.history);
    }
  };

  render() {
    // const userId = this.props.match.params.userId;
    const id = this.props.match.params.id;

    if (id) {
      var flat = this.props?.flat;
    } else {
      flat = "";
    }

    //let user_data = this.props.users?.users;
    // var addOneYear = new Date();
    // addOneYear.setFullYear(addOneYear.getFullYear() + 1);

    const initialValues = {
      societyId: this.state.societyId,
      flatNo: flat ? flat.flatNo : "",
      ownerName: flat ? flat.ownerName : "",

      flatArea: flat ? flat.flatArea : "",
      flatType: flat ? flat.flatType : "Residential",
      twoWheeler: flat ? flat.twoWheeler : 0,
      fourWheeler: flat ? flat.fourWheeler : 0,
      otherCharges: flat ? flat.otherCharges : 0,
      otherChargesDescription: flat ? flat.otherChargesDescription : "",
      outStandingAmount: flat ? flat.outStandingAmount : 0,
      ebillSubscribed: flat ? flat.ebillSubscribed : true,
      isOnRent: flat ? flat.isOnRent : false,
      tenantName: flat ? flat.tenantName : "",
      tenantContactDetails: flat ? flat.tenantContactDetails : "",
      leaseStartDate: flat ? flat.leaseStartDate : new Date(),
      leaseExpiryDate: flat ? flat.leaseExpiryDate : new Date(),
      status: flat ? flat.status : true,
    };

    const validationSchema = Yup.object().shape({
      flatNo: Yup.string().required("Please enter a Flat No."),
      ownerName: Yup.string()
        .min(1, "Your Owner Name must be at least 1 characters long")
        .max(50, "Your Owner Name must be max 50 characters long")
        .required("Please provide a Owner Name"),

      leaseStartDate: Yup.date().when("isOnRent", (isOnRent, schema) => {
        if (isOnRent) return schema.required("Lease Start Date is required");
      }),
      leaseExpiryDate: Yup.date()
        .when("isOnRent", (isOnRent, schema) => {
          if (isOnRent) return schema.required("Lease Expiry Date is required");
        })
        .min(
          Yup.ref("leaseStartDate"),
          "End date can't be before Lease Start Date"
        ),
    });

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
            Dashboard
          </Breadcrumb.Item>{" "}
          <Breadcrumb.Item linkProps={{ to: "/flat-management" }} linkAs={Link}>
            Flat Lists
          </Breadcrumb.Item>{" "}
          {!IDBObjectStore ? (
            <Breadcrumb.Item active>Add Flat Management</Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item active>Edit Flat Management</Breadcrumb.Item>
          )}
        </Breadcrumb>
        <div className="row">
          <div className="col-md-6  col-sm-12">
            {!id ? (
              <h3 className="pt-3">Add New Flat</h3>
            ) : (
              <h3 className="pt-3">Edit Flat</h3>
            )}
          </div>
          <div className="col-md-6 col-sm-12">
            <Link
              to={"/flat-management"}
              className="btn btn-primary mb-2 pull-right"
            >
              Back to Lists
            </Link>
          </div>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="form-validation">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        this.handleAdd(values);
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">Flat No *</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-flatNo"
                                  placeholder="Enter a Flat No.."
                                  name="flatNo"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.flatNo || ""}
                                />
                                {errors.flatNo && touched.flatNo ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.flatNo}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">Owner Name *</label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-ownerName"
                                  name="ownerName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.ownerName || ""}
                                  placeholder="Enter Owner Name."
                                />
                                {errors.ownerName && touched.ownerName ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.ownerName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Flat Area (Sq.ft)
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-flatArea"
                                  placeholder="Enter a flatArea.."
                                  name="flatArea"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.flatArea || ""}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">Flat Type</label>
                              <div>
                                <select
                                  className="form-control"
                                  id="val-flatType"
                                  placeholder="Enter a flatType.."
                                  name="flatType"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.flatType || ""}
                                >
                                  <option
                                    value="Residential"
                                    label="Residential"
                                  />
                                  <option
                                    value="Commercial"
                                    label="Commercial"
                                  />
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">Two Wheeler</label>
                              <div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="val-twoWheeler"
                                  placeholder="Enter a twoWheeler.."
                                  name="twoWheeler"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.twoWheeler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">Four Wheeler</label>
                              <div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="val-fourWheeler"
                                  placeholder="Enter a Four Wheeler.."
                                  name="fourWheeler"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.fourWheeler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div
                              className={`form-group ${
                                values.otherCharges
                                  ? errors.otherCharges
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Other Charges
                              </label>
                              <div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="val-otherCharges"
                                  placeholder="Enter a otherCharges.."
                                  name="otherCharges"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.otherCharges}
                                />
                                <div
                                  id="val-otherCharges-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.otherCharges && errors.otherCharges}
                                </div>

                                <div
                                  id="val-otherCharges-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="text-label">
                                Other Charges Description
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-otherChargesDescription"
                                  placeholder="Enter a other Charges Description.."
                                  name="otherChargesDescription"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.otherChargesDescription || ""}
                                />
                                {errors.otherChargesDescription &&
                                touched.otherChargesDescription ? (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.otherChargesDescription}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {!id && (
                            <div className="col-xl-3 col-sm-12">
                              <div className="form-group">
                                <label className="text-label">
                                  Outstanding Amount
                                </label>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="val-outStandingAmount"
                                    placeholder="Enter a outStanding Amount.."
                                    name="outStandingAmount"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.outStandingAmount}
                                  />
                                  {errors.outStandingAmount &&
                                  touched.outStandingAmount ? (
                                    <div
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.outStandingAmount}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <div className="form-check">
                                <input
                                  checked={values.ebillSubscribed === true}
                                  className="form-check-input"
                                  type="checkbox"
                                  id="val-ebillSubscribed"
                                  name="ebillSubscribed"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.ebillSubscribed}
                                />
                                <label className="form-check-label">
                                  Interested In Ebill
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <div className="form-check">
                                <input
                                  checked={values.isOnRent === true}
                                  className="form-check-input"
                                  type="checkbox"
                                  id="val-isOnRent"
                                  name="isOnRent"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.isOnRent}
                                />
                                <label className="form-check-label">
                                  Is On Rent
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <div className="form-check">
                                <input
                                  checked={values.status === true}
                                  className="form-check-input"
                                  type="checkbox"
                                  id="val-status"
                                  name="status"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.status}
                                />
                                <label className="form-check-label">
                                  Active
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {values.isOnRent === true ? (
                          <div className="row">
                            <div className="col-xl-4 col-sm-12">
                              <div className="form-group">
                                <label className="text-label">
                                  Tenant Name
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-tenantName"
                                    placeholder="Enter a Tenant Name.."
                                    name="tenantName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.tenantName || ""}
                                  />
                                  {errors.tenantName && touched.tenantName ? (
                                    <div
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.tenantName}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-4 col-sm-12">
                              <div className="form-group">
                                <label className="text-label">
                                  Tenant Contact Details
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-tenantContactDetails"
                                    placeholder="Enter a Tenant Contact.."
                                    name="tenantContactDetails"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.tenantContactDetails || ""}
                                  />
                                  {errors.tenantContactDetails &&
                                  touched.tenantContactDetails ? (
                                    <div
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.tenantContactDetails}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-4 col-sm-12">
                              <div className="form-group">
                                <label className="text-label">
                                  Lease Start Date
                                </label>
                                <div>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      id="leaseStartDate"
                                      format="dd/MM/yyyy"
                                      value={values.leaseStartDate}
                                      onChange={(e) =>
                                        setFieldValue("leaseStartDate", e)
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </MuiPickersUtilsProvider>
                                  {errors.leaseStartDate &&
                                  touched.leaseStartDate ? (
                                    <div
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.leaseStartDate}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-4 col-sm-12">
                              <div className="form-group">
                                <label className="text-label">
                                  Lease Expiry Date
                                </label>
                                <div>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      id="leaseExpiryDate"
                                      format="dd/MM/yyyy"
                                      value={values.leaseExpiryDate}
                                      onChange={(e) =>
                                        setFieldValue("leaseExpiryDate", e)
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </MuiPickersUtilsProvider>
                                  {errors.leaseExpiryDate &&
                                  touched.leaseExpiryDate ? (
                                    <div
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.leaseExpiryDate}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <button
                          type="submit"
                          className="btn mr-2 btn-primary"
                          disabled={isSubmitting}
                        >
                          Save
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
}

//export default AddFlat;
const mapStateToProps = (state) => {
  return {
    flat: state.flat?.flat?.flat,
    users: state.user.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      //getFlatSocietyUserAction,
      getFlatAction,
      createFlatAction,
      updateFlatAction,
      getUsersAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditFlat);
