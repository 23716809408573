import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb, Table } from "react-bootstrap";
import { format } from "date-fns";
import swal from "sweetalert";

import axiosInstance from "../../../../../services/AxiosInstance";

import {
	getPollingAction,
	updatePollingAction,
} from "../../../../../store/actions/PollingActions";

class PollingDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			polling: {},
			singlePoll: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.polling) {
			this.props.getPollingAction(id);
		}
		this.init();
	}

	init() {
		this.state.polling = this.props?.polling;
	}

	async handleVote(optId) {
		const id = this.props.match.params.id;

		const { data } = await axiosInstance
			.get(`admin/poll/${id}`)
			.then((result) => result);
		this.setState({
			singlePoll: data?.poll,
		});
		this.giveVote(optId);
	}

	giveVote(optId) {
		const id = this.props.match.params.id;
		const toBeUpdated = this.state.singlePoll?.pollOptions?.map((item) =>
			item._id === optId ? { ...item, votes: item.votes + 1 } : item
		);
		const values = {
			...this.state.singlePoll,
			pollOptions: toBeUpdated,
		};

		swal({
			title: "Are you sure?",
			text: "Are you sure you want to vote for this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.updatePollingAction(values, this.props.history, id);
				swal("Poof! Your vote has been casted!", {
					icon: "success",
				});
			} else {
				swal("Your vote is not casted!");
			}
		});
	}

	render() {
		this.state.polling = this.props?.polling;

		const pollDescription = this.state.polling?.pollDescription || "";
		const pollStartDate = this.state.polling?.pollStartDate || "";
		const pollEndDate = this.state.polling?.pollEndDate || "";
		const pollOptions = this.state.polling?.pollOptions || "";
		const status = this.state.polling?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/member-pollings" }} linkAs={Link}>
						Polling Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Polling Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Polling Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/member-pollings"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Polling Description</strong>
										<span className="mb-0">{pollDescription}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Polling Start Date</strong>
										<span className="mb-0">
											{pollStartDate &&
												format(new Date(pollStartDate), "dd-MM-yyyy")}
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Polling End Date</strong>
										<span className="mb-0">
											{pollEndDate &&
												format(new Date(pollEndDate), "dd-MM-yyyy")}
										</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
								<h4
									className="pt-3 pb-3"
									style={{ textDecoration: "underline" }}
								>
									Polling Options:{" "}
								</h4>
								<div className="row">
									<Table bordered striped responsive>
										<thead>
											<tr>
												<th>Options</th>
												<th>Select Option</th>
											</tr>
										</thead>
										<tbody>
											{pollOptions?.length
												? pollOptions.map((opt, i) => (
														<tr key={i}>
															<td>{opt.option} </td>
															<td>
																{/* {opt.votes} */}
																<Link
																	to="#"
																	className="btn btn-primary shadow btn-xs"
																	onClick={this.handleVote.bind(this, opt._id)}
																>
																	Click to vote for - {opt.option}
																</Link>
															</td>
														</tr>
												  ))
												: null}
										</tbody>
									</Table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		polling: state.polling?.polling,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getPollingAction, updatePollingAction },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(PollingDetail);
