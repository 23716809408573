import { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Breadcrumb } from "react-bootstrap";
import Pagination from "../../../components/Pagination";
//import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { Formik } from "formik"; //Field
import * as Yup from "yup";

import axiosInstance from "../../../../services/AxiosInstance";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const initialValues = {
	flatId: "",
	startDate: new Date(),
	endDate: new Date(),
};

const validationSchema = Yup.object().shape({
	flatId: Yup.string().required("Please Select Flat No."),
	startDate: Yup.date().required("Please Select Start Dt."),
	endDate: Yup.date()
		.min(Yup.ref("startDate"), "End date can't be before Start date")
		.required("Please Select End Date"),
});

class IndividualAccountDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			societyId: "",
			currentIndividualAccounts: [],
			currentPage: null,
			totalPages: null,
			flats: [],
			loading: false,
			handleAddValues: {},
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.getFlats(userData.user.societyName);
		this.setState({
			societyId: userData.user.societyName,
		});
	}

	async getFlats(societyId) {
		const { data } = await axiosInstance
			.get(`admin/flats/${societyId}`)
			.then((result) => result);

		this.setState({
			flats: data.flats,
		});
	}

	handleAdd = (values) => {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			handleAddValues: values,
		});
		this.getIndividualAccountDetails(userData.user.societyName, values);
	};

	async getIndividualAccountDetails(societyId, values) {
		const payload = {
			flatId: values.flatId,
			startDate: values.startDate,
			endDate: values.endDate,
		};

		this.setState({
			loading: true,
		});
		const { data } = await axiosInstance
			.post(`admin/report/ledger/${societyId}`, payload)
			.then((result) => result);

		this.setState({
			currentIndividualAccounts: data?.ledger,
			loading: false,
		});
	}

	onPageChanged = async (data) => {
		await this.getIndividualAccountDetails(
			this.state.societyId,
			this.state.handleAddValues
		);
		const allIndividualAccounts = this.state.currentIndividualAccounts;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentIndividualAccounts = allIndividualAccounts?.slice(
			offset,
			offset + pageLimit
		);

		this.setState({ currentPage, currentIndividualAccounts, totalPages });
	};

	handleSearch(event) {
		let currentIndividualAccounts = this.state.currentIndividualAccounts;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentIndividualAccounts = currentIndividualAccounts.filter(
				(e) =>
					e.flatNo.toLowerCase().match(searchString) ||
					e.ownerName.toLowerCase().match(searchString) ||
					e.contactDetails.toLowerCase().match(searchString)
			);
		}
		this.setState({ currentIndividualAccounts });
	}

	render() {
		let totalIndividualAccounts =
			this.state?.currentIndividualAccounts?.length || 0;

		const { currentIndividualAccounts, currentPage, totalPages } = this.state;

		let flat_data = this.state.flats;

		return (
			<>
				{this.state?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Individual Account Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Individual Account Details</h2>
								</div>

								{/* <div className="col-xl-4 col-sm-12">
								<input
									type="text"
									className="form-control mb-2 search-list pull-right"
									placeholder="Search..."
									onChange={(e) => this.handleSearch(e)}
								/>
							</div> */}
							</div>
							<hr />
							<div className="form-validation">
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									enableReinitialize={true}
									onSubmit={(values, { setSubmitting }) => {
										setTimeout(() => {
											//alert(JSON.stringify(values, null, 2));
											this.handleAdd(values);
											setSubmitting(false);
										}, 400);
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										setFieldValue,
									}) => (
										<form onSubmit={handleSubmit}>
											<div className="row">
												<div className="col-xl-1 col-sm-12"></div>
												<div className="col-xl-3 col-sm-12">
													<div className="form-group">
														<label className="text-label">Flat No.</label>
														<div>
															<select
																className="form-control"
																id="val-flatId"
																placeholder="Select a Flat Name.."
																name="flatId"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.flatId || "Select"}
															>
																<option
																	value="Select"
																	label="Select"
																	disabled
																/>
																{flat_data?.map((opt) => (
																	<option key={opt._id} value={opt._id}>
																		{opt.flatNo}
																	</option>
																))}
															</select>
															{errors.flatId && touched.flatId ? (
																<div
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.flatId}
																</div>
															) : null}
														</div>
													</div>
												</div>
												<div className="col-xl-3 col-sm-12">
													<div className="form-group">
														<label className="text-label">Start Date</label>
														<div>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<DatePicker
																	id="startDate"
																	format="dd/MM/yyyy"
																	value={values.startDate}
																	onChange={(e) =>
																		setFieldValue("startDate", e)
																	}
																	onBlur={handleBlur}
																/>
															</MuiPickersUtilsProvider>
															{errors.startDate && touched.startDate ? (
																<div
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.startDate}
																</div>
															) : null}
														</div>
													</div>
												</div>
												<div className="col-xl-3 col-sm-12">
													<div className="form-group">
														<label className="text-label">End Date</label>
														<div>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<DatePicker
																	id="endDate"
																	format="dd/MM/yyyy"
																	value={values.endDate}
																	onChange={(e) => setFieldValue("endDate", e)}
																	onBlur={handleBlur}
																/>
															</MuiPickersUtilsProvider>
															{errors.endDate && touched.endDate ? (
																<div
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.endDate}
																</div>
															) : null}
														</div>
													</div>
												</div>

												<div className="col-xl-1 col-sm-12">
													<button className="btn btn-primary">Show</button>
												</div>
												<div className="col-xl-1 col-sm-12"></div>
											</div>
										</form>
									)}
								</Formik>
							</div>
							<hr />

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Year</th>
											<th>Month</th>
											<th>Credit Amount</th>
											<th>Debit Amount</th>
											<th>Balance Amount</th>
										</tr>
									</thead>
									<tbody>
										{currentIndividualAccounts?.length
											? currentIndividualAccounts
													?.sort(
														(a, b) =>
															a._id.year - b._id.year ||
															a._id.month - b._id.month
													)
													?.map((individual, i) => (
														<tr key={i}>
															<td>{individual?._id?.year} </td>
															<td>{monthNames[individual?._id?.month - 1]}</td>
															<td>{individual?.creditAmount}</td>
															<td>{individual?.debitAmount}</td>
															<td>{individual?.balanceAmount}</td>
															{/* <td>
														<div className="d-flex align-items-center">
															{outstanding?.isOnRent === true ? (
																<i className="fa fa-check-square text-success mr-1"></i>
															) : (
																<i className="fa fa-square mr-1"></i>
															)}
														</div>
													</td> */}

															{/* <td>
														{outstanding?.leaseStartDate &&
															format(
																new Date(outstanding?.leaseStartDate),
																"dd-MM-yyyy"
															)}
													</td> */}
														</tr>
													))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalIndividualAccounts ? (
										<Pagination
											totalRecords={totalIndividualAccounts}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default IndividualAccountDetails;
