import {
	getBillingAdjustmentDetails,
	getBillingAdjustments,
	createBillingAdjustment,
	updateBillingAdjustment,
	deleteBillingAdjustment,
	getBillingAdjustmentsByFlat,
} from "../../services/BillingAdjustmentService";
import {
	CONFIRMED_GET_BILLINGADJUSTMENT_DETAILS,
	CONFIRMED_CREATE_BILLINGADJUSTMENT_ACTION,
	CONFIRMED_GET_BILLINGADJUSTMENTS,
	CONFIRMED_EDIT_BILLINGADJUSTMENT_ACTION,
	CONFIRMED_DELETE_BILLINGADJUSTMENT_ACTION,
	LOADING,
} from "./BillingAdjustmentTypes";

export function getBillingAdjustmentAction(billingId) {
	return (dispatch, getState) => {
		getBillingAdjustmentDetails(billingId).then((response) => {
			dispatch(
				confirmedGetBillingAdjustmentAction(response.data.billAdjustment)
			);
		});
	};
}

export function confirmedGetBillingAdjustmentAction(billing) {
	return {
		type: CONFIRMED_GET_BILLINGADJUSTMENT_DETAILS,
		payload: billing,
	};
}

export function deleteBillingAdjustmentAction(billingId, history) {
	return (dispatch, getState) => {
		deleteBillingAdjustment(billingId).then((response) => {
			dispatch(confirmedDeleteBillingAdjustmentAction(billingId));
			history.push("/billing-adjustment-lists");
		});
	};
}

export function confirmedDeleteBillingAdjustmentAction(billingId) {
	return {
		type: CONFIRMED_DELETE_BILLINGADJUSTMENT_ACTION,
		payload: billingId,
	};
}

export function createBillingAdjustmentAction(BillingAdjustmentData, history) {
	return (dispatch, getState) => {
		createBillingAdjustment(BillingAdjustmentData).then((response) => {
			const singleBillingAdjustment = {
				...BillingAdjustmentData,
				id: response.data._id,
			};
			dispatch(confirmedCreateBillingAdjustmentAction(singleBillingAdjustment));
			history.push("/billing-adjustment-lists");
		});
	};
}

export function getBillingAdjustmentsAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getBillingAdjustments(societyId).then((response) => {
			dispatch(confirmedGetBillingAdjustmentsAction(response.data));
		});
	};
}

export function confirmedCreateBillingAdjustmentAction(
	singleBillingAdjustment
) {
	return {
		type: CONFIRMED_CREATE_BILLINGADJUSTMENT_ACTION,
		payload: singleBillingAdjustment,
	};
}

export function confirmedGetBillingAdjustmentsAction(BillingAdjustments) {
	return {
		type: CONFIRMED_GET_BILLINGADJUSTMENTS,
		payload: BillingAdjustments,
	};
}

export function confirmedUpdateBillingAdjustmentAction(BillingAdjustment) {
	return {
		type: CONFIRMED_EDIT_BILLINGADJUSTMENT_ACTION,
		payload: BillingAdjustment,
	};
}

export function updateBillingAdjustmentAction(
	BillingAdjustment,
	history,
	billingId
) {
	return (dispatch, getState) => {
		updateBillingAdjustment(BillingAdjustment, billingId).then((reponse) => {
			dispatch(confirmedUpdateBillingAdjustmentAction(BillingAdjustment));
			history.push("/billing-adjustment-lists");
		});
	};
}

export function getBillingAdjustmentsByFlatAction(flatId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getBillingAdjustmentsByFlat(flatId).then((response) => {
			dispatch(confirmedGetBillingAdjustmentsByFlatAction(response.data));
		});
	};
}

export function confirmedGetBillingAdjustmentsByFlatAction(BillingAdjustments) {
	return {
		type: CONFIRMED_GET_BILLINGADJUSTMENTS,
		payload: BillingAdjustments,
	};
}
