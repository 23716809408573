import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getMaintenanceMasterAction,
	updateMaintenanceMasterAction,
	createMaintenanceMasterAction,
} from "../../../store/actions/MaintenanceMasterActions";

class AddEditMaintenanceMaster extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAddMode: false,
			societyId: "",
		};
		this.handleEditMaintenance = this.handleEditMaintenance.bind(this);
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});

		if (id) {
			if (this.props.maintenancemaster) {
				this.props.getMaintenanceMasterAction(userData.user.societyName);
				this.setState({
					isAddMode: !this.props.maintenanceMaster,
				});
			}
		}
	}

	handleEditMaintenance = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateMaintenanceMasterAction(
				values,
				this.props.history,
				this.props?.maintenancemaster?._id
			);
		} else {
			this.props.createMaintenanceMasterAction(values, this.props.history);
		}
	};

	render() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));

		const id = this.props.match.params.id;

		const maintenanceMaster = this.props?.maintenancemaster;

		const initialValues = {
			societyId: userData.user?.societyName,
			maintenanceType: maintenanceMaster
				? maintenanceMaster.maintenanceType
				: "OnArea",
			maintenancePeriod: maintenanceMaster
				? maintenanceMaster.maintenancePeriod
				: "Monthly",
			residentialCharges: maintenanceMaster
				? maintenanceMaster.residentialCharges
				: 0,
			commercialCharges: maintenanceMaster
				? maintenanceMaster.commercialCharges
				: 0,
			twoWheelerParkingCharges: maintenanceMaster
				? maintenanceMaster.twoWheelerParkingCharges
				: 0,
			fourWheelerParkingCharges: maintenanceMaster
				? maintenanceMaster.fourWheelerParkingCharges
				: 0,
			festivalChargesRequired: maintenanceMaster
				? maintenanceMaster.festivalChargesRequired
				: true,
			festivalCharges: maintenanceMaster
				? maintenanceMaster.festivalCharges
				: 0,
			sinkingFundCharges: maintenanceMaster
				? maintenanceMaster.sinkingFundCharges
				: 0,
			repairCharges: maintenanceMaster ? maintenanceMaster.repairCharges : 0,
			cidcoChargesRequired: maintenanceMaster
				? maintenanceMaster.cidcoChargesRequired
				: false,
			cidcoChargesPercent: maintenanceMaster
				? maintenanceMaster.cidcoChargesPercent
				: 0,
			nocChargesCaltype: maintenanceMaster
				? maintenanceMaster.nocChargesCaltype
				: "percent",
			nocCharges: maintenanceMaster ? maintenanceMaster.nocCharges : 0,
			arrearsInterestType: maintenanceMaster
				? maintenanceMaster.arrearsInterestType
				: "percent",
			arrearsInterest: maintenanceMaster
				? maintenanceMaster.arrearsInterest
				: 0,
			status: maintenanceMaster ? maintenanceMaster.status : true,
		};

		const validationSchema = Yup.object().shape({
			maintenanceType: Yup.string().required(
				"Please select a Maintenance Type"
			),
			maintenancePeriod: Yup.string().required(
				"Please select a maintenance Period"
			),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item
						linkProps={{ to: "/maintenance-information" }}
						linkAs={Link}
					>
						Maintenance Information Lists
					</Breadcrumb.Item>{" "}
					{id ? (
						<Breadcrumb.Item active>
							Edit Maintenance Information
						</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>
							Add Maintenance Information
						</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id ? (
							<h3 className="pt-3">Edit Maintenance Information</h3>
						) : (
							<h3 className="pt-3">Add Maintenance Information</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/maintenance-information"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleEditMaintenance(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											touched,
											errors,
											dirty,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div
															className={`form-group ${
																values.maintenanceType
																	? errors.maintenanceType
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Maintenance Type *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-maintenanceType"
																	placeholder="Select a Maintenance Type.."
																	name="maintenanceType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.maintenanceType}
																>
																	<option value="OnArea" label="OnArea" />
																	<option
																		value="FixedAmount"
																		label="FixedAmount"
																	/>
																</select>
																<div
																	id="val-maintenanceType-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.maintenanceType &&
																		errors.maintenanceType}
																</div>

																<div
																	id="val-maintenanceType-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div
															className={`form-group ${
																values.maintenancePeriod
																	? errors.maintenancePeriod
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Maintenance periods *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-maintenancePeriod"
																	placeholder="Select a Maintenance Period.."
																	name="maintenancePeriod"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.maintenancePeriod}
																>
																	<option value="Month" label="Month" />
																	<option value="Quater" label="Quater" />
																	<option value="HalfYear" label="HalfYear" />
																	<option value="Year" label="Year" />
																</select>

																<div
																	id="val-maintenancePeriod-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.maintenancePeriod &&
																		errors.maintenancePeriod}
																</div>
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div
															className={`form-group ${
																values.residentialCharges
																	? errors.residentialCharges
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Residential Charges *
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-residentialCharges"
																	placeholder="Enter a Residential Charges.."
																	name="residentialCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.residentialCharges}
																/>
																<div
																	id="val-residentialCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.residentialCharges &&
																		errors.residentialCharges}
																</div>

																<div
																	id="val-residentialCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div
															className={`form-group ${
																values.commercialCharges
																	? errors.commercialCharges
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Commercial Charges
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-commercialCharges"
																	placeholder="Enter a Commercial Charges.."
																	name="commercialCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.commercialCharges}
																/>
																<div
																	id="val-commercialCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.commercialCharges &&
																		errors.commercialCharges}
																</div>

																<div
																	id="val-commercialCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div
															className={`form-group ${
																values.twoWheelerParkingCharges
																	? errors.twoWheelerParkingCharges
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Two Wheeler Parking Charges
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-twoWheelerParkingCharges"
																	placeholder="Enter a Two Wheeler Parking Charges.."
																	name="twoWheelerParkingCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.twoWheelerParkingCharges || 0}
																/>
																<div
																	id="val-twoWheelerParkingCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.twoWheelerParkingCharges &&
																		errors.twoWheelerParkingCharges}
																</div>

																<div
																	id="val-twoWheelerParkingCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div
															className={`form-group ${
																values.fourWheelerParkingCharges
																	? errors.fourWheelerParkingCharges
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Four Wheeler Parking Charges
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-fourWheelerParkingCharges"
																	placeholder="Enter a Four Wheeler Parking Charges.."
																	name="fourWheelerParkingCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.fourWheelerParkingCharges || 0}
																/>
																<div
																	id="val-fourWheelerParkingCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.fourWheelerParkingCharges &&
																		errors.fourWheelerParkingCharges}
																</div>

																<div
																	id="val-fourWheelerParkingCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div
															className={`form-group ${
																values.sinkingFundCharges
																	? errors.sinkingFundCharges
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Sinking Fund Charges in (%)
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-sinkingFundCharges"
																	placeholder="Enter a sinking Fund Charges.."
																	name="sinkingFundCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.sinkingFundCharges}
																/>
																<div
																	id="val-sinkingFundCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.sinkingFundCharges &&
																		errors.sinkingFundCharges}
																</div>

																<div
																	id="val-sinkingFundCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div
															className={`form-group ${
																values.repairCharges
																	? errors.repairCharges
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Repair Charges in (%)
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-repairCharges"
																	placeholder="Enter a repair Charges.."
																	name="repairCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.repairCharges}
																/>
																<div
																	id="val-repairCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.repairCharges && errors.repairCharges}
																</div>

																<div
																	id="val-repairCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>

													<div className="col-xl-3 col-sm-12">
														<div
															className={`form-group ${
																values.arrearsInterestType
																	? errors.arrearsInterestType
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Arrears Interest type
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-arrearsInterestType"
																	placeholder="Select a Arrears Interest Type.."
																	name="arrearsInterestType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.arrearsInterestType}
																>
																	<option value="Percent" label="Percent" />
																	<option value="Fixed" label="Fixed" />
																</select>
																<div
																	id="val-arrearsInterestType-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.arrearsInterestType &&
																		errors.arrearsInterestType}
																</div>

																<div
																	id="val-arrearsInterestType-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div
															className={`form-group ${
																values.arrearsInterest
																	? errors.arrearsInterest
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Arrears Interest
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-arrearsInterest"
																	placeholder="Enter a Arrears Interest.."
																	name="arrearsInterest"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.arrearsInterest}
																/>
																<div
																	id="val-arrearsInterest-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.arrearsInterest &&
																		errors.arrearsInterest}
																</div>

																<div
																	id="val-arrearsInterest-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>

													<div className="col-xl-4 col-sm-12">
														<div
															className={`form-group ${
																values.nocChargesCaltype
																	? errors.nocChargesCaltype
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																NOC Charges Calculation Type
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-nocChargesCaltype"
																	placeholder="Select a NOC Charges Type.."
																	name="nocChargesCaltype"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.nocChargesCaltype}
																>
																	<option value="Percent" label="Percent" />
																	<option value="Fixed" label="Fixed" />
																</select>
																<div
																	id="val-nocChargesCaltype-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.nocChargesCaltype &&
																		errors.nocChargesCaltype}
																</div>

																<div
																	id="val-nocChargesCaltype-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div
															className={`form-group ${
																values.nocCharges
																	? errors.nocCharges
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">NOC Charges</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-nocCharges"
																	placeholder="Enter a NOC Charges.."
																	name="nocCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.nocCharges}
																/>
																<div
																	id="val-nocCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.nocCharges && errors.nocCharges}
																</div>

																<div
																	id="val-nocCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.cidcoChargesRequired === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-cidcoChargesRequired"
																	name="cidcoChargesRequired"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.cidcoChargesRequired}
																/>
																<label className="form-check-label">
																	Cidco Charges Required
																</label>
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div
															className={`form-group ${
																values.cidcoChargesPercent
																	? errors.cidcoChargesPercent
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Cidco Charges Percent (%)
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-cidcoChargesPercent"
																	placeholder="Enter a cidco Charges Percent.."
																	name="cidcoChargesPercent"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.cidcoChargesPercent}
																/>
																<div
																	id="val-cidcoChargesPercent-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.cidcoChargesPercent &&
																		errors.cidcoChargesPercent}
																</div>

																<div
																	id="val-cidcoChargesPercent-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={
																		values.festivalChargesRequired === true
																	}
																	className="form-check-input"
																	type="checkbox"
																	id="val-festivalChargesRequired"
																	name="festivalChargesRequired"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.festivalChargesRequired}
																/>
																<label className="form-check-label">
																	Festival Charges Required
																</label>
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div
															className={`form-group ${
																values.festivalCharges
																	? errors.festivalCharges
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Festival Charges
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-festivalCharges"
																	placeholder="Enter a Festival Charges.."
																	name="festivalCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.festivalCharges}
																/>
																<div
																	id="val-festivalCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.festivalCharges &&
																		errors.festivalCharges}
																</div>

																<div
																	id="val-festivalCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<button
														type="submit"
														className="btn mr-2 btn-primary"
														disabled={isSubmitting}
													>
														Save
													</button>
												</div>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddMaintenanceInformation;
const mapStateToProps = (state) => {
	return {
		maintenancemaster: state.maintenancemaster?.maintenancemaster,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getMaintenanceMasterAction,
			updateMaintenanceMasterAction,
			createMaintenanceMasterAction,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddEditMaintenanceMaster);
