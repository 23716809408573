import {
	CONFIRMED_GET_PAYMENT_DETAILS,
	CONFIRMED_CREATE_PAYMENT_ACTION,
	CONFIRMED_GET_PAYMENTS,
	CONFIRMED_EDIT_PAYMENT_ACTION,
	CONFIRMED_DELETE_PAYMENT_ACTION,
	CONFIRMED_GET_PAYMENTS_BY_FLAT,
	LOADING,
} from "../actions/PaymentTypes";

const initialState = {
	payments: [],
	payment: {},
};

export default function PaymentReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_PAYMENT_DETAILS) {
		return {
			...state,
			payment: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_PAYMENT_ACTION) {
		const payments = [...state.payments.paymentDetails];
		const paymentIndex = payments.findIndex(
			(payment) => payment.id === actions.payload
		);

		payments.splice(paymentIndex, 1);

		const paymentDetails = payments;

		return {
			...state,
			payments: { paymentDetails },
		};
	}

	if (actions.type === CONFIRMED_EDIT_PAYMENT_ACTION) {
		return {
			...state,
			payments: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_PAYMENT_ACTION) {
		return {
			...state,
			payments: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_PAYMENTS) {
		return {
			...state,
			payments: actions.payload,
			loading: false,
		};
	}

	if (actions.type === CONFIRMED_GET_PAYMENTS_BY_FLAT) {
		return {
			...state,
			payments: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
