import {
	CONFIRMED_GET_MAINTENANCE_DETAILS,
	CONFIRMED_CREATE_MAINTENANCE_ACTION,
	CONFIRMED_GET_MAINTENANCES,
	CONFIRMED_EDIT_MAINTENANCE_ACTION,
	CONFIRMED_DELETE_MAINTENANCE_ACTION,
	LOADING,
} from "../actions/MaintenanceTypes";

const initialState = {
	maintenances: [],
	maintenance: {},
};

export default function MaintenanceReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_MAINTENANCE_DETAILS) {
		return {
			...state,
			maintenance: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_MAINTENANCE_ACTION) {
		const maintenances = [...state.maintenance.maintenances];
		const maintenanceIndex = maintenances.findIndex(
			(maintenance) => maintenance.id === actions.payload
		);

		maintenances.splice(maintenanceIndex, 1);

		return {
			...state,
			maintenances: { maintenances },
		};
	}

	if (actions.type === CONFIRMED_EDIT_MAINTENANCE_ACTION) {
		const maintenances = [...state.maintenance.maintenances.maintenances];
		const maintenanceIndex = maintenances.findIndex(
			(maintenance) => maintenance.id === actions.payload.id
		);

		maintenances[maintenanceIndex] = actions.payload;
		return {
			...state,
			maintenances,
		};
	}

	if (actions.type === CONFIRMED_CREATE_MAINTENANCE_ACTION) {
		return {
			...state,
			maintenances: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_MAINTENANCES) {
		return {
			...state,
			maintenances: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
