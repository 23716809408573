import { Component } from "react";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import axiosInstance from "../../../../../services/AxiosInstance";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class CustomLabelMapping extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentCustomLabels: [],
			currentPage: null,
			totalPages: null,
			societyId: "",
			loading: false,
		};
		this.getCustomLabels = this.getCustomLabels.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
		});
		this.getCustomLabels();
		//this.initData();
	}

	async getCustomLabels() {
		this.setState({
			loading: true,
		});
		const resp = await axiosInstance
			.get(`admin/customLabel`)
			.then((res) => res?.data)
			.catch((err) => {
				return err;
			});
		this.setState({
			currentCustomLabels: resp?.customLabel,
			loading: false,
		});
	}

	render() {
		const { currentCustomLabels } = this.state; /// currentPage, totalPages

		return (
			<>
				{this.state?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item active>Custom Label Mapping</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Custom Label Mapping</h2>
								</div>

								<div className="col-xl-4 col-sm-12"></div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-custom-label"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Custom Label Mapping
									</Link>
								</div>
							</div>

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Society Name</th>
											<th>Custom Labels</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentCustomLabels?.length
											? currentCustomLabels.map((customChrg, i) => (
													<tr key={i}>
														<td>{customChrg?.societyId?.societyName}</td>
														<td>
															{customChrg?.customLabel?.map((itm, index) => (
																<div key={index}>{itm.label},</div>
															))}{" "}
														</td>
														<td>
															<div className="d-flex">
																<Link
																	to={`add-custom-label/${customChrg._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link>
																<Link
																	to={`custom-label-details/${customChrg._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
															</div>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default CustomLabelMapping;
