export const GET_VENDORTYPE_DETAILS = "[Vendor Type Action] Get Vendor Type";
export const CONFIRMED_GET_VENDORTYPE_DETAILS =
	"[Vendor Type Action] Confirmed Get Vendor Type";
export const CREATE_VENDORTYPE_ACTION =
	"[Vendor Type Action] Create Vendor Type";
export const CONFIRMED_CREATE_VENDORTYPE_ACTION =
	"[Vendor Type Action] Confirmed Create Vendor Type";
export const GET_VENDORTYPES = "[Vendor Types Action] Get Vendor Types";
export const CONFIRMED_GET_VENDORTYPES =
	"[Vendor Types Action] Confirmed Get Vendor Types";
export const EDIT_VENDORTYPE_ACTION = "[Vendor Types Action] Edit Vendor Types";
export const CONFIRMED_EDIT_VENDORTYPE_ACTION =
	"[Vendor Type Action] Confirmed Edit Vendor Type";
export const CONFIRMED_DELETE_VENDORTYPE_ACTION =
	"[Vendor Type Action] Confirmed Delete Vendor Type";
export const LOADING =
	"[Vendor Type Loading Action] Confirmed Vendor Types Loading";
