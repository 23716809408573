import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
//import { format } from "date-fns";
import swal from "sweetalert";

import {
	getBankDetailsAction,
	deleteBankDetailAction,
} from "../../../../store/actions/BankDetailsActions";

class BankDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentBankDetails: [],
			currentPage: null,
			totalPages: null,
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));

		this.props.getBankDetailsAction(userData.user.societyName);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.bankDetails?.bankDetail?.length !==
			this.props?.bankDetails?.bankDetail?.length
		) {
			this.setState({
				currentBankDetails: this.props?.bankDetails?.bankDetail,
			});
		}
	}

	initData() {
		let currentBankDetails =
			this.state?.currentBankDetails?.length > 0
				? this.state?.currentBankDetails
				: this.props?.bankDetails?.bankDetail;

		this.setState({ currentBankDetails });
	}

	onPageChanged = (data) => {
		const allBankDetails = this.props?.bankDetails?.bankDetail;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentBankDetails = allBankDetails?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentBankDetails, totalPages });
	};

	handleSearch(event) {
		let currentBankDetails = this.props.bankDetails?.bankDetail;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentBankDetails = currentBankDetails.filter(
				(e) =>
					e.accountHolderName.toLowerCase().match(searchString) ||
					e.bankName.toLowerCase().match(searchString)
			);
			this.setState({ currentBankDetails });
		} else {
			let arrayLength = currentBankDetails.slice(0, 5);
			this.setState({ currentBankDetails: arrayLength });
		}
		//this.setState({ currentBankDetails });
	}

	deleteRecord(id) {
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteBankDetailAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		let totalBankDetails = this.props?.bankDetails?.bankDetail?.length || 0;

		const { currentBankDetails, currentPage, totalPages } = this.state;
		this.state.currentBankDetails = this.props.bankDetails?.bankDetail;

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Bank Detail Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Bank Detail Lists</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search By Name & Bank..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-bankDetail"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Bank Detail
									</Link>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Account Holder Name</th>
											<th>Bank Name</th>
											<th>Branch Name</th>
											<th>Account Number Code</th>
											<th>IFSC Code</th>
											<th>Account Type</th>
											{/* <th>Is Active</th>*/}
											<th>Is Verified</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentBankDetails?.length
											? currentBankDetails
													//?.sort((a, b) => a.date.localeCompare(b.date))
													.map((bankDetail, i) => (
														<tr key={i}>
															<td>{bankDetail.accountHolderName} </td>
															<td>{bankDetail.bankName}</td>
															<td>{bankDetail.branchName}</td>
															<td>{bankDetail.accountNumber}</td>
															<td>{bankDetail.IFSC_Code}</td>
															<td>{bankDetail.account_type}</td>
															{/* <td>
																{bankDetail.is_active === true ? (
																	<i className="fa fa-check-square text-success mr-1"></i>
																) : (
																	<i className="fa fa-square mr-1"></i>
																)}
															</td>*/}
															<td>
																{bankDetail.is_verified === true ? (
																	<i className="fa fa-check-square text-success mr-1"></i>
																) : (
																	<i className="fa fa-square mr-1"></i>
																)}
															</td>
															<td>
																<div className="d-flex">
																	<Link
																		to={`add-bankDetail/${bankDetail._id}`}
																		className="btn btn-info shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-pencil"></i>
																	</Link>
																	<Link
																		to={`bankDetail-details/${bankDetail._id}`}
																		className="btn btn-primary shadow btn-xs sharp mr-1"
																	>
																		<i className="fa fa-th"></i>
																	</Link>
																	<Link
																		to="#"
																		className="btn btn-danger shadow btn-xs sharp"
																		onClick={this.deleteRecord.bind(
																			this,
																			bankDetail._id
																		)}
																	>
																		<i className="fa fa-trash"></i>
																	</Link>
																</div>
															</td>
														</tr>
													))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalBankDetails ? (
										<Pagination
											totalRecords={totalBankDetails}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		bankDetails: state.bankDetail?.bankDetails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getBankDetailsAction, deleteBankDetailAction },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDetail);
