import { Component } from "react";
import { Link } from "react-router-dom"; /// withRouter
import { Breadcrumb, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axiosInstance from "../../../../../services/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import flatSample from "../../../../../../flatExcelFile/society_flats.xlsx";
import readXlsxFile from "read-excel-file";

import {
  uploadFlatAction,
  updateFlatAssociationAction,
} from "../../../../../store/actions/FlatManagementActions";
import { getUsersAction } from "../../../../../store/actions/UserActions";

class UploadFlat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      societyId: "",
      currentFile: undefined,
      flatData: [],
      currentUsers: [],
    };

    this.upload = this.upload.bind(this);
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem("userDetails"));
    this.setState({
      societyId: userData.user.societyName,
    });
    // this.props.getUsersAction(userData.user.societyName);
    this.getUsers();
  }

  async getUsers() {
    const userData = await axiosInstance
      .get(`admin/users/`)
      .then((res) => res?.data);
    console.log("userData", userData?.users);
    this.setState({
      currentUsers: userData?.users,
    });
  }

  upload = async (e) => {
    e.preventDefault();
    //console.log("abc...", this.state?.flatData);

    const flat = await axiosInstance
      .post(`admin/flat/upload`, this.state?.flatData)
      .then((res) => res?.data)
      .catch((error) => {
        toast.error(error);
        console.log("err", error);
        return error;
      });
    //console.log("flat", flat);
    if (flat.success === true) {
      this.props.history.push("/flat-management");
    } else {
      toast.error("Error uploading file please check the sample format...");
    }
  };

  render() {
    ///Below Json Object for formatting upload data.
    const schema = {
      // SocietyId: {
      // 	prop: "societyId",
      // 	type: String,
      // },
      FlatNo: {
        prop: "flatNo",
        type: String,
      },
      OwnerName: {
        prop: "ownerName",
        type: String,
      },
      Mobile: {
        prop: "mobile",
        type: String,
      },
      FlatArea: {
        prop: "flatArea",
        type: Number,
      },
      FlatType: {
        prop: "flatType",
        type: String,
      },
      TwoWheeler: {
        prop: "twoWheeler",
        type: Number,
      },
      FourWheeler: {
        prop: "fourWheeler",
        type: Number,
      },
      OtherCharges: {
        prop: "otherCharges",
        type: Number,
      },
      OtherChargesDescription: {
        prop: "otherChargesDescription",
        type: String,
      },
      OutstandingAmount: {
        prop: "outStandingAmount",
        type: String,
      },
      EbillSubscribed: {
        prop: "ebillSubscribed",
        type: Boolean,
      },
      IsOnRent: {
        prop: "isOnRent",
        type: Boolean,
      },
      LeaseStartDate: {
        prop: "leaseStartDate",
        type: Date,
      },
      LeaseExpiryDate: {
        prop: "leaseExpiryDate",
        type: Date,
      },
      TenantName: {
        prop: "tenantName",
        type: String,
      },
      TenantContactDetails: {
        prop: "tenantContactDetails",
        type: String,
      },
    };
    const input = document.getElementById("input");

    input?.addEventListener("change", () => {
      readXlsxFile(input.files[0], { schema }).then((rows) => {
        console.log("rows", rows?.rows);
        const newRows = rows?.rows.map((v) => ({
          ...v,
          societyId: this.state.societyId,
        }));

        this.state.flatData = newRows;
      });
    });

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
            Dashboard
          </Breadcrumb.Item>{" "}
          <Breadcrumb.Item linkProps={{ to: "/flat-management" }} linkAs={Link}>
            Flat Lists
          </Breadcrumb.Item>{" "}
          <Breadcrumb.Item active>Upload Flats</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row">
          <div className="col-md-6  col-sm-12">
            <h3 className="pt-3">Upload Flats</h3>
          </div>
          <div className="col-md-6 col-sm-12">
            <Link
              to={"/flat-management"}
              className="btn btn-primary mb-2 pull-right"
            >
              Back to Lists
            </Link>
          </div>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="form-validation">
                  <form>
                    <div className="row">
                      <div className="col-8">
                        <label className="btn btn-default p-0">
                          <input type="file" id="input" />
                        </label>
                      </div>

                      <div className="col-4">
                        <button
                          className="btn btn-success btn-sm"
                          onClick={this.upload}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                    <div>
                      <Button
                        style={{
                          background: "none",
                          padding: "5px",
                          border: "none",
                          textDecoration: "underline",
                        }}
                      >
                        <a href={flatSample} download="Flat Excel">
                          Download Sample File
                        </a>
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </>
    );
  }
}

//export default UploadFlat;
const mapStateToProps = (state) => {
  console.log("mapStateToProps", state);
  return {
    users: state.user?.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadFlatAction,
      updateFlatAssociationAction,
      getUsersAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFlat);
