import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { format } from "date-fns";
import swal from "sweetalert";

import {
	getExpensesAction,
	deleteExpenseAction,
} from "../../../../store/actions/ExpenseActions";

const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const thisYear = new Date().getFullYear();
const mnth = new Date();
const thisMonth = monthNames[mnth.getMonth()];

class Expense extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentExpenses: [],
			currentPage: null,
			totalPages: null,
			selectYear: thisYear,
			selectMonth: thisMonth,
			societyId: "",
			years: [],
		};
		this.handleChangeYear = this.handleChangeYear.bind(this);
		this.handleChangeMonth = this.handleChangeMonth.bind(this);
		this.showData = this.showData.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChangeYear(event) {
		this.setState({ currentExpenses: [], selectYear: event.target.value });
	}

	handleChangeMonth(event) {
		this.setState({ currentExpenses: [], selectMonth: event.target.value });
	}

	showData(event) {
		let filteredData = this.props?.expenses?.expenses
			?.filter(
				(item) =>
					item?.year === this.state?.selectYear.toString() &&
					item?.month.slice(0, 3).toLowerCase() ===
						this.state?.selectMonth.slice(0, 3).toLowerCase()
			)
			.slice(0, 5);
		this.setState({
			currentExpenses: filteredData,
		});
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		let currentYear = new Date().getFullYear();
		let lastYear = currentYear - 1;
		let nextYear = currentYear + 1;
		let yearsData = [];
		yearsData.push(lastYear, currentYear, nextYear);

		this.setState({
			societyId: userData.user.societyName,
			years: yearsData,
		});

		this.props.getExpensesAction(userData.user.societyName);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.expenses?.expenses?.length !==
			this.props?.expenses?.expenses?.length
		) {
			this.setState({
				currentExpenses: this.props?.expenses?.expenses,
			});
		}
	}

	initData() {
		let currentExpenses =
			this.state?.currentExpenses?.length > 0
				? this.state?.currentExpenses?.filter(
						(item) =>
							item?.year === this.state?.selectYear.toString() &&
							item?.month.slice(0, 3).toLowerCase() ===
								this.state?.selectMonth.slice(0, 3).toLowerCase()
				  )
				: this.props?.expenses?.expenses?.filter(
						(item) =>
							item?.year === this.state?.selectYear.toString() &&
							item?.month.slice(0, 3).toLowerCase() ===
								this.state?.selectMonth.slice(0, 3).toLowerCase()
				  );

		this.setState({ currentExpenses });
	}

	onPageChanged = (data) => {
		const allExpenses = this.props?.expenses?.expenses?.filter(
			(item) =>
				item?.year === this.state?.selectYear.toString() &&
				item?.month.slice(0, 3).toLowerCase() ===
					this.state?.selectMonth.slice(0, 3).toLowerCase()
		);

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentExpenses = allExpenses?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentExpenses, totalPages });
	};

	handleSearch(event) {
		let currentExpenses = this.props.expenses?.expenses;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentExpenses = currentExpenses.filter(
				(e) =>
					e.expenseType.toLowerCase().match(searchString) ||
					e.expenseBillVoucherNo.toLowerCase().match(searchString) ||
					e.expenseCategory?.categoryName.toLowerCase().match(searchString)
			);
			this.setState({ currentExpenses });
		} else {
			let arrayLength = currentExpenses.slice(0, 5);
			this.setState({ currentExpenses: arrayLength });
		}
		//this.setState({ currentExpenses });
	}

	handleSubmit(event) {
		let filteredDate = this.state?.currentExpenses.filter(
			(item) =>
				item?.year === this.state?.selectYear &&
				item?.month.slice(0, 3).toLowerCase() ===
					this.state?.selectMonth.toLowerCase()
		);

		this.setState({ currentExpenses: filteredDate });
		event.preventDefault();
	}

	deleteRecord(id) {
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteExpenseAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		let totalExpenses =
			this.props?.expenses?.expenses?.filter(
				(item) =>
					item?.year === this.state?.selectYear.toString() &&
					item?.month.slice(0, 3).toLowerCase() ===
						this.state?.selectMonth.slice(0, 3).toLowerCase()
			).length || 0;

		this.state.currentExpenses = this.state.currentExpenses?.filter(
			(item) =>
				item?.year === this.state?.selectYear.toString() &&
				item?.month.slice(0, 3).toLowerCase() ===
					this.state?.selectMonth.slice(0, 3).toLowerCase()
		);

		const { currentExpenses, currentPage, totalPages } = this.state;

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Expense Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Expense Details</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search By Exp Category & Bill no..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-expense"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Expense Details
									</Link>
								</div>
							</div>
							<hr />
							<form onSubmit={this.handleSubmit}>
								<div className="row">
									<div className="col-xl-3 col-sm-12 ml-4">
										<div className="form-group">
											<div>
												<select
													className="form-control"
													id="selectYear"
													placeholder="Select a Select Year.."
													name="selectYear"
													onChange={this.handleChangeYear}
													value={this.state.selectYear}
												>
													<option value="Select" label="Select" disabled />
													{this.state.years?.map((opt, i) => (
														<option key={i} value={opt} label={opt} />
													))}
												</select>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-sm-12">
										<div className="form-group">
											<div>
												<select
													className="form-control"
													id="selectMonth"
													placeholder="Select a Select Month.."
													name="selectMonth"
													onChange={this.handleChangeMonth}
													value={this.state.selectMonth}
												>
													<option
														value="Select Month"
														label="Select Month"
														disabled
													/>
													<option value="January" label="January" />
													<option value="February" label="February" />
													<option value="March" label="March" />
													<option value="April" label="April" />
													<option value="May" label="May" />
													<option value="June" label="June" />
													<option value="July" label="July" />
													<option value="August" label="August" />
													<option value="September" label="September" />
													<option value="October" label="October" />
													<option value="November" label="November" />
													<option value="December" label="December" />
												</select>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-sm-12">
										<Link
											to="#"
											className="btn btn-primary"
											onClick={this.showData}
										>
											Show
										</Link>
									</div>
									{/* <div className="col-xl-3 col-sm-12">
									<button className="btn btn-primary">Show</button>
								</div> */}
								</div>
							</form>

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Expense Type</th>
											<th>Expense Category</th>
											<th>Expense Description</th>
											<th>Bill No.</th>
											<th>Bill Date</th>
											<th>Payment Mode</th>
											<th>Payment Date</th>
											<th>Amount</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentExpenses?.length
											? currentExpenses.map((exp, i) => (
													<tr key={i}>
														<td>{exp.expenseType} </td>
														<td>{exp?.expenseCategory?.categoryName} </td>
														<td>{exp.expenseDescription} </td>
														<td>{exp.expenseBillVoucherNo} </td>
														<td style={{ whiteSpace: "nowrap" }}>
															{exp.expenseBillDate &&
																format(
																	new Date(exp?.expenseBillDate),
																	"dd-MM-yyyy"
																)}{" "}
														</td>
														<td>{exp.paymentMode} </td>
														<td>
															{exp.paymentDate &&
																format(
																	new Date(exp?.paymentDate),
																	"dd-MM-yyyy"
																)}{" "}
														</td>
														<td>{exp.amount} </td>
														<td>
															<div className="d-flex">
																<Link
																	to={`add-expense/${exp._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link>
																<Link
																	to={`expense-details/${exp._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
																<Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={this.deleteRecord.bind(
																		this,
																		exp._id
																	)}
																>
																	<i className="fa fa-trash"></i>
																</Link>
															</div>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalExpenses > 5 ? (
										<Pagination
											totalRecords={totalExpenses}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		expenses: state.expense?.expenses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getExpensesAction, deleteExpenseAction },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Expense);
