import React, { useState, useEffect } from "react";
import { connect } from "react-redux"; //useDispatch
import { withRouter, Link } from "react-router-dom";

//import { logout } from "../../../store/actions/AuthActions";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

import LogoutPage from "./Logout";

import { Dropdown } from "react-bootstrap"; /// Modal
import { Link as ScrollLink } from "react-scroll";

import RequestDemoModal from "../../components/RequestDemoModal";

import axiosInstance from "../../../services/AxiosInstance";
import { format } from "date-fns";

function Header({ onNote, isAuthenticated, user }) {
	//console.log("Header.isAuthenticated", isAuthenticated);
	//console.log("user", user);

	var path = window.location?.pathname.split("/");

	var name = path[path.length - 1].split("-");

	var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
	var finalName = filterName.includes("app")
		? filterName.filter((f) => f !== "app")
		: filterName.includes("ui")
		? filterName.filter((f) => f !== "ui")
		: filterName.includes("uc")
		? filterName.filter((f) => f !== "uc")
		: filterName.includes("basic")
		? filterName.filter((f) => f !== "basic")
		: filterName.includes("jquery")
		? filterName.filter((f) => f !== "jquery")
		: filterName.includes("table")
		? filterName.filter((f) => f !== "table")
		: filterName.includes("page")
		? filterName.filter((f) => f !== "page")
		: filterName.includes("email")
		? filterName.filter((f) => f !== "email")
		: filterName.includes("ecom")
		? filterName.filter((f) => f !== "ecom")
		: filterName.includes("chart")
		? filterName.filter((f) => f !== "chart")
		: filterName.includes("editor")
		? filterName.filter((f) => f !== "editor")
		: filterName;

	//const [show, setShow] = useState(false);

	const [notifications, getNotifications] = useState("");
	const [flats, getFlats] = useState("");

	useEffect(() => {
		if (user) {
			getAllNotifications();
			// getFlatsByUser();
		}
		if (user && user.role !== "admin" && user.role !== "superAdmin") {
			getFlatsByUser();
		}
	}, []);

	const getFlatsByUser = async () => {
		axiosInstance
			.get(`admin/flat/${user._id}`)
			.then((res) => {
				const allFlats = res?.data?.flat;
				getFlats(allFlats);
			})
			.catch((error) => console.error(`Error: ${error}`));
	};

	const getAllNotifications = () => {
		axiosInstance
			.get(`admin/notification/user/${user._id}`)
			.then((response) => {
				const allNotifications = response.data.notifications;
				getNotifications(allNotifications);
			})
			.catch((error) => console.error(`Error: ${error}`));
	};

	const notifyCount = notifications.length;

	// const requestDemoClose = () => setShow(false);
	// const requestDemo = () => setShow(true);

	// function handleAdd(values) {

	// 	// "admin/queries" /// api

	// 	// this.props.updateSocietyAction(
	// 	// 	values,
	// 	// 	this.props.history,
	// 	// 	this.props?.society?._id
	// 	// );
	// 	requestDemoClose();
	// }

	function handleChange(e) {
		let selectedFlat = JSON.parse(e.target.value);

		const data = {
			expiresIn: 1000000 * 1000,
			expireDate: new Date(new Date().getTime() + 1000000 * 1000),
			userId: user?._id,
			flatId: selectedFlat?._id,
			flatNo: selectedFlat?.flatNo,
			ownerName: selectedFlat?.ownerName,
			societyId: selectedFlat?.societyId?._id,
		};

		if (localStorage.getItem("userFlatDetails") !== null) {
			localStorage.removeItem("userFlatDetails");
			localStorage.setItem("userFlatDetails", JSON.stringify(data));
		} else {
			localStorage.setItem("userFlatDetails", JSON.stringify(data));
		}
		window.location.reload();
	}

	const selectedFlatData = JSON.parse(localStorage.getItem("userFlatDetails"));

	const createObjtoArr = Object.values(flats);
	const societyNameArr = createObjtoArr.find(
		(itm) => itm?.societyId?._id === selectedFlatData?.societyId
	);

	return (
		<div className="header">
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							{isAuthenticated === true ? (
								<>
									<div
										className="dashboard_bar"
										style={{ textTransform: "capitalize" }}
									>
										{/* {finalName.join(" ").length === 0
										? "Dashboard"
										: finalName.join(" ") === "dashboard dark"
										? "Dashboard"
										: finalName.join(" ")} */}

										<h3>
											Hi Welcome to
											<span className="blue-text pl-1">Society </span>{" "}
											<span className="yellow-text pr-1"> Care</span>
											<b style={{ fontVariantCaps: "petite-caps" }}>
												{user.role}
											</b>
										</h3>
									</div>

									{!user.role && (
										<div
											className="header-right"
											style={{
												height: "100%",
												display: "flex",
												alignItems: "center",
											}}
										>
											<div>
												<select
													className="form-control"
													id="flat"
													placeholder="Select Flat.."
													name="flat"
													onChange={(e) => handleChange(e)}
													style={{ width: "250px", marginLeft: "200px" }}
												>
													<option
														value={selectedFlatData?.flatNo}
														label={`${societyNameArr?.societyId?.societyName} - ${selectedFlatData?.flatNo}`}
													>
														{selectedFlatData?.flatNo}
													</option>
													{flats && flats?.length > 0
														? flats?.map((opt, i) => (
																<option key={i} value={JSON.stringify(opt)}>
																	{opt?.societyId?.societyName} - {opt.flatNo}
																</option>
														  ))
														: null}
												</select>
											</div>
										</div>
									)}
								</>
							) : (
								<div>
									<ul
										className="header-menus"
										style={{
											display: "flex",
											listStyle: "none",
											justifyContent: "space-around",
										}}
									>
										<li className="header-nav-link">
											<ScrollLink
												activeClass="active"
												to="home"
												spy={true}
												smooth={true}
											>
												Home
											</ScrollLink>
										</li>
										<li className="header-nav-link">
											<ScrollLink to="about" spy={true} smooth={true}>
												About
											</ScrollLink>
										</li>
										<li className="header-nav-link">
											<ScrollLink to="howwehelp" spy={true} smooth={true}>
												How We Help?
											</ScrollLink>
										</li>
										<li className="header-nav-link">
											<ScrollLink to="feature" spy={true} smooth={true}>
												Feature
											</ScrollLink>
										</li>
										<li className="header-nav-link">
											<RequestDemoModal />
										</li>
									</ul>
								</div>
							)}
						</div>

						<ul className="navbar-nav header-right main-notification">
							{!isAuthenticated ? (
								<li className="nav-item header-nav-link">
									<div className="d-lg-inline-flex">
										<Link className="all-notification" to="/login">
											<i className="fa fa-lock" /> Login
										</Link>
									</div>
								</li>
							) : (
								""
							)}

							{isAuthenticated ? (
								<Dropdown
									as="li"
									className="nav-item dropdown notification_dropdown "
								>
									<Dropdown.Toggle
										variant=""
										as="a"
										className="nav-link  ai-icon i-false c-pointer "
										// href="#"
										role="button"
										data-toggle="dropdown"
									>
										<svg
											width="28"
											height="28"
											viewBox="0 0 28 28"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M23.3333 19.8333H23.1187C23.2568 19.4597 23.3295 19.065 23.3333 18.6666V12.8333C23.3294 10.7663 22.6402 8.75902 21.3735 7.12565C20.1068 5.49228 18.3343 4.32508 16.3333 3.80679V3.49996C16.3333 2.88112 16.0875 2.28763 15.6499 1.85004C15.2123 1.41246 14.6188 1.16663 14 1.16663C13.3812 1.16663 12.7877 1.41246 12.3501 1.85004C11.9125 2.28763 11.6667 2.88112 11.6667 3.49996V3.80679C9.66574 4.32508 7.89317 5.49228 6.6265 7.12565C5.35983 8.75902 4.67058 10.7663 4.66667 12.8333V18.6666C4.67053 19.065 4.74316 19.4597 4.88133 19.8333H4.66667C4.35725 19.8333 4.0605 19.9562 3.84171 20.175C3.62292 20.3938 3.5 20.6905 3.5 21C3.5 21.3094 3.62292 21.6061 3.84171 21.8249C4.0605 22.0437 4.35725 22.1666 4.66667 22.1666H23.3333C23.6428 22.1666 23.9395 22.0437 24.1583 21.8249C24.3771 21.6061 24.5 21.3094 24.5 21C24.5 20.6905 24.3771 20.3938 24.1583 20.175C23.9395 19.9562 23.6428 19.8333 23.3333 19.8333Z"
												fill="#67636D"
											/>
											<path
												d="M9.98193 24.5C10.3863 25.2088 10.971 25.7981 11.6767 26.2079C12.3823 26.6178 13.1839 26.8337 13.9999 26.8337C14.816 26.8337 15.6175 26.6178 16.3232 26.2079C17.0289 25.7981 17.6136 25.2088 18.0179 24.5H9.98193Z"
												fill="#67636D"
											/>
										</svg>
										<span className="badge light text-white bg-primary rounded-circle">
											{notifyCount}
										</span>
									</Dropdown.Toggle>
									<Dropdown.Menu align="right" className="mt-2">
										<PerfectScrollbar className="widget-media dz-scroll p-3 height380 ">
											<ul className="timeline">
												{notifications.length
													? notifications.map((notify, i) => (
															<li key={i}>
																<div className="timeline-panel">
																	<div className="media-body">
																		<h6 className="mb-1">{notify?.title}</h6>
																		<small className="d-block">
																			{notify?.sentAt &&
																				format(
																					new Date(notify?.sentAt),
																					"dd-MM-yyyy"
																				)}
																		</small>
																	</div>
																</div>
															</li>
													  ))
													: null}
											</ul>
											<div
												className="ps__rail-x"
												style={{ left: 0, bottom: 0 }}
											>
												<div
													className="ps__thumb-x"
													tabIndex={0}
													style={{ left: 0, width: 0 }}
												/>
											</div>
											<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
												<div
													className="ps__thumb-y"
													tabIndex={0}
													style={{ top: 0, height: 0 }}
												/>
											</div>
										</PerfectScrollbar>
										{/* <Link className="all-notification" to="#">
										See all notifications <i className="ti-arrow-right" />
									</Link> */}
									</Dropdown.Menu>
								</Dropdown>
							) : (
								""
							)}

							{isAuthenticated ? (
								<Dropdown as="li" className="nav-item dropdown header-profile">
									<Dropdown.Toggle
										variant=""
										as="a"
										className="nav-link i-false c-pointer"
										to="javascript:void()"
										role="button"
										data-toggle="dropdown"
									>
										<div className="header-info">
											<span>{user?.name}</span>
											{user.role ? (
												<span>{user?.role}</span>
											) : (
												<small>{user?.userName}</small>
											)}
										</div>
									</Dropdown.Toggle>
									{user.role === "admin" || user.role === "superAdmin" ? (
										<Dropdown.Menu align="right" className="mt-2">
											<Dropdown.Item as="button">
												<Link to="/profile" className="dropdown-item ai-icon">
													<svg
														id="icon-user1"
														xmlns="http://www.w3.org/2000/svg"
														className="text-primary"
														width={18}
														height={18}
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth={2}
														strokeLinecap="round"
														strokeLinejoin="round"
													>
														<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
														<circle cx={12} cy={7} r={4} />
													</svg>
													<span className="ml-2">Profile </span>
												</Link>
											</Dropdown.Item>
											<Dropdown.Item as="button">
												<Link
													to="/change-password"
													className="dropdown-item ai-icon"
												>
													<svg
														className="text-warning"
														width="24"
														height="24"
														xmlns="http://www.w3.org/2000/svg"
														fillRule="evenodd"
														clipRule="evenodd"
													>
														<path d="M4.81 4l13.243 15.714-1.532 1.286-5.092-6h-2.124l-1.046-1.013-1.302 1.019-1.362-1.075-1.407 1.081-4.188-3.448 3.346-3.564h2.21l-2.278-2.714 1.532-1.286zm8.499 6h-1.504l-1.678-2h2.06c1.145-1.683 3.104-3 5.339-3 3.497 0 6.474 2.866 6.474 6.5 0 3.288-2.444 5.975-5.54 6.431l-1.678-2c.237.045.485.069.744.069 2.412 0 4.474-1.986 4.474-4.5 0-2.498-2.044-4.5-4.479-4.5-2.055 0-3.292 1.433-4.212 3zm5.691-.125c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-10.626 1.484l-1.14-1.359h-3.022l-1.293 1.376 1.312 1.081 1.38-1.061 1.351 1.066 1.412-1.103z" />
													</svg>
													<span className="ml-2">Change Password </span>
												</Link>
											</Dropdown.Item>
											<Dropdown.Item as="button">
												<LogoutPage />
											</Dropdown.Item>
										</Dropdown.Menu>
									) : (
										<Dropdown.Menu align="right" className="mt-2">
											<Dropdown.Item as="button">
												<Link
													to="/userprofile"
													className="dropdown-item ai-icon"
												>
													<svg
														id="icon-user1"
														xmlns="http://www.w3.org/2000/svg"
														className="text-primary"
														width={18}
														height={18}
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth={2}
														strokeLinecap="round"
														strokeLinejoin="round"
													>
														<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
														<circle cx={12} cy={7} r={4} />
													</svg>
													<span className="ml-2">My Profile </span>
												</Link>
											</Dropdown.Item>
											{/* <Dropdown.Item as="button">
												<Link
													to="/member-change-password"
													className="dropdown-item ai-icon"
												>
													<svg
														className="text-warning"
														width="24"
														height="24"
														xmlns="http://www.w3.org/2000/svg"
														fillRule="evenodd"
														clipRule="evenodd"
													>
														<path d="M4.81 4l13.243 15.714-1.532 1.286-5.092-6h-2.124l-1.046-1.013-1.302 1.019-1.362-1.075-1.407 1.081-4.188-3.448 3.346-3.564h2.21l-2.278-2.714 1.532-1.286zm8.499 6h-1.504l-1.678-2h2.06c1.145-1.683 3.104-3 5.339-3 3.497 0 6.474 2.866 6.474 6.5 0 3.288-2.444 5.975-5.54 6.431l-1.678-2c.237.045.485.069.744.069 2.412 0 4.474-1.986 4.474-4.5 0-2.498-2.044-4.5-4.479-4.5-2.055 0-3.292 1.433-4.212 3zm5.691-.125c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-10.626 1.484l-1.14-1.359h-3.022l-1.293 1.376 1.312 1.081 1.38-1.061 1.351 1.066 1.412-1.103z" />
													</svg>
													<span className="ml-2">Change Password </span>
												</Link>
											</Dropdown.Item> */}
											<Dropdown.Item as="button">
												<LogoutPage />
											</Dropdown.Item>
										</Dropdown.Menu>
									)}
								</Dropdown>
							) : (
								""
							)}
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: isAuthenticated(state),
		user: state.auth?.auth?.user,
	};
};

export default withRouter(connect(mapStateToProps)(Header));
//export default Header;
