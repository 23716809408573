export const GET_EXPENSE_DETAILS = "[Expense Action] Get Expense";
export const CONFIRMED_GET_EXPENSE_DETAILS =
	"[Expense Action] Confirmed Get Expense";
export const CREATE_EXPENSE_ACTION = "[Expense Action] Create Expense";
export const CONFIRMED_CREATE_EXPENSE_ACTION =
	"[Expense Action] Confirmed Create Expense";
export const GET_EXPENSES = "[Expenses Action] Get Expenses";
export const CONFIRMED_GET_EXPENSES =
	"[Expenses Action] Confirmed Get Expenses";
export const EDIT_EXPENSE_ACTION = "[Expense Action] Edit Expense";
export const CONFIRMED_EDIT_EXPENSE_ACTION =
	"[Expense Action] Confirmed Edit Expense";
export const CONFIRMED_DELETE_EXPENSE_ACTION =
	"[Expense Action] Confirmed Delete Expense";
