import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { getSocietyAction } from "../../../../store/actions/SocietyActions";

class SocietyImageDetail extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));

		if (this.props.contact) {
			this.props.getSocietyAction(userData.user.societyName);
		}
	}

	render() {
		const images = this.props?.images?.society?.societyImages[0];

		const fileurl = images?.fileurl || "";

		return (
			<>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Society Image Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/society-images"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<div className="row">
											<div className="col-sm-12">
												<strong>Image</strong>
											</div>
											<div className="col-sm-12">
												<img
													src={fileurl}
													height="250"
													width="250"
													alt="Society Name"
												/>
											</div>
										</div>
										{/* <div className="row">
											<img src={fileurl} height="250" width="250" />
										</div> */}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		images: state.society?.society,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getSocietyAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SocietyImageDetail);
