import axiosInstance from "../services/AxiosInstance";

export function getBillingDetails(billingId) {
	return axiosInstance.get(`admin/billing/${billingId}`);
}

export function getBillings(societyId) {
	return axiosInstance.get(`admin/billings/${societyId}`);
}

export function createBilling(billingData) {
	return axiosInstance.post(`admin/billing`, billingData);
}

export function updateBilling(billing, billingId) {
	return axiosInstance.put(`admin/billing/${billingId}`, billing);
}

export function deleteBilling(billingId) {
	return axiosInstance.delete(`admin/billing/${billingId}`);
}

export function getBillingsByFlat(societyId, flatId) {
	return axiosInstance.get(`admin/billings/${societyId}/${flatId}`);
}
