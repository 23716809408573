import {
	getExpenseDetails,
	getExpenses,
	createExpense,
	updateExpense,
	deleteExpense,
} from "../../services/ExpenseService";
import {
	CONFIRMED_GET_EXPENSE_DETAILS,
	CONFIRMED_CREATE_EXPENSE_ACTION,
	CONFIRMED_GET_EXPENSES,
	CONFIRMED_EDIT_EXPENSE_ACTION,
	CONFIRMED_DELETE_EXPENSE_ACTION,
} from "./ExpenseTypes";

export function getExpenseAction(expenseId) {
	return (dispatch, getState) => {
		getExpenseDetails(expenseId).then((response) => {
			dispatch(confirmedGetExpenseAction(response.data.expense));
		});
	};
}

export function confirmedGetExpenseAction(expense) {
	return {
		type: CONFIRMED_GET_EXPENSE_DETAILS,
		payload: expense,
	};
}

export function deleteExpenseAction(expenseId, history) {
	return (dispatch, getState) => {
		deleteExpense(expenseId).then((response) => {
			dispatch(confirmedDeleteExpenseAction(expenseId));
			history.push("/expense");
		});
	};
}

export function confirmedDeleteExpenseAction(expenseId) {
	return {
		type: CONFIRMED_DELETE_EXPENSE_ACTION,
		payload: expenseId,
	};
}

export function createExpenseAction(ExpenseData, history) {
	return (dispatch, getState) => {
		createExpense(ExpenseData).then((response) => {
			const singleExpense = {
				...ExpenseData,
				id: response.data._id,
			};
			dispatch(confirmedCreateExpenseAction(singleExpense));
			history.push("/expense");
		});
	};
}

export function getExpensesAction(societyId) {
	return (dispatch, getState) => {
		getExpenses(societyId).then((response) => {
			dispatch(confirmedGetExpensesAction(response.data));
		});
	};
}

export function confirmedCreateExpenseAction(singleExpense) {
	return {
		type: CONFIRMED_CREATE_EXPENSE_ACTION,
		payload: singleExpense,
	};
}

export function confirmedGetExpensesAction(Expenses) {
	return {
		type: CONFIRMED_GET_EXPENSES,
		payload: Expenses,
	};
}

export function confirmedUpdateExpenseAction(Expense) {
	return {
		type: CONFIRMED_EDIT_EXPENSE_ACTION,
		payload: Expense,
	};
}

export function updateExpenseAction(Expense, history, expenseId) {
	return (dispatch, getState) => {
		updateExpense(Expense, expenseId).then((reponse) => {
			dispatch(confirmedUpdateExpenseAction(Expense));
			history.push("/expense");
		});
	};
}
