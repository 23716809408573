import {
	CONFIRMED_GET_NOTICE_DETAILS,
	CONFIRMED_CREATE_NOTICE_ACTION,
	CONFIRMED_GET_NOTICES,
	CONFIRMED_EDIT_NOTICE_ACTION,
	CONFIRMED_DELETE_NOTICE_ACTION,
	NOTICES_LOADING,
} from "../actions/NoticeTypes";

const initialState = {
	notices: [],
	notice: {},
};

export default function NoticeReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_NOTICE_DETAILS) {
		return {
			...state,
			notice: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_NOTICE_ACTION) {
		const notices = [...state.notices.notices];
		const noticeIndex = notices.findIndex(
			(notice) => notice.id === actions.payload
		);

		notices.splice(noticeIndex, 1);

		return {
			...state,
			notices: { notices },
		};
	}

	if (actions.type === CONFIRMED_EDIT_NOTICE_ACTION) {
		return {
			...state,
			notices: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_NOTICE_ACTION) {
		return {
			...state,
			notices: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_NOTICES) {
		return {
			...state,
			notices: actions.payload,
			loading: false,
		};
	}

	if (actions.type === NOTICES_LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
