import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

import { getUserAction } from "../../../../store/actions/UserActions";

class UserDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.user) {
			this.props.getUserAction(id);
		}
		this.init();
	}

	init() {
		this.state.user = this.props?.user;
	}

	render() {
		this.state.user = this.props?.user;

		const name = this.state.user?.name || "";
		const email = this.state.user?.email || "";
		const mobile = this.state.user?.mobile || "";
		const address = this.state.user?.address || "";
		const status = this.state.user?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/users" }} linkAs={Link}>
						Users Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>User Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">User Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link to={"/users"} className="btn btn-primary mb-2 pull-right">
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>User Name</strong>
										<span className="mb-0">{name}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Email</strong>
										<span className="mb-0">{email}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Mobile</strong>
										<span className="mb-0">{mobile}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Address</strong>
										<span className="mb-0">{address}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user?.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getUserAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
