import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

import {
	getFlatSocietyUserAction,
	getFlatAction,
} from "../../../../../store/actions/FlatManagementActions";

class FlatDetailManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			flat: {},
			societyId: "",
			userId: "",
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
			userId: userData?.user?._id,
		});

		let id = this.props.match.params.id;
		//let userId = this.props.match.params.userId;

		if (this.props.flat) {
			//this.props.getFlatSocietyUserAction(userData.user.societyName, userId);
			this.props.getFlatAction(id);
		}
		this.init();
	}

	init() {
		this.state.flat = this.props?.flat?.flat;
	}

	render() {
		this.state.flat = this.props?.flat?.flat;

		const flat = this.state?.flat;

		const flatNo = flat?.flatNo || "";
		const ownerName = flat?.ownerName || "";

		const flatArea = flat?.flatArea || "";
		const flatType = flat?.flatType || "";
		const twoWheeler = flat?.twoWheeler || 0;
		const fourWheeler = flat?.fourWheeler || 0;
		const isOnRent = flat?.isOnRent || false;
		const ebillSubscribed = flat?.ebillSubscribed || false;
		const status = flat?.status || false;

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/flat-management" }} linkAs={Link}>
						Flat Management Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Flat Management Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Flat Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/flat-management"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Flat No</strong>
										<span className="mb-0">{flatNo}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Owner Name</strong>
										<span className="mb-0 lineBreak">{ownerName}</span>
									</li>

									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Flat Area</strong>
										<span className="mb-0">{flatArea}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Flat Type</strong>
										<span className="mb-0">{flatType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Two Wheeler</strong>
										<span className="mb-0">{twoWheeler}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Four Wheeler</strong>
										<span className="mb-0">{fourWheeler}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Is On Rent</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{isOnRent === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Ebill Subscribed</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{ebillSubscribed === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<span className="mb-0">
											<div className="d-flex align-items-center">
												{status === true ? (
													<i className="fa fa-check-square text-success mr-1"></i>
												) : (
													<i className="fa fa-square mr-1"></i>
												)}
											</div>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		flat: state.flat?.flat,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{ getFlatSocietyUserAction, getFlatAction },
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FlatDetailManagement);
