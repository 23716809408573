import axiosInstance from "../services/AxiosInstance";

export function getBankDetailDetails(bankDetailId) {
	return axiosInstance.get(`admin/bankaccount/${bankDetailId}`);
}

export function getBankDetails(societyId) {
	return axiosInstance.get(`admin/bankaccounts/${societyId}`);
}

export function createBankDetail(bankDetailData) {
	return axiosInstance.post(`admin/bankaccount`, bankDetailData);
}

export function updateBankDetail(bankDetail, bankDetailId) {
	return axiosInstance.put(`admin/bankaccount/${bankDetailId}`, bankDetail);
}

export function deleteBankDetail(bankDetailId) {
	return axiosInstance.delete(`admin/bankaccount/${bankDetailId}`);
}
