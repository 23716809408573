import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import { format } from "date-fns";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { getRequestQueriesAction } from "../../../../../store/actions/RequestQueryActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class RequestQuery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentQueries: [],
			currentPage: null,
			totalPages: null,
		};
	}

	componentDidMount() {
		let flatData = JSON.parse(localStorage.getItem("userFlatDetails"));

		this.props.getRequestQueriesAction(flatData?.societyId);

		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.queries?.queriess?.length !==
			this.props?.queries?.queriess?.length
		) {
			this.setState({
				currentQueries: this.props?.queries?.queriess,
			});
		}
	}

	initData() {
		let currentQueries =
			this.state?.currentQueries?.length > 0
				? this.state?.currentQueries
				: this.props?.queries?.queriess;

		this.setState({ currentQueries });
	}

	onPageChanged = (data) => {
		const allQueries = this.props?.queries?.queriess;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentQueries = allQueries?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentQueries, totalPages });
	};

	handleSearch(event) {
		let currentQueries = this.props.queries?.queriess;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentQueries = currentQueries.filter(
				(e) =>
					e.flatNo.toLowerCase().match(searchString) ||
					e.memberName.toLowerCase().match(searchString) ||
					e.title.toLowerCase().match(searchString) ||
					(e.date &&
						format(new Date(e?.date), "dd-MM-yyyy")
							.toLowerCase()
							.match(searchString))
			);
			this.setState({ currentQueries });
		} else {
			let arrayLength = currentQueries.slice(0, 5);
			this.setState({ currentQueries: arrayLength });
		}
		//this.setState({ currentQueries });
	}

	render() {
		let totalQueries = this.props?.queries?.queriess?.length || 0;

		const { currentQueries, currentPage, totalPages } = this.state;
		this.state.currentQueries = this.props.queries?.queriess;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/memberDashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Request Query Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Request Queries</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"member/add-request-query"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Request Query
									</Link>
								</div>
							</div>
							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Flat No</th>
											<th>Member Name</th>
											<th>Title</th>
											<th>Request Status</th>
											<th>Description</th>
											<th>Date</th>
											{/* <th>Action</th> */}
										</tr>
									</thead>
									<tbody>
										{currentQueries?.length
											? currentQueries.map((query, i) => (
													<tr key={i}>
														<td>{query.flatNo} </td>
														<td>{query.memberName} </td>
														<td>{query.title} </td>
														<td>
															{/* {query.status} */}
															{query.status === "Pending" && (
																<span className="badge badge-rounded badge-primary">
																	{query.status}
																</span>
															)}
															{query.status === "Open" && (
																<span className="badge badge-rounded badge-warning">
																	{query.status}
																</span>
															)}
															{query.status === "Closed" && (
																<span className="badge badge-rounded badge-danger">
																	{query.status}
																</span>
															)}
														</td>
														<td>{query.description}</td>
														<td style={{ whiteSpace: "nowrap" }}>
															{query.date &&
																format(new Date(query?.date), "dd-MM-yyyy")}
														</td>
														{/* <td>
															<div className="d-flex">
																<Link
																	to={`add-request-query/${query._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link>
																<Link
																	to={`request-query-details/${query._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
																<Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={this.deleteRecord.bind(
																		this,
																		query._id
																	)}
																>
																	<i className="fa fa-trash"></i>
																</Link>
															</div>
														</td> */}
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalQueries ? (
										<Pagination
											totalRecords={totalQueries}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		queries: state.query?.queries,
		loading: state?.query?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getRequestQueriesAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestQuery);
