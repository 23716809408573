import axiosInstance from "../services/AxiosInstance";

export function getAmcDetails(amcId) {
	return axiosInstance.get(`admin/amc/${amcId}`);
}

export function getAmcs(societyId) {
	return axiosInstance.get(`admin/amcs/${societyId}`);
}

export function createAmc(amcData) {
	return axiosInstance.post(`admin/amc`, amcData);
}

export function updateAmc(amc, amcId) {
	return axiosInstance.put(`admin/amc/${amcId}`, amc);
}

export function deleteAmc(amcId) {
	return axiosInstance.delete(`admin/amc/${amcId}`);
}
