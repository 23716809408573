export const GET_PAYMENT_DETAILS = "[Payment Action] Get Payment";
export const CONFIRMED_GET_PAYMENT_DETAILS =
	"[Payment Action] Confirmed Get Payment";
export const CREATE_PAYMENT_ACTION = "[Payment Action] Create Payment";
export const CONFIRMED_CREATE_PAYMENT_ACTION =
	"[Payment Action] Confirmed Create Payment";
export const GET_PAYMENTS = "[Payments Action] Get Payments";
export const CONFIRMED_GET_PAYMENTS =
	"[Payments Action] Confirmed Get Payments";
export const EDIT_PAYMENT_ACTION = "[Payment Action] Edit Payment";
export const CONFIRMED_EDIT_PAYMENT_ACTION =
	"[Payment Action] Confirmed Edit Payment";
export const CONFIRMED_DELETE_PAYMENT_ACTION =
	"[Payment Action] Confirmed Delete Payment";
export const GET_PAYMENTS_BY_FLAT =
	"[Payments By Flat Action] Get Payments By Flat";
export const CONFIRMED_GET_PAYMENTS_BY_FLAT =
	"[Payments By Flat Action] Confirmed Get Payments By Flat";
export const LOADING = "[Payment Loading Action] Confirmed Payment Loading";
