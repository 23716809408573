import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

import { getAmenityMasterAction } from "../../../../store/actions/AmenitiesMasterActions";

class AmenityDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			amenity: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.amenity) {
			this.props.getAmenityMasterAction(id); //For Local
		}
		this.init();
	}

	init() {
		this.state.amenity = this.props?.amenity;
	}

	render() {
		this.state.amenity = this.props?.amenity;

		const name = this.state.amenity?.name || "";
		const webIcon = this.state.amenity?.webIcon || "";
		const status = this.state.amenity?.status || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item linkProps={{ to: "/amenity-lists" }} linkAs={Link}>
						Amenity Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Amenity Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Amenity Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/amenity-lists"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Name</strong>
										<span className="mb-0">{name}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Web Icon</strong>
										<div>
											<i className={webIcon}></i>
										</div>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Status</strong>
										<div>
											{status === true ? (
												<i className="fa fa-check-square text-success mr-1"></i>
											) : (
												<i className="fa fa-square mr-1"></i>
											)}
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		amenity: state.amenity?.amenitymaster,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getAmenityMasterAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AmenityDetail);
