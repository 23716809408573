import {
	CONFIRMED_GET_BANKDETAILS_DETAILS,
	CONFIRMED_CREATE_BANKDETAILS_ACTION,
	CONFIRMED_GET_BANKDETAILS,
	CONFIRMED_EDIT_BANKDETAILS_ACTION,
	CONFIRMED_DELETE_BANKDETAILS_ACTION,
} from "../actions/BankDetailsTypes";

const initialState = {
	bankDetails: [],
	bankDetail: {},
};

export default function BankDetailsReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_BANKDETAILS_DETAILS) {
		return {
			...state,
			bankDetail: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_BANKDETAILS_ACTION) {
		const bankDetails = [...state.bankDetails.bankDetail];
		const bankDetailIndex = bankDetails.findIndex(
			(bankDetail) => bankDetail.id === actions.payload
		);

		bankDetails.splice(bankDetailIndex, 1);

		const bankDetail = bankDetails;

		return {
			...state,
			bankDetails: { bankDetail },
		};
	}

	if (actions.type === CONFIRMED_EDIT_BANKDETAILS_ACTION) {
		return {
			...state,
			bankDetails: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_BANKDETAILS_ACTION) {
		return {
			...state,
			bankDetails: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_BANKDETAILS) {
		return {
			...state,
			bankDetails: actions.payload,
		};
	}

	return state;
}
