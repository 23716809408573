import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getBillingHeaderAction,
	createBillingHeaderAction,
	updateBillingHeaderAction,
} from "../../../../../store/actions/BillingHeaderMappingActions";
import { getSocietiesAction } from "../../../../../store/actions/SocietyActions";

class AddEditBillingHeaderMapping extends Component {
	constructor(props) {
		super(props);
		this.state = {
			billingHeader: {},
			temp: "",
			societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (id) {
			if (this.props.billingHeader) {
				this.props.getBillingHeaderAction(id);
			}
		} else {
		}
		this.props.getSocietiesAction();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.billingHeader[0]?.length !==
			this.props?.billingHeader[0]?.length
		) {
			this.setState({
				billingHeader: this.props?.billingHeader[0],
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateBillingHeaderAction(
				values,
				this.props.history,
				this.props?.billingHeader[0]?._id
			);
		} else {
			this.props.createBillingHeaderAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;
		if (id) {
			var billingHeader = this.props?.billingHeader[0];
		} else {
			billingHeader = "";
		}

		let society_data = this.props.societies?.societies;

		const initialValues = {
			societyId: billingHeader ? billingHeader.societyId : "",
			// societyHeader: billingHeader ? billingHeader.societyHeader : "",
			// societyFooter: billingHeader ? billingHeader.societyFooter : "",
			// flatNo: billingHeader ? billingHeader.flatNo : "",
			// ownerName: billingHeader ? billingHeader.ownerName : "",
			// ownerEmail: billingHeader ? billingHeader.ownerEmail : "",
			// contactDetails: billingHeader ? billingHeader.contactDetails : "",
			// flatArea: billingHeader ? billingHeader.flatArea : "",
			// flatType: billingHeader ? billingHeader.flatType : "",
			// twoWheeler: billingHeader ? billingHeader.twoWheeler : "",
			// fourWheeler: billingHeader ? billingHeader.fourWheeler : "",
			// otherCharges: billingHeader ? billingHeader.otherCharges : "",
			// otherChargesDescription: billingHeader
			// 	? billingHeader.otherChargesDescription
			// 	: "",
			// outStandingAmount: billingHeader ? billingHeader.outStandingAmount : "",
			// ebillSubscribed: billingHeader ? billingHeader.ebillSubscribed : "true",
			// isOnRent: billingHeader ? billingHeader.isOnRent : "Rented",
			// tenantName: billingHeader ? billingHeader.tenantName : "",
			// tenantContactDetails: billingHeader
			// 	? billingHeader.tenantContactDetails
			// 	: "",
			// leaseStartDate: billingHeader ? billingHeader.leaseStartDate : new Date(),
			// leaseExpiryDate: billingHeader
			// 	? billingHeader.leaseExpiryDate
			// 	: new Date(),
			//status: billingHeader ? billingHeader.status : true,
		};

		const validationSchema = Yup.object().shape({
			societyId: Yup.string().required("Please Select Society."),
			// societyHeader: Yup.string().required("Please Enter Header."),
			// societyFooter: Yup.string().required("Please Enter Footer."),
			// flatNo: Yup.string().required("Please enter a Flat No."),
			// ownerName: Yup.string()
			// 	.min(1, "Your Owner Name must be at least 1 characters long")
			// 	.max(50, "Your Owner Name must be max 50 characters long")
			// 	.required("Please provide a Owner Name"),
			// ownerEmail: Yup.string()
			// 	.email("Email is invalid")
			// 	.required("Please provide an email"),
			// contactDetails: Yup.string().required("Please provide a contact no."),
			// leaseStartDate: Yup.date().when("isOnRent", (isOnRent, schema) => {
			// 	if (isOnRent) return schema.required("Lease Start Date is required");
			// }),
			// leaseExpiryDate: Yup.date()
			// 	.when("isOnRent", (isOnRent, schema) => {
			// 		if (isOnRent) return schema.required("Lease Expiry Date is required");
			// 	})
			// 	.min(
			// 		Yup.ref("leaseStartDate"),
			// 		"End date can't be before Lease Start Date"
			// 	),
		});

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item
						linkProps={{ to: "/superadmin/billing-header-mapping" }}
						linkAs={Link}
					>
						Billing Header Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Billing Header</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Billing Header</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Billing Header</h3>
						) : (
							<h3 className="pt-3">Add Billing Header</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/superadmin/billing-header-mapping"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												{/* <div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society Name *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-societyId"
																	placeholder="Select a Society.."
																	name="societyId"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyId || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	{society_data?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt._id}
																			label={opt.societyName}
																		/>
																	))}
																</select>
																{errors.societyId && touched.societyId ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyId}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-8 col-sm-12">
														<div className="form-group">
															<label className="text-label">Header *</label>
															<div>
																<Editor
																	init={{
																		height: 500,
																		menubar: false,
																		plugins: [
																			"advlist autolink lists link image code charmap print preview anchor",
																			"searchreplace visualblocks code fullscreen",
																			"insertdatetime media table paste code help wordcount",
																		],
																		toolbar:
																			"undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help ",
																		content_style: "body { color: #828282 }",
																	}}
																	onEditorChange={(e) =>
																		handleChange({
																			target: {
																				name: "societyHeader",
																				value: e,
																			},
																		})
																	}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyHeader || ""}
																/>
																{errors.societyHeader &&
																touched.societyHeader ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyHeader}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-xl-8 col-sm-12">
														<div className="form-group">
															<label className="text-label">Footer *</label>
															<div>
																<Editor
																	init={{
																		height: 500,
																		menubar: false,
																		plugins: [
																			"advlist autolink lists link image code charmap print preview anchor",
																			"searchreplace visualblocks code fullscreen",
																			"insertdatetime media table paste code help wordcount",
																		],
																		toolbar:
																			"undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help ",
																		content_style: "body { color: #828282 }",
																	}}
																	onEditorChange={(e) =>
																		handleChange({
																			target: {
																				name: "societyFooter",
																				value: e,
																			},
																		})
																	}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyFooter || ""}
																/>
																{errors.societyFooter &&
																touched.societyFooter ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyFooter}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div> */}
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society Name *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-societyId"
																	placeholder="Select a Society.."
																	name="societyId"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyId || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	{society_data?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt._id}
																			label={opt.societyName}
																		/>
																	))}
																</select>
																{errors.societyId && touched.societyId ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyId}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													{/* <div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Flat No *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-flatNo"
																	placeholder="Enter a Flat No.."
																	name="flatNo"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.flatNo || ""}
																/>
																{errors.flatNo && touched.flatNo ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.flatNo}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Owner Name *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-ownerName"
																	name="ownerName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.ownerName || ""}
																	placeholder="Enter Owner Name."
																/>
																{errors.ownerName && touched.ownerName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.ownerName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Owner Email *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-ownerEmail"
																	placeholder="Enter a Email.."
																	name="ownerEmail"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.ownerEmail || ""}
																/>
																{errors.ownerEmail && touched.ownerEmail ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.ownerEmail}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Contact Details *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-contactDetails"
																	placeholder="Enter a contact number.."
																	name="contactDetails"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.contactDetails || ""}
																/>
																{errors.contactDetails &&
																touched.contactDetails ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.contactDetails}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Flat Area</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-flatArea"
																	placeholder="Enter a flatArea.."
																	name="flatArea"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.flatArea || ""}
																/>
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Flat Type</label>
															<div>
																<select
																	className="form-control"
																	id="val-flatType"
																	placeholder="Enter a flatType.."
																	name="flatType"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.flatType || ""}
																>
																	<option
																		value="Residential"
																		label="Residential"
																	/>
																	<option
																		value="Commercial"
																		label="Commercial"
																	/>
																</select>
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Two Wheeler</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-twoWheeler"
																	placeholder="Enter a twoWheeler.."
																	name="twoWheeler"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.twoWheeler}
																/>
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">Four Wheeler</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-fourWheeler"
																	placeholder="Enter a Four Wheeler.."
																	name="fourWheeler"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.fourWheeler}
																/>
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div
															className={`form-group ${
																values.otherCharges
																	? errors.otherCharges
																		? "is-invalid"
																		: "is-valid"
																	: ""
															}`}
														>
															<label className="text-label">
																Other Charges
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-otherCharges"
																	placeholder="Enter a otherCharges.."
																	name="otherCharges"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.otherCharges || ""}
																/>
																<div
																	id="val-otherCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																>
																	{errors.otherCharges && errors.otherCharges}
																</div>

																<div
																	id="val-otherCharges-error"
																	className="invalid-feedback animated fadeInUp"
																	style={{ display: "block" }}
																/>
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Other Charges Description
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-otherChargesDescription"
																	placeholder="Enter a other Charges Description.."
																	name="otherChargesDescription"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.otherChargesDescription || ""}
																/>
																{errors.otherChargesDescription &&
																touched.otherChargesDescription ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.otherChargesDescription}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Outstanding Amount
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-outStandingAmount"
																	placeholder="Enter a outStanding Amount.."
																	name="outStandingAmount"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.outStandingAmount || ""}
																/>
																{errors.outStandingAmount &&
																touched.outStandingAmount ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.outStandingAmount}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Tenant Name</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-tenantName"
																	placeholder="Enter a Tenant Name.."
																	name="tenantName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.tenantName || ""}
																/>
																{errors.tenantName && touched.tenantName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.tenantName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Tenant Contact Details
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-tenantContactDetails"
																	placeholder="Enter a Tenant Contact.."
																	name="tenantContactDetails"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.tenantContactDetails || ""}
																/>
																{errors.tenantContactDetails &&
																touched.tenantContactDetails ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.tenantContactDetails}
																	</div>
																) : null}
															</div>
														</div>
													</div> */}
													{/* <div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Lease Start Date
															</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="leaseStartDate"
																		format="dd/MM/yyyy"
																		value={values.leaseStartDate}
																		onChange={(e) =>
																			setFieldValue("leaseStartDate", e)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.leaseStartDate &&
																touched.leaseStartDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.leaseStartDate}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Lease End Date
															</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="leaseExpiryDate"
																		format="dd/MM/yyyy"
																		value={values.leaseExpiryDate}
																		onChange={(e) =>
																			setFieldValue("leaseExpiryDate", e)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.leaseExpiryDate &&
																touched.leaseExpiryDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.leaseExpiryDate}
																	</div>
																) : null}
															</div>
														</div>
													</div> */}
												</div>
												{/* <div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Ebill Subscribed
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-ebillSubscribed"
																	placeholder="Enter a Ebill Subscribed.."
																	name="ebillSubscribed"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.ebillSubscribed || ""}
																/>
																{errors.ebillSubscribed &&
																touched.ebillSubscribed ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.ebillSubscribed}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Is On Rent</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-isOnRent"
																	placeholder="Enter a Tenant Contact.."
																	name="isOnRent"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.isOnRent || ""}
																/>
																{errors.isOnRent && touched.isOnRent ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.isOnRent}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.ebillSubscribed === "true"}
																	className="form-check-input"
																	type="checkbox"
																	id="val-ebillSubscribed"
																	name="ebillSubscribed"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.ebillSubscribed}
																/>
																<label className="form-check-label">
																	Interested In Ebill
																</label>
															</div>
														</div>
													</div>
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.isOnRent === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-isOnRent"
																	name="isOnRent"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.isOnRent}
																/>
																<label className="form-check-label">
																	Is On Rent
																</label>
															</div>
														</div>
													</div> */}

												{/* <div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div> 
												</div>*/}
												{/* {values.isOnRent === true ? (
													<div className="row">
														<div className="col-xl-4 col-sm-12">
															<div className="form-group">
																<label className="text-label">
																	Tenant Name
																</label>
																<div>
																	<input
																		type="text"
																		className="form-control"
																		id="val-tenantName"
																		placeholder="Enter a Tenant Name.."
																		name="tenantName"
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.tenantName || ""}
																	/>
																	{errors.tenantName && touched.tenantName ? (
																		<div
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.tenantName}
																		</div>
																	) : null}
																</div>
															</div>
														</div>
														<div className="col-xl-4 col-sm-12">
															<div className="form-group">
																<label className="text-label">
																	Tenant Contact Details
																</label>
																<div>
																	<input
																		type="text"
																		className="form-control"
																		id="val-tenantContactDetails"
																		placeholder="Enter a Tenant Contact.."
																		name="tenantContactDetails"
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.tenantContactDetails || ""}
																	/>
																	{errors.tenantContactDetails &&
																	touched.tenantContactDetails ? (
																		<div
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.tenantContactDetails}
																		</div>
																	) : null}
																</div>
															</div>
														</div>
														<div className="col-xl-4 col-sm-12">
															<div className="form-group">
																<label className="text-label">
																	Lease Start Date
																</label>
																<div>
																	<MuiPickersUtilsProvider utils={DateFnsUtils}>
																		<DatePicker
																			id="leaseStartDate"
																			format="dd/MM/yyyy"
																			value={values.leaseStartDate}
																			onChange={(e) =>
																				setFieldValue("leaseStartDate", e)
																			}
																			onBlur={handleBlur}
																		/>
																	</MuiPickersUtilsProvider>
																	{errors.leaseStartDate &&
																	touched.leaseStartDate ? (
																		<div
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.leaseStartDate}
																		</div>
																	) : null}
																</div>
															</div>
														</div>
														<div className="col-xl-4 col-sm-12">
															<div className="form-group">
																<label className="text-label">
																	Lease End Date
																</label>
																<div>
																	<MuiPickersUtilsProvider utils={DateFnsUtils}>
																		<DatePicker
																			id="leaseExpiryDate"
																			format="dd/MM/yyyy"
																			value={values.leaseExpiryDate}
																			onChange={(e) =>
																				setFieldValue("leaseExpiryDate", e)
																			}
																			onBlur={handleBlur}
																		/>
																	</MuiPickersUtilsProvider>
																	{errors.leaseExpiryDate &&
																	touched.leaseExpiryDate ? (
																		<div
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.leaseExpiryDate}
																		</div>
																	) : null}
																</div>
															</div>
														</div>
													</div>
												) : null} */}

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddBillingHeader;
const mapStateToProps = (state) => {
	return {
		billingHeader: state.billingHeaderMapping?.billingHeader,
		societies: state.society?.societies,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getBillingHeaderAction,
			createBillingHeaderAction,
			updateBillingHeaderAction,
			getSocietiesAction,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddEditBillingHeaderMapping);
