import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class BarChart1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			paymentData: this.props.paymentDetails,
		};
	}

	render() {
		console.log("data", this.state.paymentData);

		var allMonths = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		const sortedData = this.state.paymentData?.sort(function(a, b) {
			return allMonths.indexOf(a) - allMonths.indexOf(b);
		});
		console.log("sortedData", sortedData);
		const months = sortedData.map((item) => item.month);
		console.log("months", months);
		const amount = sortedData.map((item) => item.amount);
		console.log("amount", amount);

		const data = {
			defaultFontFamily: "Poppins",
			labels: months,
			datasets: [
				{
					label: "Payment",
					data: amount,
					borderColor: "rgba(64, 24, 157, 1)",
					borderWidth: "0",
					backgroundColor: "#233C8E",
				},
				// {
				// 	label: "Outstanding",
				// 	data: [25, 59, 30, 11, 26, 35, 20, 10, 31, 26, 15, 30],
				// 	borderColor: "rgba(64, 24, 157, 1)",
				// 	borderWidth: "0",
				// 	backgroundColor: "#EC932F",
				// },
			],
		};

		const options = {
			legend: false,
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
						},
					},
				],
				xAxes: [
					{
						// Change here
						barPercentage: 0.5,
					},
				],
			},
		};

		return (
			<>
				<Bar data={data} height={150} options={options} />
			</>
		);
	}
}

export default BarChart1;
