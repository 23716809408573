import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getAdminUserAction,
	createAdminUserAction,
	updateAdminUserAction,
} from "../../../../../store/actions/AdminUserActions";
import { getSocietiesAction } from "../../../../../store/actions/SocietyActions";

class AddEditAdminUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			admin: {},
			societyId: "",
			role: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));
		this.setState({
			societyId: userData.user.societyName,
			role: userData.user.role,
		});

		const id = this.props.match.params.id;
		if (id) {
			this.props.getAdminUserAction(id);
		} else {
		}
		this.props.getSocietiesAction();
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.admin?.length !== this.props?.admin?.length) {
			this.setState({
				admin: this.props?.admin,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateAdminUserAction(
				values,
				this.props.history,
				this.props?.admin?._id
			);
		} else {
			this.props.createAdminUserAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;

		if (id) {
			var admin = this.props?.admin;
		} else {
			admin = "";
		}

		let society_data = this.props.societies?.societies;

		const initialValues = {
			societyName: admin ? admin.societyName?._id : "",
			userName: admin ? admin.userName : "",
			password: admin ? admin.password : "",

			role: admin ? admin.role : "admin",
		};

		const validationSchema = Yup.object().shape({
			societyName: Yup.string().required("Please Select Society Name."),
			userName: Yup.string()
				.max(100, "Max 100 Character")
				.required("Please Enter User Name."),
			password: Yup.string()
				.concat(!id ? Yup.string().required("Password is required") : null)
				.min(6, "Password must be at least 6 characters"),
		});

		return (
			<>
				<Breadcrumb>
					{this.state?.role !== "superAdmin" && (
						<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
							Dashboard
						</Breadcrumb.Item>
					)}
					<Breadcrumb.Item
						linkProps={{ to: "/superadmin/admin" }}
						linkAs={Link}
					>
						Admin User Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Admin User</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Admin User</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Admin</h3>
						) : (
							<h3 className="pt-3">Add Admin</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/superadmin/admin"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">User Name *</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-userName"
																	placeholder="Enter a Name.."
																	name="userName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.userName || ""}
																/>
																{errors.userName && touched.userName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.userName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society Name *
															</label>
															<div>
																<select
																	className="form-control"
																	id="val-societyName"
																	placeholder="Select a Society.."
																	name="societyName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyName || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	{society_data?.map((opt) => (
																		<option
																			key={opt._id}
																			value={opt._id}
																			label={opt.societyName}
																		/>
																	))}
																</select>
																{errors.societyName && touched.societyName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														{!id && (
															<div className="form-group">
																<label className="text-label">Password *</label>

																<div>
																	<input
																		type="password"
																		className="form-control"
																		id="val-password"
																		placeholder="Enter a password.."
																		name="password"
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.password || ""}
																	/>
																	{errors.password && touched.password ? (
																		<div
																			className="invalid-feedback animated fadeInUp"
																			style={{ display: "block" }}
																		>
																			{errors.password}
																		</div>
																	) : null}
																</div>
															</div>
														)}
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">Role</label>
															<div>
																<select
																	className="form-control"
																	id="val-role"
																	placeholder="Select a Role.."
																	name="role"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.role || "Select"}
																>
																	<option
																		value="Select"
																		label="Select"
																		disabled
																	/>
																	<option value="admin" label="Admin" />
																	<option value="security" label="Security" />
																</select>
															</div>
														</div>
													</div>
												</div>
												{/* <div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div> */}

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddAdmin;
const mapStateToProps = (state) => {
	return {
		admin: state.admin?.adminUser,
		societies: state.society?.societies,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getAdminUserAction,
			createAdminUserAction,
			updateAdminUserAction,
			getSocietiesAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAdminUser);
