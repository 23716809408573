import React, { Component } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import axiosInstance from "../../../../services/AxiosInstance";

import Geocode from "react-geocode";

const mapStyles = {
	width: "100%",
	height: "350px",
	marginBottom: "10px",
};

//Geocode.setApiKey("AIzaSyCb9k66nATQx9-my-0aI14Z7Bo_LZcyy6g");
Geocode.setApiKey("AIzaSyB0Jlh7lIWHjP8jaol1cxlRFfitczOOQMA");
Geocode.setLanguage("en");

export class MapContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			flatData: {},
			societyData: {},
			lat: "",
			long: "",
			showingInfoWindow: false,
			activeMarker: {},
			selectedPlace: {},
		};
		this.getLatLong = this.getLatLong.bind(this);
	}

	onMarkerClick = (props, marker, e) =>
		this.setState({
			selectedPlace: props,
			activeMarker: marker,
			showingInfoWindow: true,
		});

	onMapClicked = (props) => {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null,
			});
		}
	};

	async componentDidMount() {
		let flatData = JSON.parse(localStorage.getItem("userFlatDetails"));

		if (flatData) {
			const society = await axiosInstance
				.get(`admin/society/${flatData?.societyId}`)
				.then((res) => res);

			this.setState({
				societyData: society?.data?.society,
			});
			this.getLatLong(society?.data?.society);
		}
	}

	getLatLong(societyAddress) {
		// Get latitude & longitude from address.
		Geocode.fromAddress(societyAddress).then(
			(response) => {
				const { lat, lng } = response.results[0].geometry.location;

				this.setState({
					lat: lat,
					long: lng,
				});
			},
			(error) => {
				console.error(error);
			}
		);
	}

	render() {
		return (
			<>
				<Map
					google={this.props.google}
					zoom={14}
					style={mapStyles}
					initialCenter={{
						lat: 19.4516,
						lng: 72.7969,
						// lat: this.state?.lat,
						// lng: this.state?.long,
					}}
					className="mapContainerWrapper"
				>
					<style jsx="true">{`
						.mapContainerWrapper {
							position: relative !important;
						}
					`}</style>

					<Marker
						onClick={this.onMarkerClick}
						name={this.state.societyData.societyAddress}
					/>

					<InfoWindow
						marker={this.state.activeMarker}
						visible={this.state.showingInfoWindow}
					>
						<div>
							<h1>{this.state.selectedPlace.name}</h1>
						</div>
					</InfoWindow>
				</Map>
			</>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: "AIzaSyB0Jlh7lIWHjP8jaol1cxlRFfitczOOQMA",
})(MapContainer);
