import axiosInstance from "../services/AxiosInstance";

export function getBillingTemplateDetails(billingId) {
	return axiosInstance.get(`admin/billingTemplate/${billingId}`);
}

export function getBillingTemplates(societyId) {
	return axiosInstance.get(`admin/billingTemplates/${societyId}`);
}

export function createBillingTemplate(billingData) {
	return axiosInstance.post(`admin/billingTemplate`, billingData);
}

export function updateBillingTemplate(billing, billingId) {
	return axiosInstance.put(`admin/billingTemplate/${billingId}`, billing);
}

export function deleteBillingTemplate(billingId) {
	return axiosInstance.delete(`admin/billingTemplate/${billingId}`);
}
