import axiosInstance from "../services/AxiosInstance";

export function getFlatDetails(flatId) {
	return axiosInstance.get(`admin/flatById/${flatId}`);
}

export function getFlats(societyId) {
	return axiosInstance.get(`admin/flats/${societyId}`);
}

export function createFlat(flatData) {
	return axiosInstance.post(`admin/flat`, flatData);
}

export function updateFlat(flat, flatId) {
	return axiosInstance.put(`admin/flat/${flatId}`, flat);
}

export function deleteFlat(flatId) {
	return axiosInstance.delete(`admin/flat/${flatId}`);
}

export function getFlatBySocietyUser(societyId, userId) {
	return axiosInstance.get(`admin/flats/${societyId}/${userId}`);
}

export function updateFlatAssociation(flat, flatId) {
	return axiosInstance.put(`admin/flat/flatassociation/${flatId}`, flat);
}

export function uploadFlat(flatData) {
	return axiosInstance.post(`admin/flat/upload`, flatData);
}
