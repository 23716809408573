import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

import { getSocietyAction } from "../../../../store/actions/SocietyActions";

class SocietyContactDetail extends Component {
	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));

		if (this.props.contact) {
			this.props.getSocietyAction(userData.user.societyName);
		}
	}

	render() {
		let id = this.props.match.params.id;

		const contact = this.props?.contact?.society?.contactInfo.filter(
			(item) => item._id === id
		);

		const contactName = contact[0]?.contactName || "";
		const mobile = contact[0]?.mobile || "";
		const email = contact[0]?.email || "";
		const position = contact[0]?.position || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item
						linkProps={{ to: "/society-contacts" }}
						linkAs={Link}
					>
						Society Contact Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Society Contact Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Society Contact Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/society-contacts"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Contact Name</strong>
										<span className="mb-0">{contactName}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Mobile</strong>
										<span className="mb-0 lineBreak">{mobile}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Email</strong>
										<span className="mb-0 lineBreak">{email}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Position</strong>
										<span className="mb-0 lineBreak">{position}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		contact: state.society?.society,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getSocietyAction }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SocietyContactDetail);
