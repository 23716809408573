import {
	getAmcDetails,
	getAmcs,
	createAmc,
	updateAmc,
	deleteAmc,
} from "../../services/AmcService";
import {
	CONFIRMED_GET_AMC_DETAILS,
	CONFIRMED_CREATE_AMC_ACTION,
	CONFIRMED_GET_AMCS,
	CONFIRMED_EDIT_AMC_ACTION,
	CONFIRMED_DELETE_AMC_ACTION,
} from "./AmcTypes";

export function getAmcAction(amcId) {
	return (dispatch, getState) => {
		getAmcDetails(amcId).then((response) => {
			dispatch(confirmedGetAmcAction(response.data.amc));
		});
	};
}

export function confirmedGetAmcAction(amc) {
	return {
		type: CONFIRMED_GET_AMC_DETAILS,
		payload: amc,
	};
}

export function deleteAmcAction(amcId, history) {
	return (dispatch, getState) => {
		deleteAmc(amcId).then((response) => {
			dispatch(confirmedDeleteAmcAction(amcId));
			history.push("/amc");
		});
	};
}

export function confirmedDeleteAmcAction(amcId) {
	return {
		type: CONFIRMED_DELETE_AMC_ACTION,
		payload: amcId,
	};
}

export function createAmcAction(AmcData, history) {
	return (dispatch, getState) => {
		createAmc(AmcData).then((response) => {
			const singleAmc = {
				...AmcData,
				id: response.data._id,
			};
			dispatch(confirmedCreateAmcAction(singleAmc));
			history.push("/amc");
		});
	};
}

export function getAmcsAction(societyId) {
	return (dispatch, getState) => {
		getAmcs(societyId).then((response) => {
			dispatch(confirmedGetAmcsAction(response.data));
		});
	};
}

export function confirmedGetAmcsAction(Amcs) {
	return {
		type: CONFIRMED_GET_AMCS,
		payload: Amcs,
	};
}

export function confirmedCreateAmcAction(singleAmc) {
	return {
		type: CONFIRMED_CREATE_AMC_ACTION,
		payload: singleAmc,
	};
}

export function confirmedUpdateAmcAction(Amc) {
	return {
		type: CONFIRMED_EDIT_AMC_ACTION,
		payload: Amc,
	};
}

export function updateAmcAction(Amc, history, amcId) {
	return (dispatch, getState) => {
		updateAmc(Amc, amcId).then((reponse) => {
			dispatch(confirmedUpdateAmcAction(Amc));
			history.push("/amc");
		});
	};
}
