import {
	CONFIRMED_GET_BILLINGHEADER_DETAILS,
	CONFIRMED_CREATE_BILLINGHEADER_ACTION,
	CONFIRMED_GET_BILLINGHEADERS,
	CONFIRMED_EDIT_BILLINGHEADER_ACTION,
	CONFIRMED_DELETE_BILLINGHEADER_ACTION,
	LOADING,
} from "../actions/BillingHeaderMappingTypes";

const initialState = {
	billingHeaders: [],
	billingHeader: {},
};

export default function BillingHeaderMappingReducer(
	state = initialState,
	actions
) {
	if (actions.type === CONFIRMED_GET_BILLINGHEADER_DETAILS) {
		return {
			...state,
			billingHeader: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_BILLINGHEADER_ACTION) {
		const billingHeaders = [...state.billingHeaders.labelMapping];
		const billingHeaderIndex = billingHeaders.findIndex(
			(billingHeader) => billingHeader.id === actions.payload
		);

		billingHeaders.splice(billingHeaderIndex, 1);

		const labelMapping = billingHeaders;

		return {
			...state,
			billingHeaders: { labelMapping },
		};
	}

	if (actions.type === CONFIRMED_EDIT_BILLINGHEADER_ACTION) {
		return {
			...state,
			billingHeaders: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_BILLINGHEADER_ACTION) {
		return {
			...state,
			billingHeaders: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_BILLINGHEADERS) {
		return {
			...state,
			billingHeaders: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
