import {
	getUserDetails,
	getUsers,
	createUser,
	updateUser,
	deleteUser,
	updatePassword,
	uploadUser,
} from "../../services/UserService";
import {
	CONFIRMED_GET_USER_DETAILS,
	CONFIRMED_CREATE_USER_ACTION,
	CONFIRMED_GET_USERS,
	CONFIRMED_EDIT_USER_ACTION,
	CONFIRMED_DELETE_USER_ACTION,
	CONFIRMED_UPDATE_PASSWORD_ACTION,
	CONFIRMED_UPLOAD_USER_ACTION,
	LOADING,
} from "./UserTypes";

import { toast } from "react-toastify"; //ToastContainer
import "react-toastify/dist/ReactToastify.css";

export function getUserAction(typeId) {
	return (dispatch, getState) => {
		getUserDetails(typeId).then((response) => {
			dispatch(confirmedGetUserAction(response.data.user));
		});
	};
}

export function confirmedGetUserAction(user) {
	return {
		type: CONFIRMED_GET_USER_DETAILS,
		payload: user,
	};
}

export function deleteUserAction(typeId, history) {
	return (dispatch, getState) => {
		deleteUser(typeId).then((response) => {
			dispatch(confirmedDeleteUserAction(typeId));
			history.push("/users");
		});
	};
}

export function confirmedDeleteUserAction(typeId) {
	return {
		type: CONFIRMED_DELETE_USER_ACTION,
		payload: typeId,
	};
}

export function createUserAction(UserData, history) {
	return (dispatch, getState) => {
		createUser(UserData).then((response) => {
			const singleUser = {
				...UserData,
				id: response.data._id,
			};
			dispatch(confirmedCreateUserAction(singleUser));
			history.push("/users");
		});
	};
}

export function getUsersAction(societyId) {
	return (dispatch, getState) => {
		dispatch({ type: LOADING });
		getUsers(societyId).then((response) => {
			dispatch(confirmedGetUsersAction(response.data));
		});
	};
}

export function confirmedCreateUserAction(singleUser) {
	return {
		type: CONFIRMED_CREATE_USER_ACTION,
		payload: singleUser,
	};
}

export function confirmedGetUsersAction(Users) {
	return {
		type: CONFIRMED_GET_USERS,
		payload: Users,
	};
}

export function confirmedUpdateUserAction(User) {
	return {
		type: CONFIRMED_EDIT_USER_ACTION,
		payload: User,
	};
}

export function updateUserAction(User, history, typeId) {
	return (dispatch, getState) => {
		updateUser(User, typeId).then((reponse) => {
			dispatch(confirmedUpdateUserAction(User));
			history.push("/users");
		});
	};
}

export function updatePasswordAction(User, history, userId) {
	return (dispatch, getState) => {
		updatePassword(User, userId)
			.then((response) => {
				dispatch(confirmedUpdatePasswordAction(User));
				if (response.status === 200) {
					toast.success("Password is changed successfully!");
					history.push("/login");
					localStorage.removeItem("userDetails");
					window.location.reload();
				} else {
					toast.error(response.errMessage);
				}
			})
			.catch((error) => {
				toast.error(error?.response?.data?.errMessage);
			});
	};
}

export function confirmedUpdatePasswordAction(User) {
	return {
		type: CONFIRMED_UPDATE_PASSWORD_ACTION,
		payload: User,
	};
}

export function uploadUserAction(UserData, history) {
	return (dispatch, getState) => {
		uploadUser(UserData)
			.then((response) => {
				const singleUser = {
					...UserData,
					id: response.data._id,
				};
				dispatch(confirmedUploadUserAction(singleUser));
				toast.success(response?.data?.message);
				history.push("/users");
			})
			.catch((error) => {
				console.log(error.response);
				toast.error(error?.response?.data?.error);
			});
	};
}

export function confirmedUploadUserAction(singleUser) {
	return {
		type: CONFIRMED_UPLOAD_USER_ACTION,
		payload: singleUser,
	};
}
