import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class BarChart2 extends Component {
	constructor(props) {
		super(props);

		this.state = {
			financialYearPayments: this.props.financialYearPayments,
			financialYearBillings: this.props.financialYearBillings,
		};
	}

	render() {
		var allMonths = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		const sortedDataPayments = this.state.financialYearPayments?.sort(function(
			a,
			b
		) {
			return allMonths.indexOf(a) - allMonths.indexOf(b);
		});

		const months = sortedDataPayments.map((item) => item?._id?.month);

		const amount = sortedDataPayments.map((item) => item.totalAmount);

		const sortedDataBillings = this.state.financialYearBillings?.sort(function(
			a,
			b
		) {
			if (a?._id?.year !== b?._id?.year) {
				return a?._id?.year - b?._id?.year;
			} else {
				return (
					allMonths.indexOf(a?._id?.month) - allMonths.indexOf(b?._id?.month)
				);
			}
			//return allMonths.indexOf(a) - allMonths.indexOf(b);
		});

		const billingMonths = sortedDataBillings.map((item) => item?._id?.month);
		const billingAmount = sortedDataBillings.map(
			(item) => item?.data[0]?.totalAmount
		);

		const data = {
			defaultFontFamily: "Poppins",
			labels: billingMonths, //allMonths,
			datasets: [
				{
					label: "Payments",
					data: amount,
					borderColor: "rgba(64, 24, 157, 1)",
					borderWidth: "0",
					backgroundColor: "#233C8E",
				},
				{
					label: "Billings",
					data: billingAmount,
					borderColor: "rgba(64, 24, 157, 1)",
					borderWidth: "0",
					backgroundColor: "#EC932F",
				},
			],
		};

		const options = {
			legend: {
				display: true,
			},
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
						},
					},
				],
				xAxes: [
					{
						// Change here
						barPercentage: 0.5,
					},
				],
			},
		};

		return (
			<>
				<Bar data={data} height={150} options={options} />
			</>
		);
	}
}

export default BarChart2;
