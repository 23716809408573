export const GET_BILLINGHEADER_DETAILS =
	"[Billing Header Action] Get Billing Header";
export const CONFIRMED_GET_BILLINGHEADER_DETAILS =
	"[Billing Header Action] Confirmed Get Billing header";
export const CREATE_BILLINGHEADER_ACTION =
	"[Billing Header Action] Create Billing Header";
export const CONFIRMED_CREATE_BILLINGHEADER_ACTION =
	"[Billing Header Action] Confirmed Create Billing Header";
export const GET_BILLINGAHEADERS =
	"[Billing Headers Action] Get Billing Headers";
export const CONFIRMED_GET_BILLINGHEADERS =
	"[Billing Headers Action] Confirmed Get Billing Headers";
export const EDIT_BILLINGHEADER_ACTION =
	"[Billing Header Action] Edit Billing Header";
export const CONFIRMED_EDIT_BILLINGHEADER_ACTION =
	"[Billing Header Action] Confirmed Edit Billing Header";
export const CONFIRMED_DELETE_BILLINGHEADER_ACTION =
	"[Billing Header Action] Confirmed Delete Billing Header";
export const LOADING =
	"[Billing Header Loading] Confirmed Billing Header Loading";
