import { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Formik } from "formik";
import * as Yup from "yup";

import {
	getSocietyAction,
	createSocietyAction,
	updateSocietyAction,
} from "../../../../../store/actions/SocietyActions";

class AddEditSociety extends Component {
	constructor(props) {
		super(props);
		this.state = {
			society: {},
			// societyId: "",
		};

		this.handleAdd = this.handleAdd.bind(this);
	}

	componentDidMount() {
		// let userData = JSON.parse(localStorage.getItem("userDetails"));
		// this.setState({
		// 	societyId: userData.user.societyName,
		// });

		const id = this.props.match.params.id;
		if (id) {
			if (this.props.society) {
				this.props.getSocietyAction(id);
			}
		} else {
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.society?.society?.length !==
			this.props?.society?.society?.length
		) {
			this.setState({
				society: this.props?.society?.society,
			});
		}
	}

	handleAdd = (values) => {
		const id = this.props.match.params.id;
		if (id) {
			this.props.updateSocietyAction(
				values,
				this.props.history,
				this.props?.society?.society?._id
			);
		} else {
			this.props.createSocietyAction(values, this.props.history);
		}
	};

	render() {
		const id = this.props.match.params.id;
		if (id) {
			var society = this.props?.society?.society;
		} else {
			society = "";
		}

		const initialValues = {
			societyName: society ? society.societyName : "",
			societyAddress: society ? society.societyAddress : "",
			societyUrl: society ? society.societyUrl : "",
			societyEmail: society ? society.societyEmail : "",
			societyRegistrationNo: society ? society.societyRegistrationNo : "",
			societyMembersCount: society ? society.societyMembersCount : "",
			societyActivationYear: society ? society.societyActivationYear : "",
			societyActivationMonth: society ? society.societyActivationMonth : "",
			societySubscriptionStartDate: society
				? society.societySubscriptionStartDate
				: new Date(),
			societySubscriptionEndDate: society
				? society.societySubscriptionEndDate
				: new Date(),
			status: society ? society.status : true,
		};

		const validationSchema = Yup.object().shape({
			societyName: Yup.string()
				.max(1000, "Society Name should be of max 1000 character")
				.required("Please Enter Title."),
			societyAddress: Yup.string().required("Please Enter Address."),
			societyUrl: Yup.string().required("Please Enter Society URL."),
			societyMembersCount: Yup.string().required("Please Enter members count."),
			societyActivationYear: Yup.string().required(
				"Please Enter Society Activation Year."
			),
			societyActivationMonth: Yup.string().required(
				"Please Select Society Activation Month."
			),
			societyEmail: Yup.string().email("Enter valid Email"),
		});

		return (
			<>
				<Breadcrumb>
					{/* <Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "} */}
					<Breadcrumb.Item
						linkProps={{ to: "/superadmin/societies" }}
						linkAs={Link}
					>
						Society Lists
					</Breadcrumb.Item>{" "}
					{id !== undefined ? (
						<Breadcrumb.Item active>Edit Society</Breadcrumb.Item>
					) : (
						<Breadcrumb.Item active>Add Society</Breadcrumb.Item>
					)}
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						{id !== undefined ? (
							<h3 className="pt-3">Edit Society</h3>
						) : (
							<h3 className="pt-3">Add Society</h3>
						)}
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/superadmin/societies"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="form-validation">
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										enableReinitialize={true}
										onSubmit={(values, { setSubmitting }) => {
											setTimeout(() => {
												//alert(JSON.stringify(values, null, 2));
												this.handleAdd(values);
												setSubmitting(false);
											}, 400);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society Name *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-societyName"
																	placeholder="Enter a Society Name.."
																	name="societyName"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyName || ""}
																/>
																{errors.societyName && touched.societyName ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyName}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society Address *
															</label>
															<div>
																<textarea
																	type="text"
																	className="form-control"
																	id="val-societyAddress"
																	placeholder="Enter a society Address.."
																	name="societyAddress"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyAddress || ""}
																	rows="5"
																></textarea>
																{errors.societyAddress &&
																touched.societyAddress ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyAddress}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society URL *
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-societyUrl"
																	placeholder="Enter a Society URL.."
																	name="societyUrl"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyUrl || ""}
																/>
																{errors.societyUrl && touched.societyUrl ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyUrl}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society Email
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-societyEmail"
																	placeholder="Enter a Society Email.."
																	name="societyEmail"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyEmail || ""}
																/>
																{errors.societyEmail && touched.societyEmail ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyEmail}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society Registration No.
															</label>
															<div>
																<input
																	type="text"
																	className="form-control"
																	id="val-societyRegistrationNo"
																	placeholder="Enter a Society Registration no.."
																	name="societyRegistrationNo"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyRegistrationNo || ""}
																/>
																{errors.societyRegistrationNo &&
																touched.societyRegistrationNo ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyRegistrationNo}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society Members Count *
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-societyMembersCount"
																	placeholder="Enter a Society Member Count.."
																	name="societyMembersCount"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyMembersCount || ""}
																/>
																{errors.societyMembersCount &&
																touched.societyMembersCount ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyMembersCount}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society Activation Year *
															</label>
															<div>
																<input
																	type="number"
																	className="form-control"
																	id="val-societyActivationYear"
																	placeholder="Enter a Society Activation Year.."
																	name="societyActivationYear"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.societyActivationYear || ""}
																/>
																{errors.societyActivationYear &&
																touched.societyActivationYear ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyActivationYear}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Society Activation Month *
															</label>
															<div>
																<select
																	className="form-control"
																	id="societyActivationMonth"
																	placeholder="Select a Select Month.."
																	name="societyActivationMonth"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={
																		values.societyActivationMonth || "Select"
																	}
																>
																	<option
																		value="Select Month"
																		label="Select Month"
																		disabled
																	/>
																	<option value="January" label="January" />
																	<option value="February" label="February" />
																	<option value="March" label="March" />
																	<option value="April" label="April" />
																	<option value="May" label="May" />
																	<option value="June" label="June" />
																	<option value="July" label="July" />
																	<option value="August" label="August" />
																	<option value="September" label="September" />
																	<option value="October" label="October" />
																	<option value="November" label="November" />
																	<option value="December" label="December" />
																</select>
																{errors.societyActivationMonth &&
																touched.societyActivationMonth ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societyActivationMonth}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Subscription Start Date
															</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="societySubscriptionStartDate"
																		format="dd/MM/yyyy"
																		value={values.societySubscriptionStartDate}
																		onChange={(e) =>
																			setFieldValue(
																				"societySubscriptionStartDate",
																				e
																			)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.societySubscriptionStartDate &&
																touched.societySubscriptionStartDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societySubscriptionStartDate}
																	</div>
																) : null}
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-sm-12">
														<div className="form-group">
															<label className="text-label">
																Subscription End Date
															</label>
															<div>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<DatePicker
																		id="societySubscriptionEndDate"
																		format="dd/MM/yyyy"
																		value={values.societySubscriptionEndDate}
																		onChange={(e) =>
																			setFieldValue(
																				"societySubscriptionEndDate",
																				e
																			)
																		}
																		onBlur={handleBlur}
																	/>
																</MuiPickersUtilsProvider>
																{errors.societySubscriptionEndDate &&
																touched.societySubscriptionEndDate ? (
																	<div
																		className="invalid-feedback animated fadeInUp"
																		style={{ display: "block" }}
																	>
																		{errors.societySubscriptionEndDate}
																	</div>
																) : null}
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xl-3 col-sm-12">
														<div className="form-group">
															<div className="form-check">
																<input
																	checked={values.status === true}
																	className="form-check-input"
																	type="checkbox"
																	id="val-status"
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.status}
																/>
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
													</div>
												</div>

												<button
													type="submit"
													className="btn mr-2 btn-primary"
													disabled={isSubmitting}
												>
													Save
												</button>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

//export default AddSociety;
const mapStateToProps = (state) => {
	return {
		society: state.society?.society,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getSocietyAction,
			createSocietyAction,
			updateSocietyAction,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSociety);
