export const GET_FLAT_DETAILS = "[Flat Action] Get Flat";
export const CONFIRMED_GET_FLAT_DETAILS = "[Flat Action] Confirmed Get Flat";
export const CREATE_FLAT_ACTION = "[Flat Action] Create Flat";
export const CONFIRMED_CREATE_FLAT_ACTION =
	"[Flat Action] Confirmed Create Flat";
export const GET_FLATS = "[Flat Action] Get Flats";
export const CONFIRMED_GET_FLATS = "[Flat Action] Confirmed Get Flats";
export const EDIT_FLAT_ACTION = "[Flat Action] Edit Flat";
export const CONFIRMED_EDIT_FLAT_ACTION = "[Flat Action] Confirmed Edit Flat";
export const CONFIRMED_DELETE_FLAT_ACTION =
	"[Flat Action] Confirmed Delete Flat";
export const CONFIRMED_GET_FLAT_SOCIETY_USER_DETAILS =
	"[Flat By Society and User Action] Confirmed Get Flat By Society and User";
export const EDIT_FLAT_ASSOCIATION_ACTION =
	"[Flat Association Action] Edit Flat Association";
export const CONFIRMED_EDIT_FLAT_ASSOCIATION_ACTION =
	"[Flat Association Action] Confirmed Edit Flat Association";
export const UPLOAD_FLAT_ACTION = "[Flat Action] Upload Flat";
export const CONFIRMED_UPLOAD_FLAT_ACTION =
	"[Flat Action] Confirmed Upload Flat";
export const LOADING = "[Flat Loading Action] Confirmed Loading Action";
