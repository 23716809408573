import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import swal from "sweetalert";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
	getVendorsAction,
	deleteVendorAction,
} from "../../../../store/actions/VendorActions";

const loaderStyle = {
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	zIndex: 1,
};

class Vendor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: "",
			currentVendors: [],
			currentPage: null,
			totalPages: null,
		};
	}

	componentDidMount() {
		let userData = JSON.parse(localStorage.getItem("userDetails"));

		this.props.getVendorsAction(userData.user.societyName);
		this.initData();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.vendors?.vendors?.length !==
			this.props?.vendors?.vendors?.length
		) {
			this.setState({
				currentVendors: this.props?.vendors?.vendors,
			});
		}
	}

	initData() {
		let currentVendors =
			this.state?.currentVendors?.length > 0
				? this.state?.currentVendors
				: this.props?.vendors?.vendors;

		this.setState({ currentVendors });
	}

	onPageChanged = (data) => {
		const allVendors = this.props?.vendors?.vendors;

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		var currentVendors = allVendors?.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentVendors, totalPages });
	};

	handleSearch(event) {
		let currentVendors = this.props.vendors?.vendors;
		// Get event value
		let searchValue = event.target.value;

		// Set the state to trigger a re-rendering
		this.setState({ search: searchValue });

		let searchString = searchValue.trim().toLowerCase();
		if (searchString.length > 0) {
			// We are searching. Filter the results.
			currentVendors = currentVendors.filter(
				(e) =>
					e.vendorType.toLowerCase().match(searchString) ||
					e.vendorName.toLowerCase().match(searchString) ||
					e.vendorEmail.toLowerCase().match(searchString) ||
					e.vendorMobile.toLowerCase().match(searchString)
			);
			this.setState({ currentVendors });
		} else {
			let arrayLength = currentVendors.slice(0, 5);
			this.setState({ currentVendors: arrayLength });
		}
		//this.setState({ currentVendors });
	}

	deleteRecord(id) {
		//this.props.deleteVendorAction(id, this.props.history);
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this record ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((response) => {
			if (response) {
				this.props.deleteVendorAction(id, this.props.history);
				swal("Poof! Your file has been deleted!", {
					icon: "success",
				});
			} else {
				swal("Your file is safe!");
			}
		});
	}

	render() {
		let totalVendors = this.props?.vendors?.vendors?.length || 0;

		const { currentVendors, currentPage, totalPages } = this.state;
		this.state.currentVendors = this.props.vendors?.vendors;

		return (
			<>
				{this.props?.loading ? (
					<div style={loaderStyle}>
						<Loader
							type="Oval"
							color="#233C8E"
							secondaryColor="#d5a72f"
							height="100"
							width="100"
							timeout={3000}
						/>
					</div>
				) : null}
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Vendor Lists</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="col-xl-4 col-sm-12 pl-0">
									<h2 className="card-title">Vendors</h2>
								</div>

								<div className="col-xl-4 col-sm-12">
									<input
										type="text"
										className="form-control mb-2 search-list pull-right"
										placeholder="Search..."
										onChange={(e) => this.handleSearch(e)}
									/>
								</div>
								<div className="col-xl-4 col-sm-12">
									<Link
										to={"add-vendor"}
										className="btn btn-primary mb-2 pull-right"
									>
										Add Vendor
									</Link>
								</div>
							</div>

							<div className="card-body pb-0">
								<Table bordered striped responsive>
									<thead>
										<tr>
											<th>Vendor Type</th>
											<th>Vendor Name</th>
											<th>Vendor Mobile</th>
											<th>Vendor Email</th>
											<th>Description</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentVendors?.length
											? currentVendors.map((vendor, i) => (
													<tr key={i}>
														<td>{vendor.vendorType} </td>
														<td>{vendor.vendorName}</td>
														<td>{vendor.vendorMobile}</td>
														<td>{vendor.vendorEmail}</td>
														<td>{vendor.vendorDescription}</td>
														<td>
															<div className="d-flex">
																<Link
																	to={`add-vendor/${vendor._id}`}
																	className="btn btn-info shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-pencil"></i>
																</Link>
																<Link
																	to={`vendor-details/${vendor._id}`}
																	className="btn btn-primary shadow btn-xs sharp mr-1"
																>
																	<i className="fa fa-th"></i>
																</Link>
																<Link
																	to="#"
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={this.deleteRecord.bind(
																		this,
																		vendor._id
																	)}
																>
																	<i className="fa fa-trash"></i>
																</Link>
															</div>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<div className="d-flex flex-row py-4 pull-left">
									{currentPage && (
										<span className="current-page d-inline-block h-100 pl-4 text-secondary">
											Page{" "}
											<span className="font-weight-bold">{currentPage}</span> /{" "}
											<span className="font-weight-bold">{totalPages}</span>
										</span>
									)}
								</div>
								<div className="d-flex flex-row py-4 pull-right">
									<label className="p-2">Page:</label>
									{totalVendors ? (
										<Pagination
											totalRecords={totalVendors}
											pageLimit={5}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		vendors: state.vendor?.vendors,
		loading: state?.vendor?.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getVendorsAction, deleteVendorAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Vendor);
