import {
	CONFIRMED_GET_BILLINGADJUSTMENT_DETAILS,
	CONFIRMED_CREATE_BILLINGADJUSTMENT_ACTION,
	CONFIRMED_GET_BILLINGADJUSTMENTS,
	CONFIRMED_EDIT_BILLINGADJUSTMENT_ACTION,
	CONFIRMED_DELETE_BILLINGADJUSTMENT_ACTION,
	CONFIRMED_GET_BILLINGADJUSTMENTSBYFLAT,
	LOADING,
} from "../actions/BillingAdjustmentTypes";

const initialState = {
	billingAdjustments: [],
	billingAdjustment: {},
};

export default function BillingAdjustmentReducer(
	state = initialState,
	actions
) {
	if (actions.type === CONFIRMED_GET_BILLINGADJUSTMENT_DETAILS) {
		return {
			...state,
			billingAdjustment: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_BILLINGADJUSTMENT_ACTION) {
		const billingAdjustments = [...state.billingAdjustments.billAdjustments];
		const billingAdjustmentIndex = billingAdjustments.findIndex(
			(billingAdjustment) => billingAdjustment.id === actions.payload
		);

		billingAdjustments.splice(billingAdjustmentIndex, 1);

		const billAdjustments = billingAdjustments;

		return {
			...state,
			billingAdjustments: { billAdjustments },
		};
	}

	if (actions.type === CONFIRMED_EDIT_BILLINGADJUSTMENT_ACTION) {
		return {
			...state,
			billingAdjustments: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_BILLINGADJUSTMENT_ACTION) {
		return {
			...state,
			billingAdjustments: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_BILLINGADJUSTMENTS) {
		return {
			...state,
			billingAdjustments: actions.payload,
			loading: false,
		};
	}

	if (actions.type === CONFIRMED_GET_BILLINGADJUSTMENTSBYFLAT) {
		return {
			...state,
			billingAdjustments: actions.payload,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
