import {
	CONFIRMED_GET_POLLING_DETAILS,
	CONFIRMED_CREATE_POLLING_ACTION,
	CONFIRMED_GET_POLLINGS,
	CONFIRMED_EDIT_POLLING_ACTION,
	CONFIRMED_DELETE_POLLING_ACTION,
	LOADING,
} from "../actions/PollingTypes";

const initialState = {
	pollings: [],
	polling: {},
};

export default function PollingReducer(state = initialState, actions) {
	if (actions.type === CONFIRMED_GET_POLLING_DETAILS) {
		return {
			...state,
			polling: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_DELETE_POLLING_ACTION) {
		const pollings = [...state.pollings.polls];
		const pollingIndex = pollings.findIndex(
			(polling) => polling.id === actions.payload
		);

		pollings.splice(pollingIndex, 1);

		const polls = pollings;

		return {
			...state,
			pollings: { polls },
		};
	}

	if (actions.type === CONFIRMED_EDIT_POLLING_ACTION) {
		return {
			...state,
			pollings: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_CREATE_POLLING_ACTION) {
		return {
			...state,
			pollings: actions.payload,
		};
	}

	if (actions.type === CONFIRMED_GET_POLLINGS) {
		return {
			...state,
			pollings: actions.payload,
			loading: false,
		};
	}

	if (actions.type === LOADING) {
		return {
			...state,
			loading: true,
		};
	}

	return state;
}
