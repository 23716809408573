import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
//import { format } from "date-fns";

import { getBillingTemplateAction } from "../../../../store/actions/BillingTemplateActions";

class BillingTemplateDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			billing: {},
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		if (this.props.billing) {
			this.props.getBillingTemplateAction(id);
		}
		this.init();
	}

	init() {
		this.state.billing = this.props?.billing;
	}

	render() {
		this.state.billing = this.props?.billing;

		const templateType = this.state.billing?.templateType || "";
		const templateSubType = this.state.billing?.templateSubType || "";
		const template = this.state.billing?.template || "";

		return (
			<>
				<Breadcrumb>
					<Breadcrumb.Item linkProps={{ to: "/dashboard" }} linkAs={Link}>
						Dashboard
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item
						linkProps={{ to: "/billing-templates" }}
						linkAs={Link}
					>
						Billing Template Lists
					</Breadcrumb.Item>{" "}
					<Breadcrumb.Item active>Billing Template Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className="row">
					<div className="col-md-6  col-sm-12">
						<h3 className="pt-3">Billing Template Details</h3>
					</div>
					<div className="col-md-6 col-sm-12">
						<Link
							to={"/billing-templates"}
							className="btn btn-primary mb-2 pull-right"
						>
							Back to Lists
						</Link>
					</div>

					<div className="col-xl-12 col-lg-12 col-sm-12">
						<div className="card">
							<div className="card-body pb-0">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Template Type</strong>
										<span className="mb-0">{templateType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Template Sub Type</strong>
										<span className="mb-0">{templateSubType}</span>
									</li>
									<li className="list-group-item d-flex px-0 justify-content-between">
										<strong>Template</strong>
										<span className="mb-0">{template}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		billing: state.billing?.billing,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getBillingTemplateAction }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BillingTemplateDetail);
